import { Controller } from 'stimulus'
# dependency jQuery

export default class extends Controller
  @targets: ['form']

  # native: use native methods to submit a form
  # jQuery or jquery: use $.submit to submit a form
  @property 'method',
    get: -> @data.get('method') or 'native'

  # set data-*-now="true" to submit immediately on initialization
  @property 'now',
    get: -> @data.get('now') is 'true'

  initialize: ->
    @target = if @hasFormTarget
      @formTarget
    else
      @element

    @submit() if @now

  submit: ->
    if @method is 'jquery' or @method is 'jQuery'
      $(@target).submit()

    else # native
      if @target.requestSubmit
        @target.requestSubmit()
      else
        @target.submit()
