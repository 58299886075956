import BaseController from "./base_controller"

export default class extends BaseController
  initialize: ->
    @_configureLocationFieldAutocomplete()

  toggleFilters: ->
    return unless @hasFiltersTarget
    @$filtersTarget = $(@filtersTarget)

    # NOTE: aria-expanded probably should be specified on the button,
    # not on the top level container.
    if @element.getAttribute('aria-expanded') is 'true'
      @$filtersTarget.slideUp('fast')
      @element.setAttribute('aria-expanded', 'false')

    else
      @$filtersTarget.slideDown('fast')
      @element.setAttribute('aria-expanded', 'true')
