import { Controller } from 'stimulus'

# Delegate to App.Views.Events.ExhibitorItemFieldsView.
# This will be a simple change to make it function with Stimulus directly.
export default class extends Controller
  @targets: []

  initialize: ->
    new App.Views.Events.ExhibitorItemFieldsView
      el: @element
      model: new App.Models.EventExhibitorPackage($(@element).data('model'))