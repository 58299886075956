import { Controller } from "stimulus"
import _map from "lodash/map"
import Sortable from "sortablejs"
# dependency: jQuery

export default class extends Controller
  @targets: [
    'mainListColumn',
      'mainListContainer',
        'mainList',
          'originalTestimonial',
    
    'placementsListColumn',
      'placementSelect',
      'placementListContainer',
        'placementListCount',
        'placementList',
          'placedTestimonial'
  ]
  
  @property 'sortableInitialized',
    get: -> @data.get('sortableInitialized') is 'true'
    set: (value) -> @data.set('sortableInitialized', value)
  
  @property 'inPlacementMode',
    get: -> @data.get('inPlacementMode') is 'true'
    set: (value) -> @data.set('inPlacementMode', value)
  
  render: ->
    # Grab the selected sections
    selectedTestimonialListOptions = @placementSelectTarget.selectedOptions
    selectedTestimonialListKeys = _map selectedTestimonialListOptions, (option) -> option.value
    
    # If any sections are selected, hide all the others. If none are selected,
    # show them all.
    if selectedTestimonialListKeys.length
      @placementListContainerTargets.forEach (containerTarget) =>
        containerTarget.classList.toggle 'hidden', !(containerTarget.dataset.key in selectedTestimonialListKeys)
    else
      @placementListContainerTargets.forEach (containerTarget) =>
        containerTarget.classList.remove 'hidden'
    
    # Update the visible count of testimonials for each placement list
    @placementListContainerTargets.forEach (containerTarget) =>
      listCountContainer = containerTarget.querySelector('.testimonials-list-count')
      testimonialsCount = containerTarget.querySelectorAll('.testimonials-list__row').length
      listCountContainer.innerText = "(#{testimonialsCount})"
  
  togglePlacement: (event) ->
    event.preventDefault()
    
    if @inPlacementMode
      @inPlacementMode = 'false'
      
      @mainListColumnTarget.classList = 'col-xs-12'
      @mainListContainerTarget.classList.remove('testimonials-list--placement-mode')
      @placementsListColumnTarget.classList.add('hidden')
      
    else
      @inPlacementMode = 'true'
      @initializeSortables() unless @sortableInitialized
      @render()
      
      @mainListColumnTarget.classList = 'col-xs-6'
      @mainListContainerTarget.classList.add('testimonials-list--placement-mode')
      @placementsListColumnTarget.classList.remove('hidden')
  
  initializeSortables: ->
    @sortableInitialized = 'true'
    
    ###
      Set up a sortable for @mainListTarget that does not allow sorting its
      own items, but allowing items to be pulled and cloned from the list.
    ###
    new Sortable @mainListTarget,
      animation: 100
      ghostClass: 'sortable-placeholder-highlight-variable'
      group:
        name: 'testimonials-master-list'
        pull: 'clone'
        put: false
      sort: false
      handle: '[role="sort_handle"]'
      onMove: (event, originalEvent) =>
        ###
          Ensure we don't allow a testimonial to be added twice to a
          placement list.
        ###
        @allowTestimonialInSection(event)

      onEnd: (event) =>
        ###
          Send a PATCH request for the target list to update placements and
          ordering. Skip if the @mainListTarget is the target element.
        ###
        unless event.to is @mainListTarget
          @updateRemote(event.to)
          @render()
    
    ###
      Set up sortables for all @placementListTargets that allow sorting and
      moving between other @placementListTargets.
    ###
    @placementListTargets.forEach (target) =>
      new Sortable target,
        animation: 100
        ghostClass: 'sortable-placeholder-highlight-variable'
        group:
          name: 'testimonials-placement-list'
          pull: ['testimonials-master-list', 'testimonials-placement-list']
          put: true
          revertClone: true
        handle: '[role="sort_handle"]'
        onMove: (event, originalEvent) =>
          ###
            Ensure we don't allow a testimonial to be added twice to a
            placement list when moving a testimonial from one placement list
            to another (when event.from is not event.to). When event.from is
            the same as event.to, a user is just sorting a single placement.
          ###
          if event.from is event.to
            return true
          @allowTestimonialInSection(event)
        
        onEnd: (event) =>
          ###
            Send a PATCH request for the each list that has changed to update
            placements and ordering. Skip if the @mainListTarget is the target
            element.
          ###
          unless event.to is @mainListTarget
            @updateRemote(event.from) unless event.from is event.to
            @updateRemote(event.to)
            @render()
  
  allowTestimonialInSection: (event) ->
    currentTestimonialsInTargetSection = event.to.querySelectorAll('tr.testimonials-list__row')
    
    allowedInSection = true
    
    currentTestimonialsInTargetSection.forEach (target) ->
      if target.dataset.id == event.dragged.dataset.id
        allowedInSection = false
    
    return allowedInSection
  
  removeFromList: (event) ->
    event.preventDefault()
    placementList = event.target.closest('.testimonials-list')
    event.target.closest('.testimonials-list__row').remove()
    @updateRemote(placementList)
    @render()
  
  updateRemote: (listElement) ->
    key = listElement.dataset.key
    ids = []
    listElement.querySelectorAll('.testimonials-list__row').forEach (element, index) =>
      ids.push({ testimonial_id: element.dataset.id, order: index, key: key })
    
    fetch(@data.get('updateAllUrl'),
      method: 'PATCH'
      mode: 'same-origin'
      credentials: 'same-origin'
      headers:
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
        'Content-Type': 'application/json'
        'Accept': 'application/json'
      body: JSON.stringify({ "testimonial_placements": { "#{key}" : ids }})
    ).then((response) =>
      if response.ok
        @remoteUpdateSuccess(listElement)
      else
        @remoteUpdateFailure(listElement)
    ).catch((error) => @remoteUpdateFailure(listElement))
  
  remoteUpdateSuccess: (highlightTarget) ->
    App.Mixins.HighlightEffect.successHighlight($(highlightTarget))
  
  remoteUpdateFailure: (highlightTarget) ->
    App.Mixins.HighlightEffect.errorHighlightWithPulse($(highlightTarget))
    # At this point, we can't reliably determine placement, so, instead
    # of fetching data from the DB or trying to store previous values,
    # just reload the page.
    window.location.reload()