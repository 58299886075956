import { Controller } from "stimulus"
import _filter from 'lodash/filter'

###
  Handles individual agenda item fieldsets.
###
export default class extends Controller
  @targets: [
    'timeFieldContainer',
      'timeField',
    'timeFieldRequirementContributor'
  ]
  
  initialize: ->
    @render()
  
  render: ->
    @_updateTimeFieldRequiredAttribute()
  
  # When this action is invoked containing an event with event.detail.date,
  # dispatches an event (which time-field-controller should pick up) to change
  # the date value.  This is used when moving an agenda item from one date
  # to another.
  updateDate: (event) ->
    # Update time-field targets
    if event.detail and event.detail.date
      @timeFieldContainerTargets.forEach (target) =>
        target.dispatchEvent(new CustomEvent('updateWithNewDate', { detail: { date: event.detail.date }}))
  
  # If an empty nested record is present (e.g. built from fields_for and
  # ignored), setting the required attribute by default on the time fields will
  # prevent the form from being saved in the DOM.  For that reason, we'll
  # dynamically add or remove the required attribute from the time fields
  # when any of the other fields (@timeFieldRequirementContributorTargets)
  # have any content.
  _updateTimeFieldRequiredAttribute: ->
    fieldsWithFilledValues = _filter @timeFieldRequirementContributorTargets, (target) =>
      target.value.length > 0
    
    @timeFieldTargets.forEach (target) =>
      if fieldsWithFilledValues.length > 0
        target.required = true
      else
        target.removeAttribute('required')
