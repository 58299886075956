import { Controller } from 'stimulus'
import _isEmpty from 'lodash/isEmpty'
# @dependency: jQuery
# @dependency: BS3 Tooltip

# Set on a .form-group, validates (with feedback) the given field against
# a provided array. Useful in conjunction with validates_uniqueness_of.
#
# @example (new lines added for legibility):
#
# # Configure the controller for the outer form group and provide
# # data-*-exclusions for the array of disallowed letters
#
# .form-group{ data: {
#   controller: 'components--unique-field',
#   components__unique_field_exclusions: ["alpha", "beta"]
# }}
#   # An .input-group isn't necessary.
#   .input-group
#     = f.text_field :greek_letter, class: 'form-control', data: {
#       target: 'components--unique-field.inputField',
#       action: 'change->components--unique-field#check keyup->components--unique-field#check'
#     }
#     .input-group-addon
#       ... is a greek letter
#       # The @feedbackTarget isn't required, but will provide an indicator
#       # of validity (and a tooltip if invalid).
#       %span.bs5--ps-1{ data: { target: 'components--unique-field.feedback' }}
#
export default class extends Controller
  @targets: [
    'inputField',
    'feedback'
  ]

  # The exclusions list as an Array. Stimulus accepts this as a String, but
  # JSON.parse will convert it properly.
  @property 'exclusions',
    get: -> JSON.parse(@data.get('exclusions'))

  # Providing an optional data-*-human-name will allow the feedback's tooltip
  # to reflect the field in question:
  #
  # "This @humanName is already in use"
  #
  @property 'humanName',
    get: -> @data.get('humanName') or ''

  initialize: ->
    @_reset()

  check: ->
    value = @inputFieldTarget.value.toLowerCase()
    @_reset()

    unless _isEmpty(value)
      if value in @exclusions
        @_errorFeedback()
      else
        @_successFeedback()

  _reset: ->
    if @hasFeedbackTarget
      @feedbackTarget.classList.add('hidden')
      @feedbackTarget.classList.remove('text-success')
      @feedbackTarget.classList.remove('text-danger')
      @feedbackTarget.innerHTML = ''
      @feedbackTarget.setAttribute('title', '')
      @feedbackTarget.setAttribute('data-original-title', '')
      $(@feedbackTarget).tooltip('destroy')
    @element.classList.remove('has-success')
    @element.classList.remove('has-error')

  _successFeedback: ->
    if @hasFeedbackTarget
      @feedbackTarget.classList.add('text-success')
      @feedbackTarget.innerHTML = """
        <i class="fa fa-check no-right-margin"></i>
      """
      @feedbackTarget.classList.remove('hidden')
    @element.classList.add('has-success')

  _errorFeedback: ->
    if @hasFeedbackTarget
      @feedbackTarget.classList.add('text-danger')
      @feedbackTarget.innerHTML = """
        <i class="fa fa-exclamation-triangle no-right-margin"></i>
      """
      @feedbackTarget.classList.remove('hidden')
      @feedbackTarget.setAttribute('title', "This #{@humanName} is already in use")
      $(@feedbackTarget).tooltip
        container: 'body'
      $(@feedbackTarget).tooltip('show')
    @element.classList.add('has-error')
