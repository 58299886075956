import { Controller } from 'stimulus'
import _find from 'lodash/find'

export default class extends Controller
  @targets: ['list', 'listItem', 'newEntry', 'verifyEntry', 'noRecoveryMethodsMessage']
  
  loadNewEntry: (event) ->
    # Don't preventDefault here!
    @listTarget.classList.add('hidden')
    
    @newEntryTargets.forEach (target) =>
      target.classList.remove('hidden')
    
    true # Ensure UJS hums along
  
  returnToList: ->
    [@newEntryTargets, @verifyEntryTargets].forEach (targetSet) =>
      targetSet.forEach (target) => target.remove()
    
    @listTarget.classList.remove('hidden')
  
  addOrReplaceRecoveryMethodListItem: (event) ->
    elementToReplace = _find @listItemTargets, (listTarget) =>
      listTarget.id == event.detail.domId
    
    if elementToReplace
      elementToReplace.outerHTML = event.detail.html
    else
      @noRecoveryMethodsMessageTarget.insertAdjacentHTML('beforeBegin', event.detail.html)
    
    setTimeout =>
      highlightedTargets = @listTarget.querySelectorAll('.panel-body--highlight-success, .panel-body--highlight-danger')
      
      highlightedTargets.forEach (target) =>
        target.classList.remove(
          'panel-body--highlight-success',
          'panel-body--highlight-danger'
        )
    , 500