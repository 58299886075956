import { Controller } from "stimulus"
import * as tinymce from '../shared/tinymce'
import _isEmpty from 'lodash/isEmpty'
import { swatchColors } from "../shared/swatch_colors"

# Stimulus implementation to initialize TinyMCE editors.  Note that this still
# relies on the same data attributes as before (not data-tinymce-editor, but
# data-editor, for example), but no longer requires a .tinymce-editor class.
export default class extends Controller
  @property 'elementId',
    get: -> @data.get('elementId')
    set: (value) -> @data.set('elementId', value)

  @property 'editor',
    get: -> @data.get('editor') or @element.dataset.editor or undefined

  @property 'ownerId',
    get: -> @data.get('ownerId') or @element.dataset.ownerId or undefined

  @property 'hasOwnerId',
    get: -> !_isEmpty(@ownerId)

  @property 'ownerType',
    get: -> @data.get('ownerType') or @element.dataset.ownerType or undefined

  @property 'hasOwnerType',
    get: -> !_isEmpty(@ownerType)

  @property 'assetUrl',
    get: -> @data.get('assetUrl') or @element.dataset.assetUrl or undefined

  @property 'readonly',
    get: -> @data.get('readonly') is 'true'

  @property 'addToolbarItems',
    get: ->
      if @data.get('addToolbarItems')
        @data.get('addToolbarItems').trim().split(' ').map (addition) ->
          parts = addition.split(':')
          { position: parts[0], originalItem: parts[1], newItem: parts[2] }
      else
        []

  @property 'removeToolbarItems',
    get: ->
      if @data.get('removeToolbarItems')
        @data.get('removeToolbarItems').trim().split(' ')
      else
        []

  ###
    Use connect instead of initialize. This eliminates the need to send events
    when the TinyMCE instance becomes disconnected from the DOM, such as
    in a Sortable container or when dynamically added or removed.
  ###
  connect: ->
    if @elementId and @options
      tinymce.start(@options)

    else
      # Change the ID of the element to include a succeeding random number.
      # When used in a dynamic-load scenario or one where the element may
      # be moved around the page, if another element opens on the page with the
      # same ID or selector, TinyMCE will not initialize. This solves cases
      # like AdminResourceReview or Admin Proposals where multiple instances
      # of TinyMCE may be on a page.
      @elementId = @element.id = "#{@element.id}-tinymce-#{Math.floor(Math.random() * 9999999)}"

      # Build out options for this instance
      @options = tinymce.start(@_buildOptionsForInstance())

  ###
    Use disconnect when disconnected from the DOM. This is necessary when the
    instance may be moved around the DOM, such as in a Sortable container.
  ###
  disconnect: ->
    tinymce.stop(@options)

  _buildOptionsForInstance: ->
    ### BASE OPTIONS - TYPE SELECTION
      Set up editor default options. Use data-tinymce-editor="advanced" to use
      the advanced editor. Additionally, specify
      data-tinymce-owner-id="<group.id>" and data-tinymce-owner-id="Group" to
      use a Group's Media Manager.

      Otherwise, the options for a simple editor will be used.
    ###
    options = if @editor is 'advanced'
      if @hasOwnerId and @hasOwnerType
        tinymce.advancedOptionsWithMedia()
      else
        tinymce.advancedOptions()
    else
      tinymce.simpleOptions()

    ### BASE OPTIONS - APPEND SELECTOR
      Use the ID of the field as the selector for this instance.
    ###
    options.selector = "##{@elementId}"

    ### BASE OPTIONS - CONTENT CSS
      Use data-tinymce-asset-url to override the default content CSS for
      the TinyMCE editor. Useful for providing a different set of styles,
      such as when using TinyMCE for email building.

      Example:

        data: { tinymce_asset_url: asset_url('email.css') }
    ###
    if @assetUrl
      options.content_css = @assetUrl

    ### BASE OPTIONS - COLOR MAP
      Configures the color swatches used by leveraging platform-specific
      colors. See swatch_colors.coffee for more details.
    ###
    swatchColors(false).forEach (color) =>
      options.color_map = options.color_map.concat([color, "Platform color"])

    ### READONLY MODE
      Use data-tinymce-readonly="true" to set the editor in read only mode.
      When set to read only mode, content cannot be added to, updated, or
      changed. All toolbar items will be disabled.
    ###
    if @readonly
      options.readonly = 1

    ### ADD ADDITIONAL TOOLBAR ITEMS
      Add items to the toolbar by using data-tinymce-add-toolbar-items.
      If specifying multiple items, they should be space-separated.

      Format:

        <before|after>:<target>:<new element>

      Examples:

        before:link:hr
          Before the link item, add the hr item

        after:fullscreen:code
          After the fullscreen item, add the code item
    ###
    if @addToolbarItems.length
      @addToolbarItems.forEach (addition) =>
        replace = " #{addition.originalItem} "
        replaceWith = undefined

        if addition.position is 'before'
          replaceWith = " #{addition.newItem} #{addition.originalItem} "
        else if addition.position is 'after'
          replaceWith = " #{addition.originalItem} #{addition.newItem} "

        if replaceWith
          options.toolbar = options.toolbar.replace(replace, replaceWith)

    ### REMOVE TOOLBAR ITEMS
      Remove items from the toolbar by using data-tinymce-remove-toolbar-items.
      If specifying multiple items, they should be space-separated.

      Format:

        <element to remove>

      Example:

        "code fullscreen"
          Removes the code and fullscreen items from the toolbar
    ###
    if @removeToolbarItems
      toolbar = options.toolbar
      @removeToolbarItems.forEach (toolbarItem) =>
        toolbar = toolbar.replace(toolbarItem, "")
      options.toolbar = toolbar

    options
