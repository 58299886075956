import { Controller } from 'stimulus'
import _isNull from 'lodash/isNull'

###*
 * Displays a map on the page.  This can be done at runtime with data
 * attributes, or, alternatively, done via an event handler (preferred).
 *
 * Example markup (new lines added for legibility):
 *
 *   .geolocatable-map-container
 *     .geolocatable-map-canvas{ data: {
 *       controller: 'google--maps',
 *       action: 'refresh->google--maps#refresh',
 *       # Extra data options:
 *       google__maps_lat: '37.00000',    # Latitude value
 *       google__maps_lng: '-45.00000',   # Longitude value
 *       google__maps_is_ready: 'true'    # Whether or not to render
 *     }}
 *
###
export default class extends Controller
  @property 'lat',
    get: -> parseFloat(@data.get('lat')) or null
    set: (value) -> @data.set('lat', value)

  @property 'lng',
    get: -> parseFloat(@data.get('lng')) or null
    set: (value) -> @data.set('lng', value)

  @property 'location',
    get: ->
      lat: @lat
      lng: @lng

  @property 'isReady',
    get: -> @data.get('isReady') is 'true'
    set: (value) -> @data.set('isReady', "#{value}")

  initialize: ->
    @_hardReset()

    # Optional: if data-google--maps-ready, data-google--maps-lat, and
    # data-google--maps-lng are all set, perform a render immediately.
    # Otherwise, nothing happens until a "refresh" CustomEvent is sent.
    if @isReady and !_isNull(@lat) and !_isNull(@lng)
      @refresh
        detail:
          force: true
          ready: @isReady
          lat: @lat
          lng: @lng

  # By default, nothing happens until a "refresh" CustomEvent is sent.
  # This event expects:
  #   detail:
  #     ready:  [Boolean] expects a truthy value when everything is OK
  #             to render the map. A falsy value will hide the element in the
  #             DOM and never reach out to Google.
  #     lat:    [Number/String] latitude
  #     lng:    [Number/String] longitude
  #
  refresh: (event) ->
    # Ensure the event has detail; bail otherwise
    unless event.detail
      @_reset() # The event passed is invalid; clear the DOM
      return

    # Return if not ready or lat and/or lng are missing
    if !event.detail.ready or !event.detail.lat or !event.detail.lng
      @_reset()
      return

    # At this point, we are ready to render the map - we know it's ready for
    # rendering, and we have a lat/lng pair. However, to prevent excess calls
    # to the Google API, let's make sure we aren't re-rendering the same map
    # multiple times (if someone changes Cary Street to Cary St., for example).
    #
    # Store the pre-change value for each property and update each property
    # with the new values.
    latWas = @lat
    lngWas = @lng

    @lat = event.detail.lat
    @lng = event.detail.lng

    # If nothing has changed, simply show the map and return
    if event.detail.force isnt true and (latWas is @lat) and (lngWas is @lng)
      @_reveal()
      return

    # We're good to render from new data points.
    # Reset everything and reveal the empty element.
    @_hardReset()
    @_reveal()

    # Render the map
    map = new google.maps.Map @element,
      zoom: 14
      center: @location
      mapTypeId: google.maps.MapTypeId.ROADMAP
      draggable: false
      scrollwheel: false
      zoomControl: false
      keyboardShortcuts: false
      fullscreenControl: false

    # Render the marker
    new google.maps.Marker
      position: @location
      map: map

  _reveal: ->
    @element.classList.remove('hidden')

  _reset: ->
    @element.classList.add('hidden')

  _hardReset: ->
    @element.innerHTML = ''
    @_reset()
