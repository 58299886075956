import { Controller } from "stimulus"

# Combines the values of multiple checkbox fields into one singular text or
# hidden field.
#
# Example:
#
#   %span{ data: { controller: 'combine-fields' }}
#     = hidden_field_tag nil, "", data: { target: 'combine-fields.combinedField' }
#     .checkbox
#       %label
#         = check_box_tag nil, "one", data: { target: 'combine-fields.combinableField', action: 'combine-fields#combine' }
#         One
#     .checkbox
#       %label
#         = check_box_tag nil, "two", data: { target: 'combine-fields.combinableField', action: 'combine-fields#combine' }
#         Two
#
#   When checking the checkboxes, the value ("one" and "two") will be amended to
#   the hidden field, separated with ", ".  The @combinedFieldTarget will not
#   be filled on initialization.
#
export default class extends Controller
  @targets: ["combinableField", "combinedField"]

  combine: (event) ->
    result = []
    
    @combinableFieldTargets.forEach (target) =>
      result.push(target.value) if target.checked
    
    @combinedFieldTarget.value = result.join(', ')