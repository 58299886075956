import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["field"]

  # Specify the URL where the Bloodhound will fetch results.
  @property 'url',
    get: -> @data.get('url')

  @property 'highlight',
    get: -> @data.get('highlight') || true

  @property 'hint',
    get: -> @data.get('hint') || true

  @property 'minLength',
    get: -> @data.get('minLength') || 3

  @property 'typeaheadOptions',
    get: ->
      {
        highlight: @highlight
        hint: @hint
        minLength: @minLength
      }

  @property 'limit',
    get: -> @data.get('limit') || 10

  @property 'typeaheadDataset',
    get: ->
      {
        display: @display
        limit: @limit
        source: @bloodhound
      }

  initialize: ->
    @bloodhound = new Bloodhound
      datumTokenizer: Bloodhound.tokenizers.whitespace
      queryTokenizer: Bloodhound.tokenizers.whitespace
      remote:
        url: @url
        wildcard: 'AUTOCOMPLETEQUERY'
        filter: @filter

    @typeahead = $(@fieldTarget).typeahead(@typeaheadOptions, @typeaheadDataset)

  display: 'suggestion'

  filter: (list) ->
    $.map list.suggestions, (suggestion) ->
      { suggestion: suggestion }