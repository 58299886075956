import { Controller } from "stimulus"

# Shared controller used to manage localStorage and password-protected media
# folder access.
export default class extends Controller
  @property 'localStorageKeyTemplate',
    get: -> "hn-mediaFolder-%"

  @property 'accessTimeInMinutes',
    get: -> 90

  # Checks if the current folder targeted by this controller (or a
  # sub-controller) is password protected.  This requires:
  #   data-#{@identifier}-id = media folder ID
  #   data-#{@identifier}-protected = "true" (if protected)
  canAccessFolder: ->
    if @data.get('protected') is 'true' and @data.get('id') != 'false'
      @_hasValidLocalStorageItem()
    else
      true

  # Generate the localStorage key by a media folder ID
  _localStorageKeyById: (id) ->
    @localStorageKeyTemplate.replace("%", id)

  # Generate the localStorage key for the data ID for this controller
  _localStorageKey: ->
    @_localStorageKeyById(@data.get('id'))

  # Fetches the current time in millis.  Used to check if access is
  # still allowed.
  _getCurrentTime: ->
    return @currentTime if @currentTime
    date = new Date()
    @currentTime = date.getTime()
  
  # When creating or updating a localStorage key, generates the timestamp
  # used as the value.  The property @accessTimeInMinutes can be configured
  # to adjust this time.
  _getNewValidUntilTime: ->
    @_getCurrentTime() + (@accessTimeInMinutes * 60 * 1000)
  
  # If the controller's media folder ID is valid.  No assumptions are made
  # as to whether or not the folder itself is protected.
  _hasValidLocalStorageItem: ->
    value = @_getLocalStorageItem()
    
    if value
      if value >= @_getCurrentTime()
        # This function is generally used when inside the folder itself.
        # Extend the session/timestamp if it is valid.
        @_updateLocalStorageItem()
        true
      else
        # Clear the value if out of date
        @_removeLocalStorageItem()
        false
    else
      false
  
  # If a given media folder's ID is valid.
  _hasValidLocalStorageItemById: (id) ->
    key = @_localStorageKeyById(id)
    value = @_getLocalStorageItemByKey(key)
    
    if value
      if value >= @_getCurrentTime()
        true
      else
        @_removeLocalStorageItemByKey(key)
        false
    else
      false
  
  _getLocalStorageItemByKey: (key) ->
    localStorage.getItem(key)
  
  _getLocalStorageItem: ->
    @_getLocalStorageItemByKey(@_localStorageKey())
  
  _updateLocalStorageItem: ->
    localStorage.setItem(@_localStorageKey(), @_getNewValidUntilTime())
  
  _removeLocalStorageItemByKey: (key) ->
    localStorage.removeItem(key)
  
  _removeLocalStorageItem: ->
    @_removeLocalStorageItemByKey(@_localStorageKey())