import { Controller } from "stimulus"
import _find from 'lodash/find'

# Expects:
#
#   data-tokenfield-dataset == [{
#     "value": 1,
#     "label": "Pumpkin Spice Latte"
#   }, {
#     "value": 2,
#     "label": "Cinnamon Dolce Latte"
#   }].to_json
#
export default class extends Controller
  @property 'dataset',
    get: ->
      JSON.parse(@data.get('dataset'))

  @property 'labels',
    get: ->
      @dataset.map (val) -> val.label

  @property 'persistentAutocomplete',
    get: -> @data.get('persistentAutocomplete') is 'true'

  @property 'triggerChangeOn',
    get: -> @data.get('triggerChangeOn')
 
  initialize: ->
    @element.classList.add('tokenfield')
    @element.setAttribute('autocomplete', 'false')
    @render()

  render: ->
    $(@element).tokenfield({
      tokens: @_initialTokens()
      autocomplete:
        delay: 0
        source: @dataset
      showAutocompleteOnFocus: @persistentAutocomplete
      beautify: true
    }).on('tokenfield:createtoken', ((event) =>
      if event.attrs.label in @labels
        current_values = @element.value.split(',').map((val) -> val.trim())
        
        if event.attrs.value in current_values
          # This value already exists on the field; don't duplicate it.
          event.preventDefault()
          @_reopenAutocomplete() if @persistentAutocomplete

      else
        # Token not in @dataset; do nothing
        event.preventDefault()
        @_reopenAutocomplete() if @persistentAutocomplete
    
    )).on('tokenfield:createdtoken', ((event) =>
      @_reopenAutocomplete(10) if @persistentAutocomplete
      @_initTooltips()
      true
    
    )).on('tokenfield:removedtoken', ((event) =>
      true
    
    )).on('change', ((event) =>
      if @triggerChangeOn
        triggerElement = @element.closest(@triggerChangeOn)
        triggerElement.dispatchEvent(new CustomEvent('change')) if triggerElement
      true
    ))

    $(@element).tokenfield('enable')

    # Init tooltips
    @_initTooltips()

  _initialTokens: ->
    foundTokens = []
    
    @element.value.split(',').forEach (eachInitialValue) =>
      initialValue = String(eachInitialValue).trim()
      foundToken = _find @dataset, (data) =>
        String(data.value).trim() is initialValue
      
      foundTokens.push(foundToken) if foundToken
      
    foundTokens

  _reopenAutocomplete: (ms = 0) ->
    setTimeout =>
      $(@element).tokenfield('search')
    , ms

  # Amends rel="tooltip" title=content to all .token-labels. This is done because
  # loading a page where the fields are hidden will condense the label tags,
  # making them illegible. This is also not done on the .token itself, as
  # removing a token will persist the tooltip, making it unremovable by the
  # user.
  _initTooltips: ->
    @element.parentElement.querySelectorAll('.token-label').forEach (tokenLabel) ->
      unless tokenLabel.getAttribute('rel') is 'tooltip' and tokenLabel.getAttribute('title')
        tokenLabel.setAttribute('title', tokenLabel.innerText)
        tokenLabel.setAttribute('rel', 'tooltip')
