import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["truncatedContent", "fullContent"]

  initialize: ->
    @fullContentTargets.forEach((target) -> target.classList.add('hidden'))

  showFullContent: (event) ->
    event.preventDefault()

    @truncatedContentTargets.forEach((target) -> target.classList.add('hidden'))
    @fullContentTargets.forEach((target) -> target.classList.remove('hidden'))

  showTruncatedContent: (event) ->
    event.preventDefault()

    @truncatedContentTargets.forEach((target) -> target.classList.remove('hidden'))
    @fullContentTargets.forEach((target) -> target.classList.add('hidden'))

  toggleContent: (event) ->
    event.preventDefault()

    @truncatedContentTargets.forEach((target) -> target.classList.toggle('hidden'))
    @fullContentTargets.forEach((target) -> target.classList.toggle('hidden'))
