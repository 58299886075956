import { Controller } from "stimulus"

export default class extends Controller
  @targets: ['bank_accountFieldset', 'credit_cardFieldset']

  @property 'paymentType',
    get: ->
      @data.get('paymentType')
    
    set: (value) ->
      @data.set('paymentType', value)
      @render()

  initialize: ->
    @render()

  updatePaymentType: (event) ->
    @paymentType = event.target.value

  render: ->
    @bank_accountFieldsetTarget.hidden = true
    @bank_accountFieldsetTarget.disabled = true

    @credit_cardFieldsetTarget.hidden = true
    @credit_cardFieldsetTarget.disabled = true

    if fieldsetToShow = @targets.find("#{@paymentType}Fieldset")
      fieldsetToShow.hidden = false
      fieldsetToShow.disabled = false