import { Controller } from "stimulus"

# Controller for polling an endpoint for a particular JSON response.  If the response is validated, the page will
# be reloaded.
# 
# Example (indentation for legibility):
# 
#   %div{
#     data: {
#       controller: 'refresh-page-on-response',
#       poll_url: url_for(format: :json)                    # the URL to poll
#       key: 'the_field_to_check',                          # the field or key to check for
#       refresh_when_key_value_is: 'true'|'false'|"123456", # when the key == this value, the page will refresh.
#                                                           #   if unspecified, defaults to true.
#       poll_time: 3000                                     # how long (in ms) between polling attempts.
#                                                           #   if unspecified, defaults to 3000.
#     }
#   }
# 
export default class extends Controller
  initialize: ->
    data = $(@element).data()

    @url = data.pollUrl
    @key = data.key
    @expectedValue = if data.refreshWhenKeyValueIs is null || data.refreshWhenKeyValueIs is undefined
      true
    else
      data.refreshWhenKeyValueIs
    @pollTime = parseInt(data.pollTime) or 1500
  
    @startTimer()
  
  startTimer: ->
    @timer = setInterval =>
      request = $.get(@url)

      request.done (response) =>
        if response[@key] == @expectedValue
          clearInterval(@timer)
          @successTick(response)
          @completeAction(response)

        else
          @responseTick(response)

    , @pollTime
  
  # By default, completeAction will reload the page.  If additional behavior is necessary,
  # or an alternative action should be performed, overwrite this function.
  completeAction: (response) ->
    location.reload()
  
  responseTick: (response) ->
    # Override to provide any actions that may occur during a tick of the setInterval
    # timer.
  
  successTick: (response) ->
    # Override to provide any actions that may occur when a successful response is
    # received and the page is about to reload.
