import { Controller } from 'stimulus'
import _debounce from 'lodash/debounce'
import _isEmpty from 'lodash/isEmpty'
import _template from 'lodash/template'

# Checks the duplicate user matches endpoint against given fields, submits
# a debounced request, and presents page-specific templates when results
# are found.
#
# This requires:
#
# * The first name, last name, and location fields to have
#   data-user-duplicate-finder-target="requestField"
# * Each of those fields having a data-resource-metal-param set to their
#   corresponding key on the /duplicate-user-matches endpoint. If this
#   endpoint accepts other parameters in the future, they can be
#   specified as well without changes needed to this controller; however, all
#   are currently required to submit a request.
# * An element with data-user-duplicate-finder-target="responseContainer",
#   that will present the results generated from the following two templates.
# * A TEMPLATE with data-user-duplicate-finder-target="outerTemplate",
#   that is the template for outer formatting, text, etc. This should contain
#   a line that reads {{ data.innerContent }} for insertion of each potential
#   user duplicate found.
# * A TEMPLATE with data-user-duplicate-finder-target="innerTemplate",
#   that is the template for each potential user's formatting. This can contain
#   any {{ data.* }} tag, where * matches the keys from the JSON result.
#
# Example markup:
#
# = form_with ... html: { data: { controller: 'user-duplicate-finder' }} do |f|
#   %template{ data: { user_duplicate_finder_target: 'outerTemplate' }}
#     %div
#       I will show results:
#
#     %div
#       {{ data.innerContent }}
#
#   %template{ data: { user_duplicate_finder_target: 'innerTemplate' }}
#     %div
#       %strong {{ data.name }}
#       %strong.small.text-muted {{ data.professional_titles }}
#     %div
#       -# Rails' URLs cannot be passed curly braces or they will be escaped.
#       -# To use a URL with a data attribute, do the following:
#       - view_profile_url = community_user_path(id: "DATA_ID")
#       = link_to 'View profile', view_profile_url.gsub(/DATA_ID/, "{{ data.id }}")
#
#   = render user_form_group_partial(:first_name), f: f
#   = render user_form_group_partial(:last_name), f: f
#   = render user_form_group_partial(:location_city_state), f: f
#
#   %div{ data: { user_duplicate_finder_target: 'responseContainer' }}
#
export default class extends Controller
  @targets: [
    'requestField',
    'outerTemplate',
    'innerTemplate',
    'responseContainer'
  ]

  @property 'url',
    get: -> "/duplicate-user-matches"

  initialize: ->
    # Set a debounce (1000ms) on @update(). with a maxWait of 30 seconds.
    # This will debounce every second, but will hold off up to 30 seconds if
    # called more often (e.g. while a user is typing).
    # We don't need this requesting updates constantly.
    @update = _debounce(@update, 1000, { maxWait: 30000 }).bind(@)

    # Perform the update on page load.
    @update()

  update: ->
    # If there is no @responseContainerTarget, @outerTemplateTarget, or
    # @innerTemplateTarget, there's nothing to do. Abort.
    return unless @hasResponseContainerTarget
    return unless @hasOuterTemplateTarget
    return unless @hasInnerTemplateTarget

    # Get all the fields we want to request on. Ignore any field that has
    # the readonly attribute (e.g. autocomplete fields, like the location
    # autocomplete, will create a secondary field), and ignore any field that
    # doesn't have data-resource-metal-param.
    filteredRequestFields = @requestFieldTargets.filter (target) =>
      target.dataset.resourceMetalParam and
      (target.getAttribute('readonly') is null)

    # Filter fields down further to ensure values exist
    filteredRequestFieldsWithValues = filteredRequestFields.filter (target) =>
      !_isEmpty(target.value.trim())

    # If we don't have all fields completed, reset, and we're done.
    if filteredRequestFields.length isnt filteredRequestFieldsWithValues.length
      @reset()
      return

    # We have all the fields, so build the body we're going to send
    bodyObject = {}
    filteredRequestFieldsWithValues.forEach (target) =>
      bodyObject["#{target.dataset.resourceMetalParam}"] = target.value

    # Fetch via POST
    fetch(@url,
      method: 'POST'
      mode: 'same-origin'
      credentials: 'same-origin'
      headers:
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
        'Content-Type': 'application/json'
        'Accept': 'application/json'
      body: JSON.stringify(bodyObject)
    ).then((response) =>
      response.json()
    ).then((json) =>
      if json.length
        @_renderResults(json)
      else
        @reset()
    ).catch((error) =>
      @reset()
    )

  reset: ->
    @responseContainerTarget.classList.add('hidden')
    @responseContainerTarget.innerHTML = ''

  _renderResults: (json) ->
    eachInnerResult = []

    # Set up template options. Use the data prefix (data.name, data.email)
    # for the view, and use double curly braces {{ }} for interpolation. Using
    # the default ERB-style tags is a problem when using innerHTML, as it will
    # escape < and >, without any way around it (including using the escape
    # option).
    templateOpts = {
      'variable': 'data'
      'interpolate': /{{([\s\S]+?)}}/g
    }

    # Get each template from the page
    innerTemplate = _template(@innerTemplateTarget.innerHTML, templateOpts)
    outerTemplate = _template(@outerTemplateTarget.innerHTML, templateOpts)

    # Iterate through each set in the JSON, render each template using each
    # set, and add to the array
    json.forEach (line) =>
      eachInnerResult.push innerTemplate(line)

    # Compile the outer template, with the inner templates combined.
    outerResult = outerTemplate({ innerContent: eachInnerResult.join(' ') })
    # Set the HTML and show the container
    @responseContainerTarget.innerHTML = outerResult
    @responseContainerTarget.classList.remove('hidden')
