import { Controller } from 'stimulus'
# @dependency jQuery
# @dependency bootstrap-toggle

# Alternative implementation to data-toggle="toggle", useful when dynamically
# loading content including a bootstrap-toggle onto the page. Functions
# exactly the same as the plugin's default behavior and options except:
#
# * #size defaults to "small" instead of "normal"
# * All options must be prefixed with data-components--bootstrap-toggle-*
#   instead of just data-*
export default class extends Controller
  @values =
    # Set this value to true to re-emit `change` events as normal
    # DOM events. Otherwise, the changes cannot be captured by other
    # non-jQuery JS code.
    reemitEvents: Boolean

  initialize: ->
    $(@element).bootstrapToggle
      on:       @data.get('on')       or 'On'
      onstyle:  @data.get('onstyle')  or 'primary'
      off:      @data.get('off')      or 'Off'
      offstyle: @data.get('offstyle') or 'default'
      size:     @data.get('size')     or 'small'
      width:    @data.get('width')    or null
      height:   @data.get('height')   or null
      style:    @data.get('style')    or null

    if @reemitEventsValue
      $(@element).change (event) =>
        @element.dispatchEvent(new CustomEvent('bootstrapToggleChange', { bubbles: true }))
