import { Controller } from "stimulus"

export default class extends Controller
  @targets: ['option']

  connect: ->
    @toggle()

  toggle: (event) ->
    @hideAllOptionTargetFieldsets()
    
    if event
      for element in document.querySelectorAll(event.target.dataset.radioToggleFieldsetSelector)
        element.hidden = false
        element.disabled = false

    else
      for option in @optionTargets
        if option.checked
          for element in document.querySelectorAll(option.dataset.radioToggleFieldsetSelector)
            element.hidden = false
            element.disabled = false

  hideAllOptionTargetFieldsets: ->
    for option in @optionTargets
      for element in document.querySelectorAll(option.dataset.radioToggleFieldsetSelector)
        element.hidden = true
        element.disabled = true