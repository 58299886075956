import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["registeredUserCheck", "emailHtmlOutlet", "emailInput",
    "existingUserLogin", "newUserApply", "passwordResetLink"]

  @property 'email',
    get: ->
      @data.get('email')

    set: (value) ->
      @data.set('email', value)

      @updateEmailTargets()

  @property 'passwordResetUrl',
    get: ->
      passwordResetUrl = @data.get('passwordResetUrl')

      if passwordResetUrl && @email
        if passwordResetUrl.match /\?/
          "#{passwordResetUrl}&email=#{@email}"

        else
          "#{passwordResetUrl}?email=#{@email}"

      else
        passwordResetUrl

  @property 'userExists',
    get: ->
      JSON.parse(@data.get('userExists'))

    set: (value) ->
      @data.set('userExists', value)
      @render()

  initialize: ->
    $(@registeredUserCheckTarget).on 'registeredUserCheckCompleted', (event, data) =>
      @email = data.email
      @userExists = data.exists

    @render()

  render: ->
    switch @userExists
      when null
        @registeredUserCheckTarget.hidden = false
        @existingUserLoginTarget.hidden = true
        @newUserApplyTarget.hidden = true

      when true
        @registeredUserCheckTarget.hidden = true
        @existingUserLoginTarget.hidden = false
        @newUserApplyTarget.hidden = true

        # Set focus on the apply form.
        $(@existingUserLoginTarget).find('input[type="password"]:enabled:first').focus()

      when false
        @registeredUserCheckTarget.hidden = true
        @existingUserLoginTarget.hidden = true
        @newUserApplyTarget.hidden = false

        # Set focus on the apply form.
        $(@newUserApplyTarget).find('input[type="text"]:enabled:first').focus()

  reset: ->
    @email = ''
    @userExists = null

  updateEmailTargets: ->
    # Update tags whose content should be the email address.
    $(@emailHtmlOutletTargets).html(@email)

    # Update fields whose value should be the email address.
    $(@emailInputTargets).val(@email)

    # Update password reset links, they contain a reference to the email.
    $(@passwordResetLinkTargets).attr("href", @passwordResetUrl)