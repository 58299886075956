import { Controller } from "stimulus"
import _each from "lodash/each"

###
  For cases where:
  
  * Multiple checkboxes are presented.  One checkbox is a @defaultCheckboxTarget
    while all other checkboxes are a @contributorTarget .
  * The @contributorTargets contribute to the state of the
    @defaultCheckboxTarget
  * When any checkboxes are checked, the @defaultCheckboxTarget will
    automatically be checked
  * If the @defaultCheckboxTarget is unchecked, all @contributorTargets will
    be unchecked
  
  Example (spacing and tabs added for legibility):
  
  %div{ data: { controller: 'checkbox-with-contributors' }}
    -# This checkbox will become checked if any other checkboxes become checked
    -# This checkbox will uncheck all other checkboxes when unchecked
    %input{ type: :checkbox, data: {
      checkbox_with_contributors_target: 'defaultCheckbox',
      action: 'checkbox-with-contributors#render'
    }}
    
    - 300.times do
      -# When checked, will ensure @defaultCheckboxTarget is checked
      -# When @defaultCheckboxTarget is unchecked, this will become unchecked
      %input{ type: :checkbox, data: {
        checkbox_with_contributors_target: 'contributor',
        action: 'checkbox-with-contributors#render'
      }}
      
###
export default class extends Controller
  @targets = ["defaultCheckbox", "contributor"]
  
  initialize: ->
    @render()
  
  render: (event) ->
    if event
      if event.currentTarget is @defaultCheckboxTarget
        unless @defaultCheckboxTarget.checked
          @_uncheckAll()
      else
        @defaultCheckboxTarget.checked = true
    
    else
      if @_areAnyChecked()
        @defaultCheckboxTarget.checked = true
      else
        @defaultCheckboxTarget.checked = false
  
  _areAnyChecked: ->
    return true if @defaultCheckboxTarget.checked
    
    anySelected = false
    
    _each @contributorTargets, (target) =>
      if target.checked
        anySelected = true
      true # to continue _each
    
    anySelected

  _uncheckAll: ->
    _each @contributorTargets, (target) =>
      target.checked = false
      true # to continue _each