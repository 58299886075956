import { Controller } from "stimulus"

# Wrapper around App.Views.SendMessageView.  Replaces .btn-send-message,
# .link-send-message, and .btn-send-referral delegates.
#
# You can assign the following to the link or button:
#
#   data-sender-context - GID or signed GID string that represents the context
#     of the sender. This is also used to add the Group header to group-sent
#     messages to users.
#
export default class extends Controller
  render: (event) ->
    event.preventDefault() if event
    
    # Model and View are only initialized when the link is clicked.  This
    # saves potentially dozens of JS objects loading into memory.
    @model = new Backbone.Model($(@element).data())
    
    # Use the SendMessageView if authenticated; otherwise, bypass it altogether
    # and use the AuthenticationModalView.  This replaces previous logic
    # in SendMessageView.
    #
    # App.Config.isCurrentUserAuthenticated is not reliable here, as it may not
    # be configured on slow connections.  Instead, query the body's
    # data-authenticated value directly.
    @view or= if document.querySelector('body').dataset.authenticated is 'true'
      new App.Views.SendMessageView el: @element, model: @model

    else
      new App.Views.AuthenticationModalView
        model: new Backbone.Model
          authExplanation: "You must be logged in to send messages on #{App.Config.companyName}."
          loginPath: @model.get('loginPath')
          signupPath: @model.get('signupPath')
    
    # Render the view.  Before, SendMessageView would initialize on click,
    # then render the modal automatically.  It will now only display the modal
    # when @render() is invoked.
    @view.render()
