import ModalLinkController from "./modal_link_controller"
import _isEmpty from 'lodash/isEmpty'

export default class extends ModalLinkController
  @targets: [
    'select',
    'addGroup', 'editGroup',
    'addButton', 'editButton', 'deleteButton'
  ]
  
  initialize: ->
    @element.setAttribute('data-remote', false)
    @render()
  
  render: ->
    @changeSelect()
  
  add: (event) ->
    if @hasAddButtonTarget
      @element.setAttribute 'data-resource-categories-href', @addButtonTarget.href
      @load(event)
  
  edit: (event) ->
    if @hasEditButtonTarget
      @element.setAttribute 'data-resource-categories-href', @editButtonTarget.href
      @load(event)
  
  delete: (event) ->
    if @hasDeleteButtonTarget
      @element.setAttribute 'data-resource-categories-href', @deleteButtonTarget.href
      @load(event)
  
  changeSelect: ->
    return unless @hasEditGroupTarget

    # Show or hide edit/delete buttons
    @editGroupTarget.classList.toggle('hidden', _isEmpty(@selectTarget.value))
    
    # Assign the proper HREFs for the edit and delete buttons
    if @hasEditButtonTarget
      @editButtonTarget.href = @editButtonTarget.dataset.urlTemplate.replace(':id', @selectTarget.value)
    if @hasDeleteButtonTarget
      @deleteButtonTarget.href = @deleteButtonTarget.dataset.urlTemplate.replace(':id', @selectTarget.value)
  
  refreshList: (event) ->
    @selectTarget.innerHTML = event.detail.html
    @render()
    if event.detail.closeWithoutDelay == '1'
      @modalInstance.close()
    else
      @closeWithTimeout()
  
  closeWithTimeout: ->
    setTimeout =>
      @modalInstance.close()
    , 1500