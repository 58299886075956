import { Controller } from 'stimulus'
import _debounce from 'lodash/debounce'
import _isEmpty from 'lodash/isEmpty'
import _unescape from 'lodash/unescape'

###
  Copies HTML content from a textarea, input field, or ace editor into
  viewable content within an iframe.

  Example markup:
    .form-group{ data: { controller: 'html-preview-from-input' }}
      -# Any type of input, text area, ace editor, etc.
      %input{ data: {
        html_preview_from_input_target: 'input',
        action: 'change->html-preview-from-input#render' }}

      -# Empty message container
      %div{ data: { html_preview_from_input_target: 'noContent' }}
        This will be shown when the content is empty!

      -# iframe that will be populated with content
      %iframe.iframe--fluid.iframe--min-height-500{ data: {
        html_preview_from_input_target: 'iframe' }}
###
export default class extends Controller
  @targets: ['input', 'noContent', 'iframe']

  # Wrapper chrome around the content. None by default. Assign
  # data-@identifier-chrome to an HTML string that works with Underscore's
  # #_template function, using <%= data.content %> as a placeholder for the
  # content to be inserted.
  @property 'chrome',
    get: -> @data.get('chrome') or '<%= data.content %>'

  initialize: ->
    # Pre-build the template so it doesn't need to be built over and over again
    @template = _.template(@chrome, variable: 'data')

    # Set #render to be debounced as to not become a resource/request hog
    @render = _debounce(@render, 1000).bind(@)

    # Call render on load
    @render()

  render: ->
    value = _unescape(@inputTarget.value).trim()

    if !_isEmpty(value)
      # If there is content, hide the no content message and set up the
      # iframe with the current content.
      if @hasNoContentTarget
        @noContentTarget.classList.add('hidden')

      content = @template(content: value)
      @iframeTarget.setAttribute('srcdoc', content)
      @iframeTarget.classList.remove('hidden')

    else
      # If there is no content, reset the iframe and show the no
      # content message.
      if @hasNoContentTarget
        @noContentTarget.classList.remove('hidden')

      @iframeTarget.classList.add('hidden')
      @iframeTarget.setAttribute('srcdoc', '')
