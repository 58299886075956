import { Controller } from "stimulus"
import _each from "lodash/each"

###
  For moderator permissions, some permissions require a :read permission to be
  set, but isn't displayed.  If any of the targets are checked, set the value
  of the hidden field properly to enable the :read permission.
###
export default class extends Controller
  @targets = ["hiddenField", "contributor"]
  
  initialize: ->
    @render()
  
  render: ->
    anySelected = false
    
    _each @contributorTargets, (target) =>
      if target.checked
        anySelected = true
      true # to continue _each
    
    if anySelected
      @hiddenFieldTarget.value = @hiddenFieldTarget.dataset.abilityId
    else
      @hiddenFieldTarget.value = null