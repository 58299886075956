import { Controller } from "stimulus"
# dependency: jQuery
# dependency: cocoon
# dependency: cocoon-events

###
  Shows a message when there are no nested records in a cocoon container, and
  hides the message when items are present.

  @requires cocoon-events

  @targets
    @messageTarget - the message shown when no nested records are present.
      It is recommended to set .hidden as a class on this target to prevent
      page jumps, particularly across slow connections.

  @example (new lines added for legibility)

    %fieldset{ data: { controller: 'cocoon-empty-set' }}
      .where-cocoon-stuff-goes{
        data: {
          controller: 'cocoon-events',
          action: '
            cocoonEventsInit->cocoon-empty-set#update            # Initializes everything
            cocoonChildElementsChanged->cocoon-empty-set#update  # Updates
          '
        }
      }
        .my-empty-set-message.hidden{ data: { cocoon_empty_set_target: 'message' }}
          Hey, you haven't added any stuff.  Click that add button, buddy!

        = f.fields_for :stuff do |ff|
          = render 'stuff_fields', f: ff

          # In _stuff_fields.haml
          .nested-fields{ data: { cocoon_events_target: 'element' }}
            ...

      .btn-container-stretch
        = link_to_add_association '+ Add stuff', ...

###
export default class extends Controller
  @targets: ['message']

  update: (event) ->
    visibleElementCount = if event.detail
      event.detail.currentVisibleElementCount
    else
      null

    @messageTarget.classList.toggle('hidden', visibleElementCount is null or visibleElementCount isnt 0)