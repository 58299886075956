import { Controller } from "stimulus"

export default class extends Controller
  @targets: [
    "namedMemberSegmentIdField",
    "segmentKeyField",
    "tierIdField",
    "subchapterIdField",
    "subchapterAllRadio",
    "subchapterSelectRadio",
      "subchapterSelectTokenField",
    "subchapterInverseRadio",
      "subchapterInverseTokenField",
    "statView"
  ]

  @property 'statUrl',
    get: ->
      @data.get('statUrl')

  @property 'currentSegmentKey',
    get: ->
      foundSegmentKey = @segmentKeyFieldTargets.find((target) -> target.checked)
      foundSegmentKey.value if foundSegmentKey

  @property 'currentSegmentKeyPrefix',
    get: ->
      foundSegmentKey = @segmentKeyFieldTargets.find((target) -> target.checked)
      foundSegmentKey.dataset.prefix if foundSegmentKey

  @property 'currentTierIds',
    get: ->
      return [] unless @currentSegmentKey

      @targets.findAll("#{@currentSegmentKeyPrefix}TierIdField").filter((field) ->
        field.checked and !field.disabled
      ).map((field) ->
        field.value
      )

  @property 'currentSubchapterIds',
    get: ->
      return [] unless @currentSegmentKey

      if @data.get('subchaptersUseTokenEntry') is 'true'
        key = "#{@currentSegmentKeyPrefix}SubchapterAllRadio"
        allOption = @targets.find("#{@currentSegmentKeyPrefix}SubchapterAllRadio")
        return [] if allOption.checked

        selectOption = @targets.find("#{@currentSegmentKeyPrefix}SubchapterSelectRadio")
        if selectOption.checked
          tokenField = @targets.find("#{@currentSegmentKeyPrefix}SubchapterSelectTokenField")
          return [] unless tokenField
          return [] unless tokenField.value
          return tokenField.value.split(',').map((value) -> value.trim())
        
        inverseOption = @targets.find("#{@currentSegmentKeyPrefix}SubchapterInverseRadio")
        if inverseOption.checked
          tokenField = @targets.find("#{@currentSegmentKeyPrefix}SubchapterInverseTokenField")
          return [] unless tokenField
          return [] unless tokenField.value

          # A little bit of additional processing is required for the inverse
          # selection so stats can be updated properly
          selectedValues = tokenField.value.split(',')
            .map((value) -> value.trim())
          allValues = String(@data.get('allSubchapterIds')).split(',')
            .map((value) -> value.trim()).filter((value) -> !!value.length)

          if selectedValues.length in [0, allValues.length]
            return []
          else
            return allValues.filter((value) -> !(value in selectedValues))

      else
        return @targets.findAll("#{@currentSegmentKeyPrefix}SubchapterIdField").filter((field) ->
          field.checked && !field.disabled
        ).map((field) ->
          field.value
        )

    # Fallback
    []

  @property 'namedMemberSegmentId',
    get: ->
      if @hasNamedMemberSegmentIdFieldTarget
        @namedMemberSegmentIdFieldTarget.value

  initialize: ->
    @statsServiceIsDown = false
    @update()

  canFetchStats: ->
    # We can fetch stats when:
    #   - A segment key has been selected, AND
    #   - Either the segment key is NOT "named" OR
    #   - The segment key IS "named" and a named member segment ID has also been selected.
    @currentSegmentKey && (@currentSegmentKey != 'named' || (@currentSegmentKey == 'named' && @namedMemberSegmentId))

  update:
    _.debounce(
      ->
        if @canFetchStats()
          @statViewTarget.innerHTML = "<div class='full-line-bottom-margin'><i class='fa fa-refresh fa-spin'></i> Loading segment stats...</div>"

          request = $.ajax
            url: @statUrl
            method: 'GET'
            timeout: 10000
            data:
              member_segment:
                key: @currentSegmentKey
                tier_ids: @currentTierIds
                subchapter_ids: @currentSubchapterIds
                named_member_segment_id: @namedMemberSegmentId

          request.then (response) =>
            @statViewTarget.innerHTML = response.html

          request.fail () =>
            @statsServiceIsDown = true
            @statViewTarget.innerHTML = """
              <div class="alert alert-warning alert-with-icon full-line-top-margin">
                <i class="fa fa-exclamation-triangle"></i>
                <p>
                  We're sorry, but membership stats for your selected options
                  are temporarily unavailable.
                </p>
              </div>
            """

        else if !@statsServiceIsDown
          @statViewTarget.innerHTML = ''

    , 300)