import { Controller } from "stimulus"

export default class extends Controller
  @targets: ['addInPersonLocationButton', 
    'inPersonField', 
    'inPersonLocationTemplate',
    'inPersonLocationsContainer',
    'inPersonLocationsFieldset']

  @property 'inPerson',
    get: ->
      @data.get('inPerson') == 'true'

    set: (value) ->
      @data.set('inPerson', value)
      @render()

  @property 'inPersonLocationsCount',
    get: ->
      parseInt(@data.get('inPersonLocationsCount'))

    set: (value) ->
      parseInt(@data.set('inPersonLocationsCount', value))
      @render()

  initialize: ->
    @render()

  inPersonFieldChanged: (event) ->
    @inPerson = "#{event.target.checked}"

  render: ->
    if @inPerson
      @inPersonLocationsFieldsetTarget.hidden = false
      @inPersonLocationsFieldsetTarget.disabled = false

      # This little hack makes sure Twitter Typeahead fields have a white 
      # background when they become visible. Without this, autocompletes
      # become enabled with disabled styles fixed on the .tt-hint element.
      $(@element).find('.tt-hint').css('background-color', '#ffffff')
    
    else
      @inPersonLocationsFieldsetTarget.hidden = true
      @inPersonLocationsFieldsetTarget.disabled = true

    if @inPersonLocationsCount >= 5
      @addInPersonLocationButtonTarget.hidden = true

  addInPersonLocation: ->
    unless @inPersonLocationsCount >= 5
      clonedTemplate = @inPersonLocationTemplateTarget.content.cloneNode(true);
      @inPersonLocationsContainerTarget.appendChild(clonedTemplate)
      @inPersonLocationsCount += 1