import { Controller } from 'stimulus'

# Requires loading ace from a script tag
# Expects
export default class extends Controller
  @targets: ['field', 'editor']

  # This should match the root path of the JS from CDN
  @property 'basePath',
    get: -> "//cdnjs.cloudflare.com/ajax/libs/ace/1.11.2/"

  @property 'mode',
    get: -> @data.get('mode') or 'html'

  @property 'theme',
    get: -> @data.get('theme') or 'sqlserver'

  # @see #focusOnEditor
  @property 'focusTimeout',
    get: ->
      if @data.get('focusTimeout')
        parseInt(@data.get('focusTimeout'))
      else
        50

  initialize: ->
    # Configure CDN base path. Required for themes and syntax highlighting.
    # We'll wrap this in a try/catch; if this fails, ace is not loaded.
    try
      ace.config.set('basePath', @basePath)
    catch e
      console.warn "could not process ace in a timely manner"
      return false

    # Now that we have interacted with ace at least once, hide the field.
    @fieldTarget.classList.add('hidden')

    # Create a <div> to work from.
    div = document.createElement('div')

    # Set the data attribute of the <div> so it can be referenced after
    # being inserted in the DOM.
    div.setAttribute("data-#{@identifier}-target", 'editor')

    # Add base styles to make this look nice.
    div.classList.add('mono')
    div.setAttribute("style", """
      border: 1px solid #cccccc;
      border-radius: 4px;
      font-size: 15px;
      line-height: 1.45;
      min-height: 150px;
      padding: 6px 12px;
      position: relative;
    """)

    # Insert the <div> before the @fieldTarget.
    @fieldTarget.parentElement.insertBefore(div, @fieldTarget)

    # Now that the <div> is in place, configure ace to work in that <div>.
    # Initialize ace
    @editor = ace.edit @editorTarget,
      mode: "ace/mode/#{@mode}"
      theme: "ace/theme/#{@theme}"
      selectionStyle: "text"

    # Configure some additional settings
    @editor.renderer.setShowGutter(false)
    @editor.session.setTabSize(2)
    @editor.setOptions({ maxLines: 20 })
    @editor.setShowPrintMargin(false)

    # Connect ace's content with the textarea so:
    #
    # * We get an initial value set if there is one
    # * The @fieldTarget gets updated on any changes
    #
    @editor.getSession().setValue(@fieldTarget.value)
    # Note: The "change" event here works more like "input" - it will update
    # on every character typed, not just when changed and unfocused.
    @editor.getSession().on 'change', =>
      @fieldTarget.value = @editor.getSession().getValue()
      @fieldTarget.dispatchEvent(new CustomEvent('change'))

  # Manual event to shift focus to the editor. Requires setTimeout to handle
  # jQuery things that might function on a delay.
  focusOnEditor: ->
    setTimeout (=> @editor.focus()), @focusTimeout
