import { Controller } from "stimulus"

export default class extends Controller
  @targets: []
  @values =
    expanded: Boolean

  initialize: ->
    @container = document.querySelector(@data.get('container'))
    @expandedValue = @element.getAttribute('aria-expanded') == 'true'

  toggle: (event) ->
    event.preventDefault()
    @expandedValue = !@expandedValue

  expandedValueChanged: (value) =>
    return unless @container

    if value
      @container.dispatchEvent(new CustomEvent('opened', { bubbles: false }))
      @container.classList.remove('collapse')
      @container.classList.add('collapsing')
    else
      @container.classList.add('collapse')
      @container.classList.remove('collapsing')

    @element.setAttribute('aria-expanded', "#{ value }")
