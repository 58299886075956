import { Controller } from "stimulus"

export default class extends Controller
  @targets = ['toggle', 'heading', 'content']

  initialize: ->
    @show
      currentTarget: @toggleTargets[0]
      preventDefault: -> {}

  show: (event) ->
    event.preventDefault()
    @toggleTargets.forEach((target) -> target.classList.remove('disabled'))
    event.currentTarget.classList.add('disabled')
    @headingTarget.innerText = event.currentTarget.dataset.heading
    targetTemplate = event.currentTarget.querySelector('template')
    @contentTarget.innerHTML = targetTemplate.innerHTML
