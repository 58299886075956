import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["analyticsContainer", "buttonIcon"]

  toggleAnalyticsPanel: ->
    if @data.get('state') == 'closed'
      # Fetch the analytics
      request = $.ajax
        method: "GET"
        url: @data.get('url')

      # When they load, show them.
      request.then (response) =>
        $(@analyticsContainerTarget).html(response)
        $(@analyticsContainerTarget).collapse('show')
        @data.set('state', 'open')

        # Switch from the loading icon to the bar chart icon.
        $(@buttonIconTarget)
          .removeClass('fa-refresh fa-spin')
          .addClass('fa-bar-chart')

      # Show the loading icon
      $(@buttonIconTarget)
        .removeClass('fa-bar-chart')
        .addClass('fa-refresh fa-spin')

    else
      # Hide the container
      $(@analyticsContainerTarget).collapse('hide')

      # Set the state to closed.
      @data.set('state', 'closed')

