import { Controller } from 'stimulus'
import _camelCase from 'lodash/camelCase'
import _debounce from 'lodash/debounce'
import _isEmpty from 'lodash/isEmpty'

export default class extends Controller
  @targets: [
    'content',
    'advertiserConnectedTemplate',
      'firstName',
      'lastName',
      'email',
      'phone',
    'noAdvertiserConnectedTemplate',
      'idValue',
      'emailValue',
      'firstNameValue',
      'lastNameValue',
      'companyNameValue',
      'contactPhoneValue',
      'mailingAddressStreetValue',
      'mailingAddressCityValue',
      'mailingAddressStateValue',
      'mailingAddressZipCodeValue',
    'disableOnSearch',
    'hiddenOnSearch',
    'visibleOnSearch',
  ]

  # data-*-search-url should respond with a JSON response providing the
  # found Advertiser record
  @property 'searchUrl',
    get: -> @data.get('searchUrl')

  @property 'advertiser',
    get: ->
      try
        JSON.parse(@data.get('advertiser'))
      catch
        {}
    set: (value) ->
      @data.set('advertiser', JSON.stringify(value))
  
  # Key presses to skip to avoid unnecessary searches
  @property 'noopKeys',
    get: ->
      [
        'Control',
        'Meta',
        'Alt',
        'Shift',
        'Escape',
        'ArrowUp',
        'ArrowDown',
        'ArrowLeft',
        'ArrowRight',
        'Enter',
        'Home',
        'End',
      ]
  
  # Fields known to the nested_advertiser form
  @property 'knownFields',
    get: ->
      [
        'id',
        'first_name',
        'last_name',
        'email',
        'company_name',
        'contact_phone',
        'mailing_address_street',
        'mailing_address_city',
        'mailing_address_state',
        'mailing_address_zip_code'
      ]

  initialize: ->
    @setVisibleTemplate(!!@advertiser.id)
    @render()

    @search = _debounce((event) =>
      if _isEmpty(@emailValueTarget.value)
        @setSearchingState(false)
      else
        @searchForAdvertiser(event)
    , 400).bind(@)
  
  render: (skipEmailValue = false) ->
    @knownFields.forEach (key) =>
      # Set text fields
      if @[_camelCase("has_#{key}_target")]
        @[_camelCase("#{key}_targets")].forEach (target) =>
          target.innerText = @advertiser[key]
      
      # Set form fields
      if @[_camelCase("has_#{key}_value_target")]
        if skipEmailValue and key is 'email'
          # Skip setting email value
        else
          @[_camelCase("#{key}_value_targets")].forEach (target) =>
            target.value = @advertiser[key] || null

  setVisibleTemplate: (isConnectedToPersistedAdvertiser) ->
    if isConnectedToPersistedAdvertiser
      @contentTarget.innerHTML = @advertiserConnectedTemplateTarget.innerHTML
    else
      @contentTarget.innerHTML = @noAdvertiserConnectedTemplateTarget.innerHTML

  clearAdvertiser: ->
    @advertiser = {}
    @setVisibleTemplate(false)
    @render()

  searchForAdvertiser: (event) ->
    url = @searchUrl + "?email=#{encodeURIComponent(@emailValueTarget.value)}"
    response = fetch(url)
      .then((response) => response.json())
      .then((json) =>
        if json is null
          @advertiser = {}
          @render(true)
        else
          @advertiser = json
          @setVisibleTemplate(true)
          @render(true)
        @setSearchingState(false)
      )

  setSearching: (event) ->
    if event.key in @noopKeys
      # Skip setting search state if nav or modifier keys are pressed
    else if _isEmpty(@emailValueTarget.value)
      # Skip setting search state if email field is emptied
    else
      @setSearchingState(true)

  setSearchingState: (value) ->
    if @hasVisibleOnSearchTarget
      @visibleOnSearchTargets.forEach (target) =>
        target.classList.toggle('hidden', !value)

    if @hasHiddenOnSearchTarget
      @hiddenOnSearchTargets.forEach (target) =>
        target.classList.toggle('hidden', value)

    if @hasDisableOnSearchTarget
      @disableOnSearchTargets.forEach (target) =>
        target.classList.toggle('disabled', value)
        target.disabled = value
