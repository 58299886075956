###
  Gives a human-readable version of a provided integer byte representation of
  a file size
 
  @param   {Number}  bytes  File size
  @return  {String}         Human-readable file size (e.g.: 10 MB, 150 kB, etc.)
###
bytesToHumanFileSize = (bytes) ->
  i = Math.floor(Math.log(bytes) / Math.log(1024))
  (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]

acceptedImageMimeTypes = ->
  return [
    "image/jpeg",
    "image/jpg",
    "image/pjpeg",
    "image/x-citrix-pjpeg",
    "image/png",
    "image/x-png",
    "image/gif",
    "image/tiff"
  ]

export { bytesToHumanFileSize, acceptedImageMimeTypes }