import { Controller } from "stimulus"
import Sortable from "sortablejs"
# @dependency jQuery, Bootstrap

export default class extends Controller
  @targets: [
    "container",
    "contentBlockId", # not used as a target but within a selector
    "position"
  ]

  initialize: ->
    @initializeSortable()

  initializeSortable: ->
    @containerTargets.forEach (target, index) =>
      new Sortable target,
        animation: 50,
        chosenClass: 'content-blocks__block--chosen'
        ghostClass: 'sortable-placeholder-highlight-variable'
        group:
          name: 'content-blocks'
          put: (toContainer, fromContainer, elementBeingMoved) =>
            return elementBeingMoved.dataset.isAContainer isnt "true"
        handle: '[role="sort_handle"]'
        swapThreshold: 0.25
        onEnd: (event) =>
          # Grab some values
          toContainerElement = event.to
          fromContainerElement = event.from
          elementBeingMoved = event.item

          # Modify the element being moved to show it is currently being updated
          elementBeingMoved.classList.add('text-more-muted')

          # Modify the handle to show it is currently being updated
          handle = elementBeingMoved.querySelector('[role=sort_handle]')
          handleOriginalClassList = handle.classList.value
          handle.className = 'fa fa-fw fa-spin fa-spinner text-more-muted'

          # Remove role to prevent sorting this element further
          handle.removeAttribute('role')

          # Add tooltip
          handle.setAttribute('title', 'Saving changes...')
          $(handle).tooltip()

          @_setParentContentBlock(toContainerElement, elementBeingMoved)
          @_performSort()

          # Set callback when form submission has completed
          $(@element).one 'ajax:success', =>
            # Revert element being moved style
            elementBeingMoved.classList.remove('text-more-muted')

            # Revert handle changes
            # Remove tooltip from the handle
            handle.removeAttribute('title')
            $(handle).tooltip('destroy')

            # Re-add role to the handle
            handle.setAttribute('role', 'sort_handle')

            # Return original classList to the handle
            handle.className = handleOriginalClassList

            # Empty out the container this was being moved from so CSS:empty
            # can function properly
            if fromContainerElement.innerHTML.trim() == ""
              fromContainerElement.innerHTML = ''

            # Finally, highlight the element
            @_highlightElement(elementBeingMoved)

          # Fire the UJS submit event to submit the form
          Rails.fire @element, 'submit'

  _setParentContentBlock: (container, element) ->
    hiddenField = element.querySelector("input[data-#{@identifier}-target='contentBlockId']")
    hiddenField.value = container.dataset.contentBlockId || null

  _performSort: ->
    @positionTargets.forEach((target, index) -> target.value = index)

  _highlightElement: (element) ->
    $(element).effect 'highlight',
      easing: 'easeInExpo'
    , 2000
