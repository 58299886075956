import { Controller } from "stimulus"
import _map from 'lodash/map'
# dependency: jQuery
# dependency: Typeahead.js

###

  Adds a static suggestion list to a standard text box.  Provide suggestions
  in a comma-separated list as data-suggestions-list.
  
  Example:
  
  .form-group{ data: { controller: 'suggestions', suggestions_list: 'General Inquiry, Exhibitor Information, Ticket Information' }}
    = f.text_field :subject, class: 'form-control', data: { target: 'suggestions.formControl' }
  
###
export default class extends Controller
  @targets: ['formControl']
  
  @property 'suggestionList',
    get: ->
      new Bloodhound
        datumTokenizer: Bloodhound.tokenizers.whitespace
        queryTokenizer: Bloodhound.tokenizers.whitespace
        local: _map @data.get('list').split(','), (item) =>
          "#{item}".trim()

  initialize: ->
    $(@formControlTarget).typeahead {
      hint: true,
      highlight: true,
      minLength: 0
    },
      name: 'list'
      source: @suggestionPresenter
  
  suggestionPresenter: (query, returnable) =>
    if query.length
      @suggestionList.search(query, returnable)
    else
      returnable(@suggestionList.all())