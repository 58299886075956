import { Controller } from 'stimulus'

export default class extends Controller
  @targets = [
    'disconnectButton',
    'disconnectCourseField',
    'restoreMessage',
  ]

  @values =
    disconnectCourse: Boolean

  disconnect: ->
    @disconnectCourseValue = true

  disconnectCourseValueChanged: ->
    if @disconnectCourseValue
      @disconnectButtonTarget.hidden = true
      @restoreMessageTarget.hidden = false

      @disconnectCourseFieldTarget.value = true

    else
      @disconnectButtonTarget.hidden = false
      @restoreMessageTarget.hidden = true

      @disconnectCourseFieldTarget.value = false

  restore: ->
    @disconnectCourseValue = false