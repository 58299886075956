import { Controller } from "stimulus"
import _filter from "lodash/filter"

###
  Compatibility layer controller to emit JS events from Cocoon's jQuery events.
  Add the controller to the container where elements are added and inserted
  to gain access to the events listed below.
  
  If you would like to, additionally, retrieve the number of currently visible
  elements (essential for cocoon-empty-set and cocoon-button-changer),
  add data-target="cocoon-events.element" to each child element.
  
  @example
  
    .div-where-stuff-goes{ data: { controller: 'other-controllers cocoon-events' }}
      = fields_for :stuff do |ff|
        = render 'stuff_fields', f: ff
        
        # In _stuff_fields.haml
        .nested-fields{ data: { target: 'cocoon-events.element' }}
          ...
  
  @note It is best to place this controller last in the event stack if you
  rely on the cocoonEventsInit event.  Placing it earlier may trigger before
  other controllers can load!
    
###
export default class extends Controller
  @targets: ['element']
  
  initialize: ->
    $(@element).on 'cocoon:after-insert', @afterInsert
    $(@element).on 'cocoon:after-remove', @afterRemove
    
    # Sometimes this fires off before other JS can run.  Like Sortable, that
    # may have a large amount of JS and page content to load, don't emit this
    # event until the DOM is loaded.  This is why it is recommended to put this
    # JS last in the list of controllers just in case.
    readyPromise = new Promise (resolve, reject) =>
      if document.readyState == 'loading'
        document.addEventListener 'DOMContentLoaded', resolve
      else
        resolve()
    
    readyPromise.then => @dispatchInitEvent()
  
  dispatchInitEvent: ->
    @element.dispatchEvent(new CustomEvent('cocoonEventsInit', { bubbles: true, detail: { currentVisibleElementCount: @_currentVisibleElementCount() }}))
  
  afterInsert: (event, $insertedElement) =>
    currentVisibleElementCount = @_currentVisibleElementCount()

    @element.dispatchEvent(new CustomEvent('cocoonChildElementAdded', { bubbles: true, detail: { currentVisibleElementCount: currentVisibleElementCount }}))
    @element.dispatchEvent(new CustomEvent('cocoonChildElementsChanged', { bubbles: true, detail: { change: 1, currentVisibleElementCount: currentVisibleElementCount }}))
  
  afterRemove: (event, $elementBeingRemoved) =>
    currentVisibleElementCount = @_currentVisibleElementCount()

    @element.dispatchEvent(new CustomEvent('cocoonChildElementRemoved', { bubbles: true, detail: { currentVisibleElementCount: currentVisibleElementCount }}))
    @element.dispatchEvent(new CustomEvent('cocoonChildElementsChanged', { bubbles: true, detail: { change: -1, currentVisibleElementCount: currentVisibleElementCount }}))
  
  _currentVisibleElementCount: =>
    if @hasElementTarget
      visibleTargets = _filter @elementTargets, (element) =>
        if element.classList.contains("hidden")
          false
        else
          window.getComputedStyle(element).display isnt 'none'
      
      visibleTargets.length
    else
      0