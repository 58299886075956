import { Controller } from "stimulus"
import _find from 'lodash/find'

export default class extends Controller
  @targets: [
    "previewWrapper", "previewMissingWrapper",
    "panelBody",
    "buttonIcon", "buttonText",
    "backgroundColor", "borderColor", "color",
    "link", "icon", "textWrapper",
    "modifiedNotice"
  ]
  
  @property 'defaultButtonIcon',
    get: -> @data.get('defaultButtonIcon')
  
  @property 'defaultButtonText',
    get: -> @data.get('defaultButtonText')

  initialize: ->
    @linkTarget.setAttribute('tabindex', '-1')
    @render()

  render: ->
    @iconTarget.className = @_computedIconClass()
    @textWrapperTarget.innerHTML = @_computedButtonText()
    @textWrapperTarget.className = @_computedButtonTextClass()
    @linkTarget.setAttribute('style', @_computedStyleAttributes())
    
    if !@_hasComputedIconClass() and !@_hasComputedButtonText() and !@_hasStyleAttributes()
      @previewMissingWrapperTarget.classList.remove('hidden')
      @previewWrapperTarget.classList.add('hidden')
    else
      @previewMissingWrapperTarget.classList.add('hidden')
      @previewWrapperTarget.classList.remove('hidden')
  
  toggleFields: (event) ->
    event.preventDefault()
    
    if @panelBodyTargets.length is 1
      @panelBodyTarget.classList.toggle('hidden')
    
    else if @panelBodyTargets.length > 1
      target = _find @panelBodyTargets, (target) =>
        target.dataset.warning == '1'
      if target
        target.classList.toggle('hidden')
  
  warningAcknowledged: (event) ->
    event.preventDefault()
    
    target = _find @panelBodyTargets, (target) =>
      target.dataset.warning == '1'
    if target
      target.remove()
      @toggleFields(event)
  
  revertToDefaultValues: (event) ->
    event.preventDefault()
    
    @buttonIconTarget.value =
      @buttonTextTarget.value =
      @backgroundColorTarget.value =
      @borderColorTarget.value =
      @colorTarget.value =
      ""
    
    @render()
    @modifiedNoticeTarget.remove()
  
  fieldChange: (event) ->
    @render()
  
  _hasComputedIconClass: ->
    @buttonIconTarget.value.trim().length or @defaultButtonIcon.length
  
  _computedIconClass: ->
    text = @buttonIconTarget.value.trim()
    
    if text.length
      text = "fa fa-with-margin-at-md #{text}"
    else
      text = "fa fa-with-margin-at-md #{ @defaultButtonIcon || 'hidden' }"
    
    text
  
  _hasComputedButtonText: ->
    @buttonTextTarget.value.trim().length or @defaultButtonText.length
  
  _computedButtonText: ->
    text = if @buttonTextTarget.value.trim().length
      @buttonTextTarget.value.trim()
    else
      @defaultButtonText
    
    if @_hasComputedIconClass()
      "&nbsp;#{text}"
    else
      text
  
  _computedButtonTextClass: ->
    if @_hasComputedIconClass()
      'hidden-xs hidden-sm'
    else
      ''
  
  _hasStyleAttributes: ->
    (@backgroundColorTarget.value.length is 6) or (@borderColorTarget.value.length is 6) or (@colorTarget.value.length is 6)
  
  _computedStyleAttributes: ->
    text = ''
    
    if @backgroundColorTarget.value.length is 6
      text += "background-color: ##{@backgroundColorTarget.value} !important;"
    
    if @borderColorTarget.value.length is 6
      text += "border-color: ##{@borderColorTarget.value} !important;"
    
    if @colorTarget.value.length is 6
      text += "color: ##{@colorTarget.value} !important;"
    
    text