import { Controller } from "stimulus"
import Sortable from "sortablejs"
import _each from 'lodash/each'
import _filter from 'lodash/filter'
import _includes from 'lodash/includes'

export default class extends Controller
  @targets = [
    "form",
    "container",
    "navigationSection", "navigationSectionHeading",
    "position"
  ]

  initialize: ->
    @initializeSortable()

  initializeSortable: ->
    _each @containerTargets, (target) =>
      new Sortable target,
        animation: 50
        ghostClass: 'sortable-placeholder-highlight-variable'
        group:
          name: 'group-pages-and-navigation'
          put: (toContainer, fromContainer, elementBeingMoved) =>
            # Programmatically define where items can be dropped.

            # Do not allow dropping on anything that isn't a container
            return false unless _includes(@containerTargets, toContainer.el)

            # Check permissions of each drop section for eligibility for move
            switch @_elementType(elementBeingMoved)
              when 'resource'
                return false if toContainer.el.dataset.topLevel

                switch @_elementTypeOfResource(elementBeingMoved)
                  when 'page' then toContainer.el.dataset.allowPages is 'true'
                  when 'link' then toContainer.el.dataset.allowLinks is 'true'
                  else false

              when 'navigation-section'
                @_isNavigationSectionAllowedInContainer(toContainer.el, elementBeingMoved)

              else false

        handle: '[role="sort_handle"]'
        swapThreshold: 0.25
        onEnd: (event) =>
          # Grab some values
          toContainerElement = event.to
          elementBeingMoved = event.item

          # Modify the element being moved to show it is currently being updated
          elementBeingMoved.classList.add('text-more-muted')

          # Modify the handle to show it is currently being updated
          handle = elementBeingMoved.querySelector('[role=sort_handle]')
          handleOriginalClassList = handle.classList.value
          handle.className = 'fa fa-fw fa-spin fa-spinner text-more-muted'

          # Remove role to prevent sorting this element further
          handle.removeAttribute('role')

          # Add tooltip
          handle.setAttribute('title', 'Saving changes...')
          $(handle).tooltip()

          # Update fields on the form
          @_setNavigationSectionId(elementBeingMoved)
          @_performSort()
          
          # Set callback when form submission has completed
          $(@formTarget).one 'ajax:success', =>
            # Revert element being moved style
            elementBeingMoved.classList.remove('text-more-muted')

            # Revert handle changes
            # Remove tooltip from the handle
            handle.removeAttribute('title')
            $(handle).tooltip('destroy')

            # Re-add role to the handle
            handle.setAttribute('role', 'sort_handle')

            # Return original classList to the handle
            handle.className = handleOriginalClassList

            # Finally, highlight the element
            @_highlightElement(elementBeingMoved)

          # Fire the UJS submit event to submit the form
          Rails.fire @formTarget, 'submit'

  _setNavigationSectionId: (element) ->
    # Find the relevant hidden field for navigation_section_id
    hiddenField = document.querySelector("input[data-record-id='#{element.dataset.recordId}'][data-type='#{element.dataset.type}'][name*='navigation_section_id']")

    # Find the closest navigation section (if applicable)
    navigationSection = @_findClosestNavigationSectionToResource(element)

    # Get the new navigation section ID or null
    newValue = if navigationSection then navigationSection.dataset.recordId else ""

    # Set the hidden field
    hiddenField.value = newValue

  _performSort: ->
    _each @positionTargets, (target, index) ->
      target.value = index
      true

  _highlightElement: (element) ->
    elementToHighlight = element

    if @_elementType(element) is 'navigation-section'
      _each @navigationSectionHeadingTargets, (heading) =>
        if heading.dataset.recordId == element.dataset.recordId
          elementToHighlight = heading
          false
        else
          true

    $(elementToHighlight).effect 'highlight',
      easing: 'easeInExpo'
    , 1000

  # data-type of an element being moved.
  # @return [String] "resource" or "navigation-section"
  _elementType: (element) ->
    element.dataset.type

  # type of a "resource" being moved.
  # @return [String] "link" or "page"
  _elementTypeOfResource: (element) ->
    element.dataset.typeOfResource

  # Determines if the navigation section being moved is allowed in the
  # drop target container.
  # @return [Boolean]
  _isNavigationSectionAllowedInContainer: (targetContainer, elementBeingMoved) ->
    # Filtering all children of the target container by @containerTargets,
    # including the top level container.
    dropContainers = _filter @_getParents(targetContainer), (target) =>
      _includes(@containerTargets, target)

    # Never allow a nav section to have more than 1 nested child
    maxDepth = parseInt(elementBeingMoved.dataset.maxDepth) || 2
    return false if dropContainers.length > maxDepth

    # At this point, there is one or two drop targets (the parent container +
    # a possible subcontainer). If we are moving a system-generated nav section,
    # make sure there isn't a restriction on it.
    if elementBeingMoved.dataset.typeOfNavigationSection is 'system'
      hasRestrictions = _filter dropContainers, (dropContainer) =>
        dropContainer.dataset.allowSystemGeneratedSections is 'false'
      hasRestrictions.length is 0
    else
      hasRestrictions = _filter dropContainers, (dropContainer) =>
        dropContainer.dataset.allowNavigationSections is 'false'
      hasRestrictions.length is 0

  # Return all parents up until, and excluding, @element
  _getParents: (elements) ->
    elements = [elements].flat()
    element = elements[elements.length - 1]

    if element is null or element is undefined
      return elements

    parentElement = element.parentElement
    return elements if parentElement is @element

    elements.push(parentElement)

    @_getParents(elements)

  # Find the closest navigation section to a resource given.
  # @return [Node]
  _findClosestNavigationSectionToResource: (element) ->
    closestNavigationSection = element.parentElement
    unless closestNavigationSection.dataset.type == 'navigation-section'
      closestNavigationSection = element.parentElement.closest(@_navigationSectionMatcher())

    closestNavigationSection

  # Matcher for use with querySelector/querySelectorAll to accurately find a
  # navigation section element.
  # @return [String]
  _navigationSectionMatcher: ->
    "[data-type='navigation-section'][data-#{@identifier}-target~='navigationSection']"
