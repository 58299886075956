import { Controller } from "stimulus"

export default class extends Controller
  @targets: [
    "refreshButtonContainer",
    "tr"
  ]

  @property 'openedCount',
    get: -> parseInt(@data.get('openedCount'))
    set: (value) -> @data.set('openedCount', value)

  initialize: ->
    @waiting = @data.get('waiting') == "true"
    @hasFetchedStats = @data.get('hasFetchedStats') == 'true'
    @requestRefreshUrl = @data.get('refreshUrl')
    @pollingUrl = @data.get('url')
    @openedCount = 0

    if @waiting
      @startTimer()

  handleOpened: (event) ->
    return if @hasFetchedStats
    return if @openedCount > 0
    @openedCount = 1

    $.get(@requestRefreshUrl)
    @refresh()

  refresh: (event) ->
    if @waiting is true
      if event
        event.preventDefault()
      return

    @waiting = true

    @startTimer()

  startTimer: ->
    if @hasRefreshButtonContainerTarget
      $(@refreshButtonContainerTarget).replaceWith '''
        <span class="highlight-yellow small"><i class="fa fa-refresh fa-spin"></i> Refreshing...</span>
      '''

    @timer = setInterval =>
      request = $.get(@pollingUrl)

      request.done (response) =>
        if response.refreshed is true
          clearInterval(@timer)
          @waiting = false
          $(@trTarget).replaceWith(response.html)

    , 3000