import { Controller } from 'stimulus'
import _isEmpty from 'lodash/isEmpty'

export default class extends Controller
  @targets: [
    'nameFieldContainer',       # .form-group for the name field
      'nameField',              # input[type=text] field for name
    'nameText',                 # Anywhere to real-time update name in section
    'groupTypeRadio'            # input[type=radio] for group types
    # Not specified:
    #   'nationalGroupType, 'stateGroupType', 'localGroupType', 'expertGroupType'
    #   These are evaluated by @targets.findAll dynamically.
  ]

  initialize: ->
    @toggleGroupType()

  nameChanged: ->
    @nameTextTargets.forEach (target) =>
      target.innerText = @nameFieldTarget.value

  toggleGroupType: ->
    @_allGroupTypes().forEach (type) =>
      unless type is @_currentGroupType()
        @targets.findAll("#{type}GroupType").forEach (target) =>
          target.classList.add('hidden')
          target.querySelectorAll('input, select').forEach((target) -> target.setAttribute('disabled', 'disabled'))

    @targets.findAll("#{@_currentGroupType()}GroupType").forEach (target) =>
      target.classList.remove('hidden')
      target.querySelectorAll('input, select').forEach((target) -> target.removeAttribute('disabled'))

  _allGroupTypes: ->
    @groupTypeRadioTargets.map((field) -> field.value)

  _currentGroupType: ->
    @groupTypeRadioTargets.find((field) -> field.checked).value
