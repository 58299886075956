import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["locationFieldset"]

  @property 'exportType',
    get: ->
      @data.get('exportType')

    set: (value) ->
      @data.set('exportType', value)
      @render()

  initialize: ->
    @render()

  updateExportType: (event) ->
    @exportType = event.target.value

  render: ->
    if @exportType in ['distance', 'exact']
      @locationFieldsetTarget.disabled = @locationFieldsetTarget.hidden = false

    else
      @locationFieldsetTarget.disabled = @locationFieldsetTarget.hidden = true