import { Controller } from "stimulus"

export default class extends Controller
  @targets: [
    'newGroupTemplate',
    'main',
    'group',
      'groupContents',
    'condition'
  ]

  addCondition: (event) ->
    container = @_findClosestGroupContents(event.currentTarget)
    template = event.currentTarget.parentElement.querySelector('template').innerHTML
    template = template.replace(/NEW_RECORD/g, new Date().getTime())
    container.insertAdjacentHTML('beforeend', template)

  addTopLevelConditionGroup: (event) ->
    template = @newGroupTemplateTarget.innerHTML
    template = template.replace(/NEW_OBJECT/g, 'q')
    template = template.replace(/NEW_GROUPING/g, new Date().getTime())
    @mainTarget.insertAdjacentHTML('beforeend', template)

  addNestedConditionGroup: (event) ->
    infoContainer = @_findClosestGroup(event.currentTarget)
    container = @_findClosestGroupContents(event.currentTarget)
    template = @newGroupTemplateTarget.innerHTML
    template = template.replace(/NEW_OBJECT/g, infoContainer.dataset.objectName)
    template = template.replace(/NEW_GROUPING/g, new Date().getTime())
    container.insertAdjacentHTML('beforeend', template)

  removeCondition: (event) ->
    event.currentTarget.closest("[data-#{@identifier}-target='condition']").remove()

  removeConditionGroup: (event) ->
    event.currentTarget.closest("[data-#{@identifier}-target='group']").remove()

  _findClosestGroup: (element) ->
    element.closest("[data-#{@identifier}-target='group']")

  _findClosestGroupContents: (element) ->
    if target = @_findClosestGroup(element)
      target.querySelector("[data-#{@identifier}-target='groupContents']")
    else
      @mainTarget
