import { Controller } from 'stimulus'
import { Geolocation as GeolocationModel } from 'shared/models/geolocation'
import _debounce from 'lodash/debounce'
import _find from 'lodash/find'
import _isEmpty from 'lodash/isEmpty'

export default class extends Controller
  @targets: [
    'latField', 'lngField',
    'mapCanvas', 'mapError',
    'searchFieldContainer',
      'searchField'
    'nameContainer',
      'name',
    'nameFieldContainer',
      'nameField',
    'addressContainer',
      'address',
    'addressFieldsContainer',
      'streetAddressField',
      'cityField',
      'stateField',
      'zipCodeField',
    'newSearchContainer',
  ]

  initialize: ->
    @model = new GeolocationModel(JSON.parse(@data.get('data')))

    # Set up debounce for this functions
    debounceTime = 500
    @setStreetAddress = _debounce(@setStreetAddress, debounceTime).bind(@)
    @setCity = _debounce(@setCity, debounceTime).bind(@)
    @setState = _debounce(@setState, debounceTime).bind(@)
    @setZipCode = _debounce(@setZipCode, debounceTime).bind(@)

    @_unwrapAllFieldErrors()
    @render()

  render: ->
    # Hide or show location fields
    @element.classList.toggle('hidden', !@model.isMappable)
    @_assignModelPropertiesToFormFieldValues()

    ###*
     * This will render out the form in three different ways:
     * 1. If a complete address is present ("complete"), display it nicely
     *    on the page.
     * 2. If only a partial address is present or lat/lng values are missing,
     *    display the address fields to complete.
     * 3. If an address is missing, display the search field.
    ###
    switch @model.currentState()
      when 'complete'
        @toggleSearchContainer(false)
        @togglePresentationContainers(true)
        @toggleFieldContainers(false)
        @toggleNewSearchContainer(true)

        # If a location is present but the name isn't, show the edit name field
        @toggleNameField(_isEmpty(@model.name))

      when 'partial'
        @toggleSearchContainer(false)
        @togglePresentationContainers(false)
        @toggleFieldContainers(true)
        @toggleNewSearchContainer(true)
        @_toggleErrors()

      when 'pending'
        @toggleSearchContainer(true)
        @togglePresentationContainers(false)
        @toggleFieldContainers(false)
        @toggleNewSearchContainer(false)

    # Regardless of the case, notify the map canvas to render or hide
    @_notifyMapCanvas()

  toggleSearchContainer: (shouldShow) ->
    @searchFieldContainerTarget.classList.toggle('hidden', !shouldShow)

  togglePresentationContainers: (shouldShow) ->
    @nameContainerTarget.classList.toggle('hidden', !shouldShow)
    @addressContainerTarget.classList.toggle('hidden', !shouldShow)

  toggleFieldContainers: (shouldShow) ->
    @nameFieldContainerTarget.classList.toggle('hidden', !shouldShow)
    @addressFieldsContainerTarget.classList.toggle('hidden', !shouldShow)

  toggleNameField: (shouldShow) ->
    @nameContainerTarget.classList.toggle('hidden', shouldShow)
    @nameFieldContainerTarget.classList.toggle('hidden', !shouldShow)
    @nameFieldTarget.focus() if shouldShow

  toggleNewSearchContainer: (shouldShow) ->
    @newSearchContainerTarget.classList.toggle('hidden', !shouldShow)

  ###*
   * Prevent enter keypress on the Google Places search field.
   * This prevents the user from submitting the form while simply trying to
   * select a location.
  ###
  preventKeydown: (event) ->
    if event.keyCode == 13
      event.preventDefault()
      return false

  ###*
   * When a user clicks the "Edit Location Name" link, this updates the
   * presentation to display the location name field and hide the
   * readable content.
  ###
  editName: (event) ->
    event.preventDefault()
    @toggleNameField(true)
    @nameFieldTarget.focus()

  ###*
   * When a user clicks the "Add An Address Manually" link,
   * show all of the editing fields without any errors.
  ###
  enterAddressManually: (event) ->
    event.preventDefault()

    @toggleSearchContainer(false)
    @togglePresentationContainers(false)
    @toggleFieldContainers(true)
    @toggleNewSearchContainer(true)
    @_resetFormFieldErrors()
    @nameFieldTarget.focus()

  ###*
   * When a user clicks the "Manually Edit This Location" link,
   * show all of the editing fields, and highlight any errors.
  ###
  editAllFields: (event) ->
    event.preventDefault()

    @toggleSearchContainer(false)
    @togglePresentationContainers(false)
    @toggleFieldContainers(true)
    @toggleNewSearchContainer(true)
    @nameFieldTarget.focus()
    @_toggleErrors()

  ###*
   * When a user clicks the "Find A New Address" link, resets the location
   * information on the model.
  ###
  resetSearch: (event) ->
    event.preventDefault()

    @model.clear()
    @_assignModelPropertiesToFormFieldValues()
    @_resetFormFieldErrors()

    $(@element).scrollTop()
    @render()
    @searchFieldTarget.focus()

  # Callback function used to show or hide location fields
  setMappableLocation: (event) ->
    @model.isMappable = event.detail
    @_notifyMapCanvas()
    @render()

  # Callback function invoked when the Google Places autocomplete has found
  # a location
  setPlace: (event) ->
    @model.setLocationFromPlace(event.detail)
    @_assignModelPropertiesToFormFieldValues()
    @render()
    # Clear out the search field for another use
    @searchFieldTarget.value = ''

    if @model.currentState isnt 'complete'
      @nameFieldTarget.focus()

  setRecentLocation: (event) ->
    event.preventDefault()
    @model = new GeolocationModel(JSON.parse(event.currentTarget.dataset.data))
    @_resetFormFieldErrors()
    @render()

  # Set the name value on the model when the name field has changed
  setName: (event) ->
    @model.name = event.currentTarget.value
    @_toggleFormFieldError('name')

  # Set the streetAddress value on the model when the streetAddress field has changed
  setStreetAddress: ->
    @model.streetAddress = @streetAddressFieldTarget.value
    @_attemptManualGeolocation => @_toggleFormFieldError('streetAddress')

  # Set the city value on the model when the city field has changed
  setCity: ->
    @model.city = @cityFieldTarget.value
    @_attemptManualGeolocation => @_toggleFormFieldError('city')

  # Set the state value on the model when the state field has changed
  setState: ->
    @model.state = @stateFieldTarget.value
    @_attemptManualGeolocation => @_toggleFormFieldError('state')

  # Set the zipCode value on the model when the zipCode field has changed
  setZipCode: ->
    @model.zipCode = @zipCodeFieldTarget.value
    @_attemptManualGeolocation => @_toggleFormFieldError('zipCode')

  # Toggle the map error and any form errors
  _toggleErrors: ->
    @mapErrorTarget.classList.toggle('hidden', @model.currentState() isnt 'partial')
    @_toggleAllFormFieldErrors()

  # Toggle the error state of a field
  # @param field [String] the @target name, minus the suffixing "Field"
  _toggleFormFieldError: (field) ->
    target = @targets.find("#{field}Field")
    target.parentElement.classList.toggle('has-error', _isEmpty(@model[field]))

  # Toggle the error state of all fields
  _toggleAllFormFieldErrors: ->
    ["name", "streetAddress", "city", "state", "zipCode"].forEach (field) =>
      @_toggleFormFieldError(field)

  # Reset/remove all form field errors
  _resetFormFieldErrors: ->
    ["name", "streetAddress", "city", "state", "zipCode"].forEach (field) =>
      target = @targets.find("#{field}Field")
      target.parentElement.classList.remove('has-error')

  # Attempt to find a location from a manual address entry using our geocoder
  # endpoint
  #
  # @param callbackFunction [function, null] called when complete,
  #   regardless of the outcome
  _attemptManualGeolocation: (callbackFunction = null) ->
    @model.clear('lat')
    @model.clear('lng')

    if @model.hasFullAddress()
      request = $.get @model.internalGeolocationEndpoint,
        location: @model.fullAddress()
      
      request.done (data, textStatus, jqXHR) =>
        @model.lat = data.lat
        @model.lng = data.lng
        @_attemptManualGeolocationCompleted(callbackFunction)

      request.fail (data, textStatus, errorThrown) =>
        @_attemptManualGeolocationCompleted(callbackFunction)

    else
      @_attemptManualGeolocationCompleted(callbackFunction)

  # Action that occurs when @_attemptManualGeolocation is complete, regardless
  # of the outcome.
  #
  # @param callbackFunction [function, null]
  _attemptManualGeolocationCompleted: (callbackFunction = null) ->
    @_notifyMapCanvas()
    if callbackFunction
      callbackFunction()

  # Assigns the form field values to those present in the model
  _assignModelPropertiesToFormFieldValues: ->
    @nameTarget.innerText = @model.name
    @addressTarget.innerHTML = @model.fullAddress("<br/>")

    ["lat", "lng", "name", "streetAddress", "city", "state", "zipCode"].forEach (str) =>
      for target in @targets.findAll("#{str}Field")
        target.value = @model.get(str) or ''

  ###*
   * On initialize, removes Rails' default .field_with_errors, which wraps
   * both labels and fields, with a simple .has-error class on the .form-group.
   * This makes highlighting fields that are missing much easier and consistent,
   * instead of relying on DOM manipulation and moving/replacing elements to
   * use .field_with_errors.
  ###
  _unwrapAllFieldErrors: ->
    @element.querySelectorAll('.field_with_errors').forEach (removable) ->
      # Get the parent. This assumes this is a .form-group.
      parent = removable.parentElement

      # Move a copy of each child node to the parent
      while removable.firstChild
        parent.insertBefore(removable.firstChild, removable)

      # Remove the .field_with_errors node
      parent.removeChild(removable)

      # Ensure the error class is set on the .form-group
      parent.classList.add('has-error')

  ###*
   * When a change occurs, dispatch a refresh CustomEvent to the map canvas
   * to change its presentation.
  ###
  _notifyMapCanvas: ->
    customEvent = new CustomEvent 'refresh',
      detail:
        ready: (@model.isMappable and @model.currentState() is 'complete')
        lat: @model.lat
        lng: @model.lng
    @mapCanvasTarget.dispatchEvent(customEvent)
