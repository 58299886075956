import { Controller } from 'stimulus'

export default class extends Controller
  @values =
    aacnBod: Boolean
    aacnNameDiscrepancy: Boolean

  @targets: [
    'aacnNameDiscrepancyMessageFieldset',
    'connectedUserNameHelpBlock',
    'connectedUserPhotoHelpBlock',
    'connectedUserTitlesHelpBlock',
    'formFields',
    'userAutocomplete',
    'requiredWithoutUserLabel',
    'nameField',
    'professionalTitlesField',
    'aacnCustomerIdField'
  ]
  
  initialize: ->
    @user = JSON.parse(@userAutocompleteTarget.dataset.autocompleteUsersUser) or {}
    @render(true)

    if @aacnNameDiscrepancyValue
      @formFieldsTarget.hidden = true

  render: ->
    @requiredWithoutUserLabelTargets.forEach (target) =>
      target.classList.toggle('required', !@user.id)

    if @user.id
      if @nameFieldTarget.value is @user.name
        @nameFieldTarget.value = ''

      if @professionalTitlesFieldTarget.value is @user.professional_titles
        @professionalTitlesFieldTarget.value = ''

      @connectedUserNameHelpBlockTarget.hidden = false
      @connectedUserNameHelpBlockTarget.innerHTML = "<i class='fa fa-info-circle'></i> <i>Optional:</i> Leave blank to use name of the connected user <i>(#{@user.name})</i>."

      @connectedUserPhotoHelpBlockTarget.hidden = false
      @connectedUserPhotoHelpBlockTarget.innerHTML = "<i class='fa fa-info-circle'></i> <i>Optional:</i> Leave blank to use profile photo of the connected user."

      @connectedUserTitlesHelpBlockTarget.hidden = false
      @connectedUserTitlesHelpBlockTarget.innerHTML = "<i class='fa fa-info-circle'></i> <i>Optional:</i> Leave blank to use titles of the connected user <i>(#{@user.professional_titles})</i>."

    else
      @connectedUserNameHelpBlockTarget.hidden = true
      @connectedUserNameHelpBlockTarget.innerHTML = ''

      @connectedUserPhotoHelpBlockTarget.hidden = true
      @connectedUserPhotoHelpBlockTarget.innerHTML = ''

      @connectedUserTitlesHelpBlockTarget.hidden = true
      @connectedUserTitlesHelpBlockTarget.innerHTML = ''

  update: (event) ->
    @user = event.detail or {}
    if @aacnBodValue && @hasAacnCustomerIdFieldTarget
      @aacnCustomerIdFieldTarget.value = (@user.aacn_membership_id || '')
    @render()

  editFromAacnNameDiscrepancy: (event) ->
    @aacnNameDiscrepancyMessageFieldsetTarget.hidden = true
    @aacnNameDiscrepancyMessageFieldsetTarget.disabled = true
    @formFieldsTarget.hidden = false