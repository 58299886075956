import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["focusable", "selectable"]

  initialize: ->
    # Note: if trying to focus on a field that uses Javascript to duplicate
    # the field (such as Twitter/BS Autocomplete), this will not work.
    if @hasFocusableTarget
      @focusableTarget.focus()
    if @hasSelectableTarget
      @selectableTarget.select()