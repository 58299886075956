import { Controller } from "stimulus"

# This JS handles the process of adding or removing seats/tickets/items
# from within a modal.
export default class extends Controller
  @targets: [
    'addQuantityContainer',
    'removeQuantityContainer',
    'quantityEntry',
    'submitToConfirmButton'
  ]

  @property 'counter',
    get: -> parseInt(@data.get('counter'))
    set: (value) -> @data.set('counter', value)

  initialize: ->
    @_reset()
  
  showAddQuantityToggle: (event) ->
    event.preventDefault()
    @_reset()
    @_activateContainer(@addQuantityContainerTarget)

  showRemoveQuantityToggle: (event) ->
    event.preventDefault()
    @_reset()
    @_activateContainer(@removeQuantityContainerTarget)

  entryCheck: (event) ->
    @counter = event.currentTarget.value.length

    if @counter > 0
      @submitToConfirmButtonTarget.removeAttribute('disabled')
    else
      @submitToConfirmButtonTarget.setAttribute('disabled', 'disabled')

  _reset: ->
    @submitToConfirmButtonTarget.setAttribute('disabled', 'disabled')
    @quantityEntryTargets.forEach (target) ->
      target.value = ''
      target.setAttribute('disabled', 'disabled')
    [@addQuantityContainerTarget, @removeQuantityContainerTarget].forEach (target) ->
      target.classList.add('hidden')
    @counter = 0

  _activateContainer: (container) ->
    container.querySelectorAll('input').forEach (target) ->
      target.removeAttribute('disabled')
    container.classList.remove('hidden')
    container.querySelector('input[type="text"]').focus()