import { Controller } from 'stimulus'

# Injects a caret icon that can be clicked to toggle a section. Example:
#
#   %div{ data: { controller: 'collapse-with-caret' }}
#     %h1
#       %a{ data: {
#         collapse_with_caret_target: 'caret',      # required to inject caret on initialize
#         action: 'collapse-with-caret#toggle'      # action that toggles state
#       }, aria: {
#         expanded: 'true',                         # set to "false" to hide section on load
#         controls: 'id-of-section-to-control'
#       }}
#       Page Title
#
#     #id-of-section-to-control
#       Content
#
export default class extends Controller
  @targets: ['caret']

  # By default, carets have the right margin removed for a more condensed
  # appearance. set data-collapse-with-caret-include-right-margin="true"
  # to include standard FA icon right side padding.
  @property 'includeRightMargin',
    get: -> @data.get('includeRightMargin') is 'true'
  
  # By default, collapsed carets will point to the right. Set
  # data-collapse-with-caret-collapsed-direction="left" to use a left-pointing
  # caret instead.
  @property 'collapsedDirection',
    get: -> @data.get('collapsedDirection') or 'right'

  # Similar to @collapsedDirection, @expandedDirection can be set with
  # data-collapse-with-caret-expanded-direction="up" to use an up-pointing
  # caret instead.
  @property 'expandedDirection',
    get: -> @data.get('expandedDirection') or 'down'

  # Use .fa-fw on icons
  @property 'fixedWidth',
    get: -> @data.get('fixedWidth') is 'true'

  # Retain the initial link text
  @property 'retainText',
    get: -> @data.get('retainText') is 'true'

  @property 'injectCaret',
    get: -> @data.get('injectCaret') or 'before'

  initialize: ->
    @caretTargets.forEach (target) =>
      if @retainText
        target.setAttribute('title', target.innerText)
      if target.getAttribute('aria-expanded') is 'true'
        @setCaretExpanded(target)
      else
        @setCaretCollapsed(target)
      @setCaretControls(target)

  toggle: (event) ->
    event.preventDefault()
    @toggleCaret(event.currentTarget)

  toggleCaret: (caretTarget) ->
    if caretTarget.getAttribute('aria-expanded') is 'true'
      @setCaretCollapsed(caretTarget)
    else
      @setCaretExpanded(caretTarget)
    @setCaretControls(caretTarget)

  setCaretCollapsed: (caretTarget) ->
    caretTarget.setAttribute('aria-expanded', 'false')
    if @injectCaret == 'before'
      caretTarget.innerHTML = "<i class='#{ @caretIconClasses(@collapsedDirection) }'></i>#{ @retainedText(caretTarget) }"
    else
      caretTarget.innerHTML = "#{ @retainedText(caretTarget) } <i class='#{ @caretIconClasses(@collapsedDirection) }'></i>"

  setCaretExpanded: (caretTarget) ->
    caretTarget.setAttribute('aria-expanded', 'true')
    if @injectCaret == 'before'
      caretTarget.innerHTML = "<i class='#{ @caretIconClasses(@expandedDirection) }'></i>#{ @retainedText(caretTarget) }"
    else
      caretTarget.innerHTML = "#{ @retainedText(caretTarget) } <i class='#{ @caretIconClasses(@expandedDirection) }'></i>"

  setCaretControls: (caretTarget) ->
    toggleTarget = @element.querySelector("##{ caretTarget.getAttribute('aria-controls') }")
    toggleTarget.classList.toggle 'hidden', (caretTarget.getAttribute('aria-expanded') isnt 'true')

  caretIconClasses: (direction) ->
    classList = ['fa', "fa-caret-#{direction}"]

    unless @includeRightMargin
      classList.push 'no-right-margin'

    if @fixedWidth
      classList.push 'fa-fw'

    classList.join(' ')

  retainedText: (target) ->
    if @retainText
      " #{target.title}"
    else
      ""