import { Controller } from "stimulus"

export default class extends Controller
  @targets: ['cancelRemoveAttachment', 'manageAttachment', 'removeField']

  @property 'remove',
    get: ->
      @removeFieldTarget.value == '1'

    set: (value) ->
      @removeFieldTarget.value = value
      @update()

  initialize: ->
    @update()

  toggleRemove: ->
    if @remove
      @remove = '0'
    else
      @remove = '1'

  update: ->
    if @remove
      @manageAttachmentTarget.hidden = true
      @cancelRemoveAttachmentTarget.hidden = false

    else
      @manageAttachmentTarget.hidden = false
      @cancelRemoveAttachmentTarget.hidden = true