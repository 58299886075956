import { Controller } from "stimulus"
import { pusherInstance } from '../../shared/pusher'
import _template from 'lodash/template'

export default class extends Controller
  @targets = ['template', 'swap']

  @values =
    channel: String
    event: String
    disconnectAfterCatch: Boolean

  initialize: ->
    if @channelValue && @eventValue
      @template = _template(@templateTarget.innerHTML)
      
      @pusher = pusherInstance(@channelValue)
      
      @pusher.bind @eventValue, (data) =>
        @swapTarget.innerHTML = @template(data)
        
        if @disconnectAfterCatchValue
          @pusher.disconnect()