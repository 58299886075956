breakpoints =
  xs:
    max: 767
  sm:
    min: 768
    max: 991
  md:
    min: 992
    max: 1199
  lg:
    min: 1200

currentBreakpoint = ->
  width = document.documentElement.clientWidth
  
  switch
    when width <= breakpoints.xs.max then 'xs'
    when width >= breakpoints.sm.min and width <= breakpoints.sm.max then 'sm'
    when width >= breakpoints.md.min and width <= breakpoints.md.max then 'md'
    when width >= breakpoints.lg.min then 'lg'

module.exports = { breakpoints, currentBreakpoint }