import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["emailField", "userSearchField", "userSearchResultTable", "searchingIndicator", 
    "searchPanel", "toggleSearchPanelBtnText"]

  connect: ->
    @toggleSearchPanelState = 'closed'

  userSearchNoResultsTemplate: "
    <tr>
      <td colspan='4' class='text-center text-danger'>
        <i class='fa fa-exclamation-triangle'></i>
        <strong>No results found</strong>
      </td>
    </tr>
  "

  userSearchResultRowTemplate: _.template("
    <tr>
      <td>
        <strong><%= user.name %></strong>
        <small class='text-muted'><%= user.professional_titles %></small>
      </td>
      <td>
        <%= user.email %>
      </td>
      <td>
        <%= user.location_city_state %>
      </td>
      <td class='text-right'>
        <button 
          class='btn btn-sm btn-primary'
          type='button' 
          data-action='prospect-group-contact#copyEmailToRecord' 
          data-email='<%= user.email %>'>
            Copy email
        </button>
      </td>
    </tr>
  ", variable: "user")

  toggleSearchPanel: ->
    if @toggleSearchPanelState == 'closed'
      # Show the search panel.
      $(@searchPanelTarget).slideDown('fast')

      # Set the internal state to open
      @toggleSearchPanelState = 'open'

      # Store the contents of the toggle button's text.
      @originalToggleBtnText = $(@toggleSearchPanelBtnTextTarget).html()

      # Update the toggle button's text
      $(@toggleSearchPanelBtnTextTarget).html("Hide search")

    else
      # Hide the search panel.
      $(@searchPanelTarget).slideUp('fast')

      # Set the internal state to closed
      @toggleSearchPanelState = 'closed'

      # Restore the contents of the original toggle button's text.
      $(@toggleSearchPanelBtnTextTarget).html(@originalToggleBtnText)

  copyEmailToRecord: (event) ->
    # Copy the email address to the email field
    @emailFieldTarget.value = $(event.target).data('email')
    
    # Highlight the field.
    $(@emailFieldTarget).effect('highlight', {}, 3000)

    # Scroll the field into view.
    $(@element).get(0).scrollIntoView()

  handleUserSearchFieldKeyPress: (event) ->
    # Prevent enter in the search field from submitting any outer form, instead, use it to trigger search.
    if event.keyCode == 13
      event.preventDefault()
      @searchForUsers()

  searchForUsers: ->
    if @userSearchFieldTarget.value
      # Show the searching indicator.
      @searchingIndicatorTarget.hidden = false

      # Start the user search request.
      request = $.ajax
        method: "GET"
        url: @data.get("userSearchUrl")
        data:
          q: 
            full_name_cont: @userSearchFieldTarget.value
      
      # If the search succeeds
      request.then (response) =>
        # Empty the results table
        $(@userSearchResultTableTarget).empty()

        if response.length
          # If the user search returned results, present them.
          for user in response
            $(@userSearchResultTableTarget).append(@userSearchResultRowTemplate(user))
        
        else
          # Otherwise, show the no results template.
          $(@userSearchResultTableTarget).append(@userSearchNoResultsTemplate)

      request.done =>
        # Regardless of success or fail. The request is finished. Hide the indicator
        @searchingIndicatorTarget.hidden = true