import { Controller } from "stimulus"
import _map from 'lodash/map'

export default class extends Controller
  @targets = [
    'spinner',
    'label',
    'progressContainer',
      'progressBar',
    'takingTooLongMessage',
    'genericErrorMessage',
    'fullErrorMessageContainer',
      'fullErrorMessage',
      'fullErrorBacktrace'
  ]
  
  @property 'pollUrl',
    get: -> @data.get('pollUrl')

  @property 'tickInterval',
    get: -> 1500

  @property 'currentState',
    get: -> @data.get('currentState') or 'queued'
    set: (value) -> @data.set('currentState', value)

  @property 'timeSinceLastUpdate',
    get: ->
      if @data.get('timeSinceLastUpdate')
        parseInt(@data.get('timeSinceLastUpdate'))
      else
        0
    set: (value) -> @data.set('timeSinceLastUpdate', value)

  @property 'takingTooLongTick',
    get: -> 30000
  
  initialize: ->
    @timer = setInterval =>
      request = $.get(@pollUrl)

      request.done (response) =>
        # If the @currentState has not changed, increment @timeSinceLastUpdate
        # by @tickInterval. If it has changed, set @timeSinceLastUpdate to 0.
        if @currentState is response['processing_state']
          @timeSinceLastUpdate = @timeSinceLastUpdate + @tickInterval
        else
          @currentState = response['processing_state']
          @timeSinceLastUpdate = 0

        if @currentState is 'completed'
          # If finished, kill the timer and run the set of commands on success
          clearInterval(@timer)
          @responseTick(response)
          @complete()
        
        else if @currentState is 'failed'
          # If failed, kill the timer and run the set of commands on failure
          clearInterval(@timer)
          @failed(response)

        else
          # Start the spinner and respond to the tick
          @spinnerTarget.classList.add('fa-spin')
          @responseTick(response)

        if @timeSinceLastUpdate > @takingTooLongTick
          @takingTooLongMessageTarget.classList.remove('hidden')

      request.error (response) =>
        clearInterval(@timer)
        @failed(response)

    , @tickInterval

  responseTick: (response) ->
    currentRecordsValidated = @progressBarTarget.getAttribute('aria-valuenow') or 0
    updatedRecordsValidated = response.records_validated or 0

    if "#{currentRecordsValidated}" isnt "#{updatedRecordsValidated}"
      @timeSinceLastUpdate = 0

    @progressBarTarget.style.width = @calculateWidthString(updatedRecordsValidated)
    @progressBarTarget.setAttribute('aria-valuenow', updatedRecordsValidated)
    @labelTarget.innerText = "#{response.records_validated} / #{@totalRows()}"
  
  complete: ->
    @takingTooLongMessageTarget.classList.add('hidden')
    @spinnerTarget.classList.remove('fa-spin')
    @progressBarTarget.style.width = '100%'
    @progressBarTarget.setAttribute('aria-valuenow', @totalRows())
    @progressBarTarget.classList.remove('active')
    @progressBarTarget.classList.remove('progress-bar-striped')
    @progressBarTarget.classList.remove('progress-bar-info')
    @progressBarTarget.classList.add('progress-bar-success')
    
    @labelTarget.innerText = 'Done! Reloading page...'
    
    location.reload()
  
  failed: (response) ->
    @takingTooLongMessageTarget.classList.add('hidden')
    @progressContainerTarget.classList.add('hidden')
    @genericErrorMessageTarget.classList.remove('hidden')
    
    if @hasFullErrorMessageContainerTarget
      @fullErrorMessageTarget.innerText = response['processing_failure_message']
      @fullErrorBacktraceTarget.innerHTML = _map response['processing_failure_backtrace'], (backtraceLine) =>
        "<p class='small text-muted quarter-line-bottom-margin'>#{backtraceLine}</p>"
      .join('')
      @fullErrorMessageContainerTarget.classList.remove('hidden')
    
  totalRows: ->
    parseInt(@data.get('rows'))
  
  calculateWidth: (currentRowCount) ->
    ((parseInt(currentRowCount) / @totalRows()) * 100)
  
  calculateWidthString: (currentRowCount) ->
    @calculateWidth(currentRowCount) + "%"
