import { Controller } from 'stimulus'

export default class extends Controller
  @targets: [
    'predicateField',
    'valueFieldContainer',
      'valueField',
    'closeButtonContainer'
  ]

  initialize: ->
    @currentPredicate = @predicateFieldTarget.value
    @update(@_isValueless(@currentPredicate))

  update: (force = false) ->
    newPredicate = @predicateFieldTarget.value
    newPredicateIsValueless = @_isValueless(newPredicate)
    predicateValuelessStateChanged = @_isValueless(@currentPredicate) isnt newPredicateIsValueless
    @currentPredicate = newPredicate

    if force is true or predicateValuelessStateChanged
      @valueFieldContainerTarget.classList.toggle('hidden', newPredicateIsValueless)
      @valueFieldContainerTarget.classList.toggle('bs5--flex-fill', !newPredicateIsValueless)
      @closeButtonContainerTarget.classList.toggle('bs5--flex-fill', newPredicateIsValueless)
      @valueFieldTarget.value = if newPredicateIsValueless then '1' else ''

  _isValueless: (value) ->
    value in ['true', 'false', 'present', 'blank']