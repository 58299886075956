import { Controller } from 'stimulus'
import _debounce from 'lodash/debounce'
import _template from 'lodash/template'

export default class extends Controller
  @targets = [
    'courseIdField',
    'courseNameField',
    'courseToConnectIndicator'
    'courseToConnectTemplate',
    'noResultsIndicator',
    'searchingIndicator',
    'searchResults',
    'searchResultTemplate'
  ]

  @values =
    courseId: Number
    courseName: String
    searchUrlPattern: String
    integrationId: Number

  initialize: ->
    @searchCourses = _debounce(@searchCourses, 750).bind(@)

    # Hide some visual elements initially.
    @searchingIndicatorTarget.hidden = true
    @noResultsIndicatorTarget.hidden = true
    
    # Set up our template.
    templateOpts = {
      'variable': 'data'
      'interpolate': /{{([\s\S]+?)}}/g
    }
    @searchResultTemplate = _template(@searchResultTemplateTarget.innerHTML, templateOpts)
    @courseToConnectTemplate = _template(@courseToConnectTemplateTarget.innerHTML, templateOpts)

  handleKeypress: (event) ->
    if event.keyCode == 13
      event.preventDefault()

  searchCourses: (event) ->
    @searchingIndicatorTarget.hidden = false
    @noResultsIndicatorTarget.hidden = true
    
    # Reset the search results.
    @searchResultsTarget.innerHTML = ''

    fetch("#{@searchUrl()}?#{new URLSearchParams({ q: event.target.value }).toString()}",
      method: 'GET'
      headers:
        'Accept': 'application/json'
        'Content-Type': 'application/json'
    )
      .then((response) =>
        @searchingIndicatorTarget.hidden = true
        response.json()
      )
      .then((json) => 
        if json.records.length > 0
          # Append each record.
          for record in json.records
            @searchResultsTarget.insertAdjacentHTML('beforeend', @searchResultTemplate(record))
        else
          @noResultsIndicatorTarget.hidden = false
      )

  searchUrl: ->
    @searchUrlPatternValue.replace('INTEGRATION_ID', @integrationIdValue)

  setCourse: (event) ->
    # Set the course ID and name internal values and form fields.
    @courseIdValue = @courseIdFieldTarget.value = event.target.dataset.aomCourseId
    @courseNameValue = @courseNameFieldTarget.value = event.target.dataset.aomCourseName

    # Clear results.
    @searchResultsTarget.innerHTML = ''

  courseIdValueChanged: ->
    @showConnectedCourseIndicator() if @courseIdValue && @courseNameValue

  courseNameValueChanged: ->
    @showConnectedCourseIndicator() if @courseIdValue && @courseNameValue

  showConnectedCourseIndicator: ->
    @courseToConnectIndicatorTarget.innerHTML = @courseToConnectTemplate({ id: @courseIdValue, name: @courseNameValue })

  unsetCourse: (event) ->
    # Set the course ID and name internal values and form fields.
    @courseIdValue = @courseIdFieldTarget.value = null
    @courseNameValue = @courseNameFieldTarget.value = null

    # Clear results.
    @courseToConnectIndicatorTarget.innerHTML = ''