import { Controller } from "stimulus"

export default class extends Controller
  beforeSend: ->
    @element.classList.add('disabled')

  success: (event) ->
    [data, status] = event.detail
    response = JSON.parse(data.response)

    modalContent = App.Templates["templates/modals/text_alert_modal"]
      title: response.heading
      text: response.message
    modal = $(modalContent).remodal()
    modal.open()

    @element.classList.remove('disabled')
