import { Controller } from "stimulus"
import urlRegex from 'url-regex'

export default class extends Controller
  @targets: ["urlField", "validationMessage"]

  validateUrl: _.debounce(->
    @showValidatingMessage()

    if urlToValidate = @urlFieldTarget.value
      if urlToValidate.match(/^https?\:\/\//i)
        if urlRegex({ strict: true, exact: true }).test(urlToValidate)
          # URL is valid from a formatting perspective. Attempt to validate it via our Research mechanism.
          request = $.post '/validate-url-field',
            value: urlToValidate

          request.done (data, textStatus, jqXHR) =>
            if data is 'OK'
              @showValidationSuccess()

            else if textStatus is 'success' and urlToValidate.match(/linkedin/)
              @showCouldNotValidateMessage()

            else
              @showValidationErrorMessage(data)

          request.fail (data, textStatus, jqXHR) =>
            @showValidationErrorMessage("Validation failed, please try again.")

        else
          @showValidationErrorMessage("Provided text does not look like a URL.")

      else
        @showValidationErrorMessage("URL must begin with http:// or https://")

    else
      @clearValidationMessage()

  500)

  showValidatingMessage: ->
    @validationMessageTarget.innerHTML = "
      <i class='fa fa-refresh fa-spin'></i> Validating URL...
    "

  showCouldNotValidateMessage: ->
    @validationMessageTarget.innerHTML = "
      <span class='text-warning'>
        <i class='fa fa-exclamation-circle'></i>
        <strong>
          The URL provided could not be checked for validity.
        </strong>
        Please double-check the URL to ensure it is valid. You can safely
        ignore this message if it is valid.
      </span>
    "

  showValidationErrorMessage: (message) ->
    @validationMessageTarget.innerHTML = "
      <span class='text-danger'>
        <i class='fa fa-exclamation-triangle'></i> <strong>URL is invalid:</strong> #{message}
      </span>
    "

  showValidationSuccess: (message) ->
    @validationMessageTarget.innerHTML = "
      <span class='text-success'>
        <i class='fa fa-check-circle'></i> URL is valid!
      </span>
    "

  clearValidationMessage: ->
    @validationMessageTarget.innerHTML = ""
