import { Controller } from "stimulus"
import _each from 'lodash/each'
import _isEmpty from 'lodash/isEmpty'

export default class extends Controller
  @targets: [
    "statusMessage",
    "errorMessage",
    "successMessage",
    "progressBar",
    "percentageIndicator",
  ]
  
  initialize: ->
    # Set initial style attributes (not required on re-render)
    @progressBarTarget.style.minWidth = '2em'

    # Set defaults to properly assign and manage styles for the progress bar
    @isActive = @data.get('active') is "true"
    @currentClassModifiers = (@data.get('classModifiers') or "").split(' ')
    
    # Render out the progress bar properly
    @render()
    
    # Set up a MutationObserver to watch for data changes
    mutationObserver = new MutationObserver (mutations) =>
      _each mutations, (mutation) =>
        @render() if mutation.type is 'attributes'
    
    mutationObserver.observe @element,
      attributes: true
    
    @element.addEventListener 'progressChanged', (event) =>
      @handleProgressChanged(event, @)
  
  render: ->
    # Get the values for the progress bar
    valuemin = parseInt @data.get('valuemin')
    valuemax = parseInt @data.get('valuemax')
    valuenow = parseInt @data.get('valuenow')
    
    # Set the values for the progress bar
    @progressBarTarget.setAttribute('aria-valuemin', valuemin)
    @progressBarTarget.setAttribute('aria-valuemax', valuemax)
    @progressBarTarget.setAttribute('aria-valuenow', valuenow)
    
    # Calculate the new width for the progress bar
    @progressBarTarget.style.width = "#{Math.floor((valuenow / valuemax) * 100)}%"
    
    # Show/hide the progress bar
    if @data.get('visiblePercentage') is 'false'
      @percentageIndicatorTarget.style.display = 'none'
    else
      @percentageIndicatorTarget.style.display = 'inline'

    # Calculate the classes to be added/removed
    @isActive = @data.get('active') is "true"
    # Class modifiers should be things like "info" or "success", omitting
    # prefixed "progress-bar-".
    newClassModifiers = (@data.get('classModifiers') or "").split(' ')
    
    # Set class modifiers
    # Start by removing all existing modifiers first
    _each @currentClassModifiers, (classModifier) =>
      unless _isEmpty classModifier
        @progressBarTarget.classList.remove "progress-bar-#{classModifier}"
    
    # Set new progress bar class modifiers
    _each newClassModifiers, (classModifier) =>
      unless _isEmpty classModifier
        @progressBarTarget.classList.add "progress-bar-#{classModifier}"
    
    # Reassign @currentClassModifiers so we don't have to worry about changes
    # and manually removing classes
    @currentClassModifiers = newClassModifiers
    
    # Toggle the active class
    @progressBarTarget.classList.toggle('active', @isActive)
    
    # Set the status message content
    # Start by nullifing all text content
    @statusMessageTarget.textContent = null
    if @hasSuccessMessageTarget
      @successMessageTarget.textContent = null
    if @hasErrorMessageTarget
      @errorMessageTarget.textContent = null
    
    # If there is a message and it isn't empty, set its contents to
    # @<data-progress-bar-message-type>MessageTarget
    if !_isEmpty @data.get('message')
      targetName = "#{@data.get('messageType')}MessageTarget"
      try
        @[targetName].textContent = @data.get('message')
      catch error
        @statusMessageTarget.textContent = @data.get('message')
    
    # Set the percentage uploaded.  This will be the same value as
    # the width calculated above.
    @percentageIndicatorTarget.textContent = "#{@progressBarTarget.style.width}"
  
  handleProgressChanged: (event, _ref) =>
    _each event.detail, (value, key) ->
      _ref.data.set key, "#{value}"