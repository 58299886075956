import { Controller } from "stimulus"

import EmblaCarousel from 'embla-carousel'
import { addPrevNextBtnsClickHandlers } from 'shared/embla/embla_carousel_arrow_buttons'
import { updateSelectedSnapDisplay } from 'shared/embla/embla_carousel_selected_snap_display'

export default class extends Controller
  @targets: [
    'viewport',
    'prevBtn',
    'nextBtn',
    'snapDisplay'
  ]

  initialize: ->
    @emblaApi = EmblaCarousel(@viewportTarget, { dragFree: true })

    removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
      @emblaApi,
      @prevBtnTarget,
      @nextBtnTarget
    )
    updateSelectedSnapDisplay(@emblaApi, @snapDisplayTarget)

    @emblaApi.on('destroy', removePrevNextBtnsClickHandlers)

  expand: (event) ->
    @element.classList.add("newsletter-viewer-img-carousel--expanded")
    @emblaApi.reInit({ dragFree: false })
    
    slideNumber = parseInt(event.currentTarget.dataset.slideNumber)
    unless slideNumber == NaN
      @emblaApi.scrollTo(slideNumber, true)

  collapse: ->
    @element.classList.remove("newsletter-viewer-img-carousel--expanded")
    @emblaApi.reInit({ dragFree: true })