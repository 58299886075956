import ModalLinkController from "./modal_link_controller"
import _unescape from 'lodash/unescape'
# @dependency jQuery effects, triggers

export default class extends ModalLinkController
  @targets: [
    'list',
      'speakerIdField',
    'template'
  ]

  populate: (event) ->
    # Ensure speaker isn't already in form
    newSpeakerId = event.detail.speaker_id
    speakerItem = @findVisibleSpeakerItemBySpeakerId(newSpeakerId)
    highlight = 'default'

    if speakerItem # already exists, don't add
      highlight = 'error'

    else
      template = _.template(_unescape(@templateTarget.innerHTML), { variable: 'data' })
      result = template(event.detail).replace(/NEW_RECORD/g, new Date().getTime())
      @listTarget.insertAdjacentHTML('beforeend', result)
      @listTarget.dispatchEvent(new CustomEvent('itemInserted'))
      speakerItem = @findVisibleSpeakerItemBySpeakerId(newSpeakerId)

    if speakerItem
      App.Mixins.HighlightEffect["#{highlight}Highlight"]($(speakerItem))

  remove: (event) ->
    event.preventDefault()

    wrapper = event.target.closest('.nested-fields')

    if wrapper.dataset.newRecord is 'true'
      wrapper.remove()

    else
      destroyElement = wrapper.querySelector('input[name*="_destroy"]')
      if destroyElement
        destroyElement.value = 1
        wrapper.style.display = 'none'
      else
        wrapper.remove()

  findVisibleSpeakerItemBySpeakerId: (id) ->
    results = []

    @speakerIdFieldTargets.forEach (target) =>
      if "#{target.value}" is "#{id}"
        nestedFields = target.closest('.nested-fields')
        destroyElement = nestedFields.querySelector('input[name*="_destroy"]')
        if !destroyElement or (destroyElement.value is '0' or destroyElement.value is 'false')
          results.push(nestedFields)

    results[results.length - 1]
