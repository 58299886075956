import { Controller } from "stimulus"
import _each from 'lodash/each'

# Handles user selection of a restricted option when purchasing a ticket or
# exhibitor item
export default class extends Controller
  @property 'fieldType',
    get: -> @data.get('type')
  
  initialize: ->
    uninitializedInputTargets = if @fieldType is 'select'
      [@element.querySelector('select')]
    else if @fieldType is 'check_box'
      @element.querySelectorAll('input[type="checkbox"]')
    else if @fieldType is 'radio'
      @element.querySelectorAll('input[type="radio"]')
    else
      []
    
    _each uninitializedInputTargets, (uninitializedInputTarget) =>
      uninitializedInputTarget.dataset.action = 'eav-form-field-selection-restriction#change'
  
  change: (event) ->
    if @fieldType is 'select'
      @changeSelect(event)
    else if @fieldType is 'check_box'
      @changeCheckBoxOrRadio(event)
    else if @fieldType is 'radio'
      @changeCheckBoxOrRadio(event)
  
  changeSelect: (event) ->
    selectedOption = event.target.options[event.target.selectedIndex]

    if restrictedMessage = selectedOption.dataset.restrictedMessage
      event.target.selectedIndex = -1
      alert restrictedMessage
  
  changeCheckBoxOrRadio: (event) ->
    if event.target.checked and restrictedMessage = event.target.dataset.restrictedMessage
      event.target.checked = false
      alert restrictedMessage