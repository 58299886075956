import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["enforceNotes", "includeButton", "requireButton", "enforceButton", "valueField", "additionalOptionsFieldset"]

  initialize: ->
    @update()

  update: ->
    switch
      when @valueFieldTarget.value == "include"
        $([@requireButtonTarget]).removeClass('active') if @hasRequireButtonTarget
        $([@enforceButtonTarget]).removeClass('active') if @hasEnforceButtonTarget

        $([@includeButtonTarget]).addClass('active')

        @showAdditionalOptionsFieldset()
        @hideEnforceNotes()

      when @valueFieldTarget.value == "require"
        $([@includeButtonTarget]).removeClass('active') if @hasIncludeButtonTarget
        $([@enforceButtonTarget]).removeClass('active') if @hasEnforceButtonTarget

        $([@requireButtonTarget]).addClass('active')

        @showAdditionalOptionsFieldset()
        @hideEnforceNotes()

      when @valueFieldTarget.value == "enforce"
        $([@includeButtonTarget]).removeClass('active') if @hasIncludeButtonTarget
        $([@requireButtonTarget]).removeClass('active') if @hasRequireButtonTarget

        $([@enforceButtonTarget]).addClass('active')

        @showAdditionalOptionsFieldset()
        @showEnforceNotes()

      else
        $([@requireButtonTarget, @includeButtonTarget]).removeClass('active')

        @hideAdditionalOptionsFieldset()
        @hideEnforceNotes()

  toggleInclude: (event) ->
    if @valueFieldTarget.value == "include"
      @valueFieldTarget.value = null

    else
      @valueFieldTarget.value = "include"

    @update()

    event.target.blur()

  toggleRequire: (event) ->
    if @valueFieldTarget.value == "require"
      @valueFieldTarget.value = null

    else
      @valueFieldTarget.value = "require"

    @update()

    event.target.blur()

  toggleEnforce: (event) ->
    if @valueFieldTarget.value == "enforce"
      @valueFieldTarget.value = null

    else
      @valueFieldTarget.value = "enforce"

    @update()

    event.target.blur()

  showAdditionalOptionsFieldset: ->
    if @hasAdditionalOptionsFieldsetTarget
      $(@additionalOptionsFieldsetTarget).prop({ disabled: false, hidden: false })

  hideAdditionalOptionsFieldset: ->
    if @hasAdditionalOptionsFieldsetTarget
      $(@additionalOptionsFieldsetTarget).prop({ disabled: true, hidden: true })

  showEnforceNotes: ->
    if @hasEnforceNotesTarget
      $(@enforceNotesTarget).prop(hidden: false)

  hideEnforceNotes: ->
    if @hasEnforceNotesTarget
      $(@enforceNotesTarget).prop(hidden: true)