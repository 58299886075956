import Backbone from "backbone/backbone"
import { Controller } from "stimulus"
import * as Chartist from "chartist/dist/chartist"

export default class extends Controller
  @targets = ['chart']

  @property 'labels',
    get: ->
      @_labels or= JSON.parse(@data.get('labels'))

  @property 'keyNames',
    get: ->
      @_keyNames or= JSON.parse(@data.get('keyNames'))

  @property 'series',
    get: ->
      @_series or= JSON.parse(@data.get('series'))

  @property 'options',
    get: ->
      @_options or= if @data.get('options')
        JSON.parse(@data.get('options'))
      else
        {}

  @property 'tooltipsShowAllSeriesData',
    get: ->
      @data.get('tooltipsShowAllSeriesData') == 'true'

  initialize: ->
    @_setupModel()

    @chart = new Chartist.Line @chartTarget, @model.get('chartData'), @model.get('chartOptions')

    # When the chart is drawn, set up the "title" attributes for use with tooltips
    @chart.on 'draw', (data) =>
      # Target only the points
      if data.type is 'point'
        # Create an HTML element for the tooltip
        title = "<div class='text-left'><strong>" + @model.get('labels')[data.index] + "</strong>"

        if @tooltipsShowAllSeriesData
          # Iterate through each series and get the name and values for all points - we'll display them all
          _(@model.get('seriesCount')).times (seriesNumber) =>
            keyName = @model.get('keyNames')[seriesNumber]
            value = @model.get('series')[seriesNumber][data.index]
            title += "<br/>" + keyName + ": <strong>" + value + "</strong>"

        else
          keyName = @model.get('keyNames')[data.seriesIndex]
          value = @model.get('series')[data.seriesIndex][data.index]
          title += "<br/>" + keyName + ": <strong>" + value + "</strong>"

        title += "</div>"

        # Apply the title and give it a data attribute to target
        data.element._node.setAttribute 'rel', 'tooltip'
        data.element._node.setAttribute 'data-html', 'true'
        data.element._node.setAttribute 'title', title

  startSeriesFocus: (event) ->
    target = event.currentTarget.dataset.seriesTarget

    # Add data-series-ghosted to serieses that are not the one targeted with data-series-target
    # This can be styled to make the serieses not in focus blurred, faded, whatever
    for seriesElement in @element.querySelectorAll(".ct-series:not(#{target})")
      seriesElement.setAttribute('data-series-ghosted', true)

    # # Add data-series-targeted to the targeted series
    # # This can be styled to make the series targeted bolder, brighter, etc.
    @element.querySelector(target).setAttribute('data-series-targeted', true)

  resetSeriesFocus: (event) ->
    for seriesElement in @element.querySelectorAll(".ct-series")
      seriesElement.removeAttribute('data-series-ghosted')
      seriesElement.removeAttribute('data-series-targeted')

  toggleSeriesVisibility: (event) ->
    target = event.currentTarget.dataset.seriesTarget
    @element.querySelector(target).toggleAttribute('hidden')

    if @element.querySelector(target).hasAttribute('hidden')
      event.currentTarget.style.setProperty('opacity', 0.5)
    else
      event.currentTarget.style.setProperty('opacity', 1)

  _setupModel: ->
    @model = new Backbone.Model
      labels: @labels
      series: @series
      keyNames: @keyNames

    # Set the series count
    @model.set 'seriesCount', @series.length

    # Set the chart data and the options for the chart on the model
    @model.set 'chartData',
      labels: @labels
      series: @series

    @model.set 'chartOptions',
      axisX:
        labelInterpolationFnc: (value, index) =>
          # Only show bottom labels based on how many are displayed.  This works by dividing the number of series
          # data points by 10 and rounding to the nearest integer.  If this result is n, we display every nth label.
          #
          # Examples:
          #   With 14 data points, we show all labels.
          #   With 15 data points, we show every other label.
          #   With 27 data points, we show every third label.
          #
          modValue = Math.round(@series[0].length / 10)
          return value if (modValue is 0) or (index % modValue is 0)
          return null
        showGrid: false
      axisY:
        onlyInteger: true
        scaleMinSpace: 60 # the minimum amount of spacing between numbers, which scales responsively
      chartPadding: # add padding to top and bottom so it doesn't crunch up against other content
        top: 21
        bottom: 21
