import { Controller } from "stimulus"
import Cleave from 'cleave.js'

###
  Cleave element for a money field
###
export default class extends Controller
  initialize: ->
    # Using numeralThousandsGroupStyle: 'thousands' results in commas being
    # submitted, which is a problem for decimal fields.
    new Cleave @element,
      numeral: true
      numeralPositiveOnly: true
      numeralThousandsGroupStyle: 'none'
      stripLeadingZeroes: true