import _filter from "lodash/filter"
import _map from "lodash/map"
import _sortBy from "lodash/sortBy"
import _toUpper from "lodash/toUpper"
import _uniq from "lodash/uniq"

###
  Given a hex color string, converts the hex value to an integer,
  representing the combined hex values.  For example, given #01020A, the
  combined value would be 13 (0x01 = 1, 0x02 = 2, 0x0A = 10; 1 + 2 + 10 = 13).
  This is used for sorting colors by their darkness.
  
  @param colorString [String] Hex color string
  @return [Integer]
###
hexToIntegerSum = (colorString) ->
  colorString = colorString.replace(/\#/g, '')
  [red, green, blue] = colorString.match(/.{2}/g)
  parseInt("0x#{red}") + parseInt("0x#{green}") + parseInt("0x#{blue}")

###
  Collection of CSS variables (defined at :root) to be used to define colors
  for swatches in color pickers.
  
  @return [Array]
###
export swatchCSSVars = -> [
  "--platform-btn-default-base-color",
  "--platform-btn-primary-base-color",
  "--platform-btn-primary-alt-base-color",
  "--platform-sidebar-base-color",
  "--ui-btn-info-color",
  "--ui-btn-success-color",
  "--ui-btn-warning-color",
  "--ui-btn-danger-color",
  "--ui-shared-footer-bg",
  "--ui-top-nav-header-bg",
  "--ct-0",
  "--ct-1",
  "--ct-2",
  "--ct-3",
  "--ct-4",
  "--ct-5",
  "--ct-6",
  "--ct-7",
  "--ct-8",
  "--ct-9",
  "--ct-10",
  "--ct-11",
  "--ct-12",
  "--ct-13",
  "--ct-14",
  "--ct-15",
]

###
  CSS Variables found, converted to values, stripped, made unique, and sorted.
  
  @return [Array]
###
export swatchColors = (hasPrecedingHash = true) ->
  styles = window.getComputedStyle(document.documentElement)
  
  # Find each color from the window's :root vars.  Trim the string and uppercase
  # it (for uniqueness comparison later).
  colors = _map swatchCSSVars(), (color) =>
    _toUpper "#{styles.getPropertyValue(color)}".trim()
  
  # Make colors unique and filter out any blank strings (no value found)
  colors = _filter _uniq(colors), (color) => color isnt ""

  # Sort by overall darkness.  This can be achieved by extracting each part
  # of a hex value (R, G, B) to integer values and adding them.
  colors = _sortBy colors, (color) => hexToIntegerSum(color)
  
  # Return.  Strip preceding # if hasPrecedingHash is false.
  if hasPrecedingHash
    colors
  else
    _map colors, (color) =>
      if color.charAt(0) is "#"
        color.substring(1)
      else
        color
