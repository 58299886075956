import { Controller } from "stimulus"
import _find from 'lodash/find'
# dependency: jQuery, JST, remodal

# This JS handles selecting a ticket, package, etc. and forwarding on to
# the next set of options to add or remove seats/tickets/packages/etc.
# Assumes content is in a modal.
#
# If there is only 1 ticket/package/whatever, it will automatically redirect
# the content in the modal to the next step.
#
export default class extends Controller
  @targets: [
    'selectOption',
    'countsContainer',
    'submitButton'
  ]

  @property 'resources',
    get: -> JSON.parse(@data.get('resources'))
  
  @property 'resourceId',
    get: -> @data.get('resourceId') or null
    set: (value) -> @data.set('resourceId', value)

  @property 'resource',
    get: ->
      return null if @resourceId is null or @resourceId is undefined

      _find @resources, (eachResource) =>
        "#{eachResource.id}" == @resourceId

  initialize: ->
    if @resources.length is 1
      @resourceId = @resources[0].id
      @submit()

  render: ->
    @countsContainerTargets.forEach (target) -> target.classList.add('hidden')
    
    if @resource
      @submitButtonTarget.removeAttribute('disabled')
      foundCountsContainer = _find @countsContainerTargets, (target) =>
        target.dataset.id is @resourceId
      
      if foundCountsContainer
        foundCountsContainer.classList.remove('hidden')
    
    else
      @submitButtonTarget.setAttribute('disabled', 'disabled')

  update: (event) ->
    @resourceId = event.currentTarget.value
    @render()

  submit: ->
    return unless @resource

    $('.remodal.remodal-is-opened').html App.Templates["templates/loading"]({})

    request = fetch(@resource.edit_quantity_path,
      mode: 'same-origin'
      credentials: 'same-origin'
      method: 'GET'
      headers:
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
        'Content-Type': 'text/html'
        'Accept': 'text/html'
    ).then((response) => response.text()
    ).then((body) => $('.remodal.remodal-is-opened').html(body)
    )
