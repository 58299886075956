import { Controller } from "stimulus"
import _each from 'lodash/each'

###
  For use when having one master checkbox that toggles on or off all children
  (contributor) checkboxes, or toggling the state of the master checkbox based
  on the selections of its contributors.
  
  For example, a set of permissions may be grouped under one heading.  One
  checkbox at the top (master) can toggle on and off all the permissions (as
  checkboxes; contributors) under that heading.
  
  Use data-checkbox-with-children-allow-indeterminate="true" to allow
  setting the master checkbox to an indetermine state when only some of the
  contributors are toggled on; otherwise, it will be either checked (when any
  contributor checkbox is checked) or unchecked (when no contributor checkboxes
  are checked).
###
export default class extends Controller
  @targets: ["master", "contributor"]
  
  initialize: ->
    @allowIndeterminate = @data.get('allowIndeterminate') is 'true'
    @customChangeEvent = document.createEvent('Event')
    @customChangeEvent.initEvent('moderatorPermissionHiddenFieldChanged', true, true)
    @render()
  
  masterToggle: ->
    _each @contributorTargets, (contributorTarget) =>
      contributorTarget.checked = @masterTarget.checked
      contributorTarget.dispatchEvent(@customChangeEvent)
      true
  
  render: ->
    hasChecked = false
    hasUnchecked = false
    
    _each @contributorTargets, (contributorTarget) =>
      if contributorTarget.checked
        hasChecked = true
      else
        hasUnchecked = true
      true
    
    if @allowIndeterminate and hasChecked and hasUnchecked
      @masterTarget.classList.add('indeterminate')
      @masterTarget.checked = false
      @masterTarget.indeterminate = true
    else if hasChecked
      @masterTarget.classList.remove('indeterminate')
      @masterTarget.indeterminate = false
      @masterTarget.checked = true
    else
      @masterTarget.classList.remove('indeterminate')
      @masterTarget.indeterminate = false
      @masterTarget.checked = false