import ModalLinkController from "./modal_link_controller"

export default class extends ModalLinkController
  @targets: ['icon', 'text']
  
  initialize: ->
    @element.setAttribute('data-remote', false)
    @render()
    
  render: ->
    if @data.get('id')
      @element.href = @data.get('editPath').replace(':id', @data.get('id'))
      @textTarget.innerHTML = 'Search saved'
      @iconTarget.classList.remove('fa-star-o')
      @iconTarget.classList.add('fa-star')
    
    else
      @element.href = @data.get('newPath')
      @textTarget.innerHTML = 'Save search'
      @iconTarget.classList.remove('fa-star')
      @iconTarget.classList.add('fa-star-o')
    
    @fixedWidth = @element.offsetWidth
    @element.style.width = "#{@fixedWidth}px"
  
  rerender: ->
    @_ajaxSuccessCallbacks()
  
  closeWithTimeout: ->
    setTimeout =>
      @modalInstance.close()
    , 1500
  
  _ajaxSuccessCallbacks: (data) ->
    memberSegmentJson = JSON.parse($('.remodal.remodal-is-opened .member-segment-data')[0].dataset.memberSegment)
    
    if memberSegmentJson.id is null or memberSegmentJson.id is undefined
      @data.set('id', "")
    else
      @data.set('id', memberSegmentJson.id)
    
    @render()