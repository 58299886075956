import { Controller } from 'stimulus'
# @dependency jQuery, jQuery.countdown

# Displays a countdown timer inside of @timeRemainingTarget.
# When the timer expires:
#
#   * @timeRemainingTarget will be removed from the DOM
#   * @showWhenExpiredTargets will be shown (use .hidden)
#   * @removeWhenExpiredTargets will be removed.
#
# The time format is set to sensible defaults, but can be configured
# by setting the appropriate data values listed in the @property-ies
# below.
export default class extends Controller
  @targets: [
    'timeRemaining',
    'showWhenExpired'
    'removeWhenExpired'
  ]

  @property 'secondsFormat',
    get: -> @data.get('secondsFormat') or '%-S sec%!S'

  @property 'minutesFormat',
    get: -> @data.get('minutesFormat') or '%-M min%!M '

  @property 'hoursFormat',
    get: -> @data.get('hoursFormat') or '%-H hr%!H '

  @property 'daysFormat',
    get: -> @data.get('daysFormat') or '%-D day%!D '

  @property 'countdownUntilTime',
    get: -> new Date(@data.get('until'))

  initialize: ->
    @timer = $(@timeRemainingTarget).countdown @countdownUntilTime

    @timer.on 'update.countdown', (event) =>
      strftimeString = if event.offset.totalDays > 0
        "#{@daysFormat}#{@hoursFormat}#{@minutesFormat}#{@secondsFormat}"
      else if event.offset.totalHours > 0
        "#{@hoursFormat}#{@minutesFormat}#{@secondsFormat}"
      else if event.offset.totalMinutes > 0
        "#{@minutesFormat}#{@secondsFormat}"
      else
        "#{@secondsFormat}"

      @timeRemainingTarget.innerHTML = event.strftime(strftimeString)

    @timer.on 'finish.countdown', (event) =>
      $(@timeRemainingTarget).countdown('stop')
      @timeRemainingTarget.remove()
      @showWhenExpiredTargets.forEach (target) -> target.classList.remove('hidden')
      @removeWhenExpiredTargets.forEach (target) -> target.remove()
