import { Controller } from "stimulus"

export default class extends Controller
  @targets: ['content']
  
  initialize: ->
    if @contentTarget.offsetHeight > 750
      @element.classList.add('newsletter-viewer-article-content--collapsed')

  expand: ->
    @element.classList.remove('newsletter-viewer-article-content--collapsed')