# Based on stuff found here:
#   https://opensource.com/article/20/5/data-modeling-javascript
import _defaultsDeep from 'lodash/defaultsDeep'

# Define the model base class to export
export class Model
  # Reserved fields will never be cleared. Please do not overwrite this
  # property in child classes.
  @property 'reservedFields',
    get: -> ['_listeners']
  
  # @unclearableFields are any fields that their value will remain unchanged
  # when @clear() is invoked. You can overwrite this property in child classes.
  @property 'unclearableFields',
    get: -> []

  # Create the model based on the attributes passed in
  constructor: (attributes = {}) ->
    _defaultsDeep @, attributes, @_calculateSchema()

  # Calculate the initial schema for the constructor. This can be done in
  # several ways:
  #
  #   Method 1: Provide all properties with their own initial values
  #
  #   @property 'defaults',
  #     get: ->
  #       "name": "Steve"
  #       "id": undefined
  #
  #   Method 2: Provide a schema, where all values will be undefined by default
  #
  #   @property 'schema',
  #     get: -> ["name", "id"]
  #
  #   Method 3: Provide schema on an as-needed basis. Since JS Objects are
  #   flexible, any attribute can be added to a model at any time. The initial
  #   schema will be empty.
  #
  _calculateSchema: ->
    if @.defaults
      @.defaults
    else if @.schema
      _calculatedSchema = {}
      @.schema.forEach((field) -> _calculatedSchema[field] = undefined)
      _calculatedSchema
    else
      {}

  # Get an attribute. It is preferred to use either
  # @modelInstance.attributeName or @modelInstance[attributeName] instead.
  # This is merely here as a convenience method for Backbone -> Stimulus
  # migration.
  get: (attribute) ->
    @[attribute]

  # Sets an attribute. Using @set will trigger any listeners registered.
  # If silent operation is preferred, simply set the attribute name using
  # @modelInstance.attributeName = value or
  # @modelInstance[attributeName] = value.
  set: (attribute, value) ->
    @_listeners or= {}

    beforeValue = @[attribute]
    @[attribute] = value

    if @_listeners[attribute] and (beforeValue isnt value)
      [callbackFunction, once] = @_listeners[attribute]
      callbackFunction()
      delete(@_listeners[attribute]) if once is true

  # Sets all attributes on the model to undefined (except those listed in
  # @reservedFields or @unclearableFields). No registered listeners are invoked.
  clear: (attribute = null) ->
    if attribute
      @[attribute] = undefined
    else
      for [key, _] in Object.entries(@)
        unless key in @reservedFields.concat(@unclearableFields)
          @[key] = undefined

  # Similar to Backbone's @trigger function, triggers an attribute using @set.
  # It is preferred to use Stimulus methods instead.
  # This is merely here as a convenience method for Backbone -> Stimulus
  # migration.
  trigger: (attribute) ->
    @set(attribute, new Date().getTime())
    delete @[attribute]

  # Set up a listener to listen to changes called from @set or @trigger.
  # It is preferred to use Stimulus methods instead.
  # This is merely here as a convenience method for Backbone -> Stimulus
  # migration.
  registerListener: (attribute, callbackFunction, once = false) ->
    @_listeners or= {}
    @_listeners[attribute] = [callbackFunction, once]
