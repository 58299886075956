import { Controller } from "stimulus"
import _each from 'lodash/each'
import _find from 'lodash/find'

export default class extends Controller
  @property 'autocomplete',
    get: ->
      JSON.parse(@data.get('tags'))
  
  @property 'autocompleteLabels',
    get: ->
      @autocomplete.map (val) -> val.label
      
  initialize: ->
    $(@element).tokenfield({
      tokens: @_calculateInitialTokensFromIDs()
      autocomplete:
        delay: 0
        source: @autocomplete
      showAutocompleteOnFocus: true
      beautify: true
    }).on('tokenfield:createtoken', ((event) =>
      if event.attrs.label in @autocompleteLabels
        current_values = @element.value.split(',').map (val) ->
          val.trim()
        
        if event.attrs.value in current_values
          # This value already exists on the field; don't duplicate it.
          event.preventDefault()
        
      else
        # Token not in @autocompleteLabels; do nothing
        event.preventDefault()
    ))
    
    $(@element).tokenfield('enable')
  
  _calculateInitialTokensFromIDs: ->
    eachToken = []
    
    _each @element.value.split(','), (value) =>
      foundToken = _find @autocomplete, (eachAutocomplete) =>
        String(eachAutocomplete.value).trim() is String(value).trim()
      
      eachToken.push(foundToken) if foundToken
    
    eachToken