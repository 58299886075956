import { Controller } from "stimulus"
import savingEmittingSave from "./save_emitting_rails_ajax"

export default class extends Controller
  @values = 
    imageAttribute: String
    updateUrl: String

  handleImageDropFileUploaded: (event) ->
    if event.detail && (blob = event.detail.blob)
      data = new FormData()
      data.append @imageAttributeValue, blob.signed_id
      
      savingEmittingSave @element, 'PATCH', @updateUrlValue, data, 'script'