import { Controller } from "stimulus"
import _isEmpty from 'lodash/isEmpty'

export default class extends Controller
  @targets: [
    'summaryDetails', 'titleText', 'costText',
    'fields'
  ]
  
  initialize: ->
    if @data.get('new') is 'true'
      @summaryDetailsTarget.classList.add('hidden')
    else
      @fieldsTarget.classList.add('hidden')
  
  toggleDetails: ->
    @summaryDetailsTarget.classList.toggle('hidden')
    @fieldsTarget.classList.toggle('hidden')
  
  updateTitle: (event) ->
    if _isEmpty(event.target.value)
      @titleTextTarget.innerHTML = "<em class='text-muted'>No title set</em>"
    else
      @titleTextTarget.textContent = event.target.value
  
  updateCost: (event) ->
    if _isEmpty(event.detail.value)
      @costTextTarget.innerHTML = "<em class='text-muted'>No price set</em>"
    else
      @costTextTarget.textContent = "$#{event.detail.value}"