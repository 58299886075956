import { Controller } from 'stimulus'
import _isEmpty from 'lodash/isEmpty'

export default class extends Controller
  @targets: [
    'content',
    'updateErrorContainer',
      'updateError',
    'locationAutocompleteContainer',
      'locationField',
    'disableOnAction',
    'updatedTemplate',
      'updatedLocation'
  ]

  @property 'updateUrl',
    get: -> @data.get('updateUrl')

  @property 'location',
    get: -> @data.get('location') or @data.get('currentLocation')

  initialize: ->
    @locationAutocomplete = new App.Views.Autocompleters.LocationCityStateView
      el: $(@locationAutocompleteContainerTarget)

    @locationAutocomplete.$typeaheadComponent.on 'typeahead:select', (event, selection) =>
      @updateFromAutocomplete(selection.suggestion)
    
    @locationAutocomplete.$typeaheadComponent.on 'keyup', (event) =>
      if event.originalEvent.key is 'Enter'
        @update()
  
  update: ->
    @updateFromAutocomplete(@locationAutocomplete.$typeaheadComponent[0].value)

  updateFromAutocomplete: (suggestion) ->
    if _isEmpty(suggestion)
      # Do nothing
      @_setWorkingState(false)
      @_setError(null)

    else if !suggestion.match(/^[\w\s]+,\s?[\w\s]+$/g)
      # Error
      @_setWorkingState(false)
      @_setError("Please enter your location in \"City, State\" format")
    
    else
      # OK!
      @_setWorkingState(true)
      @_setError(null)
      @_performSearch(suggestion)

  _setWorkingState: (isWorking) ->
    if @hasDisableOnActionTarget
      @disableOnActionTargets.forEach (target) =>
        target.classList.toggle('disabled', isWorking)
        target.attributes.disabled = isWorking

  _performSearch: (suggestion) ->
    @_setError(null)

    body = JSON.stringify({ location: suggestion })
    
    fetch(@updateUrl,
      method: 'PATCH'
      headers:
        'Accept': 'application/json'
        'Content-Type': 'application/json'
      body: body
    )
      .then((response) => response.json())
      .then((json) =>
        if _isEmpty(json.error)
          @contentTarget.innerHTML = @updatedTemplateTarget.innerHTML
          @updatedLocationTargets.forEach (target) =>
            target.innerText = json.location
        else
          @_setError(json.error)
      )
      .catch((error) => @_setError('An unknown error has occurred') )
      .finally(() => @_setWorkingState(false) )

  _setError: (message) ->
    if @hasUpdateErrorContainerTarget
      @updateErrorTarget.innerText = message
      @updateErrorContainerTarget.classList.toggle('hidden', _isEmpty(message))
