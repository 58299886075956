###
  Generic sortable controller using sortablejs.  Only handles setting the order
  fields within the container; more complex sortable setups should be manually
  written elsewhere.
###
import { Controller } from "stimulus"
import _each from "lodash/each"
import Sortable from "sortablejs"
# dependency: jQuery
# dependency: Cocoon

export default class extends Controller
  @targets: [
    # The sort container; defaults to @element
    "container",

    # The order fields to be set on change
    "orderField",

    # Optional: a content tag that displays the number on the form
    "displayableIndex",

    # Optional: a container that will be shown when something is sorted,
    # such as a reminder message to save after changes are made.
    # Should have .hidden applied in the markup.
    "onChangedContent"
  ]

  # Set to "true" to add basic callbacks when items are added via cocoon
  @property 'dynamic',
    get: -> @data.get('dynamic') is 'true'

  # Set data-sortable-group-name to give the sortable group its unique target.
  # This can be ignored/not set if there is only one sortable section on
  # the page.
  @property 'groupName',
    get: -> @data.get('groupName') or "abstract-sort-group"

  # Prevents dropping of other content into this container; true by default.
  @property 'preventDrop',
    get: -> @data.get('preventDrop') isnt 'false'

  initialize: ->
    # Set the container of sortable items.  If no target is provided, use
    # the @element itself as the container.
    sortableContainer = if @hasContainerTarget then @containerTarget else @element

    # If the sortable container is added to/removed from using Cocoon, set
    # data-sortable-dynamic="true".  This adds callbacks to watch for Cocoon
    # additions/removals and resort appropriately.
    if @dynamic
      $(sortableContainer).on 'cocoon:after-insert', => @_performSort()

    # Configure Sortable.  For pages with many sortable sections, this may
    # execute after this controller is initialized but before all content
    # is properly loaded on the page, not capturing all @orderFields.
    # Listen for DOMContentLoaded to initially init sortables.
    readyPromise = new Promise (resolve, reject) =>
      if document.readyState == 'loading'
        document.addEventListener 'DOMContentLoaded', resolve
      else
        resolve()

    readyPromise.then => @_initSortable(sortableContainer)

  # Set up sortable
  _initSortable: (target) ->
    @sortable = new Sortable target,
      animation: 100,
      ghostClass: 'sortable-placeholder-highlight-variable'
      group:
        name: @groupName
        pull: true
        put: !@preventDrop
      handle: '[role="sort_handle"]'
      swapThreshold: 0.25
      onEnd: (event) =>
        @_performSort()

        if @hasOnChangedContentTarget
          @onChangedContentTarget.classList.remove('hidden')

        unless event.from is event.to
          event.to.dispatchEvent(new CustomEvent('sortableEndedOnNewTarget', { detail: { item: event.item, from: event.from, to: event.to }, bubbles: true }))

        @element.dispatchEvent(new CustomEvent('sortableSortComplete', { detail: { item: event.item }, bubbles: true }))

  _performSort: ->
    _each @orderFieldTargets, (element, index) =>
      element.value = index
      true

    # displayableIndex is optional - displays the index + 1 of the order the
    # item is presented.
    _each @displayableIndexTargets, (element, index) =>
      element.innerText = (index + 1)
      true

  moveToTop: (event) ->
    @_moveToWithInsertionFunction(event, 'unshift')

  moveToBottom: (event) ->
    @_moveToWithInsertionFunction(event, 'push')

  _moveToWithInsertionFunction: (event, insertionFunction) ->
    event.preventDefault()

    # Find the sortable element by looking for data-id.  This should be present
    # in order to make sorting work as expected; otherwise, sortablejs will
    # assign a random ID to each sortable (instead of the ID of the record).
    sortableElement = event.currentTarget.closest("[data-id]")

    return unless sortableElement

    # Grab @sortable's array of elements (identified by data-id on the child
    # elements)
    sortableArray = @sortable.toArray()

    # Get the ID of the sortable element and the index where it occurs in the
    # sortableArray
    idToMove = sortableElement.dataset.id
    indexOfIdToMove = sortableArray.indexOf(idToMove)

    # Remove the ID from the array
    sortableArray.splice(indexOfIdToMove, 1)

    # Then put it where it is supposed to go:
    #   * Use 'unshift' to move it to the top
    #   * Use 'push' to move it to the end
    sortableArray[insertionFunction](idToMove)

    # Perform the sort manually.  This will shift UI elements, but will not
    # run any callbacks.
    @sortable.sort(sortableArray)

    # Perform @_performSort() to do what needs to be done in the UI.
    @_performSort()
