import { Controller } from 'stimulus'
import * as Backbone2 from 'backbone'

export default class extends Controller
  @targets: ['collapsibleTabContainer', 'tab', 'tabPane']
  
  @values =
    activeTabClass: String

  initialize: ->
    @router = new (Backbone2.Router.extend(
      routes:
        '*tab': 'tab'
    ))()

    @router.on 'route:tab', (tabName) =>
      @showTab(tabName)

    Backbone2.history.start root: window.location.pathname

  tabClicked: (event) ->
    @navigateToTab event.target.getAttribute('href')

    if @hasCollapsibleTabContainerTarget
      $(@collapsibleTabContainerTarget).collapse('hide')

  tabTargetNames: () ->
    targetNames = []
    for tab in @tabTargets
      targetNames.push((tab.getAttribute('href') || '').replace(/^#/, ''))
    targetNames

  showTab: (tabName) ->
    if !tabName
      if firstTab = @tabTargets[0]
        @navigateToTab(firstTab.getAttribute('href'))

    else
      if tabName in @tabTargetNames()
        # Hide all the tab panes.
        for tabPane in @tabPaneTargets
          tabPane.hidden = true

        # Show the pane targeted by ID.
        if tabToShow = document.getElementById(tabName.replace(/^!/, ''))
          tabToShow.hidden = false

        # Remove the active class from all tabs.
        for tab in @tabTargets
          tab.classList.remove(@activeTabClassValue)

          # While iterating the tabs, if the current tab is the active
          # one, re-add the active class.
          if tab.getAttribute('href') == "##{tabName}"
            tab.classList.add(@activeTabClassValue)
  
  navigateToTab: (tabName) ->
    @router.navigate tabName, trigger: true