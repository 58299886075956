import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["automaticSelectionField", "manualSelectionField", "automaticFields", "manualFields"]

  initialize: ->
    @update()

  update: ->
    switch @currentSelection()
      when 'automatic'
        @automaticFieldsTarget.hidden = false
        @automaticFieldsTarget.disabled = false

        @manualFieldsTarget.hidden = true
        @manualFieldsTarget.disabled = true

      when 'manual'
        @manualFieldsTarget.hidden = false
        @manualFieldsTarget.disabled = false

        @automaticFieldsTarget.hidden = true
        @automaticFieldsTarget.disabled = true

  currentSelection: ->
    if @automaticSelectionFieldTarget.checked
      'automatic'

    else if @manualSelectionFieldTarget.checked
      'manual'