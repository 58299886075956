import { Controller } from "stimulus"

export default class extends Controller
  @targets = ["monthField", "experienceNotMetErrorContainer"]

  @property 'currentMonth',
    get: ->
      parseInt(@data.get('currentMonth'))

  @property 'currentYear',
    get: ->
      parseInt(@data.get('currentYear'))

  @property 'year',
    get: ->
      parseInt(@data.get('year'))

    set: (value) ->
      @data.set('year', value)
      @render()

  @property 'month',
    get: ->
      @data.get('month')

    set: (value) ->
      @data.set('month', value)
      @render()

  @property 'enforce',
    get: ->
      @data.get('enforce') == '1'

  @property 'enforceYearsNeeded',
    get: ->
      parseInt(@data.get('enforceYearsNeeded'))

  @property 'requireMonthForYears',
    get: ->
      parseInt(@data.get('requireMonthForYears'))

  initialize: ->
    @render()

  yearChanged: (event) ->
    @year = event.target.value

  monthChanged: (event) ->
    @month = event.target.value

  render: ->
    if @currentYear - (@year || 0) <= @requireMonthForYears
      @monthFieldTarget.hidden = false

    else
      @monthFieldTarget.hidden = true

    if @enforce && @selectedTimeAsFraction() > 1
      if @currentTimeAsFraction() - @selectedTimeAsFraction() < @enforceYearsNeeded
        @experienceNotMetErrorContainerTarget.hidden = false

      else
        @experienceNotMetErrorContainerTarget.hidden = true

  currentTimeAsFraction: ->
    (@currentYear + ((@currentMonth) / 12))

  selectedTimeAsFraction: ->
    (@year || 0) + ((@month || 0) / 12)