import { Controller } from "stimulus"
import savingEmittingSave from "./save_emitting_rails_ajax"

export default class extends Controller
  @values =
    createDroppedArticleUrl: String

  createDroppedArticle: (event) ->
    # Don't let this event bubble any higher.
    event.stopImmediatePropagation()
   
    if (signed_id = event.detail.blob.signed_id)
      # Construct FormDate for the article submission.
      data = new FormData()
      data.append 'document_signed_id', signed_id

      # Send the request to add the article.
      savingEmittingSave @element, 'POST', @createDroppedArticleUrlValue, data, 'script'