import { Controller } from 'stimulus'

export default class extends Controller
  @targets: ['locationFields']
  
  change: (event) ->
    isMappable = event.currentTarget.value in ["true", "known"]

    # customEvent = new CustomEvent 'mappableLocationChanged',
    customEvent = new CustomEvent 'toggleMappableFields',
      detail: isMappable
    @locationFieldsTarget.dispatchEvent(customEvent)