import { Controller } from 'stimulus'
import _each from 'lodash/each'

export default class extends Controller
  @targets = ["submitButton"]
  
  initialize: ->
    @element.addEventListener 'direct-uploads-started', =>
      @_disableSubmitButtons()
    
    @element.addEventListener 'direct-uploads-stopped', =>
      @_enableSubmitButtons()
  
  _disableSubmitButtons: ->
    _each @submitButtonTargets, (submitButton) =>
      submitButton.setAttribute('disabled', true)
  
  _enableSubmitButtons: ->
    _each @submitButtonTargets, (submitButton) =>
      submitButton.removeAttribute('disabled')