import { Controller } from "stimulus"
import _each from "lodash/each"

###
Used to show notes field for moderator messages with custom HTML when selecting
a reject action.
###
export default class extends Controller
  @targets = [
    'selectField',
    'notesContainer',
  ]

  @property 'showWhenStartsWith',
    get: -> @data.get('showWhenStartsWith') or 'deny'

  initialize: ->
    @render()

  render: ->
    @notesContainerTarget.classList.toggle('hidden', @_denyOptionNotSelected())

  reviewTypeChanged: (event) ->
    @render()

  _denyOptionIsSelected: ->
    @selectFieldTarget.options[@selectFieldTarget.selectedIndex].value
      .startsWith(@showWhenStartsWith)

  _denyOptionNotSelected: ->
    !@_denyOptionIsSelected()