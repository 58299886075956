import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["entries", "more", "pagination"]

  @values =
    # The default behavior will expect 
    requestStrategy: String

  # Basic infinite scroll designed to work will our will_paginate helper.
  # 
  #   Basic markup implementation:
  #     
  #     %div{ data: { controller: 'infinite-scroll' } }
  #       %div{ data: { infinite_scroll_target: 'entries' } }
  #         %div An entry!
  #         %div Another entry!
  #         %div Yet another entry!
  #       
  #       %div{ data: { infinite_scroll_target: 'pagination' } }
  #         %div{ data: { infinite_scroll_target: 'more' } }
  #         = will_paginate @entries
  #         
  #   Expected server response:
  #     
  #     With the default behavior, where @requestStrategyValue is unchanged, this controller
  #     will expect:
  #     
  #       - Server to accept a request with format: :json.
  #       - A JSON object in return with two keys:
  #       
  #         entries: The rendered entries to append to the bottom of the `entries` target
  #                  shown above.
  #         
  #         pagination: The rendered will_paginate block for the requested page of results.
  #    
  #    With modified behavior, where @requestStrategyValue is set to 'js', this controller
  #    will expect:
  #    
  #      - Server to accept a request with format: :js.
  #      - Will expect that RJS response to maniupulate the page how it sees fit.
  #   
  initialize: ->
    # Initialize an Array to capture the URLs we've called
    # we'll push each URL into this Array so we only call up
    # each pages URL once.
    @urlsFetched = []

    # Hide the pagination container.
    if @hasPaginationTarget
      @paginationTarget.hidden = true

      # Set up the intersection observer.
      @intersectionObserver = new IntersectionObserver (entries) =>
        return if (entries[0].intersectionRatio <= 0)
        if (url = @nextPageUrl())
          # We have a next page. Load it.
          @loadMore(url)

        else
          # We don't have a next page. Hide the "more" indicator and stop observing.
          @moreTarget.hidden = true
          @intersectionObserver.disconnect()

      @intersectionObserver.observe(@moreTarget)

  # Get the URL for the next page. Returns null if there is no next page.
  nextPageUrl: ->
    nextPage = @paginationTarget.querySelector("a[rel='next']")
    return if (nextPage == null)
    nextPage.href

  loadMore: (url) ->
    # Don't fetch the same URL twice.
    return unless @urlsFetched.indexOf(url) == -1
    @urlsFetched.push(url)

    if @requestStrategyValue == 'js'
      Rails.ajax
        type: 'GET',
        url: url,
        dataType: 'script',
        success: (data) =>
          window.history.pushState {}, '', url

    else
      Rails.ajax
        type: 'GET',
        url: url,
        dataType: 'json',
        success: (data) =>
          window.history.pushState {}, '', url
          @entriesTarget.insertAdjacentHTML('beforeend', data.entries)
          @paginationTarget.innerHTML = data.pagination