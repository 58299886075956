import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["field", "checkbox"]

  initialize: ->
    @setCheckboxesFromField()

  setCheckboxesFromField: ->
    if @fieldTarget.value
      for value in @fieldTarget.value.split(',')
        for checkbox in @checkboxTargets
          if checkbox.value == value
            checkbox.checked = true

  update: (event) ->
    checkedValues = []

    for checkbox in @checkboxTargets
      if checkbox.checked
        checkedValues.push(checkbox.value)
    
    @fieldTarget.value = checkedValues.join(',')