import tinymce from 'tinymce/tinymce'
import 'tinymce/plugins/table'
import { Controller } from "stimulus"
import _find from 'lodash/find'
import _template from 'lodash/template'
  
export default class extends Controller
  @targets = ['backgroundImageSelectField', 'editorField']

  @values = 
    pdfCssPaths: String
    certBgPath: String

  # Expects [[key, { options }], ... ]
  @property 'tags',
    get: -> JSON.parse(@data.get('tags') or "[]")

  connect: ->
    @initBackgroundSelect()

    tinymce.PluginManager.add 'insertCertBuilderTagsMenu', (editor, url) =>
      editor.ui.registry.addMenuButton 'certBuilderTagsMenu',
        type: 'menubutton'
        text: 'Insert merge tag'
        fetch: (callback) =>
          callback @tags.map (tag) =>
            text = tag[1].name

            if tag[0] is 'participant_name'
              text += " (REQUIRED)"
            {
              type: 'menuitem',
              text: text,
              onAction: => editor.insertContent(tag[1].string)
            }

    tinymce.init(
      body_class: 'pdfcert pdfcert--for-display'
      content_css: @pdfCssPathsValue.split(',')
      elementpath: false
      images_upload_url: '/'
      # font_formats: 'Arial=arial,helvetica,sans-serif; Carattere=Carattere,script;'
      fontsize_formats: "8px 10px 12px 14px 18px 24px 30px 36px 42px 48px 54px 64px"
      height: 861
      menubar: false
      plugins: 'image code table hr insertCertBuilderTagsMenu'
      skin: false
      statusbar: false
      target: @editorFieldTarget
      toolbar: ' forecolor bold italic underline | bullist numlist | alignleft aligncenter alignright alignjustify hr | link unlink table | image | certBuilderTagsMenu | fontselect fontsizeselect formatselect '
      toolbar_sticky: true
      max_width: 1060

    ).then((editors) =>
      # Set the TinyMCE editor instance for this specific controller.
      @tinymceInstance = _find editors, (editor) => editor.targetElm == @editorFieldTarget

      # If init is completed and this controller has a BG image, set it on
      # the containing body.
      if @certBgPathValue
        @setBackground()
    )

  disconnect: ->
    tinymce.remove(@tinymceInstance)
    @destroyBackgroundSelect()

  initBackgroundSelect: ->
    $(@backgroundImageSelectFieldTarget).selectmenu(width: 300)
    $(@backgroundImageSelectFieldTarget).selectmenu("instance")._renderItem = @backgroundSelectmenuRenderItem
    $(@backgroundImageSelectFieldTarget).on('selectmenuchange', ((event, ui) -> 
      # Since we're leveraging jQuery here, but its events don't play nicely with modern JS
      # we're going to re-emit this event here using vanilla JS so we can grab it and do
      # something useful with it in this controller. Specifically, we're going to trigger
      # changing the background of the TinyMCE editor with whichever was chosen.
      this.dispatchEvent(new CustomEvent('backgroundChanged', { detail: { originalEvent: event, ui: ui }, bubbles: true }))
    ))

  destroyBackgroundSelect: ->
    $(@backgroundImageSelectFieldTarget).selectmenu("destroy")

  backgroundSelectmenuRenderItem: (ul, item) ->
    # Here `this.element` or `@element` is the `backgroundImageSelectFieldTarget` wrapped with jQuery
    
    # If the item's value is blank, it's our "Select a background option" that comes from Rails
    # include_blank option when generating selects. This item would nullify the selection if a person
    # wanted no background or to remove a background.
    if item.value == ''
      li = $('<li><i class="fa fa-fw fa-ban"></i>No background image</li>')
    
    else
      liTemplate = _template(@element.data('certificateBuilderLiTemplate'))
      li = $(liTemplate({ image: { tag: item.element.data('imageTag'), name: item.label }}))

    li.appendTo ul

  certBgPathValueChanged: (value) ->
    @setBackground()

  handleBackgroundChanged: (event) ->
    @certBgPathValue = event.detail.ui.item.element.data('url')

  refreshSelect: (event) ->
    $(@backgroundImageSelectFieldTarget).selectmenu("refresh")
    @certBgPathValue = event.detail.newBackgroundUrl

  setBackground: ->
    if @tinymceInstance
      @tinymceInstance.getBody().style.backgroundImage = "url('#{@certBgPathValue}')"