import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller
  @targets: ['sortContainer', 'orderField']

  initialize: ->
    new Sortable @sortContainerTarget,
      animation: 50
      ghostClass: 'sortable-placeholder-highlight-variable'
      group: 'group-custom-home-page-buttons'
      handle: "[role='sort_handle']"
      swapThreshold: 0.25
      onEnd: (event) => @update()

  update: ->
    @orderFieldTargets.forEach((target, index) -> target.value = index)
