import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["link"]

  @property 'show',
    get: ->
      parseInt(@data.get('show'))

  initialize: ->
    # If a valid show param was passed.
    unless isNaN(@show)
      for link, index in @linkTargets
        if (index - 1) >= @show
          link.hidden = true

  showAll: (event) ->
    # Show all links.
    for link in @linkTargets
      link.hidden = false

    # Hide the "Show more" link
    event.target.hidden = true