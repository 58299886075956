import { Controller } from "stimulus"
import _each from 'lodash/each'

export default class extends Controller
  @targets = ["searchField", "sizeVariantSlider", "fwCheckbox", "icon", "iconContainer"]
  
  sizeVariants: ['', 'fa-lg', 'fa-2x', 'fa-3x', 'fa-4x']
  
  initialize: ->
    @sizeVariantSliderTarget.setAttribute('max', @sizeVariants.length - 1)
  
  assignSize: (event) ->
    _each @iconTargets, (target) =>
      _each @sizeVariants, (sizeVariant) =>
        unless sizeVariant is ""
          target.classList.remove(sizeVariant)
    
    newSizeVariant = @sizeVariants[parseInt(event.target.value)]
    
    unless newSizeVariant is ""
      _each @iconTargets, (target) => target.classList.add(newSizeVariant)
    
    @data.set 'size', newSizeVariant
  
  assignIcon: (event) ->
    labelElement = event.target.closest('label')
    inputField = labelElement.querySelector('input')
    
    @data.set 'icon', inputField.value
  
  setFixedWidth: (event) ->
    @data.set 'fw', "#{ @fwCheckboxTarget.checked }"
    
  filter: (event) ->
    searchValue = @searchFieldTarget.value
    
    _each @iconContainerTargets, (target) =>
      target.classList.toggle 'hidden',
        target.dataset.keywords.indexOf(searchValue.toLowerCase()) is -1
      true