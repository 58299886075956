import { Controller } from "stimulus"
import _find from 'lodash/find'

# Starting the eventual transition from Backbone -> Stimulus for event forms.
export default class extends Controller
  @targets: [
    'mappableLocationRadio',
    'virtualDetailsContainer'
  ]
  
  initialize: ->
    @render()
  
  render: ->
    @toggleVirtualDetailsContainer()
  
  mappableLocationChanged: (event) ->
    @toggleVirtualDetailsContainer()
  
  toggleVirtualDetailsContainer: (event) ->
    if @hasVirtualDetailsContainerTarget
      shouldBeHidden = @_currentMappableLocationValue() is 'to_be_determined'
      @virtualDetailsContainerTarget.classList.toggle('hidden', shouldBeHidden)
      @virtualDetailsContainerTarget.dispatchEvent(new CustomEvent('visibilityChange', { detail: { hidden: shouldBeHidden }}))
  
  _currentMappableLocationValue: ->
    target = _find @mappableLocationRadioTargets, (target) => target.checked
    target.value if target
  