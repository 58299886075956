import { Controller } from "stimulus"
import { DirectUpload } from "activestorage"
import _each from 'lodash/each'
import _isEmpty from 'lodash/isEmpty'
import _filter from 'lodash/filter'
import { bytesToHumanFileSize, acceptedImageMimeTypes } from '../shared/file_utils'
import { mimeTypeToFaIcon } from '../shared/mime_type_to_fa_icon'

###
  Features currently not supported:
  
    * File fields with multiple: true
###

export default class extends Controller
  @targets: [
    "droparea",
    "purgeField", "purgeButton"
    "inactiveButtonCollection", "activeButtonCollection",
    "restoreButton",
    "dropMessage",
    "preview", "thumbnailImageWrapper", "contentTypeIcon",
    "fileButton", "fileButtonText", "fileField", "blobField",
    "progress", "fileName"
  ]
  
  @property 'isAttached',
    get: -> @data.get('attached') is 'true'
    set: (value) -> @data.set('attached', "#{value}")
  
  @property 'isMarkedForPurge',
    get: -> @data.get('marked-for-purge') is "true"
    set: (value) -> @data.set('marked-for-purge', "#{value}")
  
  @property 'thumbnailWidth',
    get: ->
      thumbnailSize = @data.get('thumbnail-size')
      if thumbnailSize
        parseInt(thumbnailSize.split(',')[0])
      else
        null
  
  @property 'thumbnailHeight',
    get: ->
      thumbnailSize = @data.get('thumbnail-size')
      if thumbnailSize
        thumbnailDimensions = thumbnailSize.split(',')
        parseInt(thumbnailDimensions[thumbnailDimensions.length - 1].trim())
      else
        null
  
  @property 'fileButtonText',
    get: ->
      if @isAttached and !@isMarkedForPurge
        @fileButtonTextTarget.dataset.attached or 'Select or drop a new file'
      else
        @fileButtonTextTarget.dataset.unattached or 'Select file'
  
  @property 'maxFileSize',
    get: ->
      dataMaxFileSize = @data.get('max-file-size') or ""
      if _isEmpty(dataMaxFileSize)
        104857600 # 100 MB
      else
        dataMaxFileSize

  @property 'maxFileSizeHuman',
    get: -> bytesToHumanFileSize(@maxFileSize)

  @property 'acceptedFileTypes',
    get: ->
      dataAcceptedFileTypes = @data.get('accepted-file-types') or ""
      if _isEmpty(dataAcceptedFileTypes)
        []
      else
        dataAcceptedFileTypes.split(',')
    
  initialize: ->
    # Set @persisted.  This is used to determine the behavior when the remove
    # button is pressed:
    #
    # - if the file is persisted, retain the blob when trying to remove it
    # - if the file is new, destroy the blob and skip restore
    #   OPTIMIZE: send a request to an endpoint to destroy the blob so
    #   it doesn't remain in storage, disconnected from a model.
    #
    # This is changed when a new file is queued for upload.
    @persisted = @isAttached is true
    @wasPersisted = @persisted is true
    
    @renderInitialIcon()
    @render()
    
    if @hasDropareaTarget
      # Reset drop and dragover for the document.  This needs to be done or the
      # dropZone will occur over the entire page, which isn't desired,
      # especially when there may be multiple file fields.
      document.addEventListener 'drop', (event) -> event.preventDefault()
      document.addEventListener 'dragover', (event) -> event.preventDefault()
        
      @dropareaTarget.addEventListener 'dragover', (event) => @dragover(event)
      @dropareaTarget.addEventListener 'dragleave', => @dragleave()
      @dropareaTarget.addEventListener 'drop', (event) => @drop(event)
  
  renderInitialIcon: ->
    if @hasContentTypeIconTarget
      initialContentType = @contentTypeIconTarget.dataset.initialContentType
      if initialContentType
        @contentTypeIconTarget.classList.add(mimeTypeToFaIcon(initialContentType))
  
  render: ->
    @fileButtonTextTarget.innerHTML = @fileButtonText
    
    if @isMarkedForPurge
      @_hidePurgeButton()
      @_showRestoreButton()
      @_hideDropMessage()
    
    else if @isAttached
      @_showPurgeButton()
      @_hideRestoreButton()
      @_hideDropMessage()
    
    else # no file
      @_hidePurgeButton()
      @_hideRestoreButton()
      @_showDropMessage()
  
  ### actions ###
  # Clicking remove attachment button
  purge: (event) ->
    @_hideProgress()
    if @persisted
      @isMarkedForPurge = true
      @purgeFieldTarget.value = '1'
    else
      @_removeBlobField()
      @_hidePreview()
      @isAttached = false
      @isMarkedForPurge = false
      if @wasPersisted
        @purgeFieldTarget.value = '1'
      else
        @purgeFieldTarget.value = null
    @render()
  
  # Clicking restore attachment button
  restore: ->
    @_hideProgress()
    @purgeFieldTarget.value = null
    @isMarkedForPurge = false
    @render()
  
  ### drag functions ###
  ###
    When using sortable in addition to this controller, sorting items will
    inadvertently trigger the drop area for file uploads.
  ###
  dragover: (event) ->
    if @_dragoverContainsFiles(event)
      @dropareaTarget.classList.add('file-attachment-drop-area--hover')
  
  dragleave: ->
    @dropareaTarget.classList.remove('file-attachment-drop-area--hover')
  
  # On drop, send files to direct upload
  drop: (event) ->
    event.preventDefault()
    @dragleave()

    unless @fileFieldTarget.getAttribute('disabled')
      if @fileFieldTarget.multiple
        # We do not have a mechanism for handling multiple files with
        # <input type="file" multiple="true">.
      else
        file = Array.from(event.dataTransfer.files)[0]
        @startDirectUploading(file)

  _dragoverContainsFiles: (event) ->
    dragoverContainsAFile = false

    if event.dataTransfer and event.dataTransfer.types
      _each event.dataTransfer.types, (type) =>
        dragoverContainsAFile = true if type is "Files"

    dragoverContainsAFile

  ### direct file field interaction ###
  # On change (directly using the file field), send files to direct upload and
  # clear the file field.
  fileFieldChanged: (event) ->
    if @fileFieldTarget.multiple
      # We do not have a mechanism for handling multiple files with
      # <input type="file" multiple="true">.
    else
      file = Array.from(event.currentTarget.files)[0]
      @startDirectUploading(file)

    @_clearFileField(event.currentTarget)

  ### direct upload functions ###
  startDirectUploading: (file) ->
    @persisted = false
    @_disableFileButton()
    @_removeBlobField()
    @_hidePreview()
    @_emitDirectUploadStartedEvent()

    if @hasFileNameTarget
      @fileNameTarget.innerText = file.name

    @_progressStart()
    
    unless @_acceptsFileType(file)
      @_enableFileButton()
      @_progressFileTypeNotAccepted()
      @_emitDirectUploadStoppedEvent()
      @render()
      return
    
    unless @_acceptsFileSize(file)
      @_enableFileButton()
      @_progressFileSizeTooLarge()
      @_emitDirectUploadStoppedEvent()
      @render()
      return
    
    url = @fileFieldTarget.dataset.directUploadUrl
    upload = new DirectUpload(file, url, @)
    upload.create (error, blob, x) =>
      @_emitDirectUploadStoppedEvent()
      @_enableFileButton()
      
      if error
        @_progressError(error)
      else
        file.contentType = blob.content_type
        @_setPreview(file)
        @_progressComplete()
        @_createOrReplaceBlobField(blob)
      
      @render()

  ###
    Callback from DirectUpload that handles a bunch of stuff.  We're using it
    here to create an event listener that listens to progress changes.
  ###
  directUploadWillStoreFileWithXHR: (request) ->
    request.upload.addEventListener "progress", =>
      @_progressTick(event.loaded, event.total)
  
  ### event emition functions ###
  _emitDirectUploadStartedEvent: ->
    @element.closest('form').dispatchEvent(new CustomEvent('direct-uploads-started'))
  
  _emitDirectUploadStoppedEvent: ->
    @element.closest('form').dispatchEvent(new CustomEvent('direct-uploads-stopped'))

  _emitProgressChangedEvent: (detail = {}) ->
    event = new CustomEvent 'progressChanged',
      detail: detail
    @progressTarget.dispatchEvent(event)
  
  ### upload fields functions ###
  ###
    Clears out the file field, ensuring only direct post with a blob will be
    posted.  Modern browsers allow directly setting value = null to clear
    out files; older browsers (IE < 10, for example) will raise an exception.
    In this case, simply replace the file field with a copy, which will not
    contain interaction data (such as files).
  ###
  _showPurgeButton: ->
    if @hasPurgeButtonTarget
      @activeButtonCollectionTarget.appendChild(@purgeButtonTarget)
      @purgeButtonTarget.classList.remove('hidden')
  
  _hidePurgeButton: ->
    if @hasPurgeButtonTarget
      @purgeButtonTarget.classList.add('hidden')
      @element.appendChild(@purgeButtonTarget)

  _showRestoreButton: ->
    if @hasRestoreButtonTarget
      @activeButtonCollectionTarget.appendChild(@restoreButtonTarget)
      @restoreButtonTarget.classList.remove('hidden')

  _hideRestoreButton: ->
    if @hasRestoreButtonTarget
      @restoreButtonTarget.classList.add('hidden')
      @element.appendChild(@restoreButtonTarget)
  
  _enableFileButton: ->
    @fileButtonTarget.removeAttribute('disabled')
  
  _disableFileButton: ->
    @fileButtonTarget.setAttribute('disabled', true)
  
  _clearFileField: (target) ->
    target or= @fileFieldTarget
    
    try
      target.value = null
    catch error
      # ignore
    
    if target.value
      target.parentNode.replaceChild(@fileFieldTarget.cloneNode(true), @fileFieldTarget)

  _createOrReplaceBlobField: (blob) ->
    # Attempt to remove a blob field that already exists
    @_removeBlobField()
    
    ###
      Add an appropriately-named hidden input to the form with a
      value of blob.signed_id so that the blob ids will be
      transmitted in the normal upload flow
    ###
    hiddenField = document.createElement 'input'
    hiddenField.setAttribute "type", "hidden"
    hiddenField.setAttribute "value", blob.signed_id
    hiddenField.setAttribute "data-#{@identifier}-target", "blobField"
    hiddenField.name = @fileFieldTarget.name
    
    # Append to the end of the container
    @element.appendChild(hiddenField)
    
    # Update settings
    @isAttached = true
    if @hasPurgeFieldTarget
      @purgeFieldTarget.value = null
    @isMarkedForPurge = false

  _removeBlobField: ->
    # If present, remove it
    if @hasBlobFieldTarget
      @blobFieldTarget.remove()
      
    # Update settings
    @isAttached = false
    if @hasPurgeFieldTarget
      @purgeFieldTarget.value = null
    @isMarkedForPurge = false

  ### file acceptance functions ###
  _acceptsFileType: (file) ->
    if _isEmpty(@acceptedFileTypes)
      return true
    else
      accepted = false
      _each @acceptedFileTypes, (acceptedFileType) =>
        if file.type == acceptedFileType
          accepted = true
        true
      accepted
  
  _acceptsFileSize: (file) ->
    file.size <= @maxFileSize

  ### file utility functions ###
  _fileIsAnImage: (file) ->
    file.contentType in acceptedImageMimeTypes()
  
  _readFileAndGetImage: (file, callback) ->
    fileReader = new FileReader()
    fileReader.onloadend = =>
      image = new Image
      image.src = fileReader.result
      image.onload = =>
        callback(image)
    fileReader.readAsDataURL(file)

  ### progress bar functions ###
  _progressStart: ->
    @progressTarget.classList.remove('hidden')
    @_emitProgressChangedEvent
      message: ''
      classModifiers: 'info striped'
      active: true
      visiblePercentage: true
      valuenow: 0
      messageType: 'status'
  
  _progressTick: (currentValue, maxValue) =>
    @_emitProgressChangedEvent
      valuenow: currentValue
      valuemax: maxValue
  
  _progressComplete: ->
    @_emitProgressChangedEvent
      classModifiers: 'success'
      active: false
      messageType: 'status'
  
  _progressError: (message) ->
    @_emitProgressChangedEvent
      valuenow: 100
      valuemax: 100
      visiblePercentage: false
      classModifiers: 'danger'
      active: false
      message: message
      messageType: 'error'
  
  _progressFileTypeNotAccepted: ->
    @_progressError 'This file type is not allowed'
  
  _progressFileSizeTooLarge: ->
    @_progressError "This file is too large (maximum: #{@maxFileSizeHuman})"

  _hideProgress: ->
    @progressTarget.classList.add('hidden')
  
  ### thumbnail preview functions ###
  _setPreview: (file) ->
    # If there's no thumbnail container, there's nothing to do
    return unless @hasPreviewTarget

    # Hide the preview before doing any work
    @_hidePreview()

    # Find the image wrapper.  This can be set in the main outer preview
    # container (@previewTarget) or the image might have an inner wrapper for
    # styling purposes (@thumbnailImageWrapperTarget).
    imageWrapperTarget = null
    
    imageWrapperTarget = if @hasThumbnailImageWrapperTarget
      @thumbnailImageWrapperTarget
    else
      @previewTarget
    
    # Remove images and any links within
    _each imageWrapperTarget.querySelectorAll('a, img'), (element) =>
      element.remove()

    # If it's an image, read the file, get the image as a IMG with
    # src="data:image*", and append it to the wrapper.
    # If it's not an image, get the FA icon and stick it in there.
    if @_fileIsAnImage(file)
      @_readFileAndGetImage file, (image) =>
        if @thumbnailHeight
          image.setAttribute 'width', @thumbnailWidth
          image.setAttribute 'height', @thumbnailHeight
        
        # Append the image to the image wrapper
        imageWrapperTarget.appendChild image
        
        # Reveal the image wrapper
        imageWrapperTarget.classList.remove('hidden')
      
    else if @hasContentTypeIconTarget
      # Add the new icon and reveal the icon
      @contentTypeIconTarget.classList.add(mimeTypeToFaIcon(file.contentType))
      @contentTypeIconTarget.classList.remove('hidden')
    
    # Reveal the outer wrapper
    @previewTarget.classList.remove('hidden')
    
  _hidePreview: ->
    if @hasPreviewTarget
      @previewTarget.classList.add('hidden')
    if @hasThumbnailImageWrapperTarget
      @thumbnailImageWrapperTarget.classList.add('hidden')
    if @hasContentTypeIconTarget
      classesToKeep = _filter @contentTypeIconTarget.classList, (className) =>
        if className.startsWith('fa-')
          className in ['fa-lg', 'fa-2x', 'fa-3x', 'fa-4x']
        else
          true
      @contentTypeIconTarget.classList = classesToKeep.join(' ')
      @contentTypeIconTarget.classList.add('hidden')
  
  _showDropMessage: ->
    if @hasDropMessageTarget
      @dropMessageTarget.classList.remove('hidden')
  
  _hideDropMessage: ->
    if @hasDropMessageTarget
      @dropMessageTarget.classList.add('hidden')
