import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["purgeField", "thumbnailContainer", "removeButton", "newFileLabel"]

  fileChanged: (event) ->
    if @hasPurgeFieldTarget
      @purgeFieldTarget.value = '0'

    newFileName = event.target.files[0].name

    @newFileLabelTarget.innerHTML = "
      <i class='fa fa-info-circle'></i>
      Will upload <strong>#{newFileName}</strong> on save.
    "

    @newFileLabelTarget.hidden = false

  remove: (event) ->
    event.preventDefault()
    @purgeFieldTarget.value = '1'
    @removeButtonTarget.hidden = true
    $(@thumbnailContainerTarget).css('background-image', '')
