import { Controller } from 'stimulus'

# Callback for sending request to sort membership tiers in the MCP.
# Should be placed on the FORM tag, should also use sortable, and should set
# an action to sortableSortComplete->sortable-membership-tiers#update
export default class extends Controller
  update: (event) ->
    $.ajax(@element.getAttribute('action'),
      type: 'PATCH'
      data: $(@element).serialize()
    ).done((response) =>
      console.log response
      $(event.detail.item).effect('highlight', {}, 1000)
    )