import { Controller } from "stimulus"

export default class extends Controller
  @property 'behavior',
    get: ->
      @data.get('behavior') || 'replace'

  @property 'url',
    get: ->
      @data.get('url')

  initialize: ->
    @pollInterval = setInterval((=> @poll()), 2500)

  poll: ->
    request = $.ajax
      url: @url,
      method: 'GET'

    request.then (response, status) =>
      if status != 'success'
        # Stop polling.
        clearInterval(@pollInterval)

      if response.status == 'done'
        # Stop polling.
        clearInterval(@pollInterval)

        switch @behavior
          when 'replace'
            # Replace the poll content with the content from the response.
            newDiv = document.createElement('div')
            newDiv.innerHTML = response.html
            @element.parentNode.replaceChild(newDiv, @element);

          when 'refresh'
            location.reload()