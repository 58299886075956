import tinymce from 'tinymce/tinymce'
import _debounce from 'lodash/debounce'
import _extend from 'lodash/extend'

# Default icons are required for TinyMCE 5.3+
import 'tinymce/icons/default';

# A theme is also required
import 'tinymce/themes/silver';

###
  Lastly, a skin is required. Import our custom skin.

  Follow the following steps to build/update a skin:

  * Visit https://skin.tiny.cloud/t5/
  * Upload /app/javascripts/styles/tinymce/skintool.json to load settings
  * Make necessary changes and download skin. Extract the .zip file.
  * Find skintool.json and place it in /app/javascripts/styles/tinymce
  * Find both skin.css and content.css.
    * Move skin.css to    /app/javascripts/styles/tinymce/skins/ui/hn/skin.css
    * Move content.css to /app/assets/stylesheets/base/themes/tinymce/content.css
  * Make the following changes to skin.css:
    * The font family set on the skin builder has been set to REPLACE_FONTS.
      Perform a global search and replace, replacing REPLACE_FONTS with our
      font set. As of 2022/06/20, this value should be:

        "Open Sans", Arial, Helvetica, sans-serif

    * Find the first .tox-tinymce selector. Replace:

        box-shadow: none;

      with the box-shadow used for form fields and .form-control elements.
      As of 2022/06/20, this can be found in
      /app/assets/stylesheets/base/mixins/_forms.scss, and the current
      value is:

        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);

    * At the very end of the file, add the following selector to fix a z-index
      issue with dropdowns/submenus (specifically in modals):

        .tox-tinymce-aux {
          position: relative !important;
          z-index: 20000;
        }
  * Make the following changes to content.css:
    * At the very end of the file, there are two selectors: body and table.
      Remove them entirely. If this is not done, the default fonts for the
      app will be reset to sans-serif, and tables may have some awkward
      consequences.

###
import '../styles/tinymce/skins/ui/hn/skin.css';

# Import plugins
import 'tinymce/plugins/code'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/noneditable'
import 'tinymce/plugins/paste'

# Common options used for all editors.
commonOptions = ->
  {
    # Remove h5, h6, and pre
    block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4'
    body_class: 'wysiwyg-input'
    branding: false
    browser_spellcheck: true
    cache_suffix: "?v=5.10.5"
    color_cols: 5
    color_map: defaultColorMap()
    # @see MetaTagsHelper.meta_tinymce_content_css_urls
    content_css: document.querySelector('head meta[name="tinymce-content-css"]').content
    content_style: ""
    contextmenu: false
    convert_urls: false
    extended_valid_elements: "i[class]"
    menubar: false
    paste_block_drop: true
    paste_data_images: false
    plugins: ' fullscreen paste link lists code image media_manager_menu media_menu noneditable hr '
    relative_urls: false
    # Do not define our skin here; it will attempt (and 404) to bring in
    # files under a certain heirarchy on every instance load. We're already
    # including the CSS above.
    skin: false
  }

# Options for simple editors
export simpleOptions = ->
  _extend {
    plugins: ' paste link fullscreen lists '
    resize: false
    statusbar: false
    toolbar: ' bold italic underline | bullist numlist | link unlink | fullscreen '
    valid_elements: "a[href|target=_blank|target:_blank],p,strong/b,ul,ol,li,em,br"
  }, commonOptions()

# Options for advanced editors
export advancedOptions = ->
  _extend {
    toolbar: ' forecolor bold italic underline | bullist numlist | alignleft aligncenter alignright alignjustify | link unlink media_menu code fullscreen | formatselect '
    valid_elements: "*[*]"
  }, commonOptions()

# Options for advanced editors when an owner is present with a media library
export advancedOptionsWithMedia = ->
  _extend {
    toolbar: ' forecolor bold italic underline | bullist numlist | alignleft aligncenter alignright alignjustify | link unlink media_manager_menu code fullscreen | formatselect '
    valid_elements: "*[*]"
  }, commonOptions()

# Sets up custom plugin code for TinyMCE
setup = (editor) ->
  # Add FA icon dialog as a command, not requiring a plugin
  editor.addCommand 'showFaDialog', =>
    editor.windowManager.openUrl({
      title: 'Insert Icon'
      url: '/common/icon_picker'
      width: 700
      height: 500
      buttons: [
        {
          text: 'Insert'
          type: 'custom'
          subtype: 'primary'
          default: true
        },
        {
          text: 'Cancel',
          type: 'cancel'
          onAction: 'close'
        }
      ]
      onAction: (dialogApi, details) =>
        frame = document.querySelector('.tox-dialog iframe')
        dataset = frame.contentWindow.document.body.dataset

        if dataset.iconPickerIcon and dataset.iconPickerIcon isnt ""
          icon = dataset.iconPickerIcon
          size = dataset.iconPickerSize or ""

          editor.insertContent "<i class='fa fa-#{icon} #{size}'><!-- icon --></i> "
          editor.targetElm.dispatchEvent(new CustomEvent('tinymceEditorKeyUp', detail: editor.getContent()))
          dialogApi.close()

        else
          editor.windowManager.alert("No icon was selected.")
    })

  # Add media manager dialog as a command, not requiring a plugin
  editor.addCommand 'showMediaManagerDialog', =>
    new App.Views.TinymceMediaManagerView
      el: $(editor.getElement())
      editor: editor

  # Add the following items to the PluginManager so we can control if/when
  # they are visible on an instance
  mediaManagerMenuItem =
    type: 'menuitem'
    text: 'Media Manager'
    icon: 'gallery'
    tooltip: 'Manage, upload, and insert images and links to files'
    onAction: => editor.execCommand('showMediaManagerDialog')

  insertImageFromUrlMenuItem =
    type: 'menuitem'
    text: 'Insert Image from URL'
    icon: 'image'
    onAction: => editor.execCommand('mceImage')

  insertIconMenuItem =
    type: 'menuitem'
    text: 'Insert Icon'
    icon: 'emoji'
    onAction: => editor.execCommand('showFaDialog')

  # Plugin for the custom media menu without the group's Media Manager
  tinymce.PluginManager.add 'media_menu', (editor, url) =>
    editor.ui.registry.addMenuButton 'media_menu',
      type: 'menubutton'
      icon: 'image'
      fetch: (callback) =>
        callback([
          insertImageFromUrlMenuItem,
          insertIconMenuItem
        ])

  # Plugin for the custom media menu with the group's Media Manager
  tinymce.PluginManager.add 'media_manager_menu', (editor, url) =>
    editor.ui.registry.addMenuButton 'media_manager_menu',
      type: 'menubutton'
      icon: 'image'
      fetch: (callback) =>
        callback([
          mediaManagerMenuItem,
          insertImageFromUrlMenuItem,
          insertIconMenuItem
        ])

  # Initialization. Configure the background color if
  # data-tinymce-initial-bg-color is set.
  editor.on 'init', (e) =>
    # On init, if @element has data-tinymce-initial-bg-color, set the
    # background color.  Then remove it so moving it to a new container
    # (usually via Sortable) won't re-init this color, possibly setting
    # the background color incorrectly.
    sourceElement = e.target.targetElm
    if color = sourceElement.dataset.tinymceInitialBgColor
      e.target.getBody().style.backgroundColor = color
    sourceElement.removeAttribute('data-tinymce-initial-bg-color')

    if sourceElement.dataset.setTinymceFocusOnInit
      e.target.focus()

  # Trigger events when content has changed.
  changeFunction = _debounce =>
    # jQuery trigger
    $(editor.targetElm).trigger('tinymceEditorContentChanged', editor.getContent())
    # JS event
    editor.targetElm.dispatchEvent(new CustomEvent('tinymceEditorKeyUp', detail: editor.getContent()))
  , 500,
    leading: true
    trailing: true

  editor.on 'keyup', changeFunction
  editor.on 'change', changeFunction

  # Set up a re-emission of the blur event. This will allow various Stimulus controllers
  # to catch tinymce specfic blurs.
  editor.on 'blur', (event) =>
    editor = event.target
    editor.targetElm.dispatchEvent(
      new CustomEvent('tinymceEditorBlur', { bubbles: true, detail: { editor: editor } })
    )

# Initializes a TinyMCE field.
# @return [Object] compiled options
export start = (options, extendedOptions = {}) ->
  if extendedOptions
    options = _extend extendedOptions, options
  # Call setup with the editor and set it to the options
  options.setup = (editor) => setup(editor)
  tinymce.init(options)
  options

# Unloads a TinyMCE field.
export stop = (options) ->
  tinymce.remove(options.selector)

# The default value for color_map for TinyMCE 5.
defaultColorMap = ->
  [
    '#BFEDD2', 'Light Green',
    '#FBEEB8', 'Light Yellow',
    '#F8CAC6', 'Light Red',
    '#ECCAFA', 'Light Purple',
    '#C2E0F4', 'Light Blue',

    '#2DC26B', 'Green',
    '#F1C40F', 'Yellow',
    '#E03E2D', 'Red',
    '#B96AD9', 'Purple',
    '#3598DB', 'Blue',

    '#169179', 'Dark Turquoise',
    '#E67E23', 'Orange',
    '#BA372A', 'Dark Red',
    '#843FA1', 'Dark Purple',
    '#236FA1', 'Dark Blue',

    '#ECF0F1', 'Light Gray',
    '#CED4D9', 'Medium Gray',
    '#95A5A6', 'Gray',
    '#7E8C8D', 'Dark Gray',
    '#34495E', 'Navy Blue',

    '#000000', 'Black',
    '#cccccc', 'Gray',
    '#ffffff', 'White'
  ]
