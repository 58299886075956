import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["textField", "warning"]

  initialize: ->
    @handleWarningsForContent()
    
  handleChange: ->
    @handleWarningsForContent()

  handleWarningsForContent: ->
    if @_hasUnsubscribe() then @showWarning() else @hideWarning()

  # Looks for:
  # - unsubscribe
  # - optout
  # - out-out
  # - opt out
  # - remove me
  # - notification settings
  _hasUnsubscribe: ->
    (@textFieldTarget.value.match(/unsubscribe|optout|opt\-out|opt\sout|remove\sme|notification\ssettings/gi) || []).length

  showWarning: (emailsInText) ->
    $(@warningTarget).slideDown('fast')

  hideWarning: ->
    $(@warningTarget).hide()