import { Controller } from "stimulus"

export default class extends Controller
  @targets: ['expandedShowable', 'expandToggle']

  @values =
    editSettingsUrl: String

  initialize: ->
    # Default state of a component is expanded.
    @expanded = true

  hover: ->
    @element.classList.add('newsletter-component--hover')

  leave: ->
    @element.classList.remove('newsletter-component--hover')

  toggleExpand: ->
    if @expanded
      @hideExpandableTargets()
    else
      @showExpandableTargets()

  hideExpandableTargets: ->
    for expandedShowable in @expandedShowableTargets
      closestComponentControllerEl = expandedShowable.closest('[data-controller~="newsletter--builder--component"]')
      if closestComponentControllerEl == @element
        expandedShowable.hidden = true
    @expanded = false
    @expandToggleTarget.style = "transform: rotate(180deg)"

  showExpandableTargets: ->
    for expandedShowable in @expandedShowableTargets
      closestComponentControllerEl = expandedShowable.closest('[data-controller~="newsletter--builder--component"]')
      if closestComponentControllerEl == @element
        expandedShowable.hidden = false
    @expanded = true
    @expandToggleTarget.style = "transform: rotate(0deg)"

  loadSettings: (event) ->
    if @editSettingsUrlValue
      @element.dispatchEvent(new CustomEvent('loadingSettings', 
        { 
          bubbles: true
        }
      ))
      
      unless window.currentManageComponentId == @element.dataset.componentId
        Rails.ajax
          type: 'GET'
          url: @editSettingsUrlValue
          dataType: 'script'
          complete: =>
            window.currentManageComponentId = @element.dataset.componentId