import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["number", "expiration", "verification"]

  connect: ->
    if @hasNumberTarget
      $(@numberTarget).payment('formatCardNumber')

    if @hasExpirationTarget
      $(@expirationTarget).payment('formatCardExpiry')

    if @hasVerificationTarget
      $(@verificationTarget).payment('formatCardCVC')