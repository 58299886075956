import { Controller } from "stimulus"

# There is NOT a way to completely reset a BS tokenfield entry field and
# reinitialize it with new values. There was a PR created that would have added
# this kind of functionality:
#   https://github.com/sliptree/bootstrap-tokenfield/issues/111
# but it was never merged and the project was abandoned. And, with an archived
# JS repo, I think it's better to let sleeping dogs lie instead of creating
# a custom branch.
#
# Using things like $tokenfield('setTokens') is unreliable at best, and
# unpredictable at worst, usually adding additional keys to the dataset, not
# adding them to the field.
#
# Using $tokenfield('destroy'), setting the value on the field, and
# reinitializing the tokenfield also does not work.
#
# This solution works fairly simply. However, it can be quite destructive as
# entire chunks of the DOM are reset. The preferred method is simply using
# data-controller="tokenfield" when possible. FWIW, this option should not
# be used on a container when other content may be dynamically changed on the
# page.
#
# Use this controller on the outer container of a tokenfield. This only works
# with a container with a SINGLE tokenfield field. When initialized, the
# innerHTML of the container will be stored in memory. When reset, the
# entire container will be returned to the original on-page-load state and
# re-rendered appropriately.
#
# Do NOT use data-controller="tokenfield". Instead, use
#   data-target="tokenfield-resettable-container.tokenfield"
# which will get data-controller="tokenfield" dynamically added after
# initialization or reset.
#
# HAML Example (newlines added for legibility):
#
#   .form-group{
#     data_controller: 'tokenfield-resettable-container',
#     data_action: 'reset->tokenfield-resettable-container#reset' }
#
#     %label{ for: ... } Enter some stuff, yo.
#     = text_field_tag ..., data: {
#       target: 'tokenfield-resettable-container.tokenfield',
#       tokenfield_persistent_autocomplete: 'true' }
#
# JS Event Example:
#
#   const container = document.querySelector('[data-controller="tokenfield-resettable-container"]')
#   container.dispatchEvent(new CustomEvent('reset', { detail: { value: '1,2,3' }}))
#
export default class extends Controller
  @targets: ["tokenfield"]

  initialize: ->
    @preInitializeInnerHTML = @element.innerHTML
    @render(false)

  render: (resetView = true, eventDetail = null) ->
    if resetView # Do not reset innerHTML on initialize
      @element.innerHTML = @preInitializeInnerHTML
      if eventDetail
        @tokenfieldTarget.value = eventDetail.value

    # Add the tokenfield controller
    @tokenfieldTarget.dataset.controller or= ''
    @tokenfieldTarget.dataset.controller += ' tokenfield'

  reset: (event) ->
    @render(true, event.detail or {})
