import 'pusher-js'

# Example usage in Stimulus Controller:
# 
# import { Controller } from "stimulus"
# import { pusherInstance } from '../../shared/pusher'
# 
# export default class extends Controller
#   initialize: ->
#     @pusher = pusherInstance("exampleChannel")
#     @pusher.bind 'exampleEvent', (data) ->
#       # ... Do your thing ...

pusherInstance = (channelName) ->
  pusher = new Pusher(pusherAppKey(), { cluster: 'us2' })
  channel = pusher.subscribe(channelName)
  channel

pusherAppKey = ->
  document.querySelector('meta[name="pak"]').getAttribute('content')

export { pusherInstance, pusherAppKey }