import { Controller } from 'stimulus'

export default class extends Controller
  @targets: ['field', 'characterCount']

  @values =
    maxLength: Number
    isValid: Boolean
    warningClass: String
    dangerClass: String

  initialize: ->
    # Ensure all the required elements are in place.
    if @hasFieldTarget && @hasCharacterCountTarget && (@maxLengthValue = @fieldTarget.getAttribute('maxlength')) && @maxLengthValue > 0
      @isValidValue = true

      @warningClassValue or= 'text-warning'
      @dangerClassValue or= 'text-danger'

    @updateCount()

  updateCount: (event) ->
    if @isValidValue
      # Get the current length from the event or field.
      currentLength = if event
        event.target.value.length
      else
        @fieldTarget.value.length

      percentageUsed = (currentLength / @maxLengthValue)

      if percentageUsed >= 0.90
        @characterCountTarget.classList.remove(@warningClassValue)
        @characterCountTarget.classList.add(@dangerClassValue)
      else if percentageUsed >= 0.70
        @characterCountTarget.classList.add(@warningClassValue)
        @characterCountTarget.classList.remove(@dangerClassValue)
      else
        @characterCountTarget.classList.remove(@warningClassValue, @dangerClassValue)

      @characterCountTarget.innerHTML = currentLength