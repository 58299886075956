import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["checkbox"]

  selectAll: ->
    for checkbox in @checkboxTargets
      checkbox.checked = true
      @emitChangeEventOnCheckbox(checkbox)

  selectNone: ->
    for checkbox in @checkboxTargets
      checkbox.checked = false
      @emitChangeEventOnCheckbox(checkbox)

  selectGroup: (event) ->
    values = event.target.dataset.toggleAllGroup.split(',')

    for checkbox in @checkboxTargets
      if checkbox.value in values
        checkbox.checked = true
        @emitChangeEventOnCheckbox(checkbox)

  emitChangeEventOnCheckbox: (checkbox) ->
    event = new Event('change')
    checkbox.dispatchEvent(event);