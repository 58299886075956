import { Controller } from "stimulus"
import _isEmpty from "lodash/isEmpty"
import _split from 'lodash/split'

export default class extends Controller
  connect: ->
    @id = @data.get('id')
    @pollUrl = @data.get('pollUrl')
    @partialsUrl = @data.get('partialsUrl')
    @pollTime = 1500
    @timerSwell = 100

    @startTimer()
  
  startTimer: ->
    @timerInterval or= @pollTime

    setTimeout =>
      request = $.post @pollUrl,
        id: @id

      request.done (response) =>
        if response == "processing"
          @startTimer()
        else
          @_getPartialsAndUpdate()
          
    , @timerInterval

    @timerInterval += @timerSwell
  
  _getPartialsAndUpdate: ->
    partialsRequest = $.get(@partialsUrl)

    partialsRequest.done (response) =>
      $tableRow = $("#media-#{@id}-table-row")
      $tableRow.html(response.table_row)
      App.Mixins.HighlightEffect.defaultHighlight($tableRow)

      $gridItem = $("#media-#{@id}-grid-item")
      $gridItem.html(response.grid_item)
      App.Mixins.HighlightEffect.defaultHighlight($gridItem)
