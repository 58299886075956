import { Controller } from "stimulus"
import _compact from 'lodash/compact'
import _every from 'lodash/every'
import _isEmpty from 'lodash/isEmpty'
import _join from 'lodash/join'
import _map from 'lodash/map'
import _trim from 'lodash/trim'
import _values from 'lodash/values'

export default class extends Controller
  @targets: [
    # Billing fields
    "firstName", "lastName",
    "addressStreet", "addressCity", "addressState", "addressZip",

    # Error block
    "errorsContainer",

    # Stripe Elements fields
    "cardNumber", "cardExpiry", "cardCvc",

    # Hidden fields
    "lastFour", "token", "brand", "month", "year",

    # Submit button
    "submitButton"
  ]

  initialize: ->
    # Set up some stuff
    @errors = {
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null
    }

    @completed = {
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false
    }

    # Configure Stripe and Elements
    @stripe = Stripe(@data.get('pk'))
    @elements = @stripe.elements(@_elementsOptions())

    # Create the individual Stripe Element objects
    @cardNumber = @elements.create('cardNumber', @_inputOptions())
    @cardExpiry = @elements.create('cardExpiry', @_inputOptions())
    @cardCvc = @elements.create('cardCvc', @_inputOptions())

    # Configure event listeners
    @cardNumber.addEventListener 'change', (event) =>
      @completed.cardNumber = event.complete
      @errors.cardNumber = if event.error then event.error.message else null
      @_afterStripeElementsFieldChange()
    @cardExpiry.addEventListener 'change', (event) =>
      @completed.cardExpiry = event.complete
      @errors.cardExpiry = if event.error then event.error.message else null
      @_afterStripeElementsFieldChange()
    @cardCvc.addEventListener 'change', (event) =>
      @completed.cardCvc = event.complete
      @errors.cardCvc = if event.error then event.error.message else null
      @_afterStripeElementsFieldChange()

    # Mount each Stripe Element object. We have to target these by an ID.
    @cardNumber.mount('#' + $(@cardNumberTarget).attr('id'))
    @cardExpiry.mount('#' + $(@cardExpiryTarget).attr('id'))
    @cardCvc.mount('#' + $(@cardCvcTarget).attr('id'))

    # We also want to configure event listeners for the other fields so the token can
    # have accurate metadata set.
    @firstNameTarget.addEventListener 'change', (event) => @_afterBillingInfoFieldChange(event)
    @lastNameTarget.addEventListener 'change', (event) => @_afterBillingInfoFieldChange(event)
    @addressStreetTarget.addEventListener 'change', (event) => @_afterBillingInfoFieldChange(event)
    @addressCityTarget.addEventListener 'change', (event) => @_afterBillingInfoFieldChange(event)
    @addressStateTarget.addEventListener 'change', (event) => @_afterBillingInfoFieldChange(event)
    @addressZipTarget.addEventListener 'change', (event) => @_afterBillingInfoFieldChange(event)

  _afterStripeElementsFieldChange: ->
    @_resetForm()

    if @_hasStripeErrors()
      @_showError(@_stripeErrors())
    else
      @_generateStripeToken() if @_hasCompletedStripeFields()

  _afterBillingInfoFieldChange: (event) ->
    @_resetForm()
    @_generateStripeToken() if !@_hasStripeErrors() and @_hasCompletedStripeFields()

  _resetForm: ->
    @_disableSubmit()
    @tokenTarget.value = null
    @brandTarget.value = null
    @monthTarget.value = null
    @yearTarget.value = null
    @lastFourTarget.value = null

  # Shows the given error message.  If the message is empty, it will hide the message
  # instead.
  _showError: (message) ->
    if _isEmpty(message)
      $(@errorsContainerTarget).hide()
    else
      @errorsContainerTarget.innerHTML = message
      $(@errorsContainerTarget).show()

  _enableSubmit: ->
    $(@submitButtonTarget).removeClass('disabled').removeClass('btn-disabled').removeAttr('disabled')

  _disableSubmit: ->
    $(@submitButtonTarget).addClass('disabled').removeClass('btn-disabled').attr('disabled', 'disabled')
  
  # Options hash for elements to include our application font
  _elementsOptions: ->
    {
      fonts: [
        { cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans:300' }
      ]
    }
  
  # Unfortunately, font options don't carry over to the Stripe elements via CSS.  This
  # has to all be done in JS.
  _inputOptions: ->
    {
      style: {
        base: {
          fontFamily: "'Open Sans', Arial, Helvetica, sans-serif",
          fontSize: '15px',
          lineHeight: '21px'
        }
      }
    }
  
  _stripeErrors: ->
    _join(_compact([@errors.cardNumber, @errors.cardExpiry, @errors.cardCvc]))
  
  _hasStripeErrors: ->
    !_isEmpty(@_stripeErrors())
  
  _hasCompletedStripeFields: ->
    _every(_values(@completed))
  
  _hasCompletedBillingFields: ->
    billingFields = [
      @firstNameTarget,
      @lastNameTarget,
      @addressStreetTarget,
      @addressCityTarget,
      @addressStateTarget
      @addressZipTarget
    ]

    billingFieldValues = _map billingFields, (field) -> _trim(field.value)
    _every billingFieldValues

  _generateStripeToken: ->
    # Note: createToken takes one argument, which can be either a combo card element
    # (which includes card number, expiration, and CVC), or ANY one of the separate
    # elements objects.
    @stripe.createToken(@cardNumber, {
      name: _join(_compact([@firstNameTarget.value, @lastNameTarget.value]), ' '),
      address_line1: @addressStreetTarget.value,
      address_city: @addressCityTarget.value,
      address_state: @addressStateTarget.value,
      address_zip: @addressZipTarget.value,
      address_country: "US"
    }).then (result) =>
      if result.error
        # There was a problem getting the token; display the error.
        @_showError(result.error.message)
      else
        # All is good; set the token and fields and we can submit.
        @tokenTarget.value = result.token.id
        @brandTarget.value = result.token.card.brand
        @monthTarget.value = result.token.card.exp_month
        @yearTarget.value = result.token.card.exp_year
        @lastFourTarget.value = "XXXXXXXXXXXX#{result.token.card.last4}"

        # Enable the submit button only if all fields are completed
        @_enableSubmit() if @_hasCompletedBillingFields()