import ModalLinkController from "./modal_link_controller"

export default class extends ModalLinkController
  @targets: ["subjectField"]

  initialize: ->
    # NOTE: we don't need the methods in ModalLinkController#initialize here.
    
    # Store the HREF
    @initialHref = @data.get('href')
    
    # Assign the HREF dynamically to the URL
    @setHref()

  setHref: ->
    newSubject = encodeURIComponent(@subjectFieldTarget.value || @subjectFieldTarget.placeholder)
    @data.set('href', "#{@initialHref}?subject=#{newSubject}")
