import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["textField", "warning", "warningEmailList"]

  initialize: ->
    $(@textFieldTarget).on "tinymceEditorContentChanged", (event, content) =>
      @handleWarningsForContent(content)

    @handleWarningsForContent(@textFieldTarget.value)

  handleChange: ->
    @handleWarningsForContent(@textFieldTarget.value)

  handleWarningsForContent: (content) ->
    if (emails = @emailsFromContent(content) || []).length
      @showWarning(_.uniq(emails))
    
    else
      @hideWarning()

  emailsFromContent: (content) ->
    (content || "").match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)

  showWarning: (emailsInText) ->
    $(@warningEmailListTarget).html(emailsInText.join(', '))
    $(@warningTarget).slideDown('fast')

  hideWarning: ->
    $(@warningTarget).slideUp('fast')