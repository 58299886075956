import { Controller } from "stimulus"
import Sortable from "sortablejs"
import savingEmittingSave from "./save_emitting_rails_ajax"

export default class extends Controller
  @values =
    createComponentUrl: String
    parentComponentId: Number
    rootContainer: Boolean
    updateComponentPositionsUrl: String

  initialize: ->
    @_toggleEmptyMessage()

    @sortable = Sortable.create @element,
      animation: 200
      handle: '.newsletter-component__sort-handle'
      group:
        name: 'shared'
        put: (toContainer, fromContainer, elementBeingMoved) =>
          # Everything can be dragged into the root container.
          toContainerType = toContainer.el.dataset.componentContainerType
          return true if toContainerType == 'root'

          # If the destination container is not root, determine if the element
          # being moved can descend the target container type.
          cannotDescendTypes = JSON.parse(elementBeingMoved.dataset.componentCannotDescend)
          return !(toContainerType in cannotDescendTypes)
          
      sort: true
      swapThreshold: 0.85
      onStart: (event) =>
        @element.dispatchEvent(
          new CustomEvent('componentSortStart', { bubbles: true, detail: { sortableEvent: event } })
        )
      
      onEnd: (event) =>
        @element.dispatchEvent(
          new CustomEvent('componentSortEnd', { bubbles: true, detail: { sortableEvent: event } })
        )

  enableSortable: ->
    @sortable.option('disabled', false)

  disableSortable: ->
    @sortable.option('disabled', true)

  # Handles all `add|update|remove` events emitted by Sortable.JS
  handleSort: (event) ->
    # Stop this add event from bubbling any higher.
    event.stopImmediatePropagation()

    # If this item was dropped from one of our component libraries. This is
    # a new component we need to create.
    if event.type == 'add' && event.from.dataset['newsletter-BuilderTarget'] == 'componentLibrary'
      @_createNewComponentFromAddEvent(event)
      
    else if event.type in ['add', 'update']
      @_updateComponentPositions()

      if @rootContainerValue
        for element in @element.children
          element.classList.add('newsletter-component--root')
      else
        for element in @element.children
          element.classList.remove('newsletter-component--root')

    # For all events (add, update, remove)
    @_toggleEmptyMessage()

  _createNewComponentFromAddEvent: (event) ->
    # Grab the type of component, the parent section ID (If applicable), and
    # the index of the dropped element in whatever list it was dropped.
    componentType = event.item.dataset['newsletterComponentType']
    position = event.newIndex

    # Generate a UUID we'll pass off to the server. If the component is success
    # fully created, the script response will use this UUID to replace the dropped
    # placeholder with the actual rendered component.
    nonce = crypto.randomUUID()
    event.item.id = nonce

    # Build the form data to send back to the server.
    data = new FormData()
    data.append 'component_type', componentType
    if @parentComponentIdValue
      data.append 'parent_component_id', @parentComponentIdValue
    data.append 'position', position
    data.append 'nonce', nonce

    savingEmittingSave @element, 'POST', @createComponentUrlValue, data, 'script'

  _updateComponentPositions: ->
    # Build the form data to send back to the server.
    data = new FormData()
    if @parentComponentIdValue
      data.append 'parent_component_id', @parentComponentIdValue
    for element, position in @element.querySelectorAll(':scope > [data-component-id]')
      data.append "components[#{element.dataset.componentId}]", position

    savingEmittingSave @element, 'PATCH', @updateComponentPositionsUrlValue, data, 'script'

  _toggleEmptyMessage: ->
    if @element.children.length
      @element.classList.remove('empty')
    else
      @element.classList.add('empty')