import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["form", "fields", "fileField"]

  initialize: ->
    $fields = $(@fieldsTarget)
    directPostFile = new App.Models.DirectPost.File $fields.data()

    new App.Views.DirectPostFieldsView
      el: $fields
      model: directPostFile
    
  submitForm: ->
    $(@formTarget).submit()