import { Controller } from "stimulus"
import { pusherInstance } from '../../shared/pusher'

export default class extends Controller
  @values =
    channel: String
    event: String
    fetchUrl: String
    disconnectAfterCatch: Boolean

  initialize: ->
    if @channelValue && @eventValue
      @pusher = pusherInstance(@channelValue)
      @pusher.bind @eventValue, (data) =>
        
        Rails.ajax
          url: @fetchUrlValue
          type: 'GET'
          dataType: 'script'
        
        if @disconnectAfterCatchValue
          @pusher.disconnect()