import { Controller } from "stimulus"

# Base controller for shared interactions between various search forms.
# Please do not use this controller directly - use others in this folder.
export default class extends Controller
  @targets: [
    'locationField',
    'filters'
  ]

  _configureLocationFieldAutocomplete: ->
    if @hasLocationFieldTarget
      # NOTE: location autocomplete with BS Typeahead requires targeting
      # the PARENT element as a jQuery element. Passing in the input
      # field will not work!
      $locationFieldParent = $(@locationFieldTarget).parent()

      new App.Views.Autocompleters.LocationCityStateView
        el: $locationFieldParent
        fieldReference: 'input'
