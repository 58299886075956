import { Controller } from "stimulus"

### Loads a remote URL into a container using rails-ujs remote links.

@example (markup modified for legibility)

  .container{ data: {
    controller: 'load-remote-content',
    load_remote_content: {
      insertion_method: 'inner|outer', # default: inner; see notes below.
      inject_loading_indicator: 'true' # default: undefined; see notes below.
    }
  }}
    %span{ data: { load_remote_content_target: 'loadLinkContainer' }}
      = link_to 'Load content',
        resource_url,
        remote: true,
        data: {
          # To avoid console errors, it is recommended to specify
          # data-type="html" when requesting a chunk of HTML markup.
          type: 'html',
          action: '
            ajax:before->load-remote-content#before
            ajax:complete->load-remote-content#complete
          '
        }

      # Optional: a loading indicator can be injected, but your own indicator
      # may be used in the following manner:
      %span.hidden{ data: { load_remote_content_target: 'loadingContainer' }}
        = icon 'spinner fa-spin', 'Loading...'

      # Optional: a section for your content. When this is omitted, the
      # parent element will have all of its innerHTML replaced.
      .content.hidden{ data: { load_remote_content_target: 'content' }}

###
export default class extends Controller
  @targets: [
    # Container that holds the remote link. Will be hidden on click.
    "loadLinkContainer",

    # Optional (recommended): container that holds a loading message, like
    # a spinner.
    "loadingContainer",

    # Optional: container that presents the remote content. When not provided,
    # the @element will be used as the basis to insert content instead.
    "content",
  ]

  # By default, innerHTML will be used to set the content (value == "inner").
  # If you wish to replace the @contentTarget or the entire controller
  # altogether, set data-@identifier-insertion-method="outer", which will use
  # outerHTML instead.
  @property 'insertionMethod',
    get: ->
      if @data.get('insertionMethod') is 'outer'
        'outer'
      else
        'inner'

  # Markup for a loading indicator can be injected by setting
  # data-@identifier-inject-spinner="true". See markup in
  # @_performLoadingIndicatorInjection for HTML.
  @property 'injectLoadingIndicator',
    get: -> @data.get('injectLoadingIndicator') is 'true'

  initialize: ->
    # Ensure targets that shouldn't be visible aren't
    @contentTarget.classList.add('hidden') if @hasContentTarget
    @loadingContainerTarget.classList.add('hidden') if @hasLoadingContainerTarget

    # Inject a loading indicator if requested and one doesn't exist
    if !@hasLoadingContainerTarget and @injectLoadingIndicator
      @_performLoadingIndicatorInjection()

  # When this action fires, the link is hidden and the loader (if present)
  # is shown. We cannot remove the link yet - the request will fail if removed.
  before: ->
    @loadLinkContainerTarget.classList.add('hidden')
    @loadingContainerTarget.classList.remove('hidden') if @hasLoadingContainerTarget

  # When this action fires, we're done. Note there is no error handling here
  # for unsuccessful requests!
  complete: (event) ->
    # Get the HTML as a string
    html = event.detail[0].response

    # Remove DOM elements that are no longer necessary
    @loadingContainerTarget.remove() if @hasLoadingContainerTarget
    @loadLinkContainerTarget.remove()

    # Insert the HTML either in the @contentTarget (if present) or the @element,
    # using either innerHTML() or outerHTML()
    if @hasContentTarget
      if @insertionMethod is 'outer'
        @contentTarget.outerHTML = html
      else
        @contentTarget.innerHTML = html
        @contentTarget.classList.remove('hidden')

    else
      if @insertionMethod is 'outer'
        @element.outerHTML = html
        # If we've replaced the element, there's no need to perform any
        # further cleanup. Return early; we're done here.
        return
      else
        @element.innerHTML = html

    # If we've gotten to this place, there's no reason this controller
    # needs to continue to exist in memory. Disconnect it by removing the
    # @identifier from the data-controller attribute. Add spaces here to ensure
    # we don't accidentally remove a similarly-named controller with an added
    # prefix or suffix.
    @element.dataset.controller = " #{@element.dataset.controller} ".replace(" #{@identifier} ", '')

  # Injects the spinner at the end of the @element.
  _performLoadingIndicatorInjection: ->
    @element.insertAdjacentHTML "beforeend", """
      <span class="hidden text-more-muted" data-#{@identifier}-target="loadingContainer">
        <i class="fa fa-spinner fa-spin"></i>
      </span>
    """
