import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["noteDisplay", "editForm"]

  initialize: ->
    @render()

  render: ->
    if @data.get('mode') == 'edit'
      if @hasEditFormTarget
        @editFormTarget.hidden = false
      @noteDisplayTarget.hidden = true
    else
      if @hasEditFormTarget
        @editFormTarget.hidden = true
      @noteDisplayTarget.hidden = false

  toggleEdit: ->
    if @data.get('mode') == 'edit'
      @data.set('mode', 'view')
    else
      @data.set('mode', 'edit')

    @render()