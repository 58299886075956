import { Controller } from "stimulus"

export default class extends Controller
  highlight: ->
    $(@element).effect('highlight', {}, 1000)

  formChanged: (event) ->
    Rails.fire event.currentTarget, 'submit'

  doSubmit: (event) ->
    @element.submit()