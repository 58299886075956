import { Controller } from "stimulus"

export default class extends Controller
  @targets: ['links', 'template']

  add: (event) ->
    event.preventDefault()
    
    content = @templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    @linksTarget.insertAdjacentHTML('beforebegin', content)

    # Send a namespaced "added" event up the chain
    @element.dispatchEvent(new CustomEvent('nestedFields.added', { bubbles: true }))
  
  remove: (event) ->
    event.preventDefault()
    
    wrapper = event.target.closest('.nested-fields')

    if wrapper.dataset.newRecord is 'true'
      wrapper.remove()
    
    else
      destroyElement = wrapper.querySelector('input[name*="_archive"]')
      destroyElement or= wrapper.querySelector('input[name*="_destroy"]')
      
      if destroyElement
        destroyElement.value = 1
        wrapper.style.display = 'none'
        wrapper.dataset.markedForDestruction = true
      else
        wrapper.remove()

    # Send a namespaced "removed" event up the chain
    @element.dispatchEvent(new CustomEvent('nestedFields.removed', { bubbles: true }))