import { Controller } from "stimulus"
import _each from 'lodash/each'
import _isEmpty from 'lodash/isEmpty'
import _trim from 'lodash/trim'
import { mimeTypeToFaIcon } from '../shared/mime_type_to_fa_icon'

export default class extends Controller
  @targets: [
    "droparea",
    "headingIcon",
    "settings",
    "thumbnailContainer", "thumbnail",
    "icon", "text"
    "fileField",
    "filename",
    "progressBarController",
  ]
  
  @ticking = false

  @property 'settingsOpen',
    get: ->
      if @hasSettingsTarget
        @settingsTarget.getAttribute('aria-expanded') == 'true'
      else
        false
    
    set: (value) ->
      if @hasSettingsTarget
        @settingsTarget.setAttribute('aria-expanded', value)
  
  @property 'currentFilename',
    get: ->
      if @hasProgressBarControllerTarget
        _trim((@progressBarControllerTarget.dataset.progressBarFilename or ""))
      else
        null

  initialize: ->
    unless _isEmpty @iconTarget.dataset.initIconMimeType
      
      if @iconTarget.dataset.initIconMimeType.match(/^image/) and @iconTarget.dataset.neverHide is "false"
        @iconTarget.classList = ''
      else
        @iconTarget.classList = 'fa ' + mimeTypeToFaIcon(@iconTarget.dataset.initIconMimeType)
    
    if @hasHeadingIconTarget
      unless _isEmpty @headingIconTarget.dataset.initIconMimeType
        @headingIconTarget.classList = 'fa ' + mimeTypeToFaIcon(@headingIconTarget.dataset.initIconMimeType)
    
    @render()

    # Init droparea callbacks.  This is done here instead of as a data-action
    # for performance reasons, though it could be improved with
    # requestAnimationFrame in the future (seems finnicky/flickery as of now).
    if @hasDropareaTarget
      # Reset drop and dragover for the document.  This needs to be done or the
      # dropZone will occur over the entire page, which isn't desired,
      # especially when there may be multiple file fields.
      document.addEventListener 'drop', (event) -> event.preventDefault()
      document.addEventListener 'dragover', (event) -> event.preventDefault()

      @dropareaTarget.addEventListener 'dragover', @_dragover, false
      @dropareaTarget.addEventListener 'dragleave', @_dragleave, false
      @dropareaTarget.addEventListener 'drop', @_dragleave, false
  
  render: ->
    # Toggle settings container
    if @hasSettingsTarget
      @settingsTarget.classList.toggle('in', @settingsOpen)
    
    # Set filename or show "drop file here" text
    if !_isEmpty(@currentFilename)
      @textTarget.textContent = @currentFilename
    else
      @textTarget.textContent = @textTarget.dataset.default
  
  toggleSettings: (event) ->
    event.preventDefault()
    @settingsOpen = !@settingsOpen
    @render()

  uploadFileAdded: (event) ->
    @_resetThumbnail()
    
    # Set in progress stuff
    @iconTarget.classList = 'fa fa-spinner fa-spin text-info'
    if @hasHeadingIconTarget
      @headingIconTarget.classList = @headingIconTarget.dataset.defaultClasses
    @textTarget.textContent = event.detail.data.originalFiles[0].name
    
  uploadFileInvalid: (event) ->
    @_resetThumbnail()
    
    # Set icon and text back to defaults
    @iconTarget.classList = @iconTarget.dataset.defaultClasses
    if @hasHeadingIconTarget
      @headingIconTarget.classList = @headingIconTarget.dataset.defaultClasses
    @textTarget.textContent = @textTarget.dataset.default
  
  uploadSuccessful: (event) ->
    # Grab CustomEvent detail
    data = event.detail.data
    file = data.originalFiles[0]
    mimeType = file.type
    
    # If the type is an image, render a preview; otherwise, set the icon to
    # an icon for the file type uploaded.
    if mimeType.match /^image/ig
      reader = new FileReader
      
      reader.onload = (readerEvent) =>
        if @iconTarget.dataset.neverHide is "true"
          @iconTarget.classList = 'fa ' + mimeTypeToFaIcon(mimeType)
        else
          @iconTarget.classList = ''
          
        @thumbnailTarget.src = readerEvent.target.result
        @thumbnailContainerTarget.classList.remove 'hidden'
        
      reader.readAsDataURL(file)
    
    else
      @iconTarget.classList = 'fa ' + mimeTypeToFaIcon(mimeType)

    if @hasHeadingIconTarget
      @headingIconTarget.classList = 'fa ' + mimeTypeToFaIcon(mimeType)
  
  _resetThumbnail: ->
    @thumbnailContainerTarget.classList.add 'hidden'
    @thumbnailTarget.src = null
  
  _dragover: (event) =>
    if @_dragoverContainsFiles(event)
      @dropareaTarget.classList.add('paperclip-drop-area--hover')

  _dragleave: =>
    @dropareaTarget.classList.remove('paperclip-drop-area--hover')
  
  ###
    When using sortable in addition to this controller, sorting items will
    inadvertently trigger the drop area for file uploads.
  ###
  _dragoverContainsFiles: (event) ->
    dragoverContainsAFile = false
    
    if event.dataTransfer and event.dataTransfer.types
      _each event.dataTransfer.types, (type) =>
        dragoverContainsAFile = true if type is "Files"
    
    dragoverContainsAFile