import { Controller } from "stimulus"

# Base controller that handles confirming or declining a pending claim
# or offer for a seat/item/ticket/etc.
#
# Please use event--confirm-pending--claim or event--confirm-pending--offer
# instead of this controller for semantic reasons.
#
export default class extends Controller
  @targets: [
    'confirmContent',
    'confirmNotInterestedContent'
  ]

  initialize: ->
    if @data.get('jumpToNotInterestedConfirmation') is 'true'
      @confirmNotInterestedContentTarget.classList.remove('hidden')
    else
      @confirmContentTarget.classList.remove('hidden')

  toggle: (event) ->
    event.preventDefault()

    @confirmNotInterestedContentTarget.classList.toggle('hidden')
    @confirmContentTarget.classList.toggle('hidden')



