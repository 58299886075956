import { Model } from 'shared/model'
import _compact from 'lodash/compact'
import _isEmpty from 'lodash/isEmpty'
import _isUndefined from 'lodash/isUndefined'

###*
 * Location data model
###
export class Geolocation extends Model
  @property 'schema',
    get: ->
      [
        "name",
        "streetNumber",
        "streetName",
        "streetAddress",
        "city",
        "state",
        "zipCode",
        "lat",
        "lng",
        "isMappable",
      ]

  @property 'internalGeolocationEndpoint',
    get: -> '/geolocate'

  @property 'unclearableFields',
    get: -> ['isMappable']

  ###*
   * Fetch the current state of the data as given.
   *
   * @return [String]
   *   "complete" if a full address and a lat/lng pair are present
   *   "partial" if only a partial address, or a full address without a lat/lng
   *     pair are present
   *   "pending" if there is no data
  ###
  currentState: ->
    if @fullAddress() and @hasLatLng()
      'complete'
    else if @hasPartialAddress() or (@fullAddress() and !@hasLatLng())
      'partial'
    else
      'pending'

  ###*
   * Provides a full address if all address parts are present.  Also useful for
   * determining if the address is complete and can be geolocated.
   *
   * @param divider [String] The divider between the street address line
   *                         and the city/state/zip line.  Useful for
   *                         outputting a single line address or
   *                         splitting it up into two lines (for HTML)
   * @return [String] 123 Some Street, Anytown, US 12345
   * @return [Boolean] false if full address is not present
  ###
  fullAddress: (divider = ', ') ->
    if !_isEmpty(@streetAddress) and
    !_isEmpty(@city) and
    !_isEmpty(@state) and
    !_isEmpty(@zipCode)

      "#{ @streetAddress }#{ divider }#{ @city }, #{ @state } #{ @zipCode }"

    else
      false

  # Whether a full address (not including lat/lng) are provided.
  #
  # @return [Boolean]
  hasFullAddress: ->
    @fullAddress() isnt false

  # Whether or not an address has some parts (street, city, state, zip).
  #
  # @return [Boolean]
  hasPartialAddress: ->
    !_isEmpty(@streetAddress) or
    !_isEmpty(@city) or
    !_isEmpty(@state) or
    (!_isEmpty(@zipCode) and !_isUndefined(@zipCode))

  # Whether or not a latitude and longitude are set and valid.
  #
  # @return [Boolean]
  hasLatLng: ->
    latPresence = !_isUndefined(@get('lat')) and !_isEmpty("#{@get('lat')}")
    lngPresence = !_isUndefined(@get('lng')) and !_isEmpty("#{@get('lng')}")
    latPresence and lngPresence

  # Sets model information based on a Google Places API place object
  #
  # @param place [Object] The Google Places API place object
  setLocationFromPlace: (place) ->
    place.address_components.forEach (addressComponent, index) =>
      addressType = addressComponent.types[0]

      # if there is a known mapping for the Google Places address_component
      # type, grab that value and set it on the model.
      if mapping = @_placesAddressComponentMapping(addressType)
        componentValue = addressComponent[mapping]
        modelField = @_placesToInternalFieldMapping(addressType)

        # Google Places returns D.C. instead of DC for Washington, DC.
        # This exceeds the max length of the state field.
        # Truncate it down to "DC."
        if modelField is 'state' and componentValue is 'D.C.'
          componentValue = 'DC'

        # We have encountered a Google Place that provides an
        # "administrative_area_level_3" address component but not a "locality"
        # component. In this case, the "administrative_area_level_3" component
        # was the property we wanted to capture as our city. Here, we're
        # accounting for the possible case where we get a Place that has both
        # "locality" and "administrative_area_level_3" components. In that case,
        # we prefer "locality" and will allow it to set the model's city.
        # This component could override the value from
        # "administrative_area_level_3" but not the other way around.
        unless !_isEmpty(@city) and addressType is 'administrative_area_level_3'
          @[modelField] = componentValue
    
    # Set remaining fields that do not require evaluation of their type
    @name = place.name
    @streetAddress = _compact([@streetNumber, @streetName]).join(" ")
    @lat = place.geometry.location.lat()
    @lng = place.geometry.location.lng()

  ###*
   * Used to map which of the address_component objects provided by the
   * Google Places API place object that we want to use (short_name/long_name).
   * Non-present keys signify values we do not need and can be disregarded.
   *
   * @param attribute [String] The address_component key
   * @return [String] "short_name" or "long_name"
   * @return [undefined] if no match
  ###
  _placesAddressComponentMapping: (attribute) ->
    @addressComponentMapping or= new Model
      street_number: 'short_name'
      route: 'long_name'
      locality: 'long_name'
      administrative_area_level_1: 'short_name'
      administrative_area_level_3: 'long_name'
      country: 'long_name'
      postal_code: 'short_name'
    
    @addressComponentMapping[attribute]

  ###*
   * Used to map the address_component object names provided by the Google
   * Places API place object to the appropriate attributes on the model.
   *
   * @param attribute [String] The address_component key
   * @return [String] This model's corresponding attribute name for the
   *   address_component name key passed in
   * @return [undefined] if no match
  ###
  _placesToInternalFieldMapping: (attribute) ->
    @internalFieldMapping or= new Model
      street_number: 'streetNumber'
      route: 'streetName'
      locality: 'city'
      administrative_area_level_1: 'state'
      administrative_area_level_3: 'city'
      postal_code: 'zipCode'
      country: 'country'
    
    @internalFieldMapping[attribute]
