import { Controller } from "stimulus"
# dependency: jQuery
# dependency: cocoon
# dependency: cocoon-events

###
  Sets a button's (button, anchor tag, etc.) text to different values based
  on the visibility of nested records in a given cocoon container.

  @requires cocoon-events

  @data
    *-zero - text value for no nested records
    *-one - text value for one nested record
    *-many - text value for more than one nested record

    *-zero is always required.  However, either *-many or *-one can be used
    as a catch all for if there are any nested records.

  @targets
    @buttonTarget - the button that will have its text changed based on the
      number of nested records

  @example (new lines added for legibility)

    %fieldset{
      data: {
        controller: 'cocoon-button-changer',
        cocoon_button_changer: {
          zero: '+ Add a thing',
          one: '+ Add another thing',
          many: '+ Add another thing' # :one is optional here, since :one and :many are the same
        }
      }
    }
      .where-cocoon-stuff-goes{
        data: {
          controller: 'cocoon-events',
          action: '
            cocoonEventsInit->cocoon-button-changer#update            # Initializes everything
            cocoonChildElementsChanged->cocoon-button-changer#update  # Updates
          '
        }
      }
        = f.fields_for :stuff do |ff|
          = render 'stuff_fields', f: ff

          # In _stuff_fields.haml
          .nested-fields{ data: { cocoon_events_target: 'element' }}
            ...

      .btn-container-stretch
        = link_to_add_association '+ Add a thing', f, :stuff, { ..., data: { ..., cocoon_button_changer_target: 'button' }}

###
export default class extends Controller
  @targets = ['button']
  
  @property 'zeroText',
    get: -> @data.get('zero')
  
  @property 'oneText',
    get: -> @data.get('one') || @data.get('many')
  
  @property 'manyText',
    get: -> @data.get('many') || @data.get('one')
  
  update: (event) ->
    if @hasButtonTarget and event.detail
      @buttonTarget.textContent = switch event.detail.currentVisibleElementCount
        when 0, null then @zeroText
        when 1 then @oneText
        else @manyText