import { Controller } from "stimulus"
import _find from 'lodash/find'
import _isNull from 'lodash/isNull'
import _unescape from 'lodash/unescape'

export default class extends Controller
  @targets: [
    'quickForm',
      'quickFormAutocompleteId',
    'selectionContainer',
    'selectedContainer',
    'idField',
    'newForm',
    'query', # query field
    'displayTemplate', # <template> for the user information
    'extendedOptions',
    'queryText'
  ]

  @values =
    state: String

  # set data-autocomplete-speakers-endpoint to the URL where autocomplete
  # queries can be performed, returning the proper JSON.
  # Expects the user to have a
  #   ?query=AUTOCOMPLETEQUERY
  # parameter included in the URL, which will be replaced upon search.
  @property 'endpoint',
    get: -> @data.get('endpoint')

  @property 'speaker',
    get: ->
      unless _isNull(@data.get('speaker'))
        JSON.parse(@data.get('speaker'))
      else
        null
    set: (stringifiedJSON) -> @data.set('speaker', stringifiedJSON)

  initialize: ->
    @stateValue = 'init'
    @render(true)

    source = new Bloodhound
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('id', 'name', 'email')
      queryTokenizer: Bloodhound.tokenizers.whitespace
      remote:
        url: @endpoint
        wildcard: 'AUTOCOMPLETEQUERY'
        rateLimitBy: 'debounce'
        rateLimitWait: 1000

    $(@queryTarget).typeahead
      minLength: 3
    ,
      source: source
      display: 'name'
      limit: 20
      templates:
        header: @headerTemplate
        pending: @pendingTemplate
        notFound: @notFoundTemplate
        suggestion: (speaker) => @template(speaker, false, false)

    # typeahead creates a duplicate field. This kinda sucks, because now we
    # have to watch multiple fields for typeahead:select.
    @queryTargets.forEach (target) =>
      $(target).on 'typeahead:select', (event, option) =>
        @speaker = JSON.stringify(option)
        @_disableSearch()
        @setSpeakerFromAutocomplete(@speaker)

      if target.classList.contains('tt-input')
        target.focus()

  render: (initialLoad = false)->
    if @hasSelectedContainerTarget and @hasSelectionContainerTarget
      @selectedContainerTarget.classList.toggle('hidden', !@speaker)
      @selectionContainerTarget.classList.toggle('hidden', !!@speaker)

    @_resetSearch()

    unless initialLoad
      return unless @hasSelectedContainerTarget and @hasIdFieldTarget

      if @speaker
        @idFieldTarget.value = @speaker.id
        @selectedContainerTarget.innerHTML = @template(@speaker, true)

      else
        @idFieldTarget.value = null
        @selectedContainerTarget.innerHTML = ''

  handleEnter: (event) ->
    @addDetails() if event.which == 13

  reset: ->
    @speaker = null
    @render()

  template: (speaker, showLinks = false) ->
    @stateValue = 'init'
    speaker.showLinks = showLinks

    _.template(_unescape(@displayTemplateTarget.innerHTML),
      variable: 'data'
    )(speaker)

  headerTemplate: (query) =>
    """
      <div class="bs5--m-2 bs5--p-2 text-center panel panel-warning small">
        <i class="fa fa-info-circle"></i>
        Don't see the right speaker for your search?
        <a role="button" data-action="autocomplete-speakers#addDetails">Add a new speaker</a>
      </div>
    """

  pendingTemplate: (query) =>
    @stateValue = 'init'
    """
      <div class="bs5--mx-4 text-muted">
        <i class="fa fa-spinner fa-spin"></i>
        Searching for <strong>#{ query.query }</strong>...
      </div>
    """

  notFoundTemplate: (query) =>
    @queryTextTargets.forEach((target) => target.innerText = query.query.trim())
    @stateValue = 'none'

    if @hasExtendedOptionsTarget
      # Callback on value change will occur, changing the content in the DOM.
      # Return an empty string to display nothing.
      ""

    else
      """
        <div class="bs5--mx-4 text-danger">
          <i class="fa fa-exclamation-triangle"></i>
          No speakers found for <strong>#{ query.query }</strong>.
        </div>
      """

  addDetails: ->
    if @hasNewFormTarget
      Rails.fire @newFormTarget, 'submit'

  setSpeakerFromAutocomplete: (speaker) ->
    if @hasQuickFormAutocompleteIdTarget
      @quickFormAutocompleteIdTarget.value = speaker.id
      Rails.fire @quickFormTarget, 'submit'

    else if @hasSelectedContainerTarget
      @speaker = JSON.stringify(speaker)
      @render()

  stateValueChanged: (value) =>
    if @hasExtendedOptionsTarget
      @extendedOptionsTarget.classList.toggle('hidden', value isnt 'none')

  _resetSearch: ->
    @queryTargets.forEach (target) =>
      target.disabled = false
      target.value = ''

      if target.classList.contains('tt-input')
        $(target).typeahead('val', '')

  _disableSearch: ->
    @queryTargets.forEach (target) =>
      target.disabled = true
      target.value = ''

      if target.classList.contains('tt-input')
        $(target).typeahead('val', '')
