import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["editFieldset", "presentation"]

  @property 'mode',
    get: ->
      @data.get('mode') || 'present'

    set: (value) ->
      @data.set('mode', value)
      @render(false)

  @property 'preventDisable',
    get: ->
      @data.get('preventDisable') == 'true'

  initialize: ->
    @render()

  render: (animate = true)->
    if @mode == 'present'
      @presentationTarget.hidden = false

      @editFieldsetTarget.hidden = true
      unless @preventDisable
        @editFieldsetTarget.disabled = true

    else if @mode == 'edit'
      @presentationTarget.hidden = true

      @editFieldsetTarget.hidden = false
      unless @preventDisable
        @editFieldsetTarget.disabled = false

  toggleMode: ->
    if @mode == 'present'
      @mode = 'edit'
    else
      @mode = 'present'