import { Controller } from "stimulus"
import _isEmpty from 'lodash/isEmpty'
import _isNull from 'lodash/isNull'
import _unescape from 'lodash/unescape'

export default class extends Controller
  @targets: [
    'idField',
    'selectionContainer',
      'searchField',
    'selectedContainer',
    'suggestionTemplate'
  ]

  # set data-*-endpoint to the URL where autocomplete queries can be
  # performed, returning the proper JSON. Excepts the URL to have a
  # ?query=AUTOCOMPLETEQUERY parameter included which will be replaced
  # with the actual query on search
  @property 'endpoint',
    get: -> @data.get('endpoint')

  @property 'group',
    get: ->
      if _isNull(@data.get('group')) or _isEmpty(@data.get('group'))
        null
      else
        JSON.parse(@data.get('group'))
    set: (stringifiedJSON) -> @data.set('group', stringifiedJSON)

  initialize: ->
    @render(true)

    source = new Bloodhound
      identify: (object) -> object.id
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('id', 'name')
      queryTokenizer: Bloodhound.tokenizers.whitespace
      remote:
        url: @endpoint
        wildcard: 'AUTOCOMPLETEQUERY'
        rateLimitBy: 'debounce'
        rateLimitWait: 500

    $(@searchFieldTarget).typeahead
      minLength: 1
    ,
      source: source
      display: 'name'
      limit: 10
      templates:
        pending: @pendingTemplate
        notFound: @notFoundTemplate
        suggestion: (group) => @template(group, false)

    # typeahead creates a duplicate field. This kinda sucks, because now we
    # have to watch multiple fields for typeahead:select.
    @searchFieldTargets.forEach (target) =>
      $(target).on 'typeahead:select', (event, option) =>
        @group = JSON.stringify(option)
        @render()

  render: (initialLoad = false) ->
    @selectionContainerTarget.classList.toggle('hidden', !!@group)
    @selectedContainerTarget.classList.toggle('hidden', !@group)
    @searchFieldTargets.forEach (target) => target.value = ''

    unless initialLoad
      if @group
        @idFieldTarget.value = @group.id
        @selectedContainerTarget.innerHTML = @template(@group, true)

      else
        @idFieldTarget.value = null
        @selectedContainerTarget.innerHTML = ''

  reset: ->
    @group = null
    @render()

  template: (group, showLinks = false) ->
    _.template(_unescape(@suggestionTemplateTarget.innerHTML),
      variable: 'data'
    )({
      id: group.id,
      name: group.name,
      avatar: group.avatar,
      admin_manage_path: group.admin_manage_path,
      subdomain: group.subdomain,
      showLinks: showLinks
    })

  pendingTemplate: (query) =>
    """
      <div class="bs5--mx-4 text-muted">
        <i class="fa fa-spinner fa-spin"></i>
        Searching for <strong>#{ query.query }</strong>...
      </div>
    """

  notFoundTemplate: (query) =>
    """
      <div class="bs5--mx-4 text-danger">
        <i class="fa fa-exclamation-triangle"></i>
        No results found for <strong>#{ query.query }</strong>.
      </div>
    """
