import { Controller } from "stimulus"
import _each from 'lodash/each'
import _isEmpty from 'lodash/isEmpty'
import _isUndefined from 'lodash/isUndefined'

# JS controller that handles setting the appropriate fields for payment amount,
# allowing a moderator to specify a custom amount when manually activating
# a membership.
export default class extends Controller
  @targets = ['override',
    'selectablePricePoint',
    'paymentMethodFree',
    'paymentAmountField'
  ]
  
  initialize: ->
    @freeSelected = @paymentMethodFreeTarget.checked
    @tierPriceSelected = undefined
    
    _each @selectablePricePointTargets, (selectablePricePointTarget) =>
      if selectablePricePointTarget.checked
        @tierPriceSelected = selectablePricePointTarget.dataset.pricePoint
      
      _isUndefined(@tierPriceSelected) # if undefined (true), continue loop; otherwise break @_each
    
    @render()
    
  render: ->
    return false if _isUndefined(@tierPriceSelected)
    # Set the placeholder for the default amount.  This will be calculated
    # in the controller.  When no value is given on this field, the controller
    # will handle setting the payment amount.
    @paymentAmountFieldTarget.placeholder = if @freeSelected then '0.00' else @tierPriceSelected
    
    # Refresh the override hidden field, which tells the membership activation
    # payment whether or not to use a custom price or use the default price.
    @refreshOverrideField()
  
  # Sets the tier price internally and rerenders.
  # Expects a number as a string without delimiters and a precision of 2 as the
  # value for the selected radio's data-price-point.
  selectTierPricePoint: (event) ->
    @tierPriceSelected = event.currentTarget.dataset.pricePoint
    @render()
  
  # Checks if the free option is selected, then rerenders.
  selectPaymentMethod: (event) ->
    @freeSelected = @paymentMethodFreeTarget.checked
    @render()
  
  # Refresh the value of the override price hidden field.
  refreshOverrideField: ->
    @overrideTarget.value = (if _isEmpty(@paymentAmountFieldTarget.value.trim()) then '0' else '1')
