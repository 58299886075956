import { Controller } from "stimulus"

export default class extends Controller
  @targets = [
    "willingFields"
    "selectLocationFields"
  ]

  @property 'willing',
    get: ->
      @data.get('willing') == 'true'

    set: (value) ->
      @data.set('willing', value)
      @render()

  @property 'willingType',
    get: ->
      @data.get('willingType')

    set: (value) ->
      @data.set('willingType', value)
      @render()

  initialize: ->
    @render()

  updateWilling: (event) ->
    @willing = event.target.value

  updateWillingType: (event) ->
    @willingType = event.target.value

  render: ->
    if @willing
      @willingFieldsTarget.disabled = false
      @willingFieldsTarget.hidden = false

    else
      @willingFieldsTarget.disabled = true
      @willingFieldsTarget.hidden = true

    if @willingType == 'willing_to_relocate_select'
      @selectLocationFieldsTarget.disabled = false
      @selectLocationFieldsTarget.hidden = false

    else
      @selectLocationFieldsTarget.disabled = true
      @selectLocationFieldsTarget.hidden = true