import FolderAccessController from "./folder_access_controller"

# Controls whether or not the password fields should be shown on the
# Edit Folder page.
export default class extends FolderAccessController
  @targets: ["toggleCheckbox", "passwordFields"]
  
  initialize: ->
    if @_canEditFields()
      @render()
      @element.classList.remove('hidden')
    else
      @element.remove()
  
  render: ->
    @passwordFieldsTarget.classList.toggle("hidden", !@toggleCheckboxTarget.checked)
  
  toggle: ->
    @render()
  
  _canEditFields: ->
    # new record
    return true if @data.get('id') is null
    return true if @data.get('id') is 'false'
    # not password protected/admin is logged in
    return true if @data.get('protected') is 'false'
    # password protected by the current user
    return true if @data.get('owner') is @data.get('userid')
    
    false