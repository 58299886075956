import { Controller } from "stimulus"

# !!!IMPORTANT NOTE ABOUT SAFARI'S HANDLING OF THIS CONTROLLER!!!
#
#   Safari does not seem to handle nested fieldsets well and in
#   certain circumstances field-toggle will not only not work but
#   will have unpredictable/problematic effects for surrounding JS.
#
#   This seems to be remedied by setting disabled="true" as an
#   attribute in the markup on the target fieldsets this controller
#   will point to. See example below.
#
#   I have no idea why.
#
# Example use:
#
#   .form-group{ data: { controller: 'field-toggle' } }
#     .checkbox
#       = f.label :some_bool do |l|
#         = f.check_box :some_bool, data: { field-toggle-target: 'option', field-toggle-target-fieldset: 'theTargetFieldset', action: 'field-toggle#update' }
#         = l.translation
#     %fieldset.fieldset--unstyled{ disabled: true, data: { field-toggle-target: 'theTargetFieldset' } }
#       ... The content you want to show/hide ...
#       ... Notice the disabled: true here. This is important for Safari! ...
#
export default class extends Controller
  @targets: ["option"]

  initialize: ->
    @update()

  update: ->
    _.each @optionTargets, (option) =>
      targetNamesString = option.dataset.fieldToggleTargetFieldset
      if targetNamesString
        targetNames = targetNamesString.split(' ')

        if option.checked
          for targetName in targetNames
            if targetFieldset = @targets.find(targetName)
              targetFieldset.disabled = false
              targetFieldset.hidden = false

        else
          for targetName in targetNames
            if targetFieldset = @targets.find(targetName)
              targetFieldset.disabled = true
              targetFieldset.hidden = true