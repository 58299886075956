import { Controller } from 'stimulus'

###
  BS3 dropdowns with remote="true"/data-remote="true" will not close when a
  click is received. Using this controller, like the following example, will
  close dropdowns when remote items are clicked.

  = actions_dropdown data: { controller: 'dropdown-remote-fix' } do
    %li
      = link_to ..., remote: true, data: { action: 'dropdown-remote-fix#close' }

  If Rails UJS is ever removed from the JS stack, this can likely be
  removed as well.
###
export default class extends Controller
  @targets: ['dropdown']

  close: (event) ->
    @dropdownTarget.classList.remove('open')