import FolderAccessController from "./folder_access_controller"

# Handles the "enter your password" form.
export default class extends FolderAccessController
  @targets: ["passwordFormGroup", "passwordField"]
  
  # When #error is called, highlights the field with error colors and resets
  # the password field.
  error: ->
    @passwordFormGroupTarget.classList.add('has-error')
    @passwordFieldTarget.value = null
  
  # When #success is called, the password has been validated - set the session.
  # Additional actions should occur outside of this scope (such as refreshing
  # the page or rendering content).
  success: ->
    @_updateLocalStorageItem()