import { Controller } from "stimulus"

# field_toggle_v2 doesn't seem to work reliably with checkboxes.  This field
# toggle class handles multiple checkboxes with multiple options that
# toggle content, but functions similarly to field_toggle_v2.
#
# @example (breaks added for legibility):
#
#   %div{ data: { controller: 'field-toggle-checkboxes' }}
#     %label
#       = check_box_tag ... { data: { action: "field-toggle-checkboxes#update",
#         target: "field-toggle-checkboxes.option",
#         "target-fieldset": "firstThing" }}
#       Show or hide first thing
#
#     %label
#       = check_box_tag ... { data: {
#         action: "field-toggle-checkboxes#update",
#         target: "field-toggle-checkboxes.option",
#         "target-fieldset": "secondThing" }}
#       Show or hide second thing
#
#     -# .hidden class added or removed when content is toggled
#     .hidden{ data: {
#       target: "field-toggle-checkboxes.targetFieldset
#         field-toggle-checkboxes.firstThing" }}
#       First thing content
#
#     .hidden{ data: {
#       target: "field-toggle-checkboxes.targetFieldset
#         field-toggle-checkboxes.secondThing" }}
#       Second thing content
#
export default class extends Controller
  @targets: ["option"]

  initialize: -> @update()

  update: ->
    for option in @optionTargets
      if target = @targets.find(option.dataset.targetFieldset)
        target.classList.toggle('hidden', !option.checked)