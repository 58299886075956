import { Controller } from 'stimulus'

export default class extends Controller
  @targets: [
    'headerName',
    'headerNameAtBreakpoint',
    'atBreakpointPreview'
  ]

  initialize: ->
    @update()

  update: ->
    @headerNameAtBreakpointTargets.forEach (target) =>
      previewTarget = @atBreakpointPreviewTargets.find (eachPreviewTarget) =>
        eachPreviewTarget.dataset.breakpoint is target.dataset.breakpoint

      if previewTarget
        previewTarget.innerText = target.value
