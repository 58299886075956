import { Controller } from 'stimulus'

export default class extends Controller
  @targets: []
  
  initialize: ->
    @element.action = '' # Immediately nullify the action
    @element.disabled = true
    @render()

  render: ->
    @element.querySelectorAll('input, select, textarea, button').forEach (target) ->
      unless target.classList.contains('static')
        target.disabled = true
        target.classList.add 'disabled'

    # Disable all buttons and cocoon removal fields
    @element.querySelectorAll('.btn, .add_fields_via_cocoon, .remove_fields_via_cocoon').forEach (target) ->
      unless target.classList.contains('btn-back')
        target.classList.add 'disabled'

    # Disable removing tokenfield entries by stripping them of their close
    # label and amending some margin to make them not look janky.
    @element.querySelectorAll('.tokenfield .token').forEach (target) ->
      labelElement = target.querySelector('.token-label')
      actionElement = target.querySelector('.close')
      actionElement.remove()
      labelElement.classList.add('bs5--me-2')