import { Controller } from "stimulus"
import _some from 'lodash/some'

export default class extends Controller
  @targets: [
    'buyerPaysFeeFieldset'
    'pricePointNestedFields'
  ]
  
  initialize: ->
    @update()

  # When a the price of an enduring activity's price point changes, we run this
  # function which will conditionally show and enable / hide and disable the 
  # fields asking the moderator who should pay the activity's service fees.
  update: ->
    prices = []

    for pricePointNestedFields in @pricePointNestedFieldsTargets
      unless pricePointNestedFields.dataset.markedForDestruction
        priceField = pricePointNestedFields.querySelector("input[data-registration-price-point-price-field]")
        prices.push priceField.value

    if _.some(prices, (n) -> parseFloat(n) && n > 0)
      @_showAndEnableBuyerPaysFeeFieldset()
    else
      @_hideAndDisableBuyerPaysFeeFieldset()

  _showAndEnableBuyerPaysFeeFieldset: ->
    if @hasBuyerPaysFeeFieldsetTarget
      @buyerPaysFeeFieldsetTarget.hidden = false
      @buyerPaysFeeFieldsetTarget.disabled = false

  _hideAndDisableBuyerPaysFeeFieldset: ->
    if @hasBuyerPaysFeeFieldsetTarget
      @buyerPaysFeeFieldsetTarget.hidden = true
      @buyerPaysFeeFieldsetTarget.disabled = true
