import { Controller } from "stimulus"
import savingEmittingSave from "./save_emitting_rails_ajax"

export default class extends Controller
  @values =
    addAuthorUrl: String

  @targets: ['select']

  addAuthor: (event) ->
    # Don't let this event bubble any higher.
    event.stopImmediatePropagation()

    # event.target will be our <select> field.
    if author_id = event.target.value
      # Construct FormDate for the author submission.
      data = new FormData()
      data.append 'author_id', author_id

      # Send the request to add the author.
      savingEmittingSave @element, 'POST', @addAuthorUrlValue, data, 'script',
        =>
          # Set the author selector back to blank.
          @selectTarget.value = ''