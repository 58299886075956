import { Controller } from 'stimulus'
import _find from 'lodash/find'

export default class extends Controller
  @targets: [
    'scope',
    'scopedContent',
    'defaultContent'
  ]

  initialize: ->
    @updateText()

  updateText: ->
    target = _find @scopeTargets, ((target) => target.checked)

    if target
      @scopedContentTargets.forEach (content) =>
        content.classList.toggle('hidden', content.dataset.scopedTo isnt target.value)
      @defaultContentTarget.classList.add('hidden')
    else
      @scopedContentTargets.forEach((content) => content.classList.add('hidden'))
      @defaultContentTarget.classList.remove('hidden')
