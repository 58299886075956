import { Controller } from 'stimulus'
# dependency jQuery
# dependency jQuery UI (effects)

export default class extends Controller
  @targets: [
    'moreTestimonials',
    'toggleButton',
    'expandedButtonTextTemplate',
    'collapsedButtonTextTemplate'
  ]

  @values =
    expanded: Boolean

  initialize: ->
    @expandedValue = false

  toggle: (event) ->
    @expandedValue = !@expandedValue

  expandedValueChanged: (isExpanded) ->
    if isExpanded
      @toggleButtonTarget.innerHTML = @expandedButtonTextTemplateTarget.innerHTML
      $(@moreTestimonialsTarget).slideDown('fast')
    else
      @toggleButtonTarget.innerHTML = @collapsedButtonTextTemplateTarget.innerHTML
      $(@moreTestimonialsTarget).slideUp('fast')

