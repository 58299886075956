import { Controller } from 'stimulus'

###*
 * Configures a field that uses Google Places to autocomplete a location.
 * 
 * When a location is found, the "google:place_changed" CustomEvent will be
 * fired, with the Google Places object as the event's detail.
 *
 * Example markup (new lines added for legibility):
 *
 *   = f.text_field :searchfield, data: {
 *     controller: 'google--places-autocomplete-field',
 *     action: 'keydown->google--places-autocomplete-field#preventEnter'
 *   }
###
export default class extends Controller
  initialize: ->
    autocomplete = new google.maps.places.Autocomplete @element,
      types: ["establishment", "geocode"]
      componentRestrictions:
        country: ['us', 'pr', 'vi', 'bs']

    google.maps.event.addListener autocomplete, 'place_changed', =>
      customEvent = new CustomEvent 'google:place_changed',
        detail: autocomplete.getPlace()
      @element.dispatchEvent customEvent
  
  preventEnter: (event) ->
    if event.keyCode == 13
      event.preventDefault()
      return false
