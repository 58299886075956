import { Controller } from "stimulus"

export default class extends Controller
  @targets: [
    # Wraps block, also @element
    "wrapper",
    
    # Dropdown
    "viewIcon", "gridCheckedIcon", "tableCheckedIcon",
    
    # Each view type
    "tableView", "gridView",
  ]
  
  @property 'sessionView',
    get: ->
      value = localStorage.getItem("hn-mediaFolderView")
      if ['grid', 'table'].includes(value) then value else 'grid'
        
    set: (value) ->
      if ['grid', 'table'].includes(value)
        localStorage.setItem("hn-mediaFolderView", value)
      else
        localStorage.setItem("hn-mediaFolderView", 'grid')

  initialize: ->
    readyPromise = new Promise (resolve, reject) =>
      if document.readyState == 'loading'
        document.addEventListener 'DOMContentLoaded', resolve
      else
        resolve()
    readyPromise.then => @render()
  
  render: ->
    @_setDropdown()
    @_setView()
  
  changeViewToGrid: (event) ->
    event.preventDefault() if event
    @sessionView = 'grid'
    @render()
    
  changeViewToTable: (event) ->
    event.preventDefault() if event
    @sessionView = 'table'
    @render()

  _setDropdown: ->
    @viewIconTarget.classList.toggle('fa-th-large', @sessionView is 'grid')
    @viewIconTarget.classList.toggle('fa-th-list', @sessionView is 'table')

    # Set the dropdown checked state
    @gridCheckedIconTarget.classList.toggle('fa-check', @sessionView is 'grid')
    @tableCheckedIconTarget.classList.toggle('fa-check', @sessionView is 'table')

  _setView: ->
    @wrapperTarget.classList.toggle('file-manager__grid-view', @sessionView is 'grid')
    @wrapperTarget.classList.toggle('file-manager__table-view', @sessionView is 'table')
