import { Controller } from "stimulus"

export default class extends Controller
  toggle: (event) ->
    if event.target.dataset.clickToggleHideTarget
      for element in document.querySelectorAll(event.target.dataset.clickToggleHideTarget)
        element.hidden = true

    if event.target.dataset.clickToggleShowTarget
      for element in document.querySelectorAll(event.target.dataset.clickToggleShowTarget)
        element.hidden = false