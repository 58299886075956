import { Controller } from "stimulus"

# Allows confirming a checkbox with a modal.
#
# @example
#   f.check_box :field, ..., data: {
#     controller: 'checkbox-confirm',
#     action: 'checkbox-confirm->confirm',
#     'checkbox-confirm-enabled': "#{f.object.field?}"
#     'checkbox-confirm-check-message':
#       "Message that displays when attempting to check this box.
#         Omit when not wanting to confirm moving from unchecked -> checked."
#     'checkbox-confirm-uncheck-message':
#       "Message that displays when attempting to uncheck this box.
#         Omit when not wanting to confirm moving from checked -> unchecked."
#
export default class extends Controller
  # Enabled by default, setting data-*-enabled to "false" will disable the
  # confirm action.  This is useful when you only want to show a confirmation
  # message when the record is already persisted, or only when changing values
  # in one direction (i.e. if the value in the database is false, no need to
  # alert the user of an action when checking, then unchecking, the box).
  @property 'enabled',
    get: -> @data.get('enabled') isnt 'false'
  
  # The message to display when checking the box.  Omit if no confirmation.
  @property 'checkMessage',
    get: -> @data.get('checkMessage')
  
  @property 'hasNotConfirmedCheck',
    get: -> @data.get('checkMessage') and @data.get('confirmedCheck') is 'false'
  
  # The message to display when unchecking the box.  Omit if no confirmation.
  @property 'uncheckMessage',
    get: -> @data.get('uncheckMessage')
  
  @property 'hasNotConfirmedUncheck',
    get: -> @data.get('uncheckMessage') and @data.get('confirmedUncheck') is 'false'
  
  initialize: ->
    # Set up some data variables.  No need to hammer a user with multiple
    # confirmation messages.  Once confirmed in any one direction, these
    # data attributes will change to "true", and @confirm() will be bypassed.
    @data.set('confirmedCheck', 'false')
    @data.set('confirmedUncheck', 'false')
    
    # If there's no message for checking the box, mark it as confirmed
    unless @checkMessage
      @data.set('confirmedCheck', 'true')
  
    # If there's no message for unchecking the box, mark it as confirmed
    unless @uncheckMessage
      @data.set('confirmedUncheck', 'true')
    
  confirm: (event) ->
    # If disabled, skip
    return unless @enabled
    
    # If the element has been checked and has *not* been confirmed (and has
    # a message), uncheck the box (restoring its state before the action) and
    # show the confirmation dialog.  And same thing in reverse: unchecked and
    # unconfirmed, check the box and show the dialog.
    #
    # event.preventDefault() does not function here.  The "change" (default)
    # callback will not revert the checked state.
    #
    # event.preventDefault() with the "click" callback will work, but the
    # value of @element.checked becomes unreliable (@element.checked is true
    # when the box is actually unchecked).
    if @element.checked
      if @hasNotConfirmedCheck
        @element.checked = false
        @showConfirmationDialog(@checkMessage, 'confirmedCheck')
    
    else
      if @hasNotConfirmedUncheck
        @element.checked = true
        @showConfirmationDialog(@uncheckMessage, 'confirmedUncheck')

  showConfirmationDialog: (message, dataAttributeToSetToTrueOnConfirm) ->
    confirmationContent = App.Templates["templates/modals/text_confirmation_modal"]
      confirmationText: message,
      isPlain: true
    
    remodalInstance = $(confirmationContent).remodal()
    
    remodalInstance.$modal.on 'confirmation', =>
      # Once confirmed, toggle the element and set the confirmed value to
      # prevent popping the modal dialog up multiple times if the value is
      # changed again (for whatever reason).
      @toggleElement()
      @data.set(dataAttributeToSetToTrueOnConfirm, 'true')
    
    remodalInstance.open()
  
  toggleElement: ->
    @element.checked = !@element.checked