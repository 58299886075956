import { Controller } from "stimulus"

# Works in tandem with .mobile-vertical-scroll.  See notes and examples in CSS.
export default class extends Controller
  @targets: ['hasContentLeftIndicator', 'hasContentRightIndicator']
  
  initialize: ->
    @render()
  
  # Recommended: this should be triggered on the target element with:
  #   * scroll->mobile-vertical-scroll#render (updates on scroll)
  #   * resize@window->mobile-vertical-scroll#render (updates on window resize)
  render: ->
    # Using an offset of 3 throughout to not show indicators unnecessarily.
    if @element.scrollWidth + 3 > @element.clientWidth
      if @currentScrollPosition() < @maxRightScroll() - 3
        @hasContentRightIndicatorTarget.classList.add('mobile-vertical-scroll__can-scroll--enabled')
      else
        @hasContentRightIndicatorTarget.classList.remove('mobile-vertical-scroll__can-scroll--enabled')
    
      if @currentScrollPosition() > 3
        @hasContentLeftIndicatorTarget.classList.add('mobile-vertical-scroll__can-scroll--enabled')
      else
        @hasContentLeftIndicatorTarget.classList.remove('mobile-vertical-scroll__can-scroll--enabled')
    
    else
      @hasContentLeftIndicatorTarget.classList.remove('mobile-vertical-scroll__can-scroll--enabled')
      @hasContentRightIndicatorTarget.classList.remove('mobile-vertical-scroll__can-scroll--enabled')
      
  currentScrollPosition: ->
    @element.scrollLeft
  
  maxRightScroll: ->
    @element.scrollWidth - @element.clientWidth