import FolderAccessController from "./folder_access_controller"
import _each from "lodash/each"

# Handles presentation of media folder index views.
export default class extends FolderAccessController
  @targets: ["blockedView", "presentationView", "protectedFolderIcon", "protectedFolderText"]
  
  initialize: ->
    # If the current folder can be accessed, remove any non-authenticated
    # (blocked) content areas. If the current folder has a password, remove the
    # media content from the page and show the blocked content areas.
    if @canAccessFolder()
      _each @presentationViewTargets, (target) ->
        target.classList.remove('hidden')
        true

      _each @blockedViewTargets, (target) ->
        target.remove()
        true
      
      # Since the folder is accessible, but there may be other folders visible
      # that are password protected, update the index view accordingly.
      
      # For folders that are unlocked, change the icon from "lock" to "unlock"
      _each @protectedFolderIconTargets, (target) =>
        if id = target.dataset.id
          if @_hasValidLocalStorageItemById(id)
            target.classList.remove('fa-lock')
            target.classList.add('fa-unlock')
        true
      
      # For folders that are locked, hide the count of items in the folder
      _each @protectedFolderTextTargets, (target) =>
        if id = target.dataset.id
          if @_hasValidLocalStorageItemById(id)
            target.classList.remove('hidden')
          else
            target.remove()
        true
    
    else
      _each @blockedViewTargets, (target) ->
        target.classList.remove('hidden')
        true
    
      _each @presentationViewTargets, (target) ->
        target.remove()
        true
      
