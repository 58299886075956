# Polyfills for IE11+ and Safari
import "@stimulus/polyfills"
import "core-js/fn/array/for-each"
import "core-js/fn/array/filter"
import "core-js/fn/array/includes"
import "core-js/fn/array/join"
import 'core-js/fn/string/ends-with'
import "core-js/fn/string/includes"
import "core-js/fn/string/pad-end"
import "core-js/fn/string/pad-start"
import "core-js/fn/string/repeat"
import "core-js/fn/string/starts-with"
import "core-js/fn/string/trim"
import "core-js/fn/symbol/index"
import * as ActiveStorage from "activestorage"
require "shared/polyfills/custom_event"
require "shared/polyfills/classlist"
require "shared/polyfills/element/remove"
require "shared/polyfills/template"
import 'whatwg-fetch' # fetch Polyfill from GitHub
ActiveStorage.start()

import "controllers"
import "styles"
import "@mux/mux-player"

# Global Clipboard behavior
Clipboard = require "clipboard"
document.addEventListener "DOMContentLoaded", ->
  clipboard = new Clipboard('.clipboardjs')
  clipboard.on 'success', (event) ->
    copyElement = event.trigger

    $(copyElement)
      .tooltip({ html: 'true', title: '<i class="fa fa-check-circle text-success"></i> Copied!', trigger: 'manual' })
      .tooltip('show')

    setTimeout(=>
      $(copyElement).tooltip('destroy')
    , 1500)