import { Controller } from "stimulus"
import Sortable from "sortablejs"
import savingEmittingSave from "./save_emitting_rails_ajax"

export default class extends Controller
  @values =
    addImageUrl: String
    reorderImagesUrl: String

  @targets: ['image', 'imagesContainer', 'noImagesMessage']

  initialize: ->
    @showHideNoImagesMessage()

    @sortable = Sortable.create @imagesContainerTarget,
      animation: 200
      handle: '.sort-handle'          
      sort: true
      group:
        pull: false
      swapThreshold: 0.85

  addImage: (event) ->
    blob = event.detail.blob

    data = new FormData()
    data.append 'attachment_signed_id', blob.signed_id
    
    savingEmittingSave @element, 'POST', @addImageUrlValue, data, 'script'

  handleSort: (event) ->
    # Stop this event from propagating, otherwise it'll be picked up
    # by higher level sortables.
    event.stopImmediatePropagation()

    # Build the form data to send back to the server.
    data = new FormData()
    for element, position in @imagesContainerTarget.querySelectorAll(':scope > [data-component-image-placement-id]')
      data.append "component_image_placements[#{element.dataset.componentImagePlacementId}]", position

    savingEmittingSave @element, 'PATCH', @reorderImagesUrlValue, data, 'script'

  showHideNoImagesMessage: (event) ->
    event.stopImmediatePropagation() if event
    
    if @imageTargets.length
      @noImagesMessageTarget.hidden = true
    else
      @noImagesMessageTarget.hidden = false