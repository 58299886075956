import { Controller } from "stimulus"
import _toString from "lodash/toString"

export default class extends Controller
  @targets: ["radiusPicker"]

  constructor: (data) ->
    Object.defineProperties @,
      'highlightOnNextRender':
        get: ->
          retval = _toString(@data.get('highlight')) == 'true'
          @data.set('highlight', 'false')
          retval
        set: (value) ->
          @data.set('highlight', value)
      
      'subscription':
        get: -> JSON.parse(@data.get('subscription'))
        set: (value) ->
          @data.set('subscription', JSON.stringify(value))
          @render()

    super data

  initialize: ->
    @render()

  render: ->
    @radiusPickerTarget.value = @subscription.radius if @hasRadiusPickerTarget
    App.Mixins.HighlightEffect.successHighlight($(@.element)) if @highlightOnNextRender

  updateRadius: (event) ->
    event.preventDefault() if event

    request = $.ajax
      url: @subscription.update_url
      method: 'PATCH'
      data:
        radius: @radiusPickerTarget.value

    request.then (response) =>
      @highlightOnNextRender = true
      @subscription = response