import { Controller } from 'stimulus'

# Replacement for App.Views.FileSelectDetailView.
#
# Used to show the file name of a selected file, or a "No file selected."
# message when absent.
#
# All targets are required, as well as calling the #render action from the
# file input field.
#
# Usage:
#
#   .form-group{ data: { controller: 'file-select-detail' }}
#     %button.btn.btn-primary.btn-file-select{ type: :button }
#       %span Select file
#       = f.file_field ..., class: 'btn-file-select-field', data: { file_select_detail_target: 'fileField', action: 'file-select-detail#render' }
#     .btn-file-select-detail.btn-file-select-detail-under{ data: { file_select_detail_target: 'details' }}
#
# Note that, during transition from Backbone to Stimulus, the element with
# .btn-file-select-detail is targeted by Backbone on page load. This is because
# Backbone works based off of classes, but this class has specific styling
# used for forms. Specifying data-file-select-detail-target="details" on an
# element with this class is enough to skip Backbone from loading its own view,
# or a different class can be used instead.
#
export default class extends Controller
  @targets: [
    'fileField',
    'details'
  ]

  initialize: ->
    @render()

  render: ->
    return unless @hasFileFieldTarget

    if @fileFieldTarget.files.length
      # With modern HTML and JS, there is no longer a need to split parts of
      # a string. <input>.files[0].name will get the file name itself.
      @detailsTarget.innerText = @fileFieldTarget.files[0].name

    else
      @detailsTarget.innerText = 'No file selected.'
