import { Controller } from "stimulus"
import _toString from 'lodash/toString'

# Example Markup:
#
#   .form-group.form-inline{ data: { controller: "registered-user-check", "registered-user-check": { check_url: user_existence_url(subdomain: :www) } } }
#     .input-group
#       %input.form-control{ type: :text, placeholder: "Email address", size: 30, data: { action: "keyup->registered-user-check#updateEmail" } }
#       .input-group-btn
#         %button.btn.btn-submit{ disabled: true, data: { target: "registered-user-check.submitBtn", action: "registered-user-check#doCheck" } } Continue
#
export default class extends Controller
  @targets: ['submitBtn']

  @property 'checkUrl',
    get: ->
      @data.get('checkUrl')

  @property 'email',
    get: ->
      @data.get('email')

    set: (value) ->
      @data.set('email', value)
      @render()

  initialize: ->
    @render()

  updateEmail: (event) ->
    if event.keyCode == 13 && @emailIsValid()
      @doCheck()

    else
      @email = event.target.value

  emailIsValid: ->
    !!_toString(@email).match(/^[A-Z0-9_\.&%\+\-']+@(?:[A-Z0-9\-]+\.)+(?:[A-Z]{2,13})$/gi)

  render: ->
    if @emailIsValid()
      @submitBtnTarget.disabled = false

    else
      @submitBtnTarget.disabled = true

  doCheck: ->
    originalButtonHTML = @submitBtnTarget.innerHTML

    @submitBtnTarget.innerHTML = 'Working...'
    @submitBtnTarget.disabled = true

    request = $.ajax
      method: 'GET'
      url: @checkUrl
      data:
        "email": @email

    request.then (response) =>
      @submitBtnTarget.innerHTML = originalButtonHTML
      @submitBtnTarget.disabled = false

      $(@element).trigger 'registeredUserCheckCompleted',
        email: @email
        exists: response != '0'