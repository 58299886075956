import { Controller } from 'stimulus'
import _find from 'lodash/find'

export default class extends Controller
  @targets: [
    'subchapterRow',
    'membershipItemCheckbox',
    'membershipAffiliationSection',
    'membershipSubchapterCheckbox'
  ]
  
  initialize: ->
    @render()
  
  render: ->
    if @hasMembershipItemCheckboxTarget
      hasCheckedItem = !!(_find @membershipItemCheckboxTargets, (target) => target.checked)
      if @hasSubchapterRowTarget
        @subchapterRowTarget.classList.toggle(@subchapterRowTarget.dataset.enableClass, hasCheckedItem)
      if @hasMembershipAffiliationSectionTarget
        @membershipAffiliationSectionTarget.classList.toggle('hidden', !hasCheckedItem)
      if @hasMembershipSubchapterCheckboxTarget
        @membershipSubchapterCheckboxTargets.forEach (target) =>
          target.disabled = !hasCheckedItem
