import { Controller } from "stimulus"

###
  Reusable delegate to BS3 tooltip, replacing the myriad of tooltip methods
  (rel="tooltip", data-toggle="tooltip", data-toggle="tooltip-delegate", etc.).

  This should be a simple drop-in replacement for all tooltip instances.
  Simply replace any of the definers for a tooltip (i.e. rel="tooltip") with
  data-controller="tooltip", and continue using the same title and data
  attributes native to BS3.
###
export default class extends Controller
  connect: ->
    $(@element).tooltip
      animation: @element.dataset.animation   || true
      container: @element.dataset.container   || false
      delay:
        hide:    @element.dataset.delayHide   || 0
        show:    @element.dataset.delayShow   || 0
      html:      @element.dataset.html        || false
      placement: @element.dataset.placement   || 'top'
      trigger:   @element.dataset.trigger     || 'hover focus'
      viewport:  @element.dataset.viewport    || { selector: 'body', padding: 0 }

    if @data.get('openImmediately')
      $(@element).tooltip('show')

  disconnect: ->
    $(@element).tooltip('destroy')
