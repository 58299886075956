import ModalLinkController from "./modal_link_controller"

# A ModalLink controller that assigns the subject as a query param on the
# preview URL before clicking submit
export default class extends ModalLinkController
  @targets: ["subjectField"]

  # NOTE: we don't need the methods defined in ModalLinkController#initialize
  # here; overwrite initialize.
  initialize: ->
    # Store the HREF
    @initialHref = @data.get('href')

    # Assign the HREF dynamically to the URL
    @setHref()

  setHref: ->
    newSubject = encodeURIComponent(@subjectFieldTarget.value || @subjectFieldTarget.placeholder)
    @data.set('href', "#{@initialHref}?subject=#{newSubject}")
