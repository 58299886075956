import { Controller } from "stimulus"
import _isNull from 'lodash/isNull'
import _unescape from 'lodash/unescape'

export default class extends Controller
  @targets: [
    'eventId', # ID field
    'eventDetail', # where the template will be rendered
    'displayTemplate', # <template> for the event information
    'eventEntryContainer', # container with the query field
    'query' # query field
  ]

  # set data-autocomplete-events-endpoint to the URL where autocomplete queries
  # can be performed, returning the proper JSON. Expects the event to have a
  # ?query=AUTOCOMPLETEQUERY parameter included in the URL, which will be
  # replaced upon search.
  @property 'endpoint',
    get: -> @data.get('endpoint')

  @property 'event',
    get: ->
      unless _isNull(@data.get('event'))
        JSON.parse(@data.get('event'))
      else
        null
    set: (stringifiedJSON) -> @data.set('event', stringifiedJSON)

  @property 'focus',
    get: -> @data.get('focus') is 'true'

  initialize: ->
    @render()

    source = new Bloodhound
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('id', 'name')
      queryTokenizer: Bloodhound.tokenizers.whitespace
      remote:
        url: @endpoint
        wildcard: 'AUTOCOMPLETEQUERY'
        rateLimitBy: 'debounce'
        rateLimitWait: 500

    $(@queryTarget).typeahead
      minLength: 3
    ,
      source: source
      display: 'name'
      limit: 15
      templates:
        pending: @pendingTemplate
        notFound: @notFoundTemplate
        suggestion: (object) => @template(object, false, false)

    if @focus
      @queryTargets.slice(-1)[0].focus()

    # typeahead creates a duplicate field. This kinda sucks, because now we
    # have to watch multiple fields for typeahead:select.
    @queryTargets.forEach (target) =>
      $(target).on 'typeahead:select', (event, option) =>
        @event = JSON.stringify(option)
        @render(true)

  render: (dispatchChangeEvent = false) ->
    @eventDetailTarget.classList.toggle('hidden', !@event)
    @eventEntryContainerTarget.classList.toggle('hidden', !!@event)
    @queryTargets.forEach (target) => target.value = ''

    if @event
      @eventIdTarget.value = @event.id
      @eventDetailTarget.innerHTML = @template(@event, true, true)
    else
      @eventIdTarget.value = null
      @eventDetailTarget.innerHTML = ''

    if dispatchChangeEvent
      @element.dispatchEvent(new CustomEvent('autocompleteChanged', detail: @event))

  reset: ->
    @event = null
    @render(true)

  template: (object, showLinks = false, showRemoveButton = false) ->
    _.template(_unescape(@displayTemplateTarget.innerHTML),
      variable: 'data'
    )({
      id: object.id,
      name: object.name,
      startsAtMonth: object.starts_at_month,
      startsAtDay: object.starts_at_day,
      startsAtYear: object.starts_at_year,
      ended: object.ended,
      endedDistance: object.ended_distance,
      showLinks: showLinks,
      showRemoveButton: showRemoveButton
    })

  pendingTemplate: (query) =>
    """
      <div class="bs5--mx-4 text-muted">
        <i class="fa fa-spinner fa-spin"></i>
        Searching for <strong>#{ query.query }</strong>...
      </div>
    """

  notFoundTemplate: (query) =>
    """
      <div class="bs5--mx-4 text-danger">
        <i class="fa fa-exclamation-triangle"></i>
        No results found for <strong>#{ query.query }</strong>.
      </div>
    """
