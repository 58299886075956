import { Controller } from "stimulus"

export default class extends Controller
  @values =
    componentPreviewId: String

  formChanged: (event) ->
    Rails.fire(@element, 'submit')

  hover: (event) ->
    document
      .getElementById(@componentPreviewIdValue)
      .classList
      .add('newsletter-component--hover')

  leave: (event) ->
    document
      .getElementById(@componentPreviewIdValue)
      .classList
      .remove('newsletter-component--hover')