import { Controller } from "stimulus"

###
  Reusable delegate to BS3 popover, replacing data-toggle="popover-fix" in
  /app/assets/javascripts/glue/views.coffee
  
  In certain cases (particularly when HTML is inserted from frameworkless JS
  using innerHTML=), data-toggle="popover-fix" does not re-initialize.
  Instead of relying on older callbacks or sending CustomEvents just to make
  that function, this controller acts as a drop-in replacement - just replace:
  
    data-toggle="popover-fix"
  
  with:
  
    data-controller="popover"
  
  Should we wish to update or change our popover library in the future,
  this file also serves as a proxy that can be modified with the existing BS
  data attributes.
###
export default class extends Controller
  initialize: ->
    $(@element).popover
      animation: @element.dataset.animation || false
      container: @element.dataset.container || false
      html:      @element.dataset.html      || false
      placement: @element.dataset.placement || 'auto'
      trigger:   @element.dataset.trigger   || 'click'
      viewport:  @element.dataset.viewport  || { selector: 'body', padding: 0 }
      delay:
        hide:    @element.dataset.delayHide || 0
        show:    @element.dataset.delayShow || 0
      content: ->
        if $(this).data('content')
          $(this).data('content')
        else if $(this).data('popover-ajax')
          $.ajax
            url: $(this).data("popover-ajax")
            dataType: 'html'
            success: (response) =>
              $(this).attr('data-content', response)
              if $(this).data('bs.popover').$tip.is(':visible')
                $(this).data('bs.popover').$tip.find('.popover-content').html(response)
                $(this).popover('show')
        "<i class='fa fa-spinner fa-spin'></i> Loading..."
