import { Controller } from "stimulus"

# Sets required labels on location and group type fields when using the simple
# group form, used for creating a new group or editing a prospect group.
export default class extends Controller
  @targets: ["requiredLabel"]
  
  initialize: ->
    if @data.get('initialState') is 'prospect'
      @setProspect()
    else
      @setStaging()
  
  setProspect: ->
    @requiredLabelTargets.forEach (target) =>
      target.classList.remove('required')
  
  setStaging: ->
    @requiredLabelTargets.forEach (target) =>
      target.classList.add('required')