import { Controller } from 'stimulus'

export default class extends Controller
  @targets: ['codeField', 'generateLink']

  @property 'url',
    get: -> @data.get('url')

  generate: ->
    @_working(true)

    fetch(@url,
      method: 'GET'
      mode: 'same-origin'
      credentials: 'same-origin'
      headers:
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
        'Content-Type': 'text/plain'
        'Accept': 'text/plain'
    ).then((response) =>
      response.text()
    ).then((text) =>
      @codeFieldTarget.value = text
      @_working(false)
    )

  _working: (isWorking = true) ->
    @codeFieldTarget.classList.toggle('disabled', isWorking)
    @generateLinkTarget.classList.toggle('disabled', isWorking)
    @generateLinkTarget.classList.toggle('not-allowed', isWorking)

    if (icon = @generateLinkTarget.querySelector('.fa'))
      icon.classList.toggle('fa-spin', isWorking)

    if isWorking
      @codeFieldTarget.setAttribute('disabled', true)
      @generateLinkTarget.setAttribute('disabled', true)
    else
      @codeFieldTarget.removeAttribute('disabled')
      @generateLinkTarget.removeAttribute('disabled')
