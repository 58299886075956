export default rails_ajax_with_saving_emission = (eventTarget, method, url, data, dataType, callback) -> 
  eventTarget.dispatchEvent(new CustomEvent('builderSaving', { bubbles: true }))
  
  Rails.ajax
    type: method
    url: url
    data: data
    dataType: dataType
    complete: ->
      eventTarget.dispatchEvent(new CustomEvent('builderSaveComplete', { bubbles: true }))
      callback() if callback