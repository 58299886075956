import { Controller } from "stimulus"

###
Replacement for Bootstrap's button group with data-toggle="buttons" that submits a
request immediately to a given endpoint to toggle a boolean value.  The only jQuery in
use is for the AJAX request.  Since some browsers still do not support fetch (see
https://caniuse.com/#feat=fetch) and since we are still using jQuery UJS (which is not
compatible with Rails UJS), this is the only option available for dynamic AJAX requests.

Expected HAML markup:

  -# The outer wrapper, which should be given a value of true or false (as a string) as
  -# a @data.get('value') attribute, and the endpoint as a @data.get('url') attribute.
  %div{ data: { controller: 'boolean-button-toggle-and-submit', boolean_button_toggle_and_submit_value: (a_true_or_false_value).to_s }}
    .btn-group

      -# The option that results in a "true" value.  Should have disabled as a class by
      -# default, other button classes can be defined as needed.
      .btn.disabled{ data: { target: 'boolean-button-toggle-and-submit.trueOption boolean-button-toggle-and-submit.option', action: 'click->boolean-button-toggle-and-submit#submit' }}
        The text for the truthy option

      -# The option that results in a "false" value.  Should have disabled as a class by
      -# default, other button classes can be defined as needed.
      .btn.disabled{ data: { target: 'boolean-button-toggle-and-submit.falseOption boolean-button-toggle-and-submit.option', action: 'click->boolean-button-toggle-and-submit#submit' }}
        The text for the falsy option

    -# The success message displayed once submitted and saved.  This does not have to be
    -# a %div; it can be any tag, but it is recommended that any styling classes be
    -# placed inside the target instead of on it.  The .hide-initially class is required.
    .hide-initially{ data: { target: 'boolean-button-toggle-and-submit.success' }}
      %span.text-success= icon 'check', 'Saved!'

Actions:

  The only actions that occur are from clicking the buttons.  When clicking a button that
  is already selected, the action will be stopped and no request will be made in order to
  prevent excessive requests.

  When clicking an unselected button, a jQuery AJAX POST request will occur to the URL
  endpoint.  For some reason, Rails sometimes interprets this as a PATCH request; be sure
  that the route given responds to both POST and PATCH requests.

  The request sent will contain the following body:

    { new_value: "true" } or { new_value: "false" }

Handling the request:

  While you could simply use:

    my_model.toggle!(:field_name)
  
  in the controller action, it is more accurate to set the actual value using:

    #update_attribute(:field_name, params[:new_value])

  The action handling the request should provide a simple JSON response:
  
    { result: true } or { result: false }
  
  The value of :result should be what the value of the field is now set to.  This ensures
  that the front end, when updated, is changed to the actual value of the stored data,
  instead of a simple toggle.  If something goes wrong on the back end, we're not showing
  the user a false representation of their data.

###
export default class extends Controller
  @targets: ["trueOption", "falseOption", "option", "success"]

  initialize: ->
    @render()

  render: ->
    @trueOptionTarget.classList.toggle('active', @data.get('value') is "true")
    @falseOptionTarget.classList.toggle('active', @data.get('value') is "false")
    for optionTarget in @optionTargets
      optionTarget.classList.toggle('disabled', false)

  submit: (event) ->
    # Do not make a request if the value clicked is currently set to active
    return if event.target.classList.contains('active')

    # Since the option selected will have either trueOption or falseOption as a
    # data-target, this can be used to determine what the new value should be.
    newValue = /trueOption/.test(event.target.dataset.target)

    # Disable everything before the request
    for optionTarget in @optionTargets
      optionTarget.classList.toggle('disabled', true)
    @successTarget.classList.toggle('fade-in-and-hide-1s', false)
    @successTarget.classList.toggle('hide-initially', true)

    # Make the request
    request = $.post @data.get('url'),
      new_value: newValue

    request.done (response) =>
      # Set the value to the response's result value
      @data.set('value', response.result.toString())

      # Queue the success message animation
      @successTarget.classList.toggle('hide-initially', false)
      @successTarget.classList.toggle('fade-in-and-hide-1s', true)

      # Rerender with the new value
      @render()