import { Controller } from "stimulus"

export default class extends Controller
  @property 'hint',
    get: ->
      (@data.get('hint') || 'true') == 'true'

  initialize: ->
    new App.Views.Autocompleters.BaseView
      el: @element
      bloodhoundRemoteUrl: '/locations/city/list?query=%QUERY'
      typeaheadName: 'locations'
      typeaheadHint: @hint