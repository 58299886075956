import { Controller } from "stimulus"
import tinymce from 'tinymce/tinymce'
import { swatchColors } from "shared/swatch_colors"
import savingEmittingSave from "./save_emitting_rails_ajax"

export default class extends Controller
  @values =
    updateUrl: String
    attribute: String
  
  editorOptions: ->
    block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4'
    body_class: 'wysiwyg-input'
    branding: false
    browser_spellcheck: true
    color_cols: 5
    content_css: document.querySelector('head meta[name="tinymce-content-css"]').content
    content_style: ""
    contextmenu: true
    convert_urls: false
    fixed_toolbar_container: '#global-tinymce-toolbar'
    inline: true
    menubar: false
    paste_block_drop: true
    paste_data_images: false
    placeholder: 'Insert content here...'
    plugins: ' paste link lists hr '
    relative_urls: false
    skin: false
    setup: @editorSetup
    target: @element
    toolbar: ' forecolor bold italic underline hr | bullist numlist | alignleft aligncenter alignright alignjustify | link unlink | formatselect '
    valid_elements: "*[*]"

  editorSetup: (editor) ->
    editor.on 'init', (event) ->
      # This is disabled because it was moving the focus
      # down the page as the TinyMCE's did their init. Leaving the block
      # here in case we need other init.
      
      # editor.focus()
      
      true

  initialize: ->
    tinymce.init(@editorOptions())

  # Saves the content in the editor. This will get be called any time the enabled
  # editor inside this controller loses focus (blurs).
  save: (event) ->
    editorInstance = tinymce.get(@element.id)
    content = editorInstance.getContent()

    data = new FormData()
    data.append @attributeValue, content

    savingEmittingSave @element, 'PATCH', @updateUrlValue, data, 'json'