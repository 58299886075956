import { Controller } from "stimulus"

export default class extends Controller
  minHeight: 100
  minWidth: 100
  
  initialize: ->
    @$cropperImage = $(@element).find('#cropper-image')

    @_configureCropper()
  
  _configureCropper: ->
    @$cropperImage.cropper
      autoCropArea: 1
      dragMode: 'crop'
      guides: false
      movable: true
      responsive: true
      restore: true
      rotatable: false
      scalable: true
      toggleDragModeOnDblclick: false
      viewMode: 1 # prevents crop selection exiting the image canvas
      zoomable: true
      zoomOnTouch: true
      zoomOnWheel: false
      built: (event) =>
        # Assign the hidden field values after the cropper is done being built
        @_setHiddenFields()
      
      cropend: (event) =>
        # Once a crop area has been made and the user is done interacting, get an instance of cropper's current
        # cropping selection data
        cropSelection = $(event.target).cropper('getData')

        # If the height or width of the crop selection is too small, size it back up to the minimum.
        if cropSelection.height < @.minHeight or cropSelection.width < @.minWidth
          cropSelection.height = @.minHeight
          cropSelection.width = @.minWidth
          $(event.target).cropper 'setData', cropSelection

        # Sometimes a negative value is returned for x and y coordinates, which causes a Paperclip cropping error.
        # Make sure this is at least 0 to not break Paperclip.
        if cropSelection.x < 0
          cropSelection.x = 0
        if cropSelection.y < 0
          cropSelection.y = 0

        @_setHiddenFields()
  
  _setHiddenFields: ->
    # Get an instance of cropper's current cropping selection data
    cropSelection = @$cropperImage.cropper('getData')

    # Assign the hidden field values
    $(@element).find('#media-crop-x').val(cropSelection.x)
    $(@element).find('#media-crop-y').val(cropSelection.y)
    $(@element).find('#media-crop-w').val(cropSelection.width)
    $(@element).find('#media-crop-h').val(cropSelection.height)