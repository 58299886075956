import { Controller } from "stimulus"

export default class extends Controller
  @targets = ['billingTerm', 'billingTermPriceFields', 'billingTermDateFields']

  @property 'billingTerm',
    get: ->
      @billingTermTarget.value

  initialize: ->
    @update()

  update: ->
    switch @billingTerm
      when ''
        @billingTermPriceFieldsTarget.hidden = true
        @billingTermPriceFieldsTarget.disabled = true

        @billingTermDateFieldsTarget.hidden = true
        @billingTermDateFieldsTarget.disabled = true

      when 'monthly'
        @billingTermPriceFieldsTarget.hidden = false
        @billingTermPriceFieldsTarget.disabled = false

        @billingTermDateFieldsTarget.hidden = true
        @billingTermDateFieldsTarget.disabled = true

      else
        @billingTermPriceFieldsTarget.hidden = false
        @billingTermPriceFieldsTarget.disabled = false

        @billingTermDateFieldsTarget.hidden = false
        @billingTermDateFieldsTarget.disabled = false

    true