import { Controller } from "stimulus"

export default class extends Controller
  @property 'url',
    get: -> @data.get('url')

  click: (event) ->
    request = $.ajax
      url: @url
      method: 'POST'
