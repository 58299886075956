import ModalLinkController from "./modal_link_controller"

export default class extends ModalLinkController
  @targets: ["aacnMemberIdField"]

  _additionalCallbacks: ->
    @modalInstance.$wrapper.one 'click', 'button[data-remodal-confirm-with-data]', (event) =>
      value = event.currentTarget.dataset.remodalConfirmWithData
      @aacnMemberIdFieldTarget.value = value
      App.Mixins.HighlightEffect.successHighlight($(@aacnMemberIdFieldTarget))
      @modalInstance.close()