import { Controller } from "stimulus"

export default class extends Controller
  @targets: ['conditionPropertyField', 'conditionPropertyOptionsFieldset']

  @property 'currentConditionProperty',
    get: ->
      @conditionPropertyFieldTarget.value

  initialize: ->
    @render()

  conditionPropertyFieldChanged: ->
    @render()

  render: ->
    for fieldset in @conditionPropertyOptionsFieldsetTargets
      fieldset.hidden = true
      fieldset.disabled = true

      if fieldset.dataset.forConditionPropertyFields == @currentConditionProperty
        fieldset.hidden = false
        fieldset.disabled = false