import { Controller } from "stimulus"
import savingEmittingSave from "./save_emitting_rails_ajax"

export default class extends Controller
  @values = 
    updateUrl: String

  handleFileUploaded: (event) ->
    if event.detail && (blob = event.detail.blob)
      data = new FormData()
      data.append "newsletter_component_image[image_1]", blob.signed_id
      
      savingEmittingSave @element, 'PATCH', @updateUrlValue, data, 'script'