import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["allowYearsExperienceEnforceability", "brightmoveConfigFieldset"]

  @property 'webhookPostService',
    get: ->
      @data.get('webhookPostService')

    set: (value) ->
      @data.set('webhookPostService', value)
      @update()

  initialize: ->
    @update()

  enforceDefaultChanged: (event) ->
    if event.target.checked
      $(@allowYearsExperienceEnforceabilityTarget).prop(checked: true)

  webhookPostServiceChanged: (event) ->
    @webhookPostService = event.target.value

  update: ->
    if @webhookPostService == 'ExternalJobFeed::Brightmove'
      @brightmoveConfigFieldsetTarget.hidden = false
      @brightmoveConfigFieldsetTarget.disabled = false

    else
      @brightmoveConfigFieldsetTarget.hidden = true
      @brightmoveConfigFieldsetTarget.disabled = true