import { Controller } from 'stimulus'

###
Utility JS that pairs with any set of JavaScript callbacks (though optimized
for Rails UJS) to show a loading indicator during any set of simple
lifecycle actions (such as starting and ending a remote request).

This utility allows for additional UI sprinkles without getting in the way
of the request pattern (remote with JS response, remote with HTML response,
custom fetch code, etc.).

This pairs well with highlight_controller to give additional feedback when
content is updated.

@example with Rails UJS (markup modified for legibility):

  %div{ data: {
    controller: 'loading-indicator',
    # Optional: see @hideContentDuringLifecycle
    loading_indicator_hide_content_during_request: 'true'
  }}
    = link_to 'Remote Link', '#', remote: true
    %span.hidden{ data: { loading_indicator_target: 'loadingIndicator' }}
      = icon 'spinner fa-spin', 'Loading...'

    # Optional: see @hideContentDuringLifecycle
    .content{ data: { loading_indicator_target: 'content' }}
      My content

###
export default class extends Controller
  @targets: [
    # @required loadingIndicator - references the container that contains
    # the loading indicator to be shown during the lifecycle events.
    'loadingIndicator',

    # @optional content - references content that may be hidden during the
    # lifecycle events. If missing, even if @hideContentDuringLifecycle is
    # "true", nothing will happen.
    'content'
  ]

  # When data-@identifier-hide-content-during-lifecycle="true", will try to
  # hide @contentTarget during the lifecycle event. This is useful when, for
  # example, changing a SELECT should show some additional content, but whatever
  # content was there previously is no longer valid.
  @property 'hideContentDuringLifecycle',
    get: -> @data.get('hideContentDuringLifecycle') is 'true'

  initialize: ->
    @loadingIndicatorTarget.classList.add('hidden')

  start: (event) ->
    @loadingIndicatorTarget.classList.remove('hidden')

    if @hideContentDuringLifecycle and @hasContentTarget
      @contentTarget.classList.add('hidden')

  complete: ->
    @loadingIndicatorTarget.classList.add('hidden')

    if @hideContentDuringLifecycle and @hasContentTarget
      @contentTarget.classList.remove('hidden')
