import { Controller } from "stimulus"

export default class extends Controller
  @targets: [
    'imageTag',
    'attachHiddenField',
    'tempAttrUrlHiddenField',
    'purgeHiddenField',
  ]

  @values =
    railsBlobPathPattern: String
    
  handleUpload: (event) ->
    imageUrl = @railsBlobPathPatternValue
    imageUrl = imageUrl.replace('SIGNED_ID', event.detail.blob.signed_id)
    imageUrl = imageUrl.replace('FILENAME', event.detail.blob.filename)

    @imageTagTarget.src = imageUrl
    @tempAttrUrlHiddenFieldTarget.value = imageUrl
    @attachHiddenFieldTarget.value = event.detail.blob.signed_id
    @purgeHiddenFieldTarget.value = null

    @element.classList.add('file-drop--with-attachment')

  handleRemove: (event) ->
    event.preventDefault()

    @purgeHiddenFieldTarget.value = '1'
    @attachHiddenFieldTarget.value = null
    @tempAttrUrlHiddenFieldTarget.value = null

    @element.classList.remove('file-drop--with-attachment')