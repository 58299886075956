import { Controller } from "stimulus"

# @example
#
export default class extends Controller
  @targets: [
    'searchTypeState',
      'stateSelect',
    
    'searchTypeGeopoint',
      'cityStateField',
      'distanceField',
    
    'locationText'
  ]
  
  initialize: ->
    @noMatchText = @locationTextTarget.innerHTML
    
    @update()
  
  update: ->
    if @_searchTypeStateText()
      @locationTextTarget.innerHTML = @_searchTypeStateText()
    
    else if @_searchTypeGeopointText()
      @locationTextTarget.innerHTML = @_searchTypeGeopointText()
    
    else
      @locationTextTarget.innerHTML = @noMatchText
  
  _searchTypeStateText: ->
    if @searchTypeStateTarget.checked and
    @stateSelectTarget.value
      "in <strong>#{@stateSelectTarget.value}</strong>"
  
  _searchTypeGeopointText: ->
    csfTarget = @cityStateFieldTargets.find (targets) =>
      # Twitter Autocomplete creates a second field with the same attributes
      # as the first.  That means, on initialization, there *may* only be one
      # field, but once Autocomplete loads, there may be two.  Find the one
      # with Autocomplete, if present.
      targets.classList.contains('tt-input')
    
    cityStateFieldTarget = csfTarget or @cityStateFieldTarget
    
    if @searchTypeGeopointTarget.checked and
    @distanceFieldTarget.value and
    cityStateFieldTarget.value
      """
        <strong>#{@distanceFieldTarget.value} miles</strong>
        from
        <strong>#{cityStateFieldTarget.value}</strong>
      """