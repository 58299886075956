import { Controller } from "stimulus"

###
  Populates a @templateContainer with a @template based on a chosen @option
  
  As of now, this only supports checkboxes, but can be easily amended to
  support other types of form inputs.
  
  @option - A field that is used to toggle which @template is displayed in the
            DOM.  Provide data-template-priority to target a specific @template
            with a matching data-priority value.  No value will assume a
            priority of 1.  Highest priority value wins.
  
  @template - Each TEMPLATE tag (or, really, any tag) that contains content
              to be inserted into a @templateContainer on initialization or
              when #update is called.  Provide a data-priority value that
              matches a @template data-template-priority value.  Highest
              priority wins.  If no data-priority value is set, this will be
              the template used when no options are checked.  If there is
              no corresponding @option with a data-template-priority matching
              a @template data-priority value, nothing will be displayed when
              that option is chosen.
  
  @templateContainer - An empty container that each @template will be inserted
                       into on initialization or when #update is called.
  
  @example
    %div{ data: { controller: 'template-switcher' }}
      %label
        %input{ type: :checkbox, data: { target: 'template-switcher.option' }}
        First option
      
      %label
        %input{ type: :checkbox, data: { target: 'template-switcher.option', template_priority: 2 }}
        Second option
    
      %div{ data: { target: 'template-switcher.templateContainer' }}
        -# All templates get inserted here.
      
      %template{ data: { target: 'template-switcher.template' }}
        This content will be inserted into @templateContainer when neither
        checkbox is selected.  Omit this template to show nothing in the
        @templateContainer when neither checkbox is selected.
      
      %template{ data: { target: 'template-switcher.template', priority: 1 }}
        This content will be inserted into @templateContainer when the checkbox
        with the label "First option" is checked, but not when the checkbox
        with the label "Second option" is checked.  Omit this template to show
        nothing when this case occurs.
      
      %template{ data: { target: 'template-switcher.template', priority: 2 }}
        This content will be inserted into @templateContainer when the checkbox
        with the label "Second option" is checked.  Because of the higher
        priority, it doesn't matter if the "First option" checkbox is checked
        or not.  Omit this template to show nothing when this case occurs.
  
###
export default class extends Controller
  @targets: ['option', 'template', 'templateContainer']
  
  initialize: ->
    @update()
  
  update: ->
    filteredOptions = @optionTargets.filter (target) =>
      if target.type.match /checkbox/
        isChecked = target.checked
        # This is equivalent to $(target).is(':visible')
        isVisible = !!(target.offsetWidth || target.offsetHeight || target.getClientRects().length)
        
        isChecked and isVisible
      
      else
        false
    
    priority = '0'
    
    if filteredOptions.length
      priorities = filteredOptions.map (eachOption) =>
        eachOption.dataset.templatePriority
      
      priorities = priorities.filter (eachPriority) =>
        eachPriority isnt undefined and
          !Number.isNaN(eachPriority)
      
      if priorities.length
        priority = Math.max.apply(Math, priorities)
      
      else
        priority = '1'
    
    templateToDisplay = @templateTargets.find (target) =>
      if priority is '0'
        target.dataset.priority is undefined or target.dataset.priority is String(priority)
      else
        target.dataset.priority is String(priority)
        
    if templateToDisplay
      @templateContainerTarget.innerHTML = templateToDisplay.innerHTML
    else
      # console.log "couldn't find template with priority", priority
      @templateContainerTarget.innerHTML = ''
