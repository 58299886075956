import { Controller } from 'stimulus'
import Sortable from "sortablejs"
# @dependency jQuery highlight effect

export default class extends Controller
  @targets: [
    'sectionOrder',
    'members',
      'member',
        'memberOrder',
  ]
  
  initialize: ->
    @initializeSortables()

  initializeSortables: ->
    new Sortable @element,
      animation: 50
      ghostClass: 'sortable-placeholder-highlight-variable'
      group: 'committee-sections'
      handle: '[role="sort_handle"]'
      swapThreshold: 0.25
      onEnd: (event) =>
        # Only sort the CommitteeSections
        @sectionOrderTargets.forEach((target, index) -> target.value = index)

        # Set all members in their sections
        @_setMemberSections()

        # Submit and highlight the changed CommitteeSection
        @_submit()
        @_highlightElement(event.item)

    @membersTargets.forEach (target) =>
      new Sortable target,
        animation: 50
        ghostClass: 'sortable-placeholder-highlight-variable'
        group: 'committee-members'
        handle: '[role="sort_handle"]'
        swapThreshold: 0.25
        onEnd: (event) =>
          # Only sort the CommitteeMembers
          @memberOrderTargets.forEach((target, index) -> target.value = index)

          # Set all members in their sections
          @_setMemberSections()
          @_setNoMemberVisibilities()

          # Submit and highlight the changed CommitteeMember
          @_submit()
          @_highlightElement(event.item)

  _setMemberSections: ->
    @memberTargets.forEach (target, index) ->
      section = target.closest("[data-section-id]")
      hiddenSectionIdField = target.querySelector("[data-committee-section-id]")
      hiddenSectionIdField.value = section.dataset.sectionId

  _setNoMemberVisibilities: ->
    @membersTargets.forEach (target, index) ->
      if target.querySelectorAll("[data-committee-target='member']").length
        target.nextElementSibling.hidden = true
      else
        target.nextElementSibling.hidden = false

  _submit: ->
    Rails.fire @element, 'submit'

  _highlightElement: (element) ->
    $(element).effect 'highlight',
      easing: 'easeInExpo'
    , 1000
