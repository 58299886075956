import { Controller } from "stimulus"
import { DirectUpload } from "activestorage"
import _each from 'lodash/each'
import _map from 'lodash/map'
import { allSynchronously } from "../shared/all_synchronously"

export default class extends Controller
  @targets: ["droparea", "fileButton", "fileField", "statusLabel"]

  @values =
    # Set to true to have errors of file count / size / type show in
    # alerts instead of the status label.
    alertErrors: Boolean
    # If set, will set the given hover class on @element when files
    # are held over the drop zone.
    hoverClass: String
    maxNumFiles: Number
    maxFileSize: Number
    maxFileSizeLabel: String
    permittedTypes: Array
    permittedTypesLabel: String
    statusLabelElementId: String
    
  initialize: ->
    @effectiveStatusLabel = 
      (@hasStatusLabelTarget && @statusLabelTarget) || 
      (@statusLabelElementIdValue && document.getElementById(@statusLabelElementIdValue))
    
    if @effectiveStatusLabel
      @effectiveStatusLabel.hidden = true

    if @hasDropareaTarget
      # Reset drop and dragover for the document.  This needs to be done or the
      # dropZone will occur over the entire page, which isn't desired,
      # especially when there may be multiple file fields.
      document.addEventListener 'drop', (event) -> event.preventDefault()
      document.addEventListener 'dragover', (event) -> event.preventDefault()
        
      @dropareaTarget.addEventListener 'dragover', (event) => @dragover(event)
      @dropareaTarget.addEventListener 'dragleave', => @dragleave()
      @dropareaTarget.addEventListener 'drop', (event) => @drop(event)
  
  ### drag functions ###
  ###
    When using sortable in addition to this controller, sorting items will
    inadvertently trigger the drop area for file uploads.
  ###
  dragover: (event) ->
    return if @dropareaTarget.disabled
    if @_dragoverContainsFiles(event)
      @dropareaTarget.classList.add(@hoverClassValue || 'bg-info')
  
  dragleave: ->
    return if @dropareaTarget.disabled
    @dropareaTarget.classList.remove(@hoverClassValue || 'bg-info')
  
  # On drop, send files to direct upload
  drop: (event) ->
    event.preventDefault()
    return if @dropareaTarget.disabled
    @dragleave()
    @uploadFiles(Array.from(event.dataTransfer.files))

  _dragoverContainsFiles: (event) ->
    dragoverContainsAFile = false

    if event.dataTransfer and event.dataTransfer.types
      _each event.dataTransfer.types, (type) =>
        dragoverContainsAFile = true if type is "Files"

    dragoverContainsAFile

  ### direct file field interaction ###
  # On change (directly using the file field), send files to direct upload and
  # clear the file field.
  fileFieldChanged: (event) ->
    @uploadFiles(Array.from(event.currentTarget.files))
    @_clearFileField(event.currentTarget)

  uploadFiles: (files) ->
    if @maxNumFilesValue
      if files.length > @maxNumFilesValue
        errorMessage = "Please choose a maximum of #{@maxNumFilesValue} file(s)."
        if @alertErrorsValue
          alert(errorMessage)
        else
          @updateStatus("
            <span class='text-danger small'>
              <i class='fa fa-fw fa-exclamation-triangle'></i>
              #{errorMessage}
            </span>
          ")
          if @effectiveStatusLabel
            @effectiveStatusLabel.hidden = false
        return

    if @permittedTypesValue.length
      for file in files
        if file.type not in @permittedTypesValue
          errorMessage = "The file \"#{file.name}\" is not a valid type (Valid types: #{@permittedTypesLabelValue})."
          if @alertErrorsValue
            alert(errorMessage)
          else
            @updateStatus("
              <span class='text-danger small'>
                <i class='fa fa-fw fa-exclamation-triangle'></i>
                #{errorMessage}
              </span>
            ")
            if @effectiveStatusLabel
              @effectiveStatusLabel.hidden = false
          return

    if @maxFileSizeValue
      for file in files
        if file.size >= @maxFileSizeValue
          errorMessage = "The file \"#{file.name}\" is larger than the maximum allowed file size (#{@maxFileSizeLabelValue})."
          if @alertErrorsValue
            alert(errorMessage)
          else
            @updateStatus("
              <span class='text-danger small'>
                <i class='fa fa-fw fa-exclamation-triangle'></i>
                #{errorMessage}
              </span>
            ")
            if @effectiveStatusLabel
              @effectiveStatusLabel.hidden = false
          return

    @numFilesToUpload = files.length
    @currentFileUploading = 0

    # Map each file into an anon function that returns a Promise that
    # will handle the uploading of the file.
    uploadPromises = _map(files, (file) =>
      () => 
        new Promise((resolve) =>
          @currentFileUploading += 1
          @currentFileUploadingName = file.name
          @updateStatus("
            Processing file #{file.name}...
            <br>
            <span class='small text-warning'><i class='fa fa-exclamation-triangle'></i>Do not navigate away while uploading.</span>
          ")

          url = @fileFieldTarget.dataset.directUploadUrl
          upload = new DirectUpload(file, url, @)
          upload.create (error, blob, x) =>
            if error
              @_emitError("Error uploading #{file.name}: #{error}.")
            
            else
              @_emitFileUploaded(blob)
              
            resolve()
        )
    )

    allSynchronously([@_startUploadFiles(), uploadPromises..., @_stopUploadFiles()])

  ###
    Callback from DirectUpload that handles a bunch of stuff.  We're using it
    here to create an event listener that listens to progress changes.
  ###
  directUploadWillStoreFileWithXHR: (request) ->
    request.upload.addEventListener "progress", =>
      @updateStatus("
        Uploading #{@currentFileUploadingName} (File #{@currentFileUploading} of #{@numFilesToUpload}) - #{Math.round((event.loaded / event.total) * 100)}%
        <br>
        <span class='small text-warning'><i class='fa fa-exclamation-triangle'></i>Do not navigate away while uploading.</span>
      ")

  updateStatus: (message) ->
    if @effectiveStatusLabel
      @effectiveStatusLabel.innerHTML = message

  _clearFileField: (target) ->
    target or= @fileFieldTarget
    
    try
      target.value = null
    catch error
      # ignore
    
    if target.value
      target.parentNode.replaceChild(@fileFieldTarget.cloneNode(true), @fileFieldTarget)

  _enable: ->
    if @hasDropareaTarget
      @dropareaTarget.disabled = false
    @fileButtonTarget.disabled = false
    @fileFieldTarget.disabled = false

  _disable: ->
    if @hasDropareaTarget
      @dropareaTarget.disabled = true
    @fileButtonTarget.disabled = true
    @fileFieldTarget.disabled = true

  _startUploadFiles: ->
    () =>
      new Promise (resolve) =>
        if @effectiveStatusLabel
          @effectiveStatusLabel.hidden = false
        @_disable()
        @_emitAllUploadsStarted()
        resolve()

  _stopUploadFiles: ->
    () =>
      new Promise (resolve) => 
        if @effectiveStatusLabel
          @effectiveStatusLabel.hidden = true
        @_enable()
        @_clearFileField()
        @_emitAllUploadsStopped()
        @updateStatus('')
        resolve()
  
  ### event emition functions ###
  _emitAllUploadsStarted: ->
    @element.dispatchEvent(new CustomEvent('fileDrop:allUploadsStarted', bubbles: true, detail: { numFilesToUpload: @numFilesToUpload }))

  _emitAllUploadsStopped: ->
    @element.dispatchEvent(new CustomEvent('fileDrop:allUploadsStopped', bubbles: true))

  _emitError: (error) ->
    @element.dispatchEvent(new CustomEvent('fileDrop:error', bubbles: true, detail: { error: error }))

  _emitFileUploaded: (blob) ->
    @element.dispatchEvent(new CustomEvent('fileDrop:fileUploaded', bubbles: true, detail: { blob: blob }))
