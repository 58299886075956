import { Controller } from "stimulus"
import { currentBreakpoint } from "../shared/bootstrap"
import _capitalize from 'lodash/capitalize'
import _debounce from 'lodash/debounce'
import _each from 'lodash/each'
import _find from 'lodash/find'
import _includes from 'lodash/includes'

export default class extends Controller
  @targets = [
    'mainNav',
    'navItem', 'subNavLink',
    'topLevelSubNavContainer', 'topLevelSubNavLink', 'topLevelMenu'
  ]

  initialize: ->
    ###
      Set @navigationType to the type of navigation in use.  By default, this
      is "slide", which utilizes more basic functionality for standard
      sidebar-style navigation.  To use dropdowns instead, set
      data-navigation-type="dropdown".
    ###
    @navigationType = @data.get('type') or "slide"

    # Dropdown-specific variables and configuration
    if @navigationType is "dropdown"
      # Using data-navigation-constrain-dropdown-within=".class|#id", set a
      # parent container in which dropdowns should stay constrained to.
      @dropdownConstrainedWithinElement = @element.closest(@data.get('dropdownConstrainedWithin')) or @element

      # For constraints to work properly, set a fixed width for the dropdowns.
      # This can be overridden with data-navigation-dropdown-width
      @dropdownWidth = if @data.get('dropdownWidth') then parseInt(@data.get('dropdownWidth')) else 280

    @_initActiveNavItem()
    @["_initSubNavOfType#{_capitalize(@navigationType)}"](@activeNavItem)
    @render()

  render: ->
    @_setAriaExpanded()

  ###
    Toggle the main navigation on mobile (via the hamburger button).
    @attribute event [Event]
  ###
  toggleMainNav: (event) ->
    event.preventDefault()
    $(@mainNavTarget).slideToggle('fast')

  ###
    Show or hide subnavigation sections
    @attribute event [Event]
  ###
  toggleSubNav: (event) ->
    event.preventDefault()

    # Grab the subNavLink (the toggle/expand/collapse link) and find:
    #   - subNavMenu (the subnavigation menu that should be shown or hidden)
    #   - parentNavItem (the containing list item)
    subNavLink = event.target
    subNavMenu = subNavLink.nextElementSibling
    parentNavItem = subNavLink.closest('.platform-nav__list__item--with-subnav')

    # Always toggle the "open" class on the containing li
    parentNavItem.classList.toggle("open")

    # Get the open state of the parentNavItem
    isMovingToOpen = parentNavItem.classList.contains("open")

    isTopLevel = _includes(@topLevelSubNavLinkTargets, subNavLink)

    # If the navigation type is "dropdown", ensure all dropdowns are closed
    # if opening a new top level menu
    if @navigationType is "dropdown"
      @_closeAllTopLevelMenus(subNavLink) if isTopLevel and isMovingToOpen

    if isMovingToOpen
      $(subNavMenu).slideDown('fast')
    else
      subNavMenu.style.display = 'none'

    # Rerender
    @render()

  ###
    Initialize: Find or set the @activeNavItem and add the "active" class.
  ###
  _initActiveNavItem: ->
    # Attempt to find the active navigation item.
    @activeNavItem = _find @navItemTargets, (target) -> target.classList.contains('active')

    ###
      If the @activeNavItem could not be found (the content is cached or
      using some other method of defining nav item behavior), try to find it.
      If the data-navigation-current-nav-item is set, find a @navItemTarget
      with a matching data-nav-item value.
    ###
    unless @activeNavItem
      # If data-navigation-current-nav-item is set, set it to currentNavItem
      if currentNavItem = @data.get('currentNavItem')
        # Find the nav item that should be active
        @activeNavItem = _find @navItemTargets, (target) ->
          target.dataset.navItem == currentNavItem

    # Set the "active" class on the @activeNavItem
    if @activeNavItem
      # Ensure the active nav item has an active class
      @activeNavItem.classList.add('active')

  ###
    Initialize: When @navigationType is "slide", expand any relevant
    subnavigation menus recursively.

    @attribute activeMenuItemTarget [Node]
  ###
  _initSubNavOfTypeSlide: (activeMenuItemTarget) ->
    return unless activeMenuItemTarget

    # Find the closest subnav (if applicable) to the active navigation item
    subNavItem = activeMenuItemTarget.closest('.platform-nav__list__item--with-subnav')
    return unless subNavItem

    # Add the "open" class to the subnav
    subNavItem.classList.add('open')

    # Find the menu and set it to visible
    if menu = activeMenuItemTarget.closest('ul[role="menu"]')
      menu.style.display = 'block'

    @_initSubNavOfTypeSlide(subNavItem.parentElement)

  ###
    Initialize: When @navigationType is "dropdown", expand only nested
    subnavigation menus.

    Special considerations with dropdown nav:
      - If the window is resized, absolutely-positioned dropdowns can become
        wonky - mispositioned or layout-breaking.  Close them immediately.
      - Clicking another menu while the first is still open should close
        the other menu.

    @attribute activeMenuItemTarget [Node]
  ###
  _initSubNavOfTypeDropdown: (activeMenuItemTarget) ->
    ###
      Because dropdown navigation requires special considerations due to
      absolute positioning of dropdown menus and their corresponding open state,
      programmatically define @topLevelSubNavContainerTargets and
      @topLevelSubNavLinks so we can work with them directly.

      Iterate through the direct children of the main navigation.  If the child
      is not a @navItemTarget, it's a subnavigation dropdown menu.  For those
      that are subnavigation dropdown menus:
        - Make it a @topLevelSubNavContainerTarget
        - Find the corresponding link and make it a @topLevelSubNavLinkTarget
    ###
    _each @mainNavTarget.children, (target) =>
      unless _includes(@navItemTargets, target)
        identifiedTargetName = "data-#{@identifier}-target"
        baseOriginalValue = target.getAttribute(identifiedTargetName) or ""

        target.setAttribute(identifiedTargetName, "#{baseOriginalValue} topLevelSubNavContainer")

        _each target.children, (child) =>
          if _includes(@subNavLinkTargets, child)
            childOriginalValue = target.getAttribute(identifiedTargetName) or ""
            child.setAttribute(identifiedTargetName, "#{childOriginalValue} topLevelSubNavLink")
          true # continue target.children
      true #continue @mainNavTarget.children

    ###
      When the window is resized, close all top-level dropdowns.  This serves
      two functions:
        - Performance is degraded when resizing a window with an absolutely-
          positioned dropdown menu item
        - Dropdown menu positions can get strangely placed or misaligned if
          the window is resized while the dropdown is open

      Additionally, when the window is resized, manually set the left value
      of all dropdown menus.  This way, if a menu item is pushed to the side,
      the corresponding dropdown won't appear outside of the bounds of the
      @dropdownConstrainedWithinElement container.
    ###
    window.addEventListener 'resize', _debounce =>
      if currentBreakpoint() is 'xs' or currentBreakpoint() is 'sm'
        # Do nothing
      else
        @_closeAllTopLevelMenus()
        @_setDropdownPositions()

    , 200,
      leading: true
      trailing: true

    # Set dropdown positions right away
    @_setDropdownPositions()

    # Expand inner nav menu items without expanding top level menu items
    if activeMenuItemTarget
      unless _includes(@topLevelSubNavContainerTargets, activeMenuItemTarget)
        subNavItem = activeMenuItemTarget.closest('.platform-nav__list__item--with-subnav')
        # If no subNavItem was found, return early.
        return unless subNavItem

        # If the subNavItem is a top-level sub nav item, don't open it and
        # return early.
        return if _includes(@topLevelSubNavContainerTargets, subNavItem)

        # Add the "open" class to the subnav
        subNavItem.classList.add('open')

        # Find the menu and set it to visible
        if menu = activeMenuItemTarget.closest('ul[role="menu"]')
          menu.style.display = 'block'

  ###
    For accessibility, all subnavigation toggle/expand/collapse links should
    have aria-expanded set to whether or not their corresponding list is
    open (aria-expanded="true") or closed (aria-expanded="false").
    This should be performed any time a subnavigation manu is toggled.
  ###
  _setAriaExpanded: ->
    _each @subNavLinkTargets, (target) =>
      target.setAttribute('aria-expanded', !!target.parentElement.classList.contains("open"))
      true

  ###
    Closes all top-level nav menus.
    @attribute ignoreTarget [Node or null] a subnav link to optionally skip
  ###
  _closeAllTopLevelMenus: (ignoreTarget = null) ->
    _each @topLevelSubNavLinkTargets, (topLevelSubNavLink) ->
      # Don't touch the one being ignored, if present
      unless ignoreTarget is topLevelSubNavLink
        topLevelSubNavLink.parentElement.classList.remove("open")
        topLevelSubNavLink.nextElementSibling.style.display = 'none'
      true

  ###
    When using a @navigationType of "dropdown", dropdowns need to be positioned
    so they do not overflow the page/go out of bounds.  By setting an element
    to @dropdownConstrainedWithinElement and a number to @dropdownWidth,
    it can be programmatically determined if any offset is required to position
    the dropdown.  If there is, it is set.
  ###
  _setDropdownPositions: ->
    constraintRect = @dropdownConstrainedWithinElement.getBoundingClientRect()

    _each @topLevelSubNavLinkTargets, (subNavLink) =>
      subNavLinkRect = subNavLink.getBoundingClientRect()
      subNavMenu = subNavLink.nextElementSibling

      subNavMenu.style.right = if (subNavLinkRect.left + @dropdownWidth) > constraintRect.right
        "0px"
      else
        ""