import { Controller } from "stimulus"
import _map from 'lodash/map'
import Sortable from "sortablejs"

export default class extends Controller
  @targets: [
    'attachmentsContainer',
    'attachment' ]

  @values =
    createAttachmentUrl: String
    reorderAttachmentsUrl: String

  initialize: ->
    new Sortable @attachmentsContainerTarget,
      animation: 50
      ghostClass: 'sortable-placeholder-highlight-variable'
      handle: '[role="sort_handle"]'
      swapThreshold: 0.25
      onEnd: (event) => 
        @reorderAttachments(event)
        $(event.item).effect('highlight', {}, 2000)

  fileUploaded: (event) ->
    # Grab a reference to where we expect the blob's info to be.
    blob = event.detail.blob

    # Build the FormData we'll pass along with our request.
    data = new FormData()
    data.append 'blob[signed_id]', blob.signed_id
    data.append 'blob[filename]', blob.filename

    # Submit the blob's info to the EA's presentation create URL.
    Rails.ajax
      type: 'POST'
      url: @createAttachmentUrlValue
      dataType: 'script'
      data: data

  reorderAttachments: (event) ->
    orderedIds = _map @attachmentTargets, (attachment) -> attachment.dataset.attachmentId

    data = new FormData()
    data.append 'ordered_ids', orderedIds

    # Submit the blob's info to the EA's presentation create URL.
    Rails.ajax
      type: 'POST'
      url: @reorderAttachmentsUrlValue
      dataType: 'html'
      data: data