import { Controller } from "stimulus"
import "underscore.inflection"

export default class extends Controller
  @targets: ["duplicateJobAlert", "jobLocationField"]

  duplicateJobAlertTemplate: _.template("
    <div class='alert alert-warning alert-with-icon'>
      <i class='fa fa-exclamation-triangle'></i>
      <p>
        <strong>
          It looks like you already have <%= _('pending job').pluralize(data.possibleDuplicateJobs.length, true) %> 
          in this location.
        </strong>
      </p>
      <hr />
      <% _.each(data.possibleDuplicateJobs, function(job) { %>
        <p>
          <strong><%= job.title %></strong>
          <br />
          <small><%= job.location_city_state %></small>
          <br />
          <a href='<%= job.edit_path %>'>Continue with this posting</a>
        </p>
      <% }) %>
    </div>
  ", variable: 'data')

  initialize: ->
    @jobId = JSON.parse(@data.get('jobId')) || null

    @performDuplicateChecks = @data.get('performDuplicateJobCheck') || true
    
    if @performDuplicateChecks
      @advertiserRecentPendingJobs = JSON.parse(@data.get('advertiserRecentPendingJobs'))

  checkPendingJobsForDuplicates: ->
    if @performDuplicateChecks
      possibleDuplicateJobs = _.filter(@advertiserRecentPendingJobs, ((job) =>
        job.location_city_state == @jobLocation() && job.id != @jobId
      ))

      if possibleDuplicateJobs.length
        $(@duplicateJobAlertTarget).html(@duplicateJobAlertTemplate(possibleDuplicateJobs: possibleDuplicateJobs))
        $(@duplicateJobAlertTarget).slideDown('fast')
      else
        $(@duplicateJobAlertTarget).slideUp('fast')

  jobLocation: ->
    # Check the last location target on the DOM. Prevents wierdness when using Twitter typeahead
    # which duplicates the input field.
    @jobLocationFieldTargets[@jobLocationFieldTargets.length - 1].value