import { Controller } from "stimulus"
import _isEmpty from 'lodash/isEmpty'

export default class extends Controller
  @targets: ['text', 'bgcolor', 'label']
  
  initialize: ->
    @render()
  
  render: ->
    @labelTarget.classList.remove('label-default')
    @labelTarget.style.removeProperty('backgroundColor')
    
    if _isEmpty(@textTarget.value.trim())
      @labelTarget.innerHTML = '<em>Please provide a category name</em>'
    else
      @labelTarget.innerHTML = @textTarget.value
    
    if _isEmpty(@bgcolorTarget.value.trim())
      @labelTarget.classList.add('label-default')
    else
      @labelTarget.style.backgroundColor = @bgcolorTarget.value
  
  update: ->
    @render()