import { Controller } from "stimulus"

###
  @usage with SELECT fields
  
    -# for_select is required for SELECT fields
    -# possible_empty_selection should be 'true' as to ensure nothing shows by default
    %div{ data: { controller: 'field-toggle-v2', field_toggle_v2: { for_select: 'true', possible_empty_selection: 'true' }}}
      %div
        %select{ data: { target: 'field-toggle-v2.select', action: 'field-toggle-v2#update' }}
          %option Hello!
          %option World!
          %option{ data: { target_fieldset: 'aCustomNameForThisGroup' }} Show hidden stuff!
      
      %div{ data: { target: 'field-toggle-v2.targetFieldset field-toggle-v2.aCustomNameForThisGroup' }}
        This div will be shown when the "Show hidden stuff!" option is shown,
        and hidden when other options are selected.
  
###
export default class extends Controller
  @targets: ["option", "select", "tab", "targetFieldset"]

  @property 'forBsTabs',
    get: ->
      @data.get('forBsTabs') == 'true'

  @property 'forSelect',
    get: ->
      @data.get('forSelect') == 'true'

  @property 'possibleEmptySelection',
    get: ->
      @data.get('possibleEmptySelection') == 'true'

  @property 'disableChildFields',
    get: ->
      @data.get('disableChildFields') == 'true'

  initialize: ->
    if @possibleEmptySelection
      @hideAndDisableAllTargetFieldsets()

    if @forSelect
      for option in @optionTargets
        if option.value == @selectTarget.value
          @update(null, option.dataset.targetFieldset)

    else if @forBsTabs
      for tab in @tabTargets
        if 'active' in tab.classList
          @update(null, tab.dataset.targetFieldset)

    else
      for option in @optionTargets
        if option.checked
          @update(null, option.dataset.targetFieldset)

  update: (event, targetFieldset) ->
    @hideAndDisableAllTargetFieldsets()

    targetFieldsetIdentifier =
      if event && event.target.nodeName == "SELECT"
        identifierFromSelect = null
        
        for selectOption in event.target.childNodes
          if selectOption.value == event.target.value
            identifierFromSelect = selectOption.dataset.targetFieldset
            break

        identifierFromSelect

      else if event && event.target.dataset.targetFieldset
        event.target.dataset.targetFieldset

      else if event && event.currentTarget.dataset.targetFieldset
        event.currentTarget.dataset.targetFieldset
      
      else if targetFieldset
        targetFieldset

    if event && @forBsTabs
      # Remove the active state from all tabs.
      for tab in @tabTargets
        tab.classList.remove('active')

      # Add the active state to the clicked tab.
      event.currentTarget.classList.add('active')

    for targetFieldset in @targets.findAll(targetFieldsetIdentifier)
      targetFieldset.hidden = false
      targetFieldset.disabled = false
      @_toggleChildFields(targetFieldset, false)

  hideAndDisableAllTargetFieldsets: ->
    for targetFieldset in @targetFieldsetTargets
      targetFieldset.hidden = true
      targetFieldset.disabled = true
      @_toggleChildFields(targetFieldset)

  _toggleChildFields: (fieldset, shouldDisable = true) ->
    return unless @disableChildFields

    fieldset.querySelectorAll('input, select, textarea, button').forEach (target) ->
      unless target.classList.contains('static')
        target.disabled = shouldDisable