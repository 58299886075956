import { Controller } from 'stimulus'

# Delegate to App.Views.EavForm.CustomFormFieldView
export default class extends Controller
  initialize: ->
    @view = new App.Views.EavForm.CustomFormFieldView
      el: @element
      model: new Backbone.Model($(@element).data('model'))

  # We call #render on the Backbone view when dropdown items have changed.
  # This is because things like *Limit option quantities* will not be displayed
  # properly. This is a stop-gap before transitioning this code fully from
  # Backbone.
  render: ->
    @view.render()
