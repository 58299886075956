import { Controller } from "stimulus"

export default class extends Controller
  @targets: ['dollarDiscountField', 'maxActivationsField', 'percentageDiscountField', 'renewalsField', 'renewalsOption']

  @property 'maxActivations',
    get: ->
      parseInt(@maxActivationsFieldTarget.value)

  @property 'renewals',
    get: ->
      @renewalsFieldTarget.value

  connect: ->
    @update()

  disableRenewalsOptionsForMaxActivations: ->
    for option in @renewalsOptionTargets
      if option.value in ["enabled", "required"]
        option.disabled = true
        option.selected = false
      
      else if option.value in ["disabled"]
        option.disabled = false
        option.selected = true

  enableAllRenewalsOptions: ->
    for option in @renewalsOptionTargets
      option.disabled = false

  renewalsChanged: (event) ->
    if event.target.value == 'disabled'
      # If the renewals field changes to disabled, strip out the dollar and percentage
      # discount fields and disable them. Then update the view.
      @dollarDiscountFieldTarget.value = ''
      @dollarDiscountFieldTarget.disabled = true

      @percentageDiscountFieldTarget.value = ''
      @percentageDiscountFieldTarget.disabled = true

    @update()
    
  update: ->
    if @renewals in ["enabled", "required"]
      # If renewals are enabled or required, max activations cannot be in play.
      # Strip and disable the field.
      @maxActivationsFieldTarget.value = ''
      @maxActivationsFieldTarget.disabled = true

      @dollarDiscountFieldTarget.disabled = false
      @percentageDiscountFieldTarget.disabled = false

    else
      # If renewals are disabled, the max activations field can be enabled.
      @maxActivationsFieldTarget.disabled = false
      @dollarDiscountFieldTarget.disabled = true
      @percentageDiscountFieldTarget.disabled = true

      if @maxActivations && @maxActivations > 0
        @disableRenewalsOptionsForMaxActivations()
  
      else
        @enableAllRenewalsOptions()