import { Controller } from "stimulus"
import _each from "lodash/each"
import _filter from "lodash/filter"
import _find from "lodash/find"
import _findIndex from "lodash/findIndex"
import _isArray from "lodash/isArray"
import _isEmpty from 'lodash/isEmpty'
import _remove from "lodash/remove"
# dependency: jQuery datepicker

export default class extends Controller
  @targets: [
    "presetOrTemplateSelect",
    "reportCheckbox",
    "reportSelect",
    "datePickerField",
    "tokenfieldRadioField",
    "tokenfieldContainer"
  ]
  
  initialize: ->
    @render()
  
  # Should be called when the top-most SELECT is changed.  Handles setting
  # the options on the form based on the selection.
  render: ->
    selectedOption = @presetOrTemplateSelectTarget.selectedOptions[0]
    
    switch selectedOption.value
      when '0', "", null
        # Either the blank option was selected or, somehow, a disabled option
        # was selected.  In either case, don't do anything.
        true
      
      when "all_fields_selected", "no_fields_selected"
        # The keys for selecting all fields or no fields.  Ignores disabled
        # checkboxes.
        allFieldsSelected = (selectedOption.value is 'all_fields_selected')
        noFieldsSelected = (selectedOption.value is 'no_fields_selected')
        
        # Iterate through each checkbox field
        _each @reportCheckboxTargets, (target) =>
          if target.disabled
            # Ignore and do nothing
            true
          
          else if allFieldsSelected and target.dataset.valueOnSelectAll
            # When "all_fields_selected" is chosen, if a checkbox has
            # data-value-on-select-all set, this will override the default
            # checked value.
            @_setReportCheckboxState(target, (target.dataset.valueOnSelectAll is 'true'))

          else if noFieldsSelected and target.dataset.valueOnSelectNone
            # When "no_fields_selected" is chosen, if a checkbox has
            # data-value-on-select-none set, this will override the default
            # checked value.
            @_setReportCheckboxState(target, (target.dataset.valueOnSelectNone is 'true'))

          else
            @_setReportCheckboxState(target, allFieldsSelected)
          
          true # Keep _each loop going
        
        # Clear all datepicker fields
        _each @datePickerFieldTargets, (target) => @_setDatePickerFieldValue(target)

        # Reset all select fields
        _each @reportSelectTargets, (target) => @_setSelectFieldValue(target)

        @tokenfieldContainerTargets.forEach (target) ->
          target.dispatchEvent(new CustomEvent('reset', { detail: { value: '' }}))

        @tokenfieldRadioFieldTargets.forEach (target) =>
          possibleMatches = [target.value]
          if selection = target.dataset.customReportSerializationSelection
            selection.split(',').forEach((eachSelection) => possibleMatches.push eachSelection)

          if noFieldsSelected
            target.checked = 'none' in possibleMatches
          else # allfieldSelected or otherwise
            target.checked = 'all' in possibleMatches

          target.dispatchEvent(new CustomEvent('click')) if target.checked
      
      else
        # A report was selected.  Find the report.
        report = try
          JSON.parse(selectedOption.dataset.reportData)
        catch e
          # There's a problem with the JSON generated.  Set it to [] to prevent
          # exceptions from making the form otherwise work incorrectly.
          []
        
        # Bail if a report couldn't be found
        return unless report

        reportKeys = Object.keys(report)

        # Iterate over possible token entry containers that are using
        # serialized keys. Handle this first before doing anything else,
        # as it destructively modifies reportKeys.
        @_processTokenfieldContainers(report, reportKeys)

        # We have a report - go through each key and set the values
        # appropriately.  If the key's value is an array, iterate through each
        # value and find the appropriate IDs or values to check.  If the target
        # is a datepicker, set it appropriately.  Otherwise, check the checkbox
        # (or not) based on the key's value.
        reportKeys.forEach (keyName) =>
          if _isArray(report[keyName])
            @_processArrayFieldWithNameAndValue(report, keyName)
          else if target = @_findDatePickerByName(keyName)
            @_setDatePickerFieldValue(target, report[keyName])
          else if target = @_findReportSelectByName(keyName)
            @_setSelectFieldValue(target, report[keyName])
          else
            @_setReportCheckboxesByName(keyName, report[keyName])
  
  _processTokenfieldContainers: (report, reportKeys) ->
    removableKeys = new Set()

    # Go through each tokenfield container (if present) and reset each
    # with updated values
    @tokenfieldContainerTargets.forEach (target) =>
      # Specifying a data-custom-report-disables-key will remove it
      # from the key set
      if irrelevantKey = target.dataset.customReportDisablesKey
        removableKeys.add irrelevantKey
      
      # Specifying a data-custom-report-reinitialize-key will use that
      # key for the token field value
      if newValueKey = target.dataset.customReportUsesKey
        removableKeys.add newValueKey
        value = report[newValueKey]
        target.dispatchEvent(new CustomEvent('reset', { detail: { value: value }}))

    # Go through each tokenfield-adjacent radio field (if present) and
    # check the proper ones. This custom implementation will not be relevant
    # to other radio buttons on a form.
    @tokenfieldRadioFieldTargets.forEach (target) =>
      # Assumes name is set by a radio_button_tag. This will need to be modified
      # if using f.radio_button!
      removableKeys.add target.name

      # Gather the possible matches for the radio options. By default, this
      # will use the value of the target; however, there are cases where, with
      # tokenfield entry, you may not want to specify a "none" option, or even
      # an "all" option. In those cases, set:
      #
      #   data-custom-report-serialization-selection="all,none,some,many"
      #
      # Use a comma-separated list of items, without spaces, that it might
      # match on.
      possibleMatches = new Set([target.value])
      if selection = target.dataset.customReportSerializationSelection
        selection.split(',').forEach((eachSelection) => possibleMatches.add eachSelection)

      target.checked = possibleMatches.has(report[target.name])
      target.dispatchEvent(new CustomEvent('click')) if target.checked

    # Remove the keys used above since we do not need them to be iterated on.
    # _remove is destructive and modifies the original array (reportKeys).
    # Because reportKeys isn't duplicated (and is merely a pointer), we do
    # not need to return it.
    _remove reportKeys, (key) => removableKeys.has(key)

  _processArrayFieldWithNameAndValue: (report, keyName) ->
    # Clear all to start
    @_setReportCheckboxesByName(keyName, false)

    # Iterate through each value in the array
    _each report[keyName], (value) =>
      if value isnt undefined and value isnt null and !_isEmpty(String(value))
        _each @_findReportCheckboxesByNameAndValue(keyName, value), (target) =>
          @_setReportCheckboxState(target, true)
      true # Keep _each loop going

  # Find all checkboxes matching the name attribute
  _findReportCheckboxesByName: (name) ->
    _filter @reportCheckboxTargets, (target) =>
      target.name.includes("[#{name}]")

  # Find all checkboxes matching the name attribute having a specific value
  _findReportCheckboxesByNameAndValue: (name, value) ->
    _filter @reportCheckboxTargets, (target) =>
      target.name.includes("[#{name}]") and (String(target.value) is String(value))

  # Set the checked status of all checkboxes matching the name given with the
  # value provided.
  _setReportCheckboxesByName: (name, checkedMatch) ->
    _each @_findReportCheckboxesByName(name), (target) =>
      @_setReportCheckboxState(target, checkedMatch)
  
  _setReportCheckboxState: (checkbox, checkedState) ->
    checkbox.checked = checkedState
    if checkbox.dataset.toggleTarget
      checkbox.dispatchEvent(new CustomEvent('track', { bubbles: true }))
    true
  
  # Find all datepickers matching the name attribute
  _findDatePickerByName: (name) ->
    _find @datePickerFieldTargets, (target) =>
      target.name.includes("[#{name}]")
  
  # Set the value of the singluar datepicker given (target) and trigger the
  # corresponding jQuery callback chain
  _setDatePickerFieldValue: (target, value) ->
    target.value = value or ""
    $(target).trigger('change')
    true
  
  _findReportSelectByName: (name) ->
    _find @reportSelectTargets, (target) =>
      target.name.includes("[#{name}]")
    
  # Set the value of a select field
  _setSelectFieldValue: (target, optionValue) ->
    optionIndex = _findIndex target.options, (option) =>
      option.value is String(optionValue)
    
    # If _findIndex doesn't find anything, it will return -1. In that case,
    # make sure that the index is 0 (the first element) with Math.max.
    target.selectedIndex = Math.max(optionIndex, 0)
    
    # Dispatch a change event
    target.dispatchEvent(new CustomEvent('change', { bubbles: true }))
    
    true