import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["variableFieldset", "fixedFieldset", "paymentModelFieldset", "termAdditionalSettingsFieldset"]

  @property 'leadPaymentModel',
    get: ->
      @data.get('leadPaymentModel')

    set: (value) ->
      @data.set('leadPaymentModel', value)
      @render()

  @property 'leadFixedTerm',
    get: ->
      @data.get('leadFixedTerm')

    set: (value) ->
      @data.set('leadFixedTerm', value)
      @render()

  initialize: ->
    @render()

  render: ->
    # Hide and disable all the payment model fieldsets.
    for target in @paymentModelFieldsetTargets
      target.hidden = target.disabled = true

    # Then show the selected one.
    selectedTarget = @targets.find("#{@leadPaymentModel}Fieldset")
    selectedTarget.hidden = selectedTarget.disabled = false

    if @leadPaymentModel == 'fixed' && @leadFixedTerm && @leadFixedTerm != 'monthly'
      @termAdditionalSettingsFieldsetTarget.hidden = @termAdditionalSettingsFieldsetTarget.disabled = false
    else
      @termAdditionalSettingsFieldsetTarget.hidden = @termAdditionalSettingsFieldsetTarget.disabled = true


  updatePaymentModel: (event) ->
    @leadPaymentModel = event.target.value

  updateFixedTerm: (event) ->
    @leadFixedTerm = event.target.value