import { Controller } from 'stimulus'
import _debounce from 'lodash/debounce'
import _isEmpty from 'lodash/isEmpty'
import _template from 'lodash/template'

# Checks the user existence endpoint against the given email, submits a
# debounced request, and presents a page-specific template when a result is
# found.
#
# This requires:
#
# * The email field to have data-user-unique-email-target="emailField"
# * An element with data-user-unique-email-target="responseContainer",
#   that will present the results generated from the following template.
#   This container should also have an ID set for accessibility purposes.
# * A TEMPLATE with data-user-unique-email-target="template", that is the
#   template shown when a match is found. The only data available to this
#   template is {{ data.perishable_token }}.
#
# Example markup:
#
# = form_with ... html: { data: { controller: 'user-unique-email' }} do |f|
#   %template{ data: { user_unique_email_target: 'template' }}
#     %div
#       My perishable ID is {{ data.perishable_token }}
#
#   = render user_form_group_partial(:email), f: f
#
#   %div{ data: { user_unique_email_target: 'responseContainer' }}
#
export default class extends Controller
  @targets: [
    'emailField',
    'template',
    'responseContainer'
  ]

  @property 'url',
    get: -> "/user_existence"

  initialize: ->
    # Set a debounce (1000ms) on @update(). with a maxWait of 30 seconds.
    # This will debounce every second, but will hold off up to 30 seconds if
    # called more often (e.g. while a user is typing).
    # We don't need this requesting updates constantly.
    @update = _debounce(@update, 1000, { maxWait: 30000 }).bind(@)

    # Perform the update on page load.
    @update()

  update: ->
    # If there is no @responseContainerTarget or @templateTarget,
    # there's nothing to do. Abort.
    return unless @hasResponseContainerTarget
    return unless @hasTemplateTarget

    # Check that email field has a value and it kind of looks like an email
    if _isEmpty(@emailFieldTarget.value.trim()) or !@emailFieldTarget.value.includes("@")
      @reset()
      return

    # Fetch via POST. We'll build the body inline.
    fetch(@url,
      method: 'POST'
      mode: 'same-origin'
      credentials: 'same-origin'
      headers:
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
        'Content-Type': 'application/json'
        'Accept': 'text/plain'
      body: JSON.stringify({ email: @emailFieldTarget.value })
    ).then((response) =>
      response.text()
    ).then((text) =>
      # The response should be a string containing either "0" (nothing found)
      # or the user's perishable token.
      if text == '0'
        @reset()
      else
        @_renderResults(text)
    ).catch((error) =>
      @reset()
    )

  reset: ->
    @_toggleEmailFieldError(false)
    @responseContainerTarget.classList.add('hidden')
    @responseContainerTarget.innerHTML = ''

  _renderResults: (text) ->
    # Set up template options. Use the data prefix (data.name, data.email)
    # for the view, and use double curly braces {{ }} for interpolation. Using
    # the default ERB-style tags is a problem when using innerHTML, as it will
    # escape < and >, without any way around it (including using the escape
    # option).
    templateOpts = {
      'variable': 'data'
      'interpolate': /{{([\s\S]+?)}}/g
    }

    # Get the template from the page
    template = _template(@templateTarget.innerHTML, templateOpts)

    # Compile the template with the perishable token
    compiled = template({ perishable_token: text })

    # Set the HTML and show the container
    @responseContainerTarget.innerHTML = compiled
    @responseContainerTarget.classList.remove('hidden')
    @_toggleEmailFieldError(true)

  # Used to toggle .has-error on the nearest .form-group, and also sets
  # aria-describedby for accessibility purposes.
  _toggleEmailFieldError: (showError = true) ->
    formGroupElement = @emailFieldTarget.closest('.form-group')
    formGroupElement.classList.toggle('has-error', showError)

    if showError
      formGroupElement.setAttribute('aria-describedby', @responseContainerTarget.id)
    else
      formGroupElement.removeAttribute('aria-describedby')
