import { Controller } from "stimulus"

export default class extends Controller
  initialize: ->
    @url = window.location.href + ".json"

    # Only run if in the process of importing
    if @isImporting()
      @startTimer()
  
  startTimer: ->
    @timer = setInterval =>
      request = $.get @url

      request.done (response) =>
        $('.tooltip').hide() # to prevent "stuck" tooltips
        $(@element).html(response.html)
        App.Mixins.HighlightEffect.successHighlight($(@element).find('.panel'))

        if response.importing == false
          # Refresh page when done to show rows
          window.location.reload(true)
          clearInterval(@timer)
    , 3000
  
  isImporting: ->
    @data.get('importing') == '1'