import { Controller } from "stimulus"
import numberFormat from 'underscore.string/numberFormat'

export default class extends Controller
  @targets: ["displayField", "valueField"]

  initialize: ->
    @replaceRegex = if parseInt(@data.get('allowNegativeNumbers'))
      /[^\d\.\-]/g
    else
      /[^\d\.]/g

    @displayFieldTarget.value = @formattedNumberWithDelimiters(@valueFieldTarget.value) || ""

  update: ->
    number = @displayFieldTarget.value

    @valueFieldTarget.value = @formattedNumberWithoutDelimiters(number) || ""
    @displayFieldTarget.value = @formattedNumberWithDelimiters(number) || ""

    # Fire up the chain an event that this currency field changed.
    @element.dispatchEvent(new CustomEvent("currencyFieldChanged", { bubbles: true }))
    @displayFieldTarget.dispatchEvent(new CustomEvent("displayedCurrencyChanged", { bubbles: true, detail: { value: @displayFieldTarget.value }}))

  formattedNumberWithoutDelimiters: (number) ->
    if numberWithDelimiters = @formattedNumberWithDelimiters(number)
      numberWithDelimiters.replace(@replaceRegex, '')

  formattedNumberWithDelimiters: (number) ->
    if number && !isNaN(numberAsFloat = parseFloat(number.replace(@replaceRegex, '')))
      numberFormat(numberAsFloat, 2)
