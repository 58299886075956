import { Controller } from "stimulus"

export default class extends Controller
  @targets: ['editButtons', 'editingButtons', 'element']

  @values =
    updateUrl: String
    updateParamName: String
    responseParamName: String

  initialize: ->
    @responseParamNameValue or= 'title'
    @scrollParent = @getScrollParent()

    @makeNotEditable()

  getScrollParent: ->
    # Start with the element's parent
    parent = @element.parentElement

    while (parent)
      # Get the computed style of the parent
      style = window.getComputedStyle(parent)

      # Check if the parent has overflow set to auto, scroll, or overlay
      overflowY = style.overflowY

      # Consider it a scrollable parent if it has overflow and is scrollable
      isScrollableY = (overflowY == 'auto' || overflowY == 'scroll' || overflowY == 'overlay') && parent.scrollHeight > parent.clientHeight

      # Return this parent if it's scrollable
      if (isScrollableY)
        return parent

      # Or... move to the next parent in the hierarchy
      parent = parent.parentElement

    # If no scrollable parent is found, return null.
    return null

  handleBlur: (event) ->
    unless @savingFromEnterKeypress
      @saveEdits()

    @savingFromEnterKeypress = false

  makeEditable: ->
    @editing = true
    @titleContentAtEdit = @elementTarget.innerText

    # Update the UI to be functional.
    @elementTarget.contentEditable = true

    if @hasEditButtonsTarget
      @editButtonsTarget.hidden = true

    if @hasEditingButtonsTarget
      @editingButtonsTarget.hidden = false

    # This accounts for a weird Safari bug where when focus()
    # is called we might jump to the top of the page.
    if @scrollParent
      scrollPos = @scrollParent.scrollTop

    # Weird hack, but this is what it took to focus.
    setTimeout =>
      @elementTarget.focus()

      if @scrollParent
        @scrollParent.scrollTop = scrollPos;
    , 50

  # Update the UI to it's non-edit state.
  makeNotEditable: ->
    @editing = false
    @elementTarget.contentEditable = false

    if @hasEditButtonsTarget
      @editButtonsTarget.hidden = false

    if @hasEditingButtonsTarget
      @editingButtonsTarget.hidden = true

  # Cancel edits and revert the title to whatever it was before edits.
  cancelEdits: ->
    @elementTarget.innerText = @titleContentAtEdit
    @makeNotEditable()
    @elementTarget.blur()

  # Persist the text of whatever is in the title element.
  saveEdits: ->
    data = new FormData()
    data.append @updateParamNameValue, @elementTarget.innerText

    Rails.ajax
      type: 'PATCH'
      url: @updateUrlValue
      dataType: 'json'
      data: data
      success: (data) =>
        # Switch to non-edit mode.
        @makeNotEditable()

        # Set the title to whatever was actually returned by the server. This allows the
        # model to strip spaces and other things that might be invalid.
        @elementTarget.innerText = data[@responseParamNameValue]

        # Highlight the UI
        $(@elementTarget).effect('highlight')
        @elementTarget.blur()

  # Strip all markup from paste and only paste the text.
  handlePaste: (event) ->
    event.preventDefault()
    text = (event.clipboardData || window.clipboardData).getData('text');
    document.execCommand('insertText', false, text);

  handleTitleKeypress: (event) ->
    # Handle escape (Cancel edits)
    if event.keyCode == 27
      event.preventDefault()
      @cancelEdits()

    # Handle enter (Save edits)
    if event.keyCode == 13
      event.preventDefault()
      @savingFromEnterKeypress = true
      @saveEdits()

  # If the element is double-clicked, make this editable.
  handleTitleDoubleClick: (event) ->
    event.preventDefault()
    @makeEditable() if !@editing