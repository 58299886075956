import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller
  @targets: [
    "componentLibrary"

    "focusContainer"

    "component"
    "componentContainer"
    
    "tab"
    "buildTab"
    "manageTab"
    "stylesTab"

    "tabContent"
    "buildTabContent"
    "manageTabContent"
    "stylesTabContent"

    "savingIndicator"
    "savedIndicator"
  ]

  initialize: ->
    # Turn each of the component library targets into a sortable.
    for componentLibrary in @componentLibraryTargets
      Sortable.create componentLibrary,
        draggable: '.newsletter-builder__droppable-component'
        group:
          name: 'shared'
          pull: 'clone'
          put: false
        sort: false
    
    @showTab(null, 'build')

  focus: (event) ->
    event.stopImmediatePropagation()

    @disableAllComponentContainerSortablesFrom(@element)

    # It is expected that the resolved element would have .newsletter-component.
    # Set the focusElement so that our unfocus can be generic.
    @focusElement = document.getElementById(event.target.dataset.target)
    
    @focusElementOriginalParent = @focusElement.parentNode
    @focusElementOriginalNextSibling = @focusElement.nextSibling

    @focusContainerTarget.appendChild(@focusElement)

    @enableAllComponentContainerSortablesFrom(@focusContainerTarget)

    # Expand all the components that are children of the article
    @expandAllFromNode(@focusElement)
    
    # Add focused modifiers to the builder and the component.
    @focusElement.classList.add('newsletter-component--focused')
    @element.classList.add('newsletter-builder--focused')

    @showTab(null, 'build')

  unfocus: (event) ->
    return unless @focusElement

    @enableAllComponentContainerSortablesFrom(@element)

    if @focusElementOriginalNextSibling
      @focusElementOriginalParent.insertBefore(@focusElement, @focusElementOriginalNextSibling)
    else
      @focusElementOriginalParent.appendChild(@focusElement)

    # Remove focused modifiers from the builder and the component.
    @focusElement.classList.remove('newsletter-component--focused')
    @element.classList.remove('newsletter-builder--focused')

  toggleComponentContainerSortablesFrom: (parentNode, action) ->
    componentContainerNodes = parentNode.querySelectorAll('[data-controller*="newsletter--builder--component-container"]')

    for componentContainer in componentContainerNodes
      componentContainerController = this.application
        .getControllerForElementAndIdentifier(componentContainer, "newsletter--builder--component-container")
      
      switch action
        when 'enable' then componentContainerController.enableSortable()
        when 'disable' then componentContainerController.disableSortable()

  disableAllComponentContainerSortablesFrom: (parentNode) ->
    @toggleComponentContainerSortablesFrom(parentNode, 'disable')

  enableAllComponentContainerSortablesFrom: (parentNode) ->
    @toggleComponentContainerSortablesFrom(parentNode, 'enable')

  expandAll: (event) ->
    for component in @componentTargets
      componentController = this.application.getControllerForElementAndIdentifier(component, "newsletter--builder--component")
      componentController.showExpandableTargets()

  expandAllFromNode: (node) ->
    for component in @componentTargets
      if node.contains(component)
        componentController = this.application.getControllerForElementAndIdentifier(component, "newsletter--builder--component")
        componentController.showExpandableTargets()

  collapseAll: (event) ->
    for component in @componentTargets
      componentController = this.application.getControllerForElementAndIdentifier(component, "newsletter--builder--component")
      componentController.hideExpandableTargets()

  showTab: (event, tabName) ->
    unless tabName
      tabName = event.target.dataset.tabToShow

    # Make all tabs inactive.
    for tab in @tabTargets
      tab.classList.remove('active')

    # Activate the right tab.
    tabToActivate = @targets.find("#{tabName}Tab")
    tabToActivate.classList.add('active') if tabToActivate

    # Hide all tab contents.
    for tabContent in @tabContentTargets
      tabContent.hidden = true

    # Show the right tab content.
    tabContentToShow = @targets.find("#{tabName}TabContent")
    tabContentToShow.hidden = false if tabContentToShow

  loadingSettings: (event) ->
    @showTab(null, 'manage')

  handleRemote: (event) ->
    if event.target.dataset.isComponentEditLink == 'true'
      # A component's edit link was clicked. The RJS response from that click
      # will load the component's edit form into the right panel, but we capture
      # that click so we can automatically switch to the 'manage' panel.
      @showTab(null, 'manage')

  showSavingIndicator: ->
    @savedIndicatorTarget.hidden = true
    @savingIndicatorTarget.hidden = false

  hideSavingIndicator: ->
    @savingIndicatorTarget.hidden = true
    @savedIndicatorTarget.hidden = false