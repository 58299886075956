import { Controller } from "stimulus"

export default class extends Controller
  @targets: [
    "shouldGenerateFixedInvoicePanel",
    "shouldGenerateFixedInvoiceField",
    "termEndField",
    "termEndFieldContainer"
  ]

  @property 'term',
    get: ->
      @data.get('term')

    set: (value) ->
      @data.set('term', value)
      @render()

  initialize: ->
    @render()

  render: ->
    switch @term
      when 'quarterly', 'semi-annual', 'annual'
        @termEndFieldContainerTarget.hidden = false
        @termEndFieldTarget.disabled = false

        @shouldGenerateFixedInvoicePanelTarget.hidden = false
        @shouldGenerateFixedInvoiceFieldTarget.disabled = false

      else
        @termEndFieldContainerTarget.hidden = true
        @termEndFieldTarget.disabled = true

        @shouldGenerateFixedInvoicePanelTarget.hidden = true
        @shouldGenerateFixedInvoiceFieldTarget.disabled = true

  termChanged: (event) ->
    @term = event.target.value