import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["tierFieldset", "submitBtn"]

  initialize: ->
    @requiresSubchapter = @data.get('requiresSubchapter') == 'true'
    @subchapterId = @data.get('subchapterId')

    @tierId = null
    @tierPricePointId = null
    @tierRequiresAttachment = false
    @tierHasAttachment = false

    @update()

  canSubmit: ->
    # All conditions must be met:
    #   - Has a tier.
    #   - Has a tier price point.
    #   - Doesn't require a subchapter OR requires a subchapter AND has one.
    #   - Doest't require an attachment OR requires an attachment AND has one.
    @tierId && @tierPricePointId &&
      (!@requiresSubchapter || (@requiresSubchapter && @subchapterId)) && 
      (!@tierRequiresAttachment || (@tierRequiresAttachment && @tierHasAttachment))

  subchapterIdChanged: (event) ->
    @subchapterId = event.target.value
    
    @update()

  tierChanged: (event) ->
    @tierId = event.detail.tierId
    @tierPricePointId = event.detail.tierPricePointId
    @tierRequiresAttachment = event.detail.tierRequiresAttachment
    @tierHasAttachment = event.detail.tierHasAttachment
    
    @update()

  hideAllTierFieldsets: ->
    for tierFieldset in @tierFieldsetTargets
      tierFieldset.hidden = tierFieldset.disabled = true

  update: ->
    @hideAllTierFieldsets()

    if @tierId
      fieldsetToShow = @targets.find("tier#{@tierId}Fieldset")
      fieldsetToShow.disabled = false
      fieldsetToShow.hidden = false

    if @canSubmit()
      @submitBtnTarget.disabled = false
      @submitBtnTarget.classList.add('btn-submit')
      @submitBtnTarget.classList.remove('disabled')
    
    else
      @submitBtnTarget.disabled = true
      @submitBtnTarget.classList.remove('btn-submit')
      @submitBtnTarget.classList.add('disabled')