import { Controller } from "stimulus"

###
  Given a @fieldTarget (an INPUT field) and any number of @textTargets (usually
  one), handles live updating the @textTargets when @fieldTarget is changed.

  In order to have this function appropriately, the @fieldTarget should set
  data-action to "keyup->field-live-update#update".
###
export default class extends Controller
  @targets: ['field', 'text']
  
  initialize: ->
    @render()
  
  render: ->
    # If no @fieldTarget is found, do nothing.
    if @hasFieldTarget
      @textTargets.forEach (element) =>
        element.textContent = @fieldTarget.value
  
  update: ->
    @render()