import { Controller } from "stimulus"
import _isNull from 'lodash/isNull'
import _unescape from 'lodash/unescape'

###
  Rich autocomplete for a user, displaying the user's name and email with ID
  and avatar. Once a user is selected, the search field is removed from the
  page and replaced with the same template on the page, while also setting the
  user ID field to the ID of the selected user.
  
  Expects a JSON response like:

    [{
      id: 123,
      name: 'Bob Jones',
      email: 'bobbyboi@gmail.com',
      avatar: '/img.jpg' # or null
    }, ...]

  This can be generated with
  
    render json: users, each_serializer: UserAutocompleteSerializer
  
  or
  
    UserAutocompleteSerializer.new(user).to_json
  
###
export default class extends Controller
  @targets: [
    'userId', # hidden field for the user's ID
    'userDetail', # where the template will be rendered
    'displayTemplate', # <template> for the user information
    'userEntryContainer', # container with the query field
    'query' # query field
  ]

  # set data-autocomplete-users-endpoint to the URL where autocomplete queries
  # can be performed, returning the proper JSON. Expects the user to have a
  # ?query=AUTOCOMPLETEQUERY parameter included in the URL, which will be
  # replaced upon search.
  @property 'endpoint',
    get: -> @data.get('endpoint')

  @property 'user',
    get: ->
      unless _isNull(@data.get('user'))
        JSON.parse(@data.get('user'))
      else
        null
    set: (stringifiedJSON) -> @data.set('user', stringifiedJSON)

  @property 'focus',
    get: -> @data.get('focus') is 'true'

  initialize: ->
    @render()

    source = new Bloodhound
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('id', 'name', 'email')
      queryTokenizer: Bloodhound.tokenizers.whitespace
      remote:
        url: @endpoint
        wildcard: 'AUTOCOMPLETEQUERY'
        rateLimitBy: 'debounce'
        rateLimitWait: 500

    $(@queryTarget).typeahead
      minLength: 3
    ,
      source: source
      display: 'name'
      limit: 20
      templates:
        pending: @pendingTemplate
        notFound: @notFoundTemplate
        suggestion: (user) => @template(user, false, false)

    if @focus
      @queryTargets.slice(-1)[0].focus()

    # typeahead creates a duplicate field. This kinda sucks, because now we
    # have to watch multiple fields for typeahead:select.
    @queryTargets.forEach (target) =>
      $(target).on 'typeahead:select', (event, option) =>
        @user = JSON.stringify(option)
        @render(true)

  render: (dispatchChangeEvent = false) ->
    @userDetailTarget.classList.toggle('hidden', !@user)
    @userEntryContainerTarget.classList.toggle('hidden', !!@user)
    @queryTargets.forEach (target) => target.value = ''

    if @user
      @userIdTarget.value = @user.id
      @userDetailTarget.innerHTML = @template(@user, false, true)
    else
      @userIdTarget.value = null
      @userDetailTarget.innerHTML = ''

    if dispatchChangeEvent
      @element.dispatchEvent(new CustomEvent('autocompleteChanged', detail: @user))

  reset: ->
    @user = null
    @render(true)

  template: (user, showLinks = false, showRemoveButton = false) ->
    # Format email
    email = user.email
    ['@', '.'].forEach (symbol) ->
      email = email.replaceAll(symbol, "<wbr></wbr>#{symbol}")

    _.template(_unescape(@displayTemplateTarget.innerHTML),
      variable: 'data'
    )({
      id: user.id,
      name: user.name,
      avatar: user.avatar,
      professional_titles: user.professional_titles,
      email: email,
      showLinks: showLinks,
      showRemoveButton: showRemoveButton
    })

  pendingTemplate: (query) =>
    """
      <div class="bs5--mx-4 text-muted">
        <i class="fa fa-spinner fa-spin"></i>
        Searching for <strong>#{ query.query }</strong>...
      </div>
    """

  notFoundTemplate: (query) =>
    """
      <div class="bs5--mx-4 text-danger">
        <i class="fa fa-exclamation-triangle"></i>
        No results found for <strong>#{ query.query }</strong>.
      </div>
    """
