import { Controller } from "stimulus"

###
  Handles a group of agenda item fieldsets within a given date.
###
export default class extends Controller
  @targets: ['agendaItem']
  
  @property 'isValidEventDate',
    get: -> @data.get('withinEventTimeframe') is 'true'
  
  @property 'groupDate',
    get: -> @data.get('date')
  
  update: (event) ->
    if event.type is "sortableEndedOnNewTarget"
      # When a new item is moved to this list from another sortable list,
      # update the dates for the fields accordingly by triggering the individual
      # agenda-item controllers.
      @agendaItemTargets.forEach (target) =>
        target.dispatchEvent(new CustomEvent('agendaItemMoved', { detail: { date: @groupDate }}))
    
    else if event.type is "cocoonChildElementsChanged"
      # If the event is a cocoon change, there are no elements left, and this
      # group contains agenda items for a date that is no longer in the scope
      # of the event, hide it and unbind everything.
      if event.detail.currentVisibleElementCount is 0 and !@isValidEventDate
        @element.classList.add('hidden')
        @element.dataset.controller = "" # unbind everything