import { Controller } from "stimulus"

###
  Delegates a form (set data-controller on the form object) to use the
  deprecated App.Views.DirectPostFormView. Using class="direct-post-form" does
  not work with dynamically-loaded content (like a modal).
###
export default class extends Controller
  initialize: ->
    new App.Views.DirectPostFormView el: @element
