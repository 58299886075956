import { Controller } from "stimulus"

export default class extends Controller
  @values =
    mode: String
    # If you do not want this controller to bind to the typical change events, you can set
    # skipInitialize to truthy. In this case, you will need to catch a different event 
    # using Stimulus' syntax to fire the doSubmit method.
    skipInitialize: Boolean
    virtualForm: Boolean
    virtualFormUrl: String

  initialize: ->
    unless @skipInitializeValue
      if @modeValue is 'vanillajs'
        @element.addEventListener 'change', (event) => @doSubmit(event)
        @element.addEventListener 'typeahead:select', (event) => @doSubmit(event)

      else
        $(@element).on 'change typeahead:select', (event) => @doSubmit(event)

  doSubmit: (event) ->
    if @element.dataset.remote is "true"
      if @virtualFormValue
        # Create virtual form to submit back to the server
        virtualForm = document.createElement("form")

        # Set the properties Rails.fire will need to make
        # an RJS request of our virtual form.
        virtualForm.action = @virtualFormUrlValue
        virtualForm.dataset.remote = true

        # Copy the form fields that appear within the event.currentTarget. Specify
        # the form should be hidden
        virtualForm.insertAdjacentHTML('beforeend', event.currentTarget.innerHTML)
        virtualForm.hidden = true
        virtualForm.style.display = 'none';
        
        # Copying a form does not copy its selections. We'll iterate the fields in our copied
        # node and set the same answers on the virtual form.
        for element, index in event.currentTarget.querySelectorAll('input, select, textfield, textarea')
          virtualForm.querySelectorAll('input, select, textfield, textarea')[index].value = element.value

        # Append the virtual form
        document.body.appendChild(virtualForm)
        
        Rails.fire(virtualForm, 'submit')

        # Remove the virtual form
        virtualForm.remove()

      else
        Rails.fire(@element, 'submit')
    
    else
      @element.submit()