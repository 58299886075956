import { Controller } from "stimulus"
import _isEmpty from 'lodash/isEmpty'

export default class extends Controller
  @targets = ['body', 'footer', 'closing']
  
  initialize: ->
    @arrID = @data.get('id')
    @arrIsCompleted = @data.get('completed')
    @remodalInstance = $('.remodal').remodal
      modifier: 'remodal--flex'

    if @hasClosingTarget
      @_startClosingTimer()
    
    if @arrIsCompleted is "true"
      tableCellId = "admin-resource-review-tr-#{@arrID}-review"
      document.getElementById(tableCellId).innerHTML = '<strong class="text-success nowrap"><i class="fa fa-check"></i>Done</strong>'
    
    @render()
    
  disconnect: ->
    if @timer
      clearInterval(@timer)
  
  render: ->
    @bodyTarget.classList.toggle('remodal-body--no-footer', _isEmpty(@footerTarget.innerHTML))
  
  # refreshing() replaces the footer with a FA spinner and the text
  # "Refreshing...", and is designed to be used with ajax:beforeSend while a
  # remote request is happening.  If different text is desired, specify it on
  # the link or button that triggers this action as data-refreshing-text.
  refreshing: (event) ->
    refreshingMessage = "#{event.target.dataset["refreshingText"] || "Refreshing..."}"
    @footerTarget.innerHTML = "<h3 class='section-description text-center no-top-margin full-line-bottom-margin'><i class='fa fa-refresh fa-spin'></i> #{refreshingMessage}</h3>"
  
  # submit() should be used with ajax:success to refresh the modal's content
  # with the received content.
  submit: (event) ->
    [data, status, xhr] = event.detail
    @_setContent xhr.response
    @_open()
    
  _setContent: (content) ->
    @remodalInstance.$modal.html(content)
  
  _remodalState: ->
    @remodalInstance.getState()
    
  _open: ->
    unless @remodalInstance.getState() == 'opened'
      @remodalInstance.open()
  
  _startClosingTimer: ->
    @seconds = 5
    @closingTarget.innerHTML = @seconds
    
    @timer = setInterval =>
      @seconds--
      @closingTarget.innerHTML = @seconds
      if @seconds is 0
        @remodalInstance.close() unless @remodalInstance.getState() == 'closed'
        @disconnect()
    , 1000