import { Controller } from "stimulus"
import _isUndefined from 'lodash/isUndefined'

export default class extends Controller
  initialize: ->
    @modalInstance = $(@element).remodal()

    @modalInstance.$modal.on "closed", =>
      @modalInstance.destroy()

    @openImmediately = @data.get('openImmediately')
    @autoOpenAfterSeconds = @data.get('autoOpenAfterSeconds')
    @autoOpenTrackingUrl = @data.get('autoOpenTrackingUrl')
    @autoOpenLimit = @data.get('autoOpenLimit')
    @autoOpenedCount = @data.get('autoOpenedCount')

    @render()

  render: ->
    if @openImmediately
      @openModal()

    else if @autoOpenAfterSeconds
      if !_isUndefined(@autoOpenLimit) && !_isUndefined(@autoOpenedCount) && (@autoOpenedCount < @autoOpenLimit)
        setTimeout (=>
          # Don't auto-open a modal if there is already an open modal. This hi-jacks
          # whatever the user might've been doing and is annoying.
          return if $('.remodal.remodal-is-opened').length

          @openModal()

          # If the modal was opened via the `autoOpenAfterSeconds` property. Ping the given URL
          # to track the number of times this was opened.
          if @autoOpenTrackingUrl
            $.post @autoOpenTrackingUrl

        ), @autoOpenAfterSeconds * 1000

  openModal: (event) ->
    event.preventDefault() if event

    @modalInstance.open()

  closeModal: (event) ->
    event.preventDefault() if event

    @modalInstance.$modal.trigger('closed')