import { Controller } from "stimulus"

export default class extends Controller
  @targets: ['lookupPanel', 'searchButton', 'aacnCustomerIdField', 'emailField']
  
  @values =
    searchUrl: String
  
  toggleLookupPanel: (event) ->
    $(@lookupPanelTarget).slideToggle()

  doSearch: (event) ->
    @disableSearchButton()

    Rails.ajax
      url: @searchUrlValue
      type: 'GET'
      dataType: 'script'
      data: new URLSearchParams({ email: @emailFieldTarget.value }).toString()
      complete: =>
        @enableSearchButton()

  handleKeypress: (event) ->
    if event.keyCode == 13
      event.preventDefault()
      @doSearch()

  chooseMember: (event) ->
    event.preventDefault()
    @aacnCustomerIdFieldTarget.value = event.target.dataset['aacnMemberId']
    @aacnCustomerIdFieldTarget.classList.add('highlight-fade-confirm')
    $(@lookupPanelTarget).slideUp()

  disableSearchButton: ->
    @searchButtonTarget.innerHTML = 'Searching...'
    @searchButtonTarget.disabled = true

  enableSearchButton: ->
    @searchButtonTarget.innerHTML = 'Search'
    @searchButtonTarget.disabled = false