import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["salaryTypeField", "fixedSalaryFields", "rangeSalaryFields"]

  initialize: ->
    @showFieldsForType()

  showFieldsForType: ->
    if @salaryTypeFieldTarget.value == 'fixed'
      @showFixedSalaryFields()
    
    else
      @showRangeSalaryFields()

  showFixedSalaryFields: ->
    # Hide the range salary fields and disable nested inputs
    $(@rangeSalaryFieldsTarget) 
      .prop("disabled", true)
      .prop("hidden", true)

    # Show the fixed fields and disabled nested inputs
    $(@fixedSalaryFieldsTarget)
      .prop("disabled", false)
      .prop("hidden", false)

  showRangeSalaryFields: ->
    # Hide the fixed salary fields and disable nested inputs
    $(@fixedSalaryFieldsTarget)
      .prop("disabled", true)
      .prop("hidden", true)

    # Show the range fields and disabled nested inputs
    $(@rangeSalaryFieldsTarget)
      .prop("disabled", false)
      .prop("hidden", false)

  switchToSalaryRange: ->
    @salaryTypeFieldTarget.value = 'variable'
    @showFieldsForType()

  switchToSalaryFixed: ->
    @salaryTypeFieldTarget.value = 'fixed'
    @showFieldsForType()