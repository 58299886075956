import { Controller } from "stimulus"
# dependency: jQuery

export default class extends Controller
  @targets: ["sendPreviewButton", "messageField", "subjectField"]

  @property 'basePreviewContent',
    get: -> @data.get('basePreviewContent')

  @property 'message',
    get: ->
      if @hasMessageFieldTarget
        tinymce.get(@messageFieldTarget.id).getContent()
      else if @data.get('message') in [null, "null"]
        ""
      else
        @data.get('message')
    set: (value) -> @data.set('message', value)

  @property 'messagePreviewNotice',
    get: ->
      if @data.get('messagePreviewNotice')
        "
          <div class='alert alert-info'>
            #{ @data.get('messagePreviewNotice') }
          </div>
        "
      else
        ""

  @property 'previewContent',
    get: ->
      @_previewContent or= $("
        <div>
          <button data-remodal-action='close' class='remodal-close'></button>
          #{ @messagePreviewNotice }
          <iframe height='500' width='100%' style='border: 1px solid #f0f0f0;' />
        </div>
      ")

  @property 'previewContentIframe',
    get: -> @previewContent.find('iframe')

  @property 'sendPreviewUrl',
    get: -> @data.get('sendPreviewUrl')

  @property 'subject',
    get: ->
      if @hasSubjectFieldTarget
        @subjectFieldTarget.value
      else
        ""

  initialize: ->
    @sendPreviewButtonTargetInitialHtml = @sendPreviewButtonTarget.innerHTML

  updatePreviewContent: ->
    @previewContentIframe.prop(srcdoc: @basePreviewContent.replace('MESSAGE STUB', @message))

  sendPreview: ->
    @sendPreviewButtonTarget.classList.add('btn-disabled')
    @sendPreviewButtonTarget.setAttribute('disabled', 'disabled')

    request = $.ajax
      method: 'POST'
      url: @sendPreviewUrl
      data:
        message:
          subject: @subject
          message: @message

    request.then (response, status) =>
      if status == 'success'
        @sendPreviewButtonTarget.innerHTML = "
          <span>
            <i class='fa fa-check-circle'></i> Preview sent
          </span>
        "

        setTimeout(=>
          @sendPreviewButtonTarget.innerHTML = @sendPreviewButtonTargetInitialHtml
          @sendPreviewButtonTarget.classList.remove('btn-disabled')
          @sendPreviewButtonTarget.removeAttribute('disabled')
        , 3000)

  showPreview: ->
    @updatePreviewContent()

    # Build a modal instance using our confirm modal template
    remodalInstance = $(@previewContent).remodal()

    remodalInstance.$modal.on 'closed', (event) =>
      remodalInstance.destroy()

    # Finally show the modal
    remodalInstance.open()
