import { Controller } from "stimulus"
import _debounce from 'lodash/debounce'
import _filter from "lodash/filter"
import _flatMap from "lodash/flatMap"
import _map from "lodash/map"
import _sortBy from 'lodash/sortBy'
import _toUpper from "lodash/toUpper"
import _uniq from "lodash/uniq"
import Pickr from "@simonwep/pickr"
import { swatchColors } from "../shared/swatch_colors"

# IMPORTANT: Please use ColorPickerController, a proxy to this controller
export default class extends Controller
  @targets: ['button', 'textField']

  initialize: ->
    # Get the default value from the text field.  The Default color should be
    # white in the color picker if the selected value is transparent.
    defaultColor = @textFieldTarget.value
    defaultColor = if defaultColor is "transparent" then "white" else defaultColor
    
    # Init pickr.
    # @see https://github.com/Simonwep/pickr for configuration options
    pickr = Pickr.create
      el: @buttonTarget
      autoReposition: true
      default: defaultColor
      lockOpacity: true # force no alpha
      position: 'bottom-end'
      swatches: swatchColors()
      theme: 'monolith'
      components:
        palette: true
        preview: true
        opacity: false
        hue: true
        interaction:
          input: true # allow Hex entry in picker
          clear: true # allow clear button, used to set transparent
          save: true  # allow save button, used to set @textFieldTarget value
      strings:
        save: 'Set'
    
    # Add a keypress event listener to the corresponding text field.  This
    # allows a user to type a color ("green") or a hex color string (#00FF00)
    # directly and have the changes update on the color picker.
    @textFieldTarget.addEventListener 'keypress', _debounce =>
      pickr.setColor((if @textFieldTarget.value is "transparent" then "white" else @textFieldTarget.value), true)
      pickr.applyColor(true)
    , 500,
      leading: false
      trailing: true
    
    # When the color is cleared (set to transparent), set the text field and
    # dispatch a change event.  Ensure the text field is set to transparent
    # while the color swatch is set to white.
    pickr.on 'clear', (instance) =>
      instance.setColor('white')
      pickr.applyColor(true)
      @textFieldTarget.value = 'transparent'
      @textFieldTarget.dispatchEvent(new CustomEvent('change'))
    
    # When the color is saved from the color picker, update the text field
    # accordingly, dispatch a change event on the text field to notify any
    # event listeners, and close (hide) the picker.
    pickr.on 'save', (color, instance) =>
      if color is null or color is undefined
        @textFieldTarget.value = 'transparent'
      else
        @textFieldTarget.value = color.toHEXA().toString()
      
      @textFieldTarget.dispatchEvent(new CustomEvent('change'))
      instance.hide()