import { Controller } from "stimulus"
import _each from 'lodash/each'

export default class extends Controller
  @targets: [
    'toggleFieldsContainerCheckbox',
    'fieldsContainer',
      'videoChatServiceSelect',
        'videoChatServiceOtherFields',
      'stateRadioColumn',
        'stateRadio',
      'stateAlertColumn',
        'stateAlert',
          'statePrivacyDetailPublic',
          'statePrivacyDetailPrivate',
  ]

  initialize: ->
    @toggleFieldsContainer()
    @render()
    
  render: ->
    if @hasVideoChatServiceSelectTarget
      videoChatService = @videoChatServiceSelectTarget.selectedOptions[0].text
      @videoChatServiceOtherFieldsTarget.classList.toggle("hidden", videoChatService != "Other")
    
    if @hasStateAlertColumnTarget
      stateRadioValue = 'unpublished'
      
      _each @stateRadioTargets, (target) =>
        if target.checked
          stateRadioValue = target.value
        true # continue loop
      
      @stateAlertColumnTarget.classList.toggle('hidden', stateRadioValue == "unpublished")
      @stateAlertColumnTarget.classList.toggle('col-xs-12', stateRadioValue != "unpublished")
      @stateAlertColumnTarget.classList.toggle('col-sm-6', stateRadioValue != "unpublished")

      if @hasStateRadioColumnTarget
        @stateRadioColumnTarget.classList.toggle('col-sm-6', stateRadioValue != "unpublished")

      if @hasStateAlertTarget
        @stateAlertTarget.classList.toggle('hidden', stateRadioValue == "unpublished")
      
      if @hasStatePrivacyDetailPublicTarget
        @statePrivacyDetailPublicTarget.classList.toggle('hidden', stateRadioValue != "public")
      
      if @hasStatePrivacyDetailPrivateTarget
        @statePrivacyDetailPrivateTarget.classList.toggle('hidden', stateRadioValue != "private")
  
  toggleFieldsContainer: (event) ->
    @fieldsContainerTarget.classList.toggle('hidden', !@toggleFieldsContainerCheckboxTarget.checked)
  
  # When this group of fields is hidden by event-form-controller (by setting
  # the location to TBD), set the checkbox for #has_virtual_details? to an
  # unchecked state, and save the current checked state to data-was-checked.
  # This forces a form that is being saved without the option to have
  # virtual details with #has_virtual_details? == false.
  toggleFieldsetVisibility: (event) ->
    # Alias @toggleFieldsContainerCheckboxTarget to make code more legible
    checkbox = @toggleFieldsContainerCheckboxTarget
    
    if event.detail.hidden
      if !checkbox.dataset.wasChecked
        checkbox.dataset.wasChecked = "#{checkbox.checked}"
      checkbox.checked = false
    
    else
      if checkbox.dataset.wasChecked
        checkbox.checked = checkbox.dataset.wasChecked is 'true'
      checkbox.removeAttribute('data-was-checked')

    @toggleFieldsContainer()