import { Controller } from "stimulus"

#   Markup:
# 
#   %div{ data: { controller: "control-toggle" }} 
#     %input{ type: :radio, name: :dorp, data: { target: "control-toggle.control", action: "control-toggle#update", "control-toggle-toggle-container-target": "beep" } }
#     %input{ type: :radio, name: :dorp, data: { target: "control-toggle.control", action: "control-toggle#update", "control-toggle-toggle-container-target": "boop" } }
# 
#     %div{ data: { target: "control-toggle.container control-toggle.beep" }} I'm the beep
#     %div{ data: { target: "control-toggle.container control-toggle.boop" }} I'm the boop
#     
export default class extends Controller
  @targets: ["control", "container"]
  
  initialize: ->
    @update()

  update: ->
    # Hide all the containers initially
    for target in @containerTargets
      target.hidden = true

    # Initialize a collection of containers to show.
    showContainers = []

    # Collect the targets that should be visible
    for control in @controlTargets
      if containerTargetToShow = control.checked && control.dataset.controlToggleContainerTarget
        showContainers.push containerTargetToShow

    # Show the containers that should be shown
    if showContainers.length
      for container in showContainers
        if containerToShow = @targets.find(container)
          containerToShow.hidden = false