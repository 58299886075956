import { Controller } from "stimulus"
import _isEmpty from "lodash/isEmpty"
import _toString from "lodash/toString"
import _trim from "lodash/trim"
import _merge from "lodash/merge"

export default class extends Controller
  @targets: [
    "unsubscribedContent",
      "subscribeForm",
        "subscribeFormEmailField",
        "subscribeFormSubmit",
    "subscribedContent",
      "subscribedConfirmedContent",
      "query",
      "location",
      "locationNear",
      "locationElse",
      "updateForm",
        "radiusPicker",
      "deleteForm",
        "deleteFormSubmit",
  ]

  constructor: (data) ->
    Object.defineProperties @,
      'email':
        get: -> @data.get('email')
        set: (value) ->
          _.each @application.controllers, (controller) =>
            controller.emailLocal = value if controller.identifier is "search-subscription"
      
      'emailLocal':
        set: (value) ->
          @data.set('email', value)
          @setFieldAndValidateEmail() if @hasSubscribeFormEmailFieldTarget
      
      'highlightOnNextRender':
        get: ->
          retval = _toString(@data.get('highlight')) == 'true'
          @data.set('highlight', 'false')
          retval
        set: (value) ->
          @data.set('highlight', value)
      
      'actionOnSubmit':
        get: -> @data.get('onSubmit')
      
      'search':
        get: -> JSON.parse(@data.get('search'))
      
      'subscription':
        get: -> JSON.parse(@data.get('subscription'))
        set: (value) ->
          _.each @application.controllers, (controller) =>
            if controller.identifier == "search-subscription"
              controller.subscriptionLocal = JSON.stringify(value)
      
      'subscriptionLocal':
        set: (value) ->
          @data.set('subscription', value)
          if _toString(@data.get('onSubmit')) == 'closeModal'
            @closeClosestModal()
          else
            @render()
      
      'url':
        get: -> @data.get('url')
    
    super data

  initialize: ->
    @render()

  render: ->
    @subscribedContentTarget.hidden = !@subscription
    @unsubscribedContentTarget.hidden = !!@subscription
    
    if @subscription
      @subscribedConfirmedContentTarget.hidden = !@subscription.confirmed
      @queryTarget.hidden = _isEmpty(_trim(_toString(@subscription.query)))
      @queryTarget.textContent = @subscription.query
      @locationTarget.hidden = _isEmpty(_trim(_toString(@subscription.location)))
      @radiusPickerTarget.value = @subscription.radius if @hasRadiusPickerTarget
      App.Mixins.HighlightEffect.successHighlight($(@.element)) if @highlightOnNextRender

    else
      @setFieldAndValidateEmail() if @hasSubscribeFormEmailFieldTarget

  subscribe: (event) ->
    event.preventDefault() if event

    # Submit the form, creating the subscription.
    request = $.ajax
      url: @url
      method: 'POST'
      data: _merge(@search, { email: @email })

    request.then (response) =>
      @highlightOnNextRender = true
      @subscription = response

  closeClosestModal: ->
    modal = $(@.element).closest('[ref="modal"]')[0]
    controller = @application.getControllerForElementAndIdentifier(modal, 'modal')
    controller.closeModal()

  updateRadius: (event) ->
    event.preventDefault() if event

    request = $.ajax
      url: @subscription.update_url
      method: 'PATCH'
      data:
        radius: @radiusPickerTarget.value

    request.then (response) =>
      @highlightOnNextRender = true
      @subscription = response

  unsubscribe: (event) ->
    event.preventDefault() if event

    request = $.ajax
      url: @subscription.update_url
      method: 'DELETE'

    request.then (response) => @subscription = null

  setPropertyAndValidateEmail: (event) ->
    @email = event.target.value
    @validateEmail()

  setFieldAndValidateEmail: ->
    $(@subscribeFormEmailFieldTarget).val(@email)
    @validateEmail()

  validateEmail: ->
    if @isEmailValid()
      $(@subscribeFormSubmitTarget).removeAttr('disabled')
    else
      $(@subscribeFormSubmitTarget).attr('disabled', 'disabled')

  isEmailValid: ->
    !!_toString(@email).match(/^[A-Z0-9_\.&%\+\-']+@(?:[A-Z0-9\-]+\.)+(?:[A-Z]{2,13})$/gi)