import { Controller } from "stimulus"
import Cleave from 'cleave.js'
import _isNull from 'lodash/isNull'

###
  Simple controller using Cleave.js to handle numeric entry.
  
  @data
    *-allowNegativeValues - set to "true" to allow negative values.
      Defaults to false.
    *-wholePart - the number of numbers to the left of the decimal point to
      allow.
    *-decimalPart - the number of numbers to the right of the decimal point to
      allow.
    *-delimiter - the thousands delimiter.  Default is "," if not provided.
  
  If *-wholePart and *-decimalPart are both undefined, but the field has a
  maxlength attribute set:
  
    * The maxlength attribute's value will be used for numbers to the left
      of the decimal point
    * No numbers will be allowed to the right of the decimal point.
  
  @note
    The maxlength attribute will be removed from the field, but is useful
    to still define for fallback purposes.
  
  @example (new lines added for legibility)
    
    # This will allow entering a 5-digit, non-negative, non-decimal number
    = f.text_field :some_number_field, ..., maxlength: 5, data: {
      controller: 'number-field'
    }
    
    # This will allow entering a non-negative, {5}.{2} number
    = f.text_field :some_number_field, ..., maxlength: 9, data: {
      controller: 'number-field',
      number_field_whole_part: 5,
      number_field_decimal_part: 2
    }
    
    # This will allow entering a negative, 6-digit number without decimals or delimiters
    = f.text_field :some_number_field, ..., maxlength: 7, data: {
      controller: 'number-field',
      number_field_whole_part: 6,
      number_field_delimiter: '',
      number_field_allow_negative_values: 'true'
    }
  
###
export default class extends Controller
  @property 'allowNegativeValues',
    get: -> @data.get('allowNegativeValues') is 'true'
  
  @property 'wholePart',
    get: -> @data.get('wholePart') or null
  
  @property 'decimalPart',
    get: -> @data.get('decimalPart') or null
  
  @property 'delimiter',
    get: ->
      if _isNull(@data.get('delimiter')) then ',' else @data.get('delimiter')
  
  @property 'maxlength',
    get: ->
      try
        parseInt(@element.getAttribute('maxlength'))
      catch e
        null
  
  initialize: ->
    options =
      numeral: true
      numeralPositiveOnly: !@allowNegativeValues
      delimiter: @delimiter
    
    if !_isNull(@wholePart) and !_isNull(@decimalPart)
      # We have both scale values - use them
      options.numeralIntegerScale = @wholePart
      options.numeralDecimalScale = @decimalPart
    
    else if !_isNull(@wholePart)
      # We only have a scale value for numbers to the left of the decimal.
      # Set numeralDecimalScale to disallow decimals.
      options.numeralIntegerScale = @wholePart
      options.numeralDecimalScale = 0
    
    else if !_isNull(@decimalPart)
      # We only have a scale value for numbers to the right of the decimal.
      # Don't set numeralIntegerScale.
      options.numeralDecimalScale = @decimalPart
    
    else if !_isNull(@maxlength)
      # We have no set values, so fall back on the @element's maxlength
      # attribute if possible.
      # Set numeralDecimalScale to disallow decimals.
      options.numeralIntegerScale = @maxlength
      options.numeralDecimalScale = 0
    
    # Init Cleave
    @cleave = new Cleave @element, options
    
    # Once inited and everything is OK, remove the maxlength attribute since
    # it creates interference with Cleave's settings.
    @element.removeAttribute('maxlength')
