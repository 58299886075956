import { Controller } from "stimulus"

export default class extends Controller
  initialize: ->
    @tocVisible = false
  
  toggleToc: ->
    if @tocVisible
      @tocVisible = false
      @element.classList.remove('newsletter-viewer--show-nav')
    
    else
      @tocVisible = true
      @element.classList.add('newsletter-viewer--show-nav')

  handleTocClick: (event) ->
    @toggleToc()
