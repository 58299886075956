import { Controller } from "stimulus"
import _each from 'lodash/each'
import _includes from 'lodash/includes'
import _toLower from 'lodash/toLower'

###
  This controller takes the @watchScope (either a @controllerTarget, if
  specified, or the @element itself) and sets up event listeners to watch for
  form changes. If a change is detected, @alertTarget will be set to that
  target's data-watch-for-change-message value.  Ideally, where the
  @alertTarget is specified, it should have an :empty { display: none; } CSS
  attribute set.
  
  @optimize Figure out how to use _.debounce or _.throttle to limit the number
  of calls.  As of now (2019-09-11), I could not get this working properly (DC).
  
  @optimize Let this controller handle different scenarios based on different
  given targets, such as a watch-for-changes.disable target to disable submit
  buttons, or a data-watch-for-changes-onleave data attribute to prevent page
  change with an alert.
###
export default class extends Controller
  @targets = ['container', 'alert']
  
  initialize: ->
    @watchScope = if @hasContainerTarget then @containerTarget else @element
    
    _each ['input', 'textarea', 'select'], (tagName) =>
      _each @watchScope.getElementsByTagName(tagName), (tag) =>
        # use _toLower because tag types can be different cases
        tagType = _toLower(tag.type)
        
        # Ignore hidden fields as they emit no events
        unless tagType is 'hidden'
          # Only watch for keypress on text and textarea fields
          if _includes(['input', 'textarea'], tagName)
            # Watch for both a keypress and keyup.  Some browsers don't support
            # one, or either, so it's good to have both.  If browsers don't
            # support either, a change event listener is added later.
            tag.addEventListener 'keypress', => @_callbacks()
            tag.addEventListener 'keyup', => @_callbacks()

          # Listen for TinyMCE changes.
          if _includes(tag.dataset.controller, "tinymce")
            tag.addEventListener 'tinymceEditorKeyUp', => @_callbacks()

          # Listen to change on everything
          tag.addEventListener 'change', => @_callbacks()
    
    # Trigger click callbacks on buttons and anchor links that perform Stimulus
    # actions
    _each ['a', 'button'], (tagName) =>
      _each @watchScope.getElementsByTagName(tagName), (tag) =>
        dataset = tag.dataset
        
        if dataset.action isnt undefined
          # A link with a stimulus action attached will likely modify something.
          # Skip the watcher by setting data-skip-watch-for-change="true"
          unless dataset.skipWatchForChange is "true"
            tag.addEventListener 'click', => @_callbacks()

    # Instead of #addEventListener, use $().on() to watch for changes on any
    # element that has data-watch-for-changes="1". jQuery's events are different
    # than native events and #addEventListener won't always catch these, such
    # as with bootstrap-toggle.
    _each @element.querySelectorAll('[data-watch-for-changes="1"]'), (target) =>
      $(target).on 'change', =>
        @_callbacks()

    # Watch for changes with Cocoon's insert/remove
    $(@watchScope).on 'cocoon:after-insert', => @_callbacks()
    $(@watchScope).on 'cocoon:after-remove', => @_callbacks()

    # Watch for dragging elements on the page
    @watchScope.addEventListener 'dragend', => @_callbacks()

  _callbacks: ->
    if @hasAlertTarget
      if @alertTarget.classList.contains('hidden')
        @alertTarget.classList.remove('hidden')
      @alertTarget.innerHTML = @alertTarget.dataset.watchForChangeMessage