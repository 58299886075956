import { Controller } from "stimulus"

export default class extends Controller
  none: ->
    _.each @targets.findAll('noneField'), (field) ->
      field.checked = true

  includeAll: ->
    _.each @targets.findAll('includeField'), (field) ->
      field.checked = true

  requireAll: ->
    _.each @targets.findAll('requireField'), (field) ->
      field.checked = true