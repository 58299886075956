import { Controller } from "stimulus"
import _isEmpty from 'lodash/isEmpty'
import Cleave from 'cleave.js'

###
  Handles the intricacies of a time field.
  For more details and usage,
  @see views/shared/application/partials/_time_field.haml.
###
export default class extends Controller
  @targets: ['field', 'ampmField', 'hiddenField']
  
  # Since time fields aren't often used and can cause a lot of issues,
  # a data-*-date attribute should be set to fill the full datetime stamp
  @property 'date',
    get: -> @data.get('date')
  
  initialize: ->
    # Set up the AM/PM selector and the time field with the proper values.
    # Doing this from the Rails view leads to all kinds of time zone issues,
    # particularly when using Controller::EventTimeZoneSupport.
    if !!@hiddenFieldTarget.value
      persistedDate = new Date(@hiddenFieldTarget.value)
      @ampmFieldTarget.value = if @_dateIsAm(persistedDate) then 'AM' else 'PM'
      @fieldTarget.value = @_dateAs12HourTimeString(persistedDate)

    @cleave = new Cleave @fieldTarget,
      time: true
      timePattern: ['h', 'm']
      timeFormat: '12'
  
  # Small fix to Cleave that, when entering a time, allows the user to enter
  # "1:30" instead of "01:30".  Otherwise, typing 1 assumes the user is waiting
  # for a 0, 1, or 2 to complete 10:, 11:, or 12:, and Cleave doesn't respond
  # normally to ":".
  semicolonFix: (event) ->
    if event.code is "Semicolon" and @cleave.getRawValue() is "1"
      @cleave.setRawValue('01')
    true # required; omitting this stops event bubbling to Cleave
  
  # If a completed value is reported by Cleave and we can compute a full date
  # string, set the value of the hidden field and remove any errors.  Otherwise,
  # nullify the hidden field and add an error.
  update: (event) ->
    if @_formattedDateString()
      @hiddenFieldTarget.value = @_formattedDateString()
      @element.classList.remove('field_with_errors')
    else
      @hiddenFieldTarget.value = ""
      @element.classList.add('field_with_errors')
  
  # Trigger this action with a CustomEvent having detail: { date: "%Y-%m-%d" }
  # set, and all fields will be updated with the new date.  Useful for when
  # you may have sortable targets for different dates.
  updateWithNewDate: (event) ->
    @data.set('date', event.detail.date)
    @update()

  # Is the time value valid and complete?
  _isCleaveTimeComplete: ->
    !!@cleave.getFormattedValue().match(/(0?[1-9]|1[0-2]):[0-5][0-9]$/)
  
  # The complete date string - date + formatted time string
  _formattedDateString: ->
    if @_isCleaveTimeComplete()
      "#{ @date } #{ @_formattedTimeString(@cleave.getFormattedValue(), @ampmFieldTarget.value) }:00"
    else
      null
  
  # The formatted time string
  _formattedTimeString: (timeString, amOrPm) ->
    [hours, minutes, _] = timeString.split(':')
    hoursInt = parseInt(hours)
    
    if amOrPm is 'AM'
      hoursInt = 0 if hoursInt is 12 # 12 am == 00:00
    else # amOrPm is 'PM'
      if hoursInt isnt 12 # 12 pm == 12:00
        hoursInt += 12
    
    "#{ @_withLeadingZero(hoursInt) }:#{ @_withLeadingZero(minutes) }"
  
  # Converts a date string to a 12 hour time string
  _dateAs12HourTimeString: (date) ->
    if date.getHours() is 0
      "12#{ @_withLeadingZero(date.getMinutes()) }"
    
    else if date.getHours() > 12
      "#{ @_withLeadingZero(date.getHours() - 12) }#{ @_withLeadingZero(date.getMinutes()) }"
    
    else
      "#{ @_withLeadingZero(date.getHours()) }#{ @_withLeadingZero(date.getMinutes()) }"
  
  # Is the time in the AM?
  _dateIsAm: (date) ->
    date.getHours() < 12
  
  # Pads time segments with a leading 0; otherwise, a time like 9:05 AM would
  # result in "9:5" instead of "09:05"
  _withLeadingZero: (hourInteger) ->
    "#{ if hourInteger < 10 then '0' else "" }#{ String(hourInteger) }"