import { Controller } from "stimulus"
import _each from 'lodash/each'

export default class extends Controller
  @targets = ['reviewLink']
  
  initialize: ->
    @remodalInstance = $('<div></div>').remodal
      modifier: 'remodal--flex'
    @remodalInstance.$modal.on 'closed', (event) =>
      # Don't destroy - leave it open but set content to nil
      @_setContent('')

    @remodalInstance.$modal.on 'click', '.btn-close', (event) =>
      @remodalInstance.close()
    
    @openModalId = @data.get('open-modal-id')
    
    # If open-modal-id == the ID value of a link, open it immediately (via
    # click()).  This allows us to retain links in emails to edit pages, as
    # well as retaining the modal process and its actions.
    _each @reviewLinkTargets, (element, index) =>
      if element.dataset["id"] == @openModalId
        element.click()
        return
  
  initModal: ->
    @_setContent '<h3 class="section-description"><i class="fa fa-refresh fa-spin"></i> Loading...</h3>'
    @_open()
  
  openModal: (event) ->
    [data, status, xhr] = event.detail
    @_setContent xhr.response
    @_open()
  
  _setContent: (content) ->
    @remodalInstance.$modal.html(content)
  
  _open: ->
    unless @remodalInstance.getState() == 'opened'
      @remodalInstance.open()