import { Controller } from "stimulus"

export default class extends Controller
  @targets: ['budgetLabel', 'budgetLevelField']

  updateLevels: (event) ->
    event.stopImmediatePropagation()

    for budgetLabel, index in @budgetLabelTargets
      budgetLabel.innerHTML = index + 1

    for budgetLevelField, index in @budgetLevelFieldTargets
      budgetLevelField.value = index + 1