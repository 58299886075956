import { Controller } from "stimulus"
import _each from 'lodash/each'

# Adds sticky headings, useful for long pages with lots of content, to keep
# track of your current location on the page.
#
# This version uses %fieldset, and assumes each target item is a
# .fieldset-description.  Each .fieldset-description will have the
# .fieldset-description--sticky class added to it, which may muck up some
# formatting, so be careful.
#
# When scrolling down the page, once the sticky description/heading reaches
# the top, the .fieldset-description--sticky--is-stuck class will be added,
# adding additional styles to make it stand out on the page and not get lost
# in subcontent.
#
# This will only work on Chrome and Safari, so it is ideal for use in the
# Admin, but use caution when using on other parts of the site.  This is because
# position: sticky is not supported on IE/Edge fully, and has mixed support
# in Firefox.
#
export default class extends Controller
  @targets: ['stickyFieldsetDescription', 'rightOption']
  
  stickyDefaultClass: 'fieldset-description--sticky'
  stickyStuckClass: 'fieldset-description--sticky--is-stuck'
  
  initialize: ->
    # If the browser supports position: sticky or position: -webkit-sticky,
    # we can do this.  If not, this controller will literally do nothing.
    if CSS.supports
      if CSS.supports('position', 'sticky') or CSS.supports('position', '-webkit-sticky')
        # Configure this as a Promise on DOMContentLoaded. Large page loads,
        # such as the group edit page, don't always load all targets by the
        # time this executes.
        readyPromise = new Promise (resolve, reject) =>
          if document.readyState == 'loading'
            document.addEventListener 'DOMContentLoaded', resolve, { once: true }
          else
            resolve()
        
        readyPromise.then => @_initStickyFieldsetDescriptions()
  
  # Init will go through each @stickyFieldsetDescriptionTarget, trigger the
  # @_toggleIsStuck() function, and add an event listener on scroll for
  # the window.
  #
  # If content is provided for @rightOptionTarget (called so as this would
  # usually be a right-side button), it will be copied and appended to the
  # start of every .fieldset-description.  This can be used to add a Bootstrap
  # dropdown with anchor links to all the sections on the page.  This should
  # be floated right.
  _initStickyFieldsetDescriptions: ->
    _each @stickyFieldsetDescriptionTargets, (target) =>
      target.classList.add(@stickyDefaultClass)
      if @hasRightOptionTarget
        target.insertAdjacentHTML 'afterBegin', @rightOptionTarget.outerHTML
    
      @_toggleIsStuck(target)
    
      window.addEventListener 'scroll', => @_toggleIsStuck(target)
  
  # This calculation simply finds if the target in question is actually at the
  # top of the page.  The @stickyStuckClass will be added if it is.
  _toggleIsStuck: (target) ->
    currentOffset = target.getBoundingClientRect().top
    stickyOffset = parseInt(getComputedStyle(target).top.replace('px',''))
    # Using Math.floor here, as it is possible to see 0.75px - not ideal
    isStuck = Math.floor(currentOffset) <= Math.floor(stickyOffset)
    
    target.classList.toggle(@stickyStuckClass, isStuck)