import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["bestContactPhoneFields", "bestContactEmailFields"]

  @property 'bestContactMethod',
    get: ->
      @data.get('bestContactMethod')

    set: (bestContactMethod) ->
      @data.set('bestContactMethod', bestContactMethod)
      @update()

  initialize: ->
    @update()

  bestContactMethodChanged: (event) ->
    @bestContactMethod = event.target.value

  update: ->
    switch @bestContactMethod
      when 'phone'
        @bestContactPhoneFieldsTarget.hidden = false
        @bestContactPhoneFieldsTarget.disabled = false

        @bestContactEmailFieldsTarget.hidden = true
        @bestContactEmailFieldsTarget.disabled = true

      when 'email'
        @bestContactPhoneFieldsTarget.hidden = true
        @bestContactPhoneFieldsTarget.disabled = true

        @bestContactEmailFieldsTarget.hidden = false
        @bestContactEmailFieldsTarget.disabled = false

      else
        @bestContactPhoneFieldsTarget.hidden = true
        @bestContactPhoneFieldsTarget.disabled = true

        @bestContactEmailFieldsTarget.hidden = true
        @bestContactEmailFieldsTarget.disabled = true