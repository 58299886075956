import { Controller } from 'stimulus'

# Copies values between two sets of address fields (or more) on the same page.
#
# Each address field must have a target defined (see @targets for supported
# fields) as well as data-identifier, which is an arbitrary string value to
# identify a set of address fields. For example, if you have two sets of
# address fields - one for a mailing address, and one for a billing address -
# the street address fields might look something like:
#
# = f.text_field :mailing_street_address, class: 'form-control', maxlength: 255,
#   data: { copy_address_target: 'streetAddress', identifier: 'mailing' }
#
# = f.text_field :billing_street_address, class: 'form-control', maxlength: 255,
#   data: { copy_address_target: 'streetAddress', identifier: 'billing' }
#
# Amending a button that calls the @_copy() function requires additional data
# attributes as well: data-from and data-to. These reference the identifiers
# mentioned above. If you want a button that copies the billing address to
# the mailing address, you'd use something like:
#
# %button{ type: :button,
#   data: { action: 'copy-address#copy', from: 'billing', to: 'mailing' }}
#   Copy billing to mailing
#
# And, when click, will copy all billing address fields to the mailing address
# fields appropriately.
#
# Adding additional sets of addresses (beyond two) is as simple as giving
# them unique identifiers.
#
# Of note: this uses the value of one field and sets it to the value of
# another. Any differences between fields (such as using a SELECT with
# different values) may result in this not working as expected.
#
export default class extends Controller
  @targets: ['name', 'streetAddress', 'city', 'state', 'zip']

  copy: (event) ->
    from = event.currentTarget.dataset.from
    to = event.currentTarget.dataset.to

    return unless from and to

    @_performCopy(@nameTargets, from, to)
    @_performCopy(@streetAddressTargets, from, to)
    @_performCopy(@cityTargets, from, to)
    @_performCopy(@stateTargets, from, to)
    @_performCopy(@zipTargets, from, to)

  _performCopy: (targets, from, to) ->
    fromTarget = targets.find((target) => target.dataset.identifier is from)
    toTarget = targets.find((target) => target.dataset.identifier is to)

    return unless fromTarget and toTarget

    toTarget.value = fromTarget.value

    $(toTarget).effect("highlight", {}, 1000)