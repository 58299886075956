import { Controller } from "stimulus"
import _debounce from 'lodash/debounce'
import _toLower from 'lodash/toLower'

export default class extends Controller
  @targets: ["searchField", "searchContainer"]
  
  @property 'filteredSearch',
    get: -> _toLower(@searchFieldTarget.value.replace(/[^a-zA-Z@\d]/g, ''))
  
  initialize: ->
    @searchContainerTargets.forEach (target) ->
      target.dataset.searchContent = _toLower(target.innerText.replace(/[^a-zA-Z@\d]/g, ''))
    
    # debounce @render so inline search won't go crazy
    @render = _debounce(@render, 50).bind(@)
    
    if @element.classList.contains('dropdown')
      $(@element).on 'shown.bs.dropdown', (event) => @focus()
  
  render: (event) ->
    if @filteredSearch.length < 2
      @searchContainerTargets.forEach (target) ->
        target.classList.remove('hidden')
    
    else
      @searchContainerTargets.forEach (target) =>
        target.classList.toggle(
          'hidden',
          !target.dataset.searchContent.includes(@filteredSearch)
        )
  
  focus: (event) ->
    @searchFieldTarget.focus()