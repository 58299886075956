import { Controller } from "stimulus"

export default class extends Controller
  @targets: ['tierPricePointRenewalFieldset']

  initialize: ->
    @tierId = @data.get('tierId')
    @tierPricePointId = @data.get('tierPricePointId')
    @tierRequiresAttachment = @data.get('tierRequiresAttachment') == 'true'
    @tierHasAttachment = @data.get('tierHasAttachment') == 'true'

    if @data.get('isCurrentTier') == 'true'
      @dispatchTierInfo()

    @render()

  tierPricePointIdChanged: (event) ->
    @tierPricePointId = event.target.value
    @dispatchTierInfo()
    @render()

  reviewAttachmentChanged: (event) ->
    @tierHasAttachment = event.target.files.length > 0
    @dispatchTierInfo()

  selected: (event) ->
    @dispatchTierInfo()

  dispatchTierInfo: ->
    @element.dispatchEvent(
      new CustomEvent("tierChanged", {
        bubbles: true
        detail:
          tierId: @tierId
          tierPricePointId: @tierPricePointId
          tierRequiresAttachment: @tierRequiresAttachment
          tierHasAttachment: @tierHasAttachment
        }
      )
    )

  hideAllTierPricePointRenewalFieldsets: ->
    for tierPricePointRenewalFieldset in @tierPricePointRenewalFieldsetTargets
      tierPricePointRenewalFieldset.disabled = true
      tierPricePointRenewalFieldset.hidden = true

  render: ->
    @hideAllTierPricePointRenewalFieldsets()

    renewalFieldsetToShow = @tierPricePointId && @targets.find("tierPricePoint#{@tierPricePointId}RenewalFieldset")
    if renewalFieldsetToShow
      renewalFieldsetToShow.disabled = false
      renewalFieldsetToShow.hidden = false
