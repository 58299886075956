import { Controller } from "stimulus"

export default class extends Controller
  @targets: ["recipientKey", "emailListId", "statView"]

  @property 'statUrl',
    get: ->
      @data.get('statUrl')

  @property 'recipientKey',
    get: ->
      for field in @recipientKeyTargets
        return field.value if field.checked

  @property 'currentEmailListId',
    get: ->
      for field in @emailListIdTargets
        return field.value if field.checked

  update: 
    _.debounce(
      ->
        @statViewTarget.innerHTML = "<div class='full-line-bottom-margin'><i class='fa fa-refresh fa-spin'></i> Loading segment stats...</div>"

        request = $.ajax
          url: @statUrl
          method: 'GET'
          data:
            segment:
              recipient_key: @recipientKey
              email_list_id: @currentEmailListId

        request.then (response) =>
          @statViewTarget.innerHTML = response.html

    , 500)