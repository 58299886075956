import { Controller } from "stimulus"

export default class extends Controller
  @property 'noThanksUrl',
    get: ->
      @data.get('noThanksUrl')

  handleClick: (event) ->
    request = $.ajax
      url: @noThanksUrl
      method: 'POST'

    request.then =>
      # If this event took place from inside an opened modal, close the modal.
      $(@element).parents('.remodal.remodal-is-opened').remodal().close()
