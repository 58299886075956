import { Controller } from "stimulus"

export default class extends Controller
  @targets: [
    "customWallpaperFields",
    "xsWallpaperLengthOptions"
  ]

  constructor: (data) ->
    Object.defineProperties @,

      'useCustomWallpaper':
        get: -> @data.get('useCustomWallpaper') == 'true'
        set: (value) -> @data.set('useCustomWallpaper', value)

      'xsWallpaperSize':
        get: -> @data.get('xsWallpaperSize')
        set: (value) -> @data.set('xsWallpaperSize', value)

    super data

  initialize: ->
    @render()

  toggleUse: (event) ->
    @useCustomWallpaper = event.target.value
    @render()

  wallpaperSizeChanged: (event) ->
    @xsWallpaperSize = event.target.value
    @render()

  render: ->
    @customWallpaperFieldsTarget.disabled = !@useCustomWallpaper
    @customWallpaperFieldsTarget.hidden = !@useCustomWallpaper

    @xsWallpaperLengthOptionsTarget.hidden = @xsWallpaperSize != 'length'